import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
// import type { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft, faArrowsAltH, faArrowsAltV, faBan, faBars, faCalendar, faCalendarAlt, faCaretDown, faCaretUp, faCheck, faCircle, faClipboard, faClock, faColumns, faCopy, faEdit, faExclamationTriangle, faExternalLinkAlt, faFileExport, faFilter, faInfinity, faLightbulb, faLink, faMinus, faNetworkWired, faPlus, faQuestionCircle, faSearch, faShieldAlt, faSpinner, faStar, faSync, faTimes, faTrash, faWindowClose, IconDefinition } from '@fortawesome/free-solid-svg-icons';

export enum OrbiIconType {
    faAngleLeft = "faAngleLeft",
    faArrowsAltV = "faArrowsAltV",
    faArrowsAltH = "faArrowsAltH",
    faBan="faBan",
    faBars="faBars",
    faCalendar = "faCalendar",
    faCalendarAlt = "faCalendarAlt",
    faCaretDown = "faCaretDown",
    faCaretUp = "faCaretUp",
    faCheck = "faCheck",
    faCircle = "faCircle",
    faClipboard = "faClipboard",
    faClock = "faClock",
    faCopy = "faCopy",
    faColumns = "faColumns",
    faEdit = "faEdit",
    faExternalLink = "faExternalLinkAlt",
    faExclamationTriangle = "faExclamationTriangle",
    faFileExport = "faFileExport",
    faFilter = "faFilter",
    faInfinity = "faInfinity",
    faLightbulb = "faLightbulb",
    faLink = "faLink",
    faMinus="faMinus",
    faNetworkWired = "faNetworkWired",
    faPlus = "faPlus",
    faQuestionCircle = "faQuestionCircle",
    faSearch = "faSearch",
    faShieldAlt="faShieldAlt",
    faSpinner="faSpinner",
    faStar = "faStar",
    faSync = "faSync", 
    faTimes = "faTimes",
    faTrash = "faTrash",

    faWindowClose = "faWindowClose",
};

@Component({
  selector: 'app-orbiicon',
  templateUrl: './orbiicon.component.html',
  styleUrls: ['./orbiicon.component.scss']
})
export class OrbiiconComponent implements OnInit {
    faAngleLeft = faAngleLeft;
    faArrowsAltV = faArrowsAltV;
    faArrowsAltH = faArrowsAltH;
    faBan=faBan;
    faBars=faBars;
    faCalendar = faCalendar;
    faCalendarAlt = faCalendarAlt;
    faCaretDown = faCaretDown;
    faCaretUp = faCaretUp;
    faCheck = faCheck;
    faCircle = faCircle;
    faClipboard = faClipboard;
    faClock = faClock;
    faCopy = faCopy;
    faColumns = faColumns;
    faEdit = faEdit;
    faExternalLink = faExternalLinkAlt;
    faExclamationTriangle = faExclamationTriangle;
    faFileExport = faFileExport;
    faFilter = faFilter;
    faInfinity = faInfinity;
    faLightbulb = faLightbulb;
    faLink = faLink;
    faMinus = faMinus;
    faNetworkWired = faNetworkWired;
    faPlus = faPlus;
    faQuestionCircle = faQuestionCircle;
    faSearch = faSearch;
    faSpinner = faSpinner;
    faStar = faStar;
    faSync = faSync;    
    faShieldAlt = faShieldAlt;
    faTimes = faTimes;
    faTrash = faTrash;
    faWindowClose = faWindowClose;
    

    @Input() iconName! : string; 
    @Input() title! : string;
    @Input() cssClass! : string; 
    public myIcon! : IconDefinition;
    public myTitle! : string;
    private x : number = 1;

    constructor() { }

    ngOnInit(): void {
        ++ this.x;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.myIcon = this.faExclamationTriangle;
        for (const propName in changes) 
        {
            const changedProp = changes[propName];
            const to = changedProp.currentValue;
            switch (propName) 
            {
                case "iconName":
                    if (this.hasOwnProperty(to)) {
                        //@ts-ignore
                        this.myIcon = <IconDefinition>(this[to]);
                    } else {
                        console.warn(`OrbiiconComponent unknown icon enum ${to}`);
                    }      
                    break;

                case "title":
                    if (to)
                    {
                        this.myTitle = to;
                    }
                    break;

                case "cssClass":
                    if (to)
                    {
                        this.cssClass = to;
                    }
                    break;
                    /*
                default:
                    OanDebugutils.debuggerWrapper(".?."); */
                // case "title":
                    // this.myTooltip = to;
            }
        }
    }

      ngAfterContentInit()	{
        ++ this.x;
      }

      ngAfterContentChecked()	 {
        ++ this.x;
      }

      ngAfterViewInit()	 {
        ++ this.x;
      }
}
