<div class="container-fluid px-0">

    <!-- NAVIGATION -->
  
    <nav class="orbi-main-nav main-nav navbar navbar-expand-lg px-3">
      <a class="navbar-brand" href="#">
        <img [src]="_uiState.logoUrl" alt="Orbitrust logo">
      </a>
  
      <!-- see how bootstrap nav bar drop down is supposed to work 
        https://getbootstrap.com/docs/5.0/components/navbar/
        -->
      <button class="navbar-toggler" type="button">
        <span class="navbar-toggler-icon">
            <app-orbiicon class="ml-2" iconName="faBars"></app-orbiicon>
        </span>
      </button>
  
      <div class="collapse navbar-collapse">
        
        <ul class="navbar-nav ml-auto">
            <li  class="nav-item">
                <!-- <div class="col warning"> -->
                <div>
                    <div *ngIf="_uiState.uiFlags.amILoggedIn" ngbDropdown class="d-inline-block nav-profile" >
                        <button class="btn btn-lightX " id="dropdownFormOrgSelector"  ngbDropdownToggle><fa-icon [icon]="['fas', 'user']"></fa-icon>Selected GitHub resource: {{_uiState.orgName}}</button>                    
                        <div class="dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownFormOrgSelector">
                        <button *ngFor="let frt of _uiState.orgDropDownPossibleValues"  ngbDropdownItem (click)="_uiState.orgNameUpdate($event, frt)">
                                <span *ngIf="!frt.targetObjAccessible">Sign-Up </span>
                                <span>{{frt.isUserResource?'GitHubUser':'GitHubOrg'}} </span>
                                <b>{{frt.targetObj}}</b>
                            </button>
                        </div>
                    </div>
                </div>
            </li> 

            <li class="nav-item">              
                <a [ngClass]="{'selected':'/mwgithub' == this.activeRouteUrl}" class="nav-link" routerlink="/mwgithub" ng-reflect-router-link="/mwgithub" href="/mwgithub">
                    <img src="../assets/img/github-brands.svg" alt="">
                    <!-- <fa-icon [icon]="faCog"></fa-icon> -->
                    GitHub reports</a>
            </li>

            <li class="nav-item">              
                <a [ngClass]="{'selected':'/mwgithubmetrics' == this.activeRouteUrl}" class="nav-link" routerlink="/mwgithubmetrics" ng-reflect-router-link="/mwgithubmetrics" href="/mwgithubmetrics">
                    <img src="../assets/img/github-brands.svg" alt="">
                    <!-- <fa-icon [icon]="faCog"></fa-icon> -->
                    GitHub metrics</a>
            </li>

            <li *ngIf="_uiState.isLocalMode"  class="nav-item">
                <a [ngClass]="{'selected':'/orgsettings' == this.activeRouteUrl}" class="nav-link" [routerLink]="'/orgsettings'"><fa-icon [icon]="faCog"></fa-icon>Settings</a>
            </li> 
            
            <li class="nav-item">  
                <a class="nav-link" (click)="_uiState.uiFlags.showSideOverlay = !_uiState.uiFlags.showSideOverlay"><fa-icon [icon]="faQuestion"></fa-icon>Help</a>
            </li> 

            <li class="nav-item">
                <!-- <div class="row"> -->
                    <div *ngIf="_uiState.uiFlags.amILoggedIn; then navbar_logged_in else navbar_not_logged_in">                 </div>
                        <ng-template #navbar_logged_in>
                            <!-- <div class="col"> -->
                                <div *ngIf="_uiState.uiFlags.amILoggedIn" ngbDropdown class="d-inline-block nav-profile" >
                                    <button class="btn btn-lightx" id="dropdownForm1" ngbDropdownToggle><fa-icon [icon]="['fas', 'user']"></fa-icon> Hello {{ this._uiState.uiFlags.amILoggedInName }}</button>                    
                                    <div class="dropdown-menu-right user-nav-item" ngbDropdownMenu aria-labelledby="dropdownForm1">
                                        <button ngbDropdownItem [routerLink]="'/profile'">Profile</button>
                                        <button ngbDropdownItem (click)="onLogout($event)">Sign Out</button>
                                        <button ngbDropdownItem>New around here? Sign up</button>
                                        <button ngbDropdownItem>Forgot password?</button>
                                    </div>
                                </div>
                            <!-- </div> -->
                        </ng-template>
                        <ng-template #navbar_not_logged_in>
                          <!--
                            DC 2022M12 This is hidden because it's confusing; if the user is not logged in why should they have a 'sign-in' link
                            that makes the isgn-in disappear
                            <a class="nav-link" (click)="_uiState.panelSignin.isCollapsed = !_uiState.panelSignin.isCollapsed">Sign In <fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon></a>
                          -->
                        </ng-template>
                <!-- </div> -->
            </li>
            
        </ul>
      </div>
     
    </nav>
  
    
  <!-- NAVIGATION -->
    <div class="jumbotron signin-container" 
      *ngIf="! _uiState.uiFlags.amILoggedIn && ! _uiState.uiFlags.signupProcessStep ">
        <div class="row">
          <div class="col-12 col-lg-8 mx-auto">
            <div class="text-center">
              <div class="img-holder">
                <img alt="Orbitrust logo" src="../assets/img/gitrospect-logo.svg">
              </div>
              <h2 class="orbi-section-title">GitH GitRospect</h2>
              <p>You are not currently Signed-In</p>

              <div class="access-panel">
                <div class="tile sign-up">
                  <h2>Sign-Up</h2>
                  <ul>
                    <li>Installed Gitrospect in your GitHub organization, or Github user account.</li>
                    <li>Construct a view that helps you manage compliance, posture management and so on.</li>
                  </ul>
                  <div class="button-holder">
                    <button *ngIf="_uiState.showSignupButton" class="btn btn-primary" (click)="onSignupStep0()">Sign-up</button>    
                  </div>
                </div>
                <div class="tile sign-in">
                  <h2>Sign-In</h2>
                  <ul>
                    <li>Use Gitrospect to view organizations where the organization member has already signed up and given you permissions.</li>
                    <li>Gitrospect uses your GitHub delegation to view organizations permissions</li>
                    <li>You do not need to 'Sign-up' for Gitrospect to Sign-In</li>
                  </ul>
                  <div class="button-holder">
                    <button class="btn btn-primary" (click)="onSigninGeneral()">Sign-in</button>    
                  </div>
                </div>
              </div>



              </div>
            </div>
        </div>        
    </div>
  
    <!-- this is where errors appear -->
    <app-notification-pagealert *ngIf="cmpNotificationsEvents.showNotAGithubOrgWarning">
        <ngb-alert type="info" (closed)="cmpNotificationsEvents.dismiss($event)">
            <h4 class="summary">GitHub user context</h4>
            <p>The current selected GitHub context '{{_uiState.orgName}}' is a GitHub user, but not a GitHub organization.  GitRospect provides most benefits when used with a github organization so there is visibility across different users, repos and other resources.</p>
            <p *ngIf="0 < _uiState?.userInfoAccessibleOrgs?.length">GitRospect is installed for <span *ngFor="let currAccessibleOrg of _uiState.userInfoAccessibleOrgs"><a (click)="_uiState.orgNameUpdate($event, currAccessibleOrg.targetObject)">{{currAccessibleOrg.targetObject}}</a> | </span></p>
            <div *ngIf="0 == _uiState.userInfoOrgsWithoutAccess.length">
                <p>The GitHub user '{{_uiState.orgName}}' is not a public member of any GitHub organizations.</p>
            </div>
            <div *ngIf="0 < _uiState.userInfoOrgsWithoutAccess.length">
                <p>To install GitRospect on an additional organization, please follow these steps:</p>
                <ol>
                    <li>sign-in to your github organization <a href="https://www.github.com/login" target="_blank"><app-orbiicon class="ml-2" iconName="faExternalLink"></app-orbiicon>github.com</a></li> 
                    <li>choose the organization for which you want to install: <span *ngFor="let currUserOrg of _uiState.userInfoOrgsWithoutAccess"><a (click)="_uiState.orgNameInstall($event, currUserOrg)">{{currUserOrg}}</a> </span> </li>
                </ol>
            </div>
            <div>
                <p>To install GitRospect on a private organization, an organization owner can select the organization on the following screen. <a (click)="_uiState.orgNameInstall($event, undefined)">Install</a></p>
            </div>
            <div>
                <button (click)="cmpNotificationsEvents.dismiss($event)" class="btn btn-primary">Dismiss</button>
            </div>
        </ngb-alert>
    </app-notification-pagealert>


      <app-notification-pagealert>
          <p  *ngFor="let alertObj of svcNotifier.pageAlerts | keyvalue"> 
            <ngb-alert   [type]="alertObj.value.type" (closed)="svcNotifier.removePagealert(alertObj.value)">{{ alertObj.value.text }}</ngb-alert>
        </p>
      </app-notification-pagealert>
  
      <app-notification-toast aria-live="polite" aria-atomic="true" class="toast-container position-absolute top-0 end-0 p-3">
        <ngb-toast
            *ngFor="let toast of svcNotifier.toasts | keyvalue"
            [header]="toast.value.header" [autohide]="false" 
            [class]="toast.value.classname"
            (hide)="svcNotifier.removeToast(toast.value)"
            (click)="svcNotifier.removeToast(toast.value)">
            <a (click)="svcNotifier.removeToast(toast.value)" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span></a>
            {{toast.value.textOrTpl}}
            <a *ngIf="toast.value.deepLinkTarget" [href]="toast.value.deepLinkTarget" class="btn btn-primary btn-sm d-block mt-4">
                <app-orbiicon class="ml-2" iconName="faLink"></app-orbiicon>
                {{toast.value?.deepLinkText}}
            </a>
        </ngb-toast>
      </app-notification-toast>
  
      <router-outlet *ngIf="_uiState.uiFlags.amILoggedIn"></router-outlet> 
      <div *ngIf="! _uiState.uiFlags.amILoggedIn" >
        <p>You must Signin to continue</p>
    </div> 
  <hr>


<ngx-spinner  type="ball-scale-multiple"><h2>{{this.model.spinnerMsg }} </h2></ngx-spinner>




<div class="side-panel-outer">
  <div [ngClass]="_uiState.uiFlags.showSideOverlay ? 'open' : 'open-not'" class="orbi-side-overlay">
      <div class="help-overlay-header">
          <h2 class="orbi-section-title">Orbitrust Help Section</h2>
          <button type="button" class="btn btn-light" aria-label="Close" (click)="_uiState.uiFlags.showSideOverlay = !_uiState.uiFlags.showSideOverlay">
              <span aria-hidden="true">&times;</span>
            </button>
      </div>
      <div class="help-overlay-body">
        <div class="row">
          <div class="col"><h3 class="orbi-section-subtitle">Frequently Asked Questions</h3></div>
        </div>
        <div class="help-row">
          <div class="help__head">
              <h2 class="orbi-section-subtitle">How long is a piece of string ?</h2>
              <button class="btn btn-light" type="button">
                <!-- <fa-icon [icon]="faCaretDown"></fa-icon> -->
              </button>
          </div>
          <div class="help__answer">
              <p class="help__text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia nulla rem hic blanditiis laudantium eveniet corporis modi tempora cum, suscipit ipsum dolorum, eos voluptate necessitatibus consequatur? Vel exercitationem aspernatur architecto.
              Quisquam a, distinctio quam, itaque temporibus animi magnam ratione quo pariatur placeat accusamus optio cum et tempore dolore ad nulla quibusdam totam. Placeat illum expedita quae obcaecati aliquam facere nulla.</p>
          </div>
        </div>
        <div class="help-row">
          <div class="help__head">
              <h2 class="orbi-section-subtitle">How long is a piece of string ?</h2>
              <button class="btn btn-light" type="button">
                <!-- <fa-icon [icon]="faCaretDown"></fa-icon> -->
              </button>
          </div>
          <div class="help__answer">
              <p class="help__text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia nulla rem hic blanditiis laudantium eveniet corporis modi tempora cum, suscipit ipsum dolorum, eos voluptate necessitatibus consequatur? Vel exercitationem aspernatur architecto.
              Quisquam a, distinctio quam, itaque temporibus animi magnam ratione quo pariatur placeat accusamus optio cum et tempore dolore ad nulla quibusdam totam. Placeat illum expedita quae obcaecati aliquam facere nulla.</p>
          </div>
        </div>

      </div>
      <div class="help-overlay-footer">Footer</div>
  </div>

</div>

<footer>
    <ul class="footer-list">
        <li class="logo"><a href="#">
          <img [src]="_uiState.logoUrlWhite" alt="logo">
        </a>
      </li>
        <li><a href="mailto:info@gitrospect.com">Contact us</a></li>
        <li><a href="sm-tw">Twitter</a></li>
        <li><a href="sm-fb">Facebook</a></li>
    </ul>
</footer>  
