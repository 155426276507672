import { HttpClient, HttpEvent, HttpResponse, HttpSentEvent, HttpStatusCode } from "@angular/common/http";
import { ApiMwgithubBase, ApiMwgithubResult, ApiPageStateInterface, ApiPageState_init, OrbiApiEntrypointKey, OrbiFilterState, OrbiFilterStateArgCharacteristicSet, OrbiFilterStateErrorHandlerInterface, OrbiFilterStateInterface } from "@danclarke2000/gitrospectdto";
import { BehaviorSubject, NextObserver, Observable, PartialObserver, throwError } from "rxjs";
import { OrbibackendPublicService } from "src/app/svc/orbibackend.public.service";
import { DefaultApiMwgithubResultObj } from "src/app/svc/orbigithub.service";
import { OangnotificationsService } from "src/oang/svc/oangnotifications.service";
import { OrbiHttpOptions } from "./orbibackend.types";
import { OrbiRequest, OrbiRequestBase, OrbiRequestState } from "./orbirequest";

interface ResponsePageItem
{
    apiResult : ApiMwgithubResult;
}

interface ResponseItem
{
    myCacheKey : string,
    responsePages : ResponsePageItem[];
    totalCount : number;
    captureTime : Date;
    responsePagesInProgress  : any,
    responsePagesFailed  : any,
};

export class ArgsHelperNormalizer
{
    public static normalizeArgs(apiEndpoint : OrbiApiEntrypointKey, requestCtr:string, filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) : string  
    {
        let reqCtrVal = `requestCtr=${requestCtr}`;
        if (Array.isArray(filterState?.extraParamsNameValues)) {
            let spliceIndex = filterState.extraParamsNameValues.findIndex((curr:string)=> /^requestCtr=/.test(curr));
            if (-1 < spliceIndex) {
                filterState.extraParamsNameValues.splice(spliceIndex, 1, reqCtrVal);
            } else {
                filterState.extraParamsNameValues.push(reqCtrVal);
            }
        } else {
            filterState.extraParamsNameValues = [reqCtrVal];
        }

        let strUrlArgs = ArgHelper.marshallArgs(filterState, apiEndpoint);

        // placeholder for page cursor args
        let pageNumToCalculateOffset = (0 < pageState.currentPage) ? pageState.currentPage-1 : 0;
        let pageStateDto : Partial<ApiPageStateInterface> = {
            currentPage: pageState.currentPage,
            limit: pageState.limit,
            offset : pageNumToCalculateOffset*pageState.limit,
            orderbycol : pageState.orderbycol,
            orderbydir : pageState.orderbydir
        }; 

        let strPageState = encodeURIComponent(JSON.stringify(pageStateDto));
        let newArgs : string[] = [ strUrlArgs, strPageState, apiEndpoint ];
        return newArgs.join('/');
    }
}

class ArgHelper
{
    static readonly strUndefined = 'undefined'
    private static apiRoot2 : string | undefined;
    public static API_ROUTES_BY_KEY : Map<OrbiApiEntrypointKey, (args:string)=>string> | undefined ;
    public static composeUrl(path:string) {
        if (!ArgHelper.apiRoot2) {
            debugger;
        }

        return this.apiRoot2 + path;
    };    

    public static staticInit(svcOrbiBePublic : OrbibackendPublicService)
    {
        ArgHelper.apiRoot2 = svcOrbiBePublic.getUrlbasePrivebe();
        ArgHelper.API_ROUTES_BY_KEY = new Map<OrbiApiEntrypointKey, (args:string)=>string>([
            // getCachedRepos: (orgName:string, topic: string, pageCursor: string | undefined, numResults: number) => this.composeUrl(`/searchcacherepo/${orgName}/${topic}/${pageCursor}/${numResults}`),
            [OrbiApiEntrypointKey.healthcheck, (strArgs:string) => this.composeUrl(`/healthcheck/${strArgs}`)],
            [OrbiApiEntrypointKey.searchcacherepo, (strArgs:string) => this.composeUrl(`/searchcacherepo/${strArgs}`)],
            [OrbiApiEntrypointKey.searchcachepullrequest, (strArgs:string) => this.composeUrl(`/searchcachepullrequest/${strArgs}`)],
            [OrbiApiEntrypointKey.searchcachecommit, (strArgs:string) => this.composeUrl(`/searchcachecommit/${strArgs}`)],
            [OrbiApiEntrypointKey.searchcachecodeanalysisvulnalerts, (strArgs:string) => this.composeUrl(`/searchcachecodeanalysisvulnalerts/${strArgs}`)],
            [OrbiApiEntrypointKey.searchcacherelease, (strArgs:string) => this.composeUrl(`/searchcacherelease/${strArgs}`)],
            [OrbiApiEntrypointKey.searchcacheworkflow, (strArgs:string)  => this.composeUrl(`/searchcacheworkflow/${strArgs}`)],
            [OrbiApiEntrypointKey.webhookevents, (strArgs:string) => this.composeUrl(`/webhookevents/${strArgs}`)],
            [OrbiApiEntrypointKey.exportrepos, (strArgs:string) => this.composeUrl(`/exportrepos/${strArgs}`)],
            [OrbiApiEntrypointKey.searchcacheworkflowrun, (strArgs:string) => this.composeUrl(`/searchcacheworkflowrun/${strArgs}`)],
            [OrbiApiEntrypointKey.accesscontrol, (strArgs:string) => this.composeUrl(`/accesscontrol/${strArgs}`)],
            [OrbiApiEntrypointKey.cachedusers, (strArgs:string) => this.composeUrl(`/cachedusers/${strArgs}`)],
            [OrbiApiEntrypointKey.cachedteams, (strArgs:string) => this.composeUrl(`/cachedteams/${strArgs}`)],        
            [OrbiApiEntrypointKey.auditresults, (strArgs:string) => this.composeUrl(`/getauditresults/${strArgs}`)],        
            [OrbiApiEntrypointKey.environments, (strArgs:string) => this.composeUrl(`/environments/${strArgs}`)],        
            [OrbiApiEntrypointKey.secrets, (strArgs:string) => this.composeUrl(`/secrets/${strArgs}`)],  
        ]);     
    }

    public static marshallArgs(filterState:OrbiFilterStateInterface, apiEndpoint : OrbiApiEntrypointKey) : string  
    {
        let myFilterState= filterState;
        let attrsCharacteristics : OrbiFilterStateArgCharacteristicSet | undefined  = OrbiFilterState.urlArgAttrCharacterisitics[apiEndpoint];

        
        let args = [] as string[];
        let filterStateVars : OrbiFilterStateInterface = myFilterState;
        let keys : (keyof OrbiFilterStateInterface)[] = <(keyof OrbiFilterStateInterface)[]>(<unknown>([ 'orgName', 'repositorySelector', 'repositorySelectorCustomValue', 'auditfocusSelector', 'auditfocusSelectorCustomValue','dateSelector' ]));
        for (let key of keys) {
            //  
            let myVal:string|string[]|Date|undefined = filterStateVars[key];
            let validatedValue : string | undefined;
            // @ts-ignore
            let myAttrCharacteristics : any | undefined = (attrsCharacteristics) ? attrsCharacteristics[key] : undefined;
            if ("string" == typeof myVal && 0 < myVal.length) {
                if (OrbiFilterState.regExpArgValidation.test(myVal) && OrbiFilterState.individualArgMaxLength > myVal.length) {
                    validatedValue = myVal;
                } else {
                    console.error(`normalizeArgs - key=${key} is not a valid value, val=${myVal}`);
                }
            } else if (undefined != myVal) {
                // should be handled ..
                debugger;
            }
            
            if (validatedValue && 0 < validatedValue.length) {
                args.push(validatedValue);
            }
            else {
                if (myAttrCharacteristics && myAttrCharacteristics.required) {
                    console.error(`normalizeArgs - key=${key} is required but not provided`);
                } 

                args.push(ArgHelper.strUndefined);
            }
        }

        let dateAttrCharacteristics : any[] = (attrsCharacteristics) 
            ? [ attrsCharacteristics['fromDate'], attrsCharacteristics['toDate'] ] 
            : [ { required:false }, { required:false } ] ;
        if (filterStateVars.fromDate || filterStateVars.toDate)
        {   
            let dateArgs : (Date | undefined)[] = [filterStateVars.fromDate, filterStateVars.toDate] ;
            for (let i = 0; i < dateArgs.length; i++) {
                let currDateArg = dateArgs[i];            
                let currEncodedArg = OrbiFilterState.encodeQueryArgDate(currDateArg);   
                if (!currEncodedArg && dateAttrCharacteristics[i] && dateAttrCharacteristics[i].required) {
                    console.error(`normalizeArgs - date[${i}] is required but not provided`);
                } 

                if (currEncodedArg && currEncodedArg.length > 0) {
                    args.push(currEncodedArg);
                } else {
                    args.push(ArgHelper.strUndefined);
                }
            }
        } else {
            let attrCharacteristicsReqDate = dateAttrCharacteristics.filter((v:any) => true == v.required);
            if (attrCharacteristicsReqDate.length > 0) {
                console.error(`normalizeArgs - dates are required but not provided`);
            }

            args.push(...[ArgHelper.strUndefined,ArgHelper.strUndefined]);
        }

        let extraParams = myFilterState.extraParamsNameValues;
        if (! Array.isArray(extraParams))
        {
            // have constant num params
            extraParams = [];
        }
        args.push(encodeURIComponent(JSON.stringify(extraParams)));

        // placehiolder for pagination params
        let strUrlArgs = args.join('/');
        return strUrlArgs;
    }

    public unmarshallArgs(attrsCharacteristics:any) : any  {
    }

    public static normalizeArgs(apiEndpoint : OrbiApiEntrypointKey, requestCtr:string, filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) : any  
    {
        return ArgsHelperNormalizer.normalizeArgs(apiEndpoint, requestCtr, filterState, pageState);
    }

    public static spinnerCtrs : any = {};
    public static doLoadingSpinner(callerFnName:string, reqId:number, loadingStatus:boolean, ) {
        if (! ArgHelper.spinnerCtrs[callerFnName]) {
            ArgHelper.spinnerCtrs[callerFnName] = 0;
        }

        let inc = (loadingStatus) ? 1 : -1;
        ArgHelper.spinnerCtrs[callerFnName] += inc;
        OrbiRequestBase._loading$.next( { status: true, isLoading: loadingStatus, msg : ``, ctxt: callerFnName, reqId: reqId } );        
    }  
}

class OrbiRequestMwgithubApiPage extends OrbiRequestBase {
    static readonly PageSize : number = 100;
    static debugOn : boolean = false;
    
    constructor(httpClient: HttpClient, svcError : OangnotificationsService, public requestCtr : string, url : string) {
        super(requestCtr, httpClient, svcError, url);
        // this.resultsobs = resultsObs;
    }

    // private loadPageObsHolder : LoadPageObserverContainer | undefined;
    public static loadPage(httpClient:HttpClient, svcError: OangnotificationsService, requestCtr:string, apiEntry: OrbiApiEntrypointKey, filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface)
    {
        let strNormalizedArg : string = ArgHelper.normalizeArgs(apiEntry, requestCtr, filterState, pageState);
        let apiUrl : string = ArgHelper.API_ROUTES_BY_KEY!.get(apiEntry)!(strNormalizedArg);
        let newReq = new OrbiRequestMwgithubApiPage(httpClient, svcError, requestCtr, apiUrl);
        return newReq;
    }

    public getApiPage(extraObservers? : PartialObserver<any>) {
        this.state = OrbiRequestState.InProgress;
        // @ts-ignore
        let myObs : Observable<HttpResponse<ApiMwgithubResult>> = this.httpClient.get<ApiMwgithubResult>(this.url, OrbiHttpOptions.getHttpOptions() as object);
        let bs = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
        myObs.subscribe({
            next: (val:HttpResponse<ApiMwgithubResult>) => {
                // dont fwd the EarlyHints default value
                if (val && val.body && (val.body.httpStatus >= HttpStatusCode.Ok && val.body.httpStatus < HttpStatusCode.BadRequest)) {
                    bs.next(val.body);
                }
            },
            error: (v:any) => {
                console.error(v);
                debugger;
            }
        });
        
        return bs;
    }
}

export class OrbiRequestMwgithubApi 
{
    public  requestId = Math.floor(1000000000 * Math.random());
    private requestCtrInner : number = 0;
    private static responseCache : Map<string, ResponseItem> = new Map<string, ResponseItem>();    
    private static requestCtr : number = 0;
    private pageState : ApiPageStateInterface | undefined;
    private myResponseItem : ResponseItem | undefined;

    public static debugSearchCache<T extends ApiMwgithubBase>( keyTest:RegExp, myOrphanObjs : any ) {
        Object.values(myOrphanObjs).forEach((element:any) => {
            // { objectid: currRow.objectid, objectidlogical: currRow.objectidlogical };
            OrbiRequestMwgithubApi.responseCache.forEach((value:ResponseItem, key:string) => {
                if (keyTest.test(key)) {
                    value.responsePages.forEach((page:ResponsePageItem) => {
                        let myResults :T[] = page.apiResult.data as T[];
                        myResults.forEach((currRow:T) => {
                            if (currRow.identifier == element.objectid || currRow.identifier == element.objectidlogical) {
                                console.log(`Found orphaned objectid ${element.objectid} in cache`);
                                debugger;
                            }
                        });                        
                    });
                }
            });
        });
    }

    public static staticInit(svcOrbiBePublic : OrbibackendPublicService)
    {
        ArgHelper.staticInit(svcOrbiBePublic);  
    }

    public static createApiRequest(fnName : string, httpClient: HttpClient, svcError : OangnotificationsService, apiCallerObs : BehaviorSubject<ApiMwgithubResult>,
        apiEntry : OrbiApiEntrypointKey, filterState:OrbiFilterStateInterface, loadAllPages : boolean)
    {
        loadAllPages = true;
        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(filterState));
        let strRequestStr=`${++OrbiRequestMwgithubApi.requestCtr}`;
        let myObj = new OrbiRequestMwgithubApi(fnName, strRequestStr, httpClient, svcError, apiCallerObs, apiEntry, myFilterState, loadAllPages);
        return myObj;
    }
    
    public static getCacheKey(apiEntry: OrbiApiEntrypointKey, filterState : OrbiFilterStateInterface, pageState : ApiPageStateInterface) {
        let filterStateVars = filterState;
        let strFromDate =  (filterStateVars.fromDate) ? OrbiFilterState.encodeQueryArgDate(filterStateVars.fromDate) : '';
        let strToDate = (filterStateVars.toDate) ? OrbiFilterState.encodeQueryArgDate(filterStateVars.toDate) : '';        
        let interestingExtraArgsFilter = (OrbiApiEntrypointKey.auditresults === apiEntry) ? [/^objectType=/, /^importId=/] : [/^eventCategory=/];
        let interestingExtraArgs = (! Array.isArray(filterStateVars.extraParamsNameValues)) 
            ? [] 
            : filterStateVars.extraParamsNameValues.filter((value:string) => interestingExtraArgsFilter.some((currRegex:RegExp) => currRegex.test(value)));
            
        let strExtraArgs = interestingExtraArgs.join(',');
        let cacheKey = `${apiEntry}/${filterStateVars.orgName}/${filterStateVars.repositorySelector}/${strFromDate}/${strToDate}/${strExtraArgs}`;
        return cacheKey;
    }

    constructor(private callerFnName : string, private requestCtr:string, private httpClient: HttpClient, private svcError : OangnotificationsService, private apiCallerObs : BehaviorSubject<ApiMwgithubResult>, 
        private apiEntry : OrbiApiEntrypointKey, private filterState:OrbiFilterStateInterface, private loadAllPages : boolean)
    {
        if (undefined == filterState.orgName) { debugger; }
    }

    private signalApiResult(myResponseItem:ResponseItem, wasCacheHit:boolean, isFirstPage:boolean)
    {
        let ctxt : any | undefined = undefined;
        let pageInfo : any  | undefined = undefined;
        let errors : any[] = [];

        let dataMarshall : any = {};        
        myResponseItem.responsePages.forEach((currPage:ResponsePageItem) => {
            if (! Array.isArray(currPage.apiResult.data)) {
                debugger;
            }

            let numDuplicates = 0;
            currPage.apiResult.data.forEach((currData:any) => {
                let id = currData.identifer;
                if (! id) {
                    id = currData.idlogical;
                }

                if (dataMarshall[id]) {
                    // duplicate id (might happen a lot for audit results)
                    ++numDuplicates;
                }
                
                dataMarshall[id] = currData;
            });

            if (0 < numDuplicates) {
                console.warn(`OrbiRequestMwgithubApi.signalApiResult: ${numDuplicates} duplicates found in page offset=${currPage.apiResult.pageInfo.offset}`);
            }

            let resultErrors = Array.isArray(currPage.apiResult.errors) ? currPage.apiResult.errors : [];
            let resultData = Array.isArray(currPage.apiResult.data) ? currPage.apiResult.data : [];
            errors.push(...resultErrors);
            if (currPage.apiResult.context) {
                ctxt = currPage.apiResult.context;
            }
            if (currPage.apiResult.pageInfo) {
                pageInfo = currPage.apiResult.pageInfo;
            }
        });

        let data : any[] = Object.values(dataMarshall);
        let result : ApiMwgithubResult = { 
            httpStatus:HttpStatusCode.Ok, 
            eTag: undefined,
            context: ctxt, 
            errors:errors, 
            data:data,
            pageInfo : {
                totalCount: (pageInfo?.totalCount) ? pageInfo.totalCount : 0,
                limit: data.length, 
                offset: 0
            }
        };
        this.apiCallerObs.next(result);
    }
        
    private queuePageRequest(
        requestCtr:string, pageState : ApiPageStateInterface
    ) 
    {
        let newReq : OrbiRequestMwgithubApiPage = OrbiRequestMwgithubApiPage.loadPage(this.httpClient, this.svcError, requestCtr, this.apiEntry, this.filterState, pageState);
        this.myResponseItem!.responsePagesInProgress[requestCtr] = newReq;

        // @ts-ignore
        let myObjs : Observable<ApiMwgithubResult> = newReq.getApiPage();
        myObjs.subscribe({
            next: (val:ApiMwgithubResult) => {
                if (val && (val.httpStatus >= HttpStatusCode.Ok && val.httpStatus < HttpStatusCode.BadRequest)) {
                    let myApiResult : ApiMwgithubResult = val;
                    let importId = myApiResult?.context?.importId;
                    let limit = myApiResult?.pageInfo?.limit;
                    let offset = myApiResult?.pageInfo?.offset;
                    let totalCount = myApiResult?.pageInfo?.totalCount;
                    let respReqCtr = myApiResult?.context?.requestCtr;

                    if (undefined != limit && undefined != offset && undefined != totalCount && undefined != respReqCtr)
                    {
                        let isFirstPage = (0 == offset);
                        let currPage = 1 + Math.floor(offset / limit);
                        let maxPages = Math.ceil(totalCount / limit);                        
                        this.myResponseItem!.responsePages[currPage] = { apiResult :  val }
                        if (true == this.loadAllPages && isFirstPage && 1 < maxPages) {
                            // first page, queue up the other pages 
                            Array(maxPages-1).fill(1).forEach((element:number, index:number, self:number[]) => {
                                ++pageState.currentPage;
                                pageState.offset += pageState.limit;
                                this.queuePageRequest(this.callerFnName + ".loadPage" + index, pageState);
                            });                             
                        }
                        
                        if (respReqCtr && this.myResponseItem!.responsePagesInProgress[respReqCtr])
                        {
                            delete this.myResponseItem!.responsePagesInProgress[respReqCtr];
                        }
                        else
                        {
                            // weird; how can we have a response without a pending request ?
                            console.warn(`unexpected response ${JSON.stringify(myApiResult?.context)}`);
                            debugger; 
                        }

                        let numPendingReqs = Object.keys(this.myResponseItem!.responsePagesInProgress).length;
                        if (0 == numPendingReqs) 
                        {
                            ArgHelper.doLoadingSpinner(this.callerFnName, this.requestId, false);                           
                            this.signalApiResult(this.myResponseItem!, false, isFirstPage);
                        }
                    }
                    else 
                    {
                        console.warn(`Invalid response ${JSON.stringify(myApiResult?.context)}`)
                        debugger;
                    }
                } else {
                    // TBD handle error here
                    if (val && val.httpStatus != HttpStatusCode.EarlyHints) {
                        debugger;
                    }
                }
            },
            error: (err:any) => {
                // TBD handle network error here
                debugger;
            }
            /*,                
            // @ts-ignore
            __debugTag: {
                id : this.apiEntry + ".loadPage(0)"
            } */   
        });
    }

    public debugGetApiPages(pageState : ApiPageStateInterface) {
        let strNormalizedArg : string = ArgHelper.normalizeArgs(this.apiEntry, this.requestCtr, this.filterState, pageState);
        let myCacheKey = OrbiRequestMwgithubApi.getCacheKey(this.apiEntry, this.filterState, pageState);
        let myResponseItem : ResponseItem | undefined = OrbiRequestMwgithubApi.responseCache.get(myCacheKey);
        debugger;
    }

    public getApiPages(pageState : ApiPageStateInterface) {
        this.pageState = JSON.parse(JSON.stringify(pageState)) as ApiPageStateInterface;
        let strNormalizedArg : string = ArgHelper.normalizeArgs(this.apiEntry, this.requestCtr, this.filterState, this.pageState);
        let myCacheKey = OrbiRequestMwgithubApi.getCacheKey(this.apiEntry, this.filterState, this.pageState);
        let myResponseItem : ResponseItem | undefined = OrbiRequestMwgithubApi.responseCache.get(myCacheKey);
        if (undefined == myResponseItem) {
            this.myResponseItem = {
                myCacheKey : myCacheKey,
                responsePages : [],
                totalCount : -1,
                captureTime : new Date(),
                responsePagesInProgress  : [],
                responsePagesFailed  : [],
            };
            OrbiRequestMwgithubApi.responseCache.set(myCacheKey, this.myResponseItem);
            let signalOnFirstPage = 1 == pageState.currentPage;
            let pageCtr = this.requestCtr + '.'+(++this.requestCtrInner);

            ArgHelper.doLoadingSpinner(this.callerFnName, this.requestId, true);
            this.queuePageRequest(this.callerFnName + ".loadPage" + pageCtr, pageState);                                
        } else {
            // we have response item but maybe
            // - server hasnt replied yet
            // - that page isnt loaded 
            // - that page request timed out..   
            if (0 != myResponseItem.responsePagesFailed.length) {
                //
                // this could be more intelligent, we just make all the requests again (needs testing!)
                debugger; 
                OrbiRequestMwgithubApi.responseCache.delete(myCacheKey);
                this.getApiPages(pageState);
            } else {
                // 
                let hasAllPages = (0 >= Object.keys(myResponseItem.responsePagesInProgress).length);
                if (hasAllPages) {
                    this.signalApiResult(myResponseItem, true, false);
                } else {
                    if (! this.loadAllPages && myResponseItem.responsePages[pageState.currentPage]) {
                        // we have the page we want
                        this.signalApiResult(myResponseItem, true, false);
                    } else {
                        // this case is, we dont have all pages, but we want to load all pages
                        // .. so wait
                    }
                }
            }
        }
    }
}

