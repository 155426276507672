<div class="form-group  orbi-filters-list">
    <ul class="mb-3">        
        <input type="text" [formControl]="frmCtrlValuesFilter" class="form-control"  />
        <a (click)="uiEvents.onBtnFilterClear($event)" class="btn btn-link">
            <app-orbiicon class="ml-2" iconName="faTimes" [ngClass]="0 == this.frmCtrlValuesFilter?.value.length ? 'disabled' : ''" ></app-orbiicon>
        </a>
        <div *ngIf="frmCtrlValuesFilter.invalid && (frmCtrlValuesFilter.dirty || frmCtrlValuesFilter.touched)">
            <div *ngIf="frmCtrlValuesFilter.errors?.['incorrect']" class="alert">
                Incorrect pattern is required; must be alphanumeric, or valid regular expression.
            </div>
        </div>
    </ul>
    <ul class="mb-3" *ngIf="includeSelectAllNone">      
        <a (click)="uiEvents.onBtnSelectAll($event)" class="btn btn-link">Select all</a> | 
        <a (click)="uiEvents.onBtnSelectAllAdditionally($event)" class="btn btn-link">Select all additonally</a> | 
        <a (click)="uiEvents.onBtnSelectClearVisible($event)" class="btn btn-link">Clear visible</a> | 
        <a (click)="uiEvents.onBtnSelectNone($event)" class="btn btn-link">Clear selection</a>
    </ul>
    <ul>
        <li *ngFor="let currOption of this.uiValuesToShow" ><!-- [hidden]="! currOption.visible"-->
            <button [ngClass]="currOption.selected ? 'selected' : 'selected-not'" 
                (click)="uiEvents.onNameSelectClick($event, currOption)"
                class="btn btn-filter btn-sm">{{currOption.label}}                                                        
                <app-orbiicon iconName="faTimes"></app-orbiicon>
            </button>
        </li>
    </ul>
</div>
