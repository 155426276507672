import { Component, EventEmitter, forwardRef, Inject, Input, LOCALE_ID, OnInit, Output, Provider, SimpleChanges, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { OrbiConstants, OrbiSettingDTO, OrbiSettingGroupName, OrbiSettingGroupName_allvalues, OrbiSettingNvpName } from '@danclarke2000/gitrospectdto';
import { OrbisettingsService } from 'src/app/svc/orbisettings.service';
import {KeyValue} from '@angular/common';
import { OanAnalyzerParams } from 'src/app/cmn/analyzer/OanAnalyzerParams';
import { ControlValueAccessor, FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrbiwidgetValue } from './orbiwidgetcmn';
import { OanDebugutils } from '../utils/OanDebugutils';

class Impl
{
 
           
}

interface OrbiwidgettextboxlistUiState 
{
    cmpThis : OrbiwidgettextboxlistComponent;
    widgetTextInputs : OrbiwidgetValue[]; 
    frmGroup : UntypedFormGroup;    
    frmControls : any;
}

@Component({
  selector: 'app-orbiwidgettextboxlist',
  templateUrl: './orbiwidgettextboxlist.component.html',
  styleUrls: ['./orbiwidgettextboxlist.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: OrbiwidgettextboxlistComponent,
    multi: true,
  }]
})
export class OrbiwidgettextboxlistComponent implements OnInit, ControlValueAccessor {
    public static readonly className = "orbiwidgettextboxlist";
    @Input() frmCtrlIdLink : string = '';  
    @Input() validationPattern : string = '.*';
    @Input() validationPatternDescription : string = '(unknown)';        
    
    public debugJsonStringify(s : any[]) {
        return ''; 
    }
    
    public model : any = {} ;
    public uiState : OrbiwidgettextboxlistUiState;
    public uiEvents : any = {    
        cmpThis : this,        
        onTextMinus($event:any, widgetToMinus:OrbiwidgetValue)  {
            this.cmpThis.uiState.widgetTextInputs = this.cmpThis.uiState.widgetTextInputs.filter( (currEl:OrbiwidgetValue) => currEl.key != widgetToMinus.key);
            this.cmpThis.uiState.frmGroup.removeControl(widgetToMinus.key);
            this.cmpThis.uiState.frmControls[widgetToMinus.key] = undefined;
        },
        onPlus($event:any) {
            this.cmpThis.addNewTextBox();
        },
    }

    private addNewTextBox(initialval:string) 
    {
        let nextVal : OrbiwidgetValue = { 
            label: '',
            key: 'key' + Math.floor(Math.random()*100000),
            selectedvalues: [''],
            visible:true ,
            selected:false,             
        };
        this.uiState.widgetTextInputs.push(nextVal);
        this.uiState.frmControls[nextVal.key] = this.fb.control(initialval);
        this.uiState.frmGroup.addControl(nextVal.key, this.uiState.frmControls[nextVal.key]);
    }

    constructor(@Inject(LOCALE_ID) public locale: string,
        private calendar: NgbCalendar,
        private fb : UntypedFormBuilder,
        public svcSettings : OrbisettingsService) 
    {
        this.uiState = {    
            cmpThis : this,        
            widgetTextInputs : [],
            frmGroup : this.fb.group({}),
            frmControls : {}
        };

        this.uiState.frmGroup.valueChanges.pipe(
                debounceTime(1000)
            ).subscribe((selectedValue:any)  => {
                // structure here is 
                // {
                //      ctrl-key: newvalue(string)
                //      ctrl-key2: newvalue(string)
                if (this.uiState.frmGroup.valid)
                {
                    let validationRegExp = new RegExp(this.validationPattern);
                    let allVals : string[] = Object.values(selectedValue);
                    let values : string[] = allVals.filter((currVal:any) => {
                        return currVal && 0 < currVal.length;
                    });
                    
                    this.model.selectedvalues = values;
                    this.onChange(this.model);
                
                }
            });

        this.addNewTextBox('');
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {                
    }

    /*
    * reactive form control interface */
    onChange = (values:string[]) => {};
    onTouched = () => {};
    touched = false;
    disabled = false;

    writeValue(values: any) {
        if (values.type != OrbiwidgettextboxlistComponent.className) {
            // this means the html is referring to one widget e.g. orbiwidgettextboxlist, but the json to a different widget e.g. radiobutton
            OanDebugutils.debuggerWrapper(".?.");
        }

        this.model = values;
        if (this.model.selectedvalues && Array.isArray(this.model.selectedvalues))
        {
            this.uiState.widgetTextInputs.forEach( (currCtrl:any) => {
                this.uiEvents.onTextMinus(null, currCtrl);
            });

            this.model.selectedvalues.forEach((currVal:string) => {
                this.addNewTextBox(currVal);
            });

            this.addNewTextBox('');
        }
        else
        {
            OanDebugutils.debuggerWrapper(".?.");
        }
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
}
