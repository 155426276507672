import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, Subject, SubscriptionLike, throwError} from 'rxjs';
import { DecimalPipe} from '@angular/common';
import { catchError, debounceTime, delay, filter, mergeMap, retryWhen, switchMap, tap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEvent, HttpStatusCode } from '@angular/common/http';
import { OrbiHttpOptions } from 'src/app/cmn/svc/orbibackend.types';
import { OrbiFilterState, ApiMwgithubRepo, ApiMwgithubPullrequest, OrbiSettingDTO, OrbiApiEntrypointKey, OrbiFilterStateArgCharacteristicSet, OrbiFilterStateErrorHandlerInterface, OrbiObjectType, OrbiResultsFilterEnum, ApiPageState_init, ApiPageStateInterface, OrbiFilterStateInterface } from '@danclarke2000/gitrospectdto';
import  { ApiMwgithubResult, ApiMwgithubWorkflow, ApiMwgithubWorkflowrun } from '@danclarke2000/gitrospectdto';
// import * as internal from 'stream';
import { OrbiRequest, OrbiRequestBase } from 'src/app/cmn/svc/orbirequest';
import { OangnotificationsService } from 'src/oang/svc/oangnotifications.service';
import { OrbibackendPublicService } from './orbibackend.public.service';
import { OanDebugutils } from '../cmn/utils/OanDebugutils';
import { ArgsHelperNormalizer, OrbiRequestMwgithubApi } from '../cmn/svc/orbirequestmwgithubapi';

export const  DefaultApiMwgithubResultObj : ApiMwgithubResult = {
    httpStatus: HttpStatusCode.EarlyHints, 
    eTag: undefined,
    data: [],
    progress: undefined,
    context: undefined,
    pageInfo : {
        totalCount : 0,
        limit : -1,
        offset : -1
    },
    errors: []
};

export type SortColumn = keyof ApiMwgithubRepo | '';
export type SortDirection = 'asc' | 'desc' | '';
// const rotate: {[key: string]: SortDirection} = { 'asc': 'desc', 'desc': '', '': 'asc' };



/*
    const DefaultPageSize : number = 25;

    export enum ApiPageStateOrderByDir {
        DESC = "DESC",
        ASC = "ASC"
    };

    export interface ApiPageStateInterface {
        invalidated : boolean;
        currentPage : number;
        expectedTotal : number;
        limit : number;
        offset : number;
        orderbycol : string[];
        orderbydir : ApiPageStateOrderByDir; 
        impl? : string[];
    }; 

    export function ApiPageState_init(pageName?:string, apiResult? : Partial<ApiMwgithubResult>) : ApiPageStateInterface {
        let r : ApiPageStateInterface = {
            invalidated : false,
            currentPage : 1,
            expectedTotal : (apiResult && apiResult.totalCount) ? apiResult.totalCount : -1,
            limit : 100,
            offset : 0,
            orderbycol : ["updated_at"],
            orderbydir : ApiPageStateOrderByDir.DESC,
            impl : (pageName) ? [ `pageName=${pageName}` ] : []
        };

        return r;
    } 

    export function ApiPageState_applyApiResult(pageState : ApiPageStateInterface, apiResult: Partial<ApiMwgithubResult>)
    {
        pageState.expectedTotal = (apiResult && apiResult.totalCount) ? apiResult.totalCount : -1;
    }  */

    const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

    function sort(repos: ApiMwgithubRepo[], direction: string): any[] {
        if (direction === '') {
            return repos;
        } else {
            return [...repos].sort((a, b) => {
                const res = compare(a.repo.name, b.repo.name);
                return direction === 'asc' ? res : -res;
            });
        }
    }

    function matches(repo: ApiMwgithubRepo, term: string, pipe: PipeTransform) {
        return repo.repo.name.toLowerCase().includes(term.toLowerCase());
    }

@Injectable({
  providedIn: 'root'
})
export class OrbigithubService 
{
    private static apiRoot2 : string | undefined;
    private static composeUrl(path:string) {
        if (!OrbigithubService.apiRoot2) {
            debugger;
        }

        return this.apiRoot2 + path;
    };

    private static readonly API_ROUTES : any= {
    }; 

    private _healthcheck$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _importhistory$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _reposcached$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _reposummarylist$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _repoaccesssettings$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _codeanalysisvulnalerts$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _reposexport$ = new BehaviorSubject<string>('');
    private _pullrequestscached$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _commitscached$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _releasemgmtscached$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);        
    private _workflowscached$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _workflowrunscached$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _users$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _teams$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _webhookevents$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _auditresults$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _metricdata$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _githubenvironments$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);
    private _githubsecrets$ = new BehaviorSubject<ApiMwgithubResult>(DefaultApiMwgithubResultObj);

    constructor(private httpClient: HttpClient, 
        private svcOrbiBePublic : OrbibackendPublicService,
        private svcError : OangnotificationsService, private pipe: DecimalPipe) 
    {
        // @ts-ignore 
        this._healthcheck$.__debugTag = { id: "OrbigithubService._healthcheck" };
        // @ts-ignore 
        this._importhistory$.__debugTag = { id: "OrbigithubService._importhistory" };
        // @ts-ignore 
        this._reposcached$.__debugTag = { id: "OrbigithubService._reposcached" };
        // @ts-ignore 
        this._reposummarylist$.__debugTag = { id: "OrbigithubService._reposummarylist" };
        // @ts-ignore 
        this._repoaccesssettings$.__debugTag = { id: "OrbigithubService._repoaccesssettings" };
        // @ts-ignore 
        this._codeanalysisvulnalerts$.__debugTag = { id: "OrbigithubService._codeanalysisvulnalerts" };
        // @ts-ignore 
        this._reposexport$.__debugTag = { id: "OrbigithubService._reposexport" };
        // @ts-ignore 
        this._pullrequestscached$.__debugTag = { id: "OrbigithubService._pullrequestscached" };
        // @ts-ignore 
        this._commitscached$.__debugTag = { id: "OrbigithubService._commitscached" };
        // @ts-ignore 
        this._releasemgmtscached$.__debugTag = { id: "OrbigithubService._releasemgmtscached" };
        // @ts-ignore 
        this._workflowscached$.__debugTag = { id: "OrbigithubService._workflowscached" };
        // @ts-ignore 
        this._workflowrunscached$.__debugTag = { id: "OrbigithubService._workflowrunscached" };
        // @ts-ignore 
        this._users$.__debugTag = { id: "OrbigithubService._users" };
        // @ts-ignore 
        this._teams$.__debugTag = { id: "OrbigithubService._teams" };
        // @ts-ignore 
        this._webhookevents$.__debugTag = { id: "OrbigithubService._webhookevents" };
        // @ts-ignore 
        this._auditresults$.__debugTag = { id: "OrbigithubService._auditresults" };
        // @ts-ignore 
        this._metricdata$.__debugTag = { id: "OrbigithubService._metricdata" };
        // @ts-ignore 
        this._githubenvironments$.__debugTag = { id: "OrbigithubService._githubenvironments" };
        // @ts-ignore 
        this._githubsecrets$.__debugTag = { id: "OrbigithubService._githubsecrets" };
    }

    public apiInit()
    {
        OrbigithubService.apiRoot2 = this.svcOrbiBePublic.getUrlbasePrivebe();
        OrbigithubService.API_ROUTES.getReposExport = (strArgs:string) => OrbigithubService.composeUrl(`/exportrepos/${strArgs}`);
        OrbigithubService.API_ROUTES.healthcheck = (strArgs:string) => OrbigithubService.composeUrl(`/healthcheck/${strArgs}`);
        OrbigithubService.API_ROUTES.getimporthistory = (orgName:string) => OrbigithubService.composeUrl("/getimporthistory/" + orgName);
        OrbigithubService.API_ROUTES.getmetricdata = (orgName:string) => OrbigithubService.composeUrl("/getmetricdata/" + orgName);
        OrbiRequestMwgithubApi.staticInit(this.svcOrbiBePublic);
    }

    get healthcheck$() { return this._healthcheck$.asObservable(); }
    get importhistory$() { return this._importhistory$.asObservable(); }
    get reposummarylist$() { return this._reposummarylist$.asObservable(); }
    get reposcached$() { return this._reposcached$.asObservable(); }
    get repoaccesssettings$() { return this._repoaccesssettings$.asObservable(); }
    get codeanalysisvulnalerts$() { return this._codeanalysisvulnalerts$.asObservable(); }
    get reposexport$() { return this._reposexport$.asObservable(); }
    get pullrequestscached$() { return this._pullrequestscached$.asObservable(); }
    get commitscached$() { return this._commitscached$.asObservable(); }
    get releasemgmtscached$() { return this._releasemgmtscached$.asObservable(); }
    get workflowscached$() { return this._workflowscached$.asObservable(); } 
    get workflowrunscached$() { return this._workflowrunscached$.asObservable(); } 
    get users$() { return this._users$.asObservable(); }
    get teams$() { return this._teams$.asObservable(); }
    get webhookevents$() { return this._webhookevents$.asObservable(); }    
    get githubenvironments$() { return this._githubenvironments$.asObservable(); }    
    get githubsecrets$() { return this._githubsecrets$.asObservable(); }    
    get auditresults$() { return this._auditresults$.asObservable(); }    
    get metricdata$() { return this._metricdata$.asObservable(); }    
   
    public getLoadingObs() {
        return OrbiRequestBase.getLoadingObs();
    }

    public subscribeObs(fnName: string, obsHttpEv : Observable<any>, bsVal: BehaviorSubject<any>, bsLoading: BehaviorSubject<any>) {
        bsLoading.next({ isLoading: true, msg: "", ctxt: "bsLoading" });

        obsHttpEv.subscribe({
            complete: () => {
            },
            next: (val: any) => {
                bsVal.next(val.body);
                bsLoading.next({ isLoading: false, msg: "", ctxt: "bsLoading2" });                
            },
            error: (err:any) => {
                OanDebugutils.debuggerWrapper(".?.");
                bsLoading.next({ isLoading: false, msg: "", ctxt: "bsLoading3" });
                console.error(fnName + ": " + JSON.stringify(err)); 
                throwError(err);
            }
        });
    }

    public apiExportRepos(query : string) {
        var fnName = "_apiExportRepos";
        var url : string = OrbigithubService.API_ROUTES.getReposExport(query);

        const headers = new Headers();
        headers.append('Content-Type', 'application/octet-stream');

        var httpopts : any = JSON.parse(JSON.stringify(OrbiHttpOptions.getHttpOptions()));
        httpopts.headers = headers;
        httpopts.responseType = 'text';

        var obsHttpEv : Observable<any> = this.httpClient.get(url, httpopts);
        this.subscribeObs(fnName, obsHttpEv, this._reposexport$, OrbiRequestBase.getLoadingBs());
    }

    public apiHealthcheck(orgName:string) {    
       
        let fnName = "apiHealthcheck";
        // let argsAttrs : any = filterState.marshallArgs();
        let somePageState : ApiPageStateInterface = ApiPageState_init();
    
        
        
        let url : string = OrbigithubService.API_ROUTES.healthcheck(orgName);
        let newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._healthcheck$);
        OrbiRequestBase.addRequest(newReq); 
        newReq.get(fnName);
    }


    public apiImportHistory(orgName:string) {
        var fnName = "apiImportHistory";
        var url : string = OrbigithubService.API_ROUTES.getimporthistory(orgName);
        let newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._importhistory$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName);
    }

    /*
    public apiGetRepoSummaryList(orgName:string) {    
       
        let fnName = "apiGetRepoSummaryList";
        let url : string = OrbigithubService.API_ROUTES.getreposummarylist(orgName);
        let newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._reposummarylist$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get();
    }
    */

    public apiGetCachedRepos(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) {    
       
        let fnName = "apiGetCachedRepos";
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._reposcached$, OrbiApiEntrypointKey.searchcacherepo, 
            filterState, true);
        myReq.getApiPages(pageState);
        /*
        let strNormalizedArg : string = this.normalizeArgs(OrbiApiEntrypointKey.searchcacherepo, filterState, pageState);
        let url : string = this.API_ROUTES.getCachedRepos(strNormalizedArg);
        let newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._reposcached$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName);
        */
    }

    public apiGetWebhookEvents(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) {
        var fnName = "apiGetWebhookEvents";
        let fromDate = filterState.fromDate;
        let toDate = filterState.toDate;
        if (! fromDate || !toDate || typeof fromDate.toISOString !== 'function' || typeof toDate.toISOString !== 'function') {
            console.error(fnName + " - invlid date types");
            throw new Error(fnName);
        }
        
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._webhookevents$, OrbiApiEntrypointKey.webhookevents, 
            filterState, false);
        myReq.getApiPages(pageState);
    }

    public apiGetEnvironments(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) {
        var fnName = "apiGetEnvironments";
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._githubenvironments$, OrbiApiEntrypointKey.environments, 
            filterState, false);
        myReq.getApiPages(pageState);
        /*
        var args : any = this.normalizeArgs(OrbiApiEntrypointKey.environments, filterState, pageState);
        var url : string = this.API_ROUTES.getGithubEnvironments(args);
        var newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._githubenvironments$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName); */
    }

    public apiGetSecrets(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) {
        var fnName = "apiGetSecrets";
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._githubsecrets$, OrbiApiEntrypointKey.secrets, 
            filterState, false);
        myReq.getApiPages(pageState);
        /*
        var args : any = this.normalizeArgs(OrbiApiEntrypointKey.secrets, filterState, pageState);
        var url : string = this.API_ROUTES.getGithubSecrets(args);
        var newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._githubsecrets$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName); */
    }

    public apiGetCachedPullrequests(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface)
    {    
        let fnName = "apiGetCachedPrs";
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._pullrequestscached$, OrbiApiEntrypointKey.searchcachepullrequest, 
            filterState, false);
        myReq.getApiPages(pageState);
        /*
        var args : any = this.normalizeArgs(OrbiApiEntrypointKey.searchcachepullrequest, filterState, pageState);
        var url : string = this.API_ROUTES.getCachedPullrequests(args);
        var newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._pullrequestscached$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName); */
    }


    public apiGetCachedCommits(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface)
        //orgName:string, reposet:string, fromDate:string, toDate:string, pageState : ApiPageStateInterface ) 
    {
        var fnName = "apiGetCachedCommits";
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._commitscached$, OrbiApiEntrypointKey.searchcachecommit, 
            filterState, false);
        myReq.getApiPages(pageState);
        /*
        var args : any = this.normalizeArgs(OrbiApiEntrypointKey.searchcachecommit, filterState, pageState);
        var url : string = this.API_ROUTES.getCachedCommits(args);
        var newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._commitscached$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName); */
    }


    public apiGetCachedCodeAnalysVulnAlerts(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface)
        // orgName:string, reposet:string, fromDate:string, toDate:string, pageState : ApiPageStateInterface ) 
    {    
        var fnName = "apiGetCachedCodeAnalysVulnAlerts";
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._codeanalysisvulnalerts$, OrbiApiEntrypointKey.searchcachecodeanalysisvulnalerts, 
            filterState, false);
        myReq.getApiPages(pageState);
        /*
        var args : any = this.normalizeArgs(OrbiApiEntrypointKey.searchcachecodeanalysisvulnalerts, filterState, pageState);        
        var url : string = this.API_ROUTES.getCachedCodeAnalysisVulnAlerts(args);
        var newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._codeanalysisvulnalerts$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName); */
    }

    public apiGetCachedReleases(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) // orgName:string, fromDate:string, toDate:string, pageState : ApiPageStateInterface) 
    {
        var fnName = "apiGetCachedReleases";
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._releasemgmtscached$, OrbiApiEntrypointKey.searchcacherelease, 
            filterState, false);
        myReq.getApiPages(pageState);
        /*
        var args : any = this.normalizeArgs(OrbiApiEntrypointKey.searchcacherelease, filterState, pageState);
        var url : string = this.API_ROUTES.getCachedReleases(args);
        var newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._releasemgmtscached$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName); */
    }

    public async apiGetRepoAccessSettings(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) //orgName : string, pageState : ApiPageStateInterface) 
    {
        var fnName = "_apiGetRepoAccessSettings";
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._repoaccesssettings$, OrbiApiEntrypointKey.accesscontrol, 
            filterState, false);
        myReq.getApiPages(pageState);
        /*
        var args : any = this.normalizeArgs(OrbiApiEntrypointKey.accesscontrol, filterState, pageState);
        var url : string = this.API_ROUTES.getRepoAccessSettings(args);
        var newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._repoaccesssettings$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName); */
    } 
    
    public apiGetRepoWorkflowsCached(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) // orgName : string, pageState : ApiPageStateInterface) 
    {
        var fnName = "apiGetRepoWorkflowsCached";    
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._workflowscached$, OrbiApiEntrypointKey.searchcacheworkflow, 
            filterState, false);
        myReq.getApiPages(pageState);
        /*
        var args : any = this.normalizeArgs(OrbiApiEntrypointKey.searchcacheworkflow, filterState, pageState);        
        var url : string = this.API_ROUTES.getRepoWorkflowsCached(args);
        var newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._workflowscached$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName); */
    }


    public apiGetRepoWorkflowRunsCached(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) // orgName : string, fromDate:string, toDate:string, pageState : ApiPageStateInterface) 
    {
        var fnName = "apiGetRepoWorkflowRunsCached";
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._workflowrunscached$, OrbiApiEntrypointKey.searchcacheworkflowrun, 
            filterState, false);
        myReq.getApiPages(pageState);
        /*
        var args : any = this.normalizeArgs(OrbiApiEntrypointKey.searchcacheworkflowrun, filterState, pageState);        
        var url : string = this.API_ROUTES.getRepoWorkflowRunsCached(args);
        var newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._workflowrunscached$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName); */
    }


    public apiGetUsers(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) 
    {
        var fnName = "apiGetUsers";
        // ## TBD needs to be implemented via db
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._users$, OrbiApiEntrypointKey.cachedusers, 
            filterState, false);
        myReq.getApiPages(pageState);
        /*
        var args : any = this.normalizeArgs(OrbiApiEntrypointKey.cachedusers, filterState, pageState);
        var url : string = this.API_ROUTES.getusers(args);
        var newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._users$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName); */
    }

    public apiGetTeams(filterState:OrbiFilterStateInterface, pageState : ApiPageStateInterface) 
    {
        var fnName = "_apiGetTeams";
        // ## TBD needs to be implemented via db
        let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName, this.httpClient, this.svcError, this._teams$, OrbiApiEntrypointKey.cachedteams, 
            filterState, false);
        myReq.getApiPages(pageState);
        /*
        var args : any = this.normalizeArgs(OrbiApiEntrypointKey.cachedteams, filterState, pageState);
        var url : string = this.API_ROUTES.getteams(args);
        var newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._teams$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName); */
    }

    public apiGetAuditResults(objType:OrbiObjectType, filterState:OrbiFilterStateInterface, importIds : any) 
    {
        let fnName = "apiGetAuditResults";
        if (0 < Object.keys(importIds).length) {
            Object.keys(importIds).forEach((currImportId:string) => {
                if (isNaN(Number(currImportId)) || (true !== importIds[currImportId] && false !== importIds[currImportId])) { debugger; }
                let extraArgs = [ `auditenvelopeid=latest`, `objectType=${objType}`, `importId=${currImportId}`];
                filterState.extraParamsNameValues = extraArgs;    
                let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName+`_${objType}_${currImportId}`, this.httpClient, this.svcError, this._auditresults$, OrbiApiEntrypointKey.auditresults, 
                filterState, true);                

                let initPageState = ApiPageState_init();
                myReq.getApiPages(initPageState);
            });
        } else {
            if (objType != OrbiObjectType.GithubWebhookEvent) { debugger;}
            let extraArgs = [ `auditenvelopeid=latest`, `objectType=${objType}`];
            filterState.extraParamsNameValues = extraArgs;    
            let myReq = OrbiRequestMwgithubApi.createApiRequest(fnName+`_${objType}_`, this.httpClient, this.svcError, this._auditresults$, OrbiApiEntrypointKey.auditresults, 
                filterState, true);

            let initPageState = ApiPageState_init();
            myReq.getApiPages(initPageState); 
        }
    }

    public apiGetMetricData(orgName:string, filterState:OrbiFilterStateInterface) 
    {
        let fnName = "apiGetMetricData";
        let pageState : ApiPageStateInterface = ApiPageState_init(); 
        let strNormalizedArg : string = ArgsHelperNormalizer.normalizeArgs(OrbiApiEntrypointKey.metricdata, '0', filterState, pageState);
        var url : string = OrbigithubService.API_ROUTES.getmetricdata(strNormalizedArg);
        let newReq = new OrbiRequest<ApiMwgithubResult>(fnName, this.httpClient, this.svcError, url, this._metricdata$);
        OrbiRequestBase.addRequest(newReq);
        newReq.get(fnName);
    }
}
