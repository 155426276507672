import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'oanpipefilterwithcallback',
    pure: false
})
export class OanPipeFilterWithCallback implements PipeTransform {
    transform(items: any[], cb: (item : any) => boolean): any {
        if (!items || !cb) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => cb(item));
    }
}

@Pipe({
    name: 'oanpipefilterbyattrNameAndValue',
    pure: false
})
export class OanPipeFilterByAttrNameAndValue implements PipeTransform {
    transform(items: any[], item : any, attrName:string, attrValue:any) : any[] {
        let r = [] as any[]
        if (Array.isArray(items)) {
            r = items.filter((item:any) => item[attrName] == attrValue);
        } 

        return r;
    }
}

@Pipe({
    name: 'oanpipearraymap',
    pure: false
})
export class OanPipeArrayMap implements PipeTransform {
    transform(items: any[], attrName:string) : any[] {
        let r = [] as any[]
        if (Array.isArray(items)) {
            r = items.map((item:any) => item[attrName]);
        } 

        return r;
    }
}


@Pipe({
    name: 'oanpluck',
    pure: true
})
export class OanPluckPipe implements PipeTransform {
    transform (input: any[], key: string): any {
        return input.map(value => value[key]);
    }
}
