import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, ReplaySubject, Subject, SubscriptionLike, throwError} from 'rxjs';
import { DecimalPipe} from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { OrbiHttpOptions } from 'src/app/cmn/svc/orbibackend.types';
import { OrbiApiResult,OrbiApiResultPayload, OrbiApiProfileData } from '@danclarke2000/gitrospectdto';

// import * as internal from 'stream';
import { OrbiRequest, OrbiRequestBase } from 'src/app/cmn/svc/orbirequest';
import { OrbibackendPublicService } from './orbibackend.public.service';
import { OanHttpUtils } from '../cmn/utils/oanhttputils';
import { OangnotificationsErrorId, OangnotificationsService } from 'src/oang/svc/oangnotifications.service';
import { OanDebugutils } from '../cmn/utils/OanDebugutils';
import { OrbiBrowserStorageItemId, OrbiBrowserStorageService } from './orbibrowserstorage.service';

interface ProfileStorageState {
    stateVersion : string;
    orgName : string | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class OrbiprofileService 
{
    private apiRoot2 : string | undefined;
    private composeUrl(path:string) {
        if (! this.apiRoot2) {
            this.apiRoot2 = this.svcOrbiBePublic.getUrlbaseProfileapi();
        }

        return this.apiRoot2 + path;
    };

    private readonly API_ROUTES = {
        getprofileinfo: () => this.composeUrl(`/profileinfo`),
        getprofiledata: () => this.composeUrl(`/profiledata`),
        postprofiledata: () => this.composeUrl(`/profiledata`),
        userprefs: () => this.composeUrl(`/userprefs`),
    };

    private _profiledata$ = new ReplaySubject<OrbiApiResult>();
    private _profileinfo$ = new ReplaySubject<OrbiApiResult>();
    private _userprefs$ = new ReplaySubject<any>();
    private _tenantInfo = undefined;
    private _userInfo = undefined;
    private _userPrefs = undefined;
    private _orgName : string | undefined;
    private _profileData : OrbiApiProfileData | undefined = undefined;
    constructor(
        private svcOrbiBePublic : OrbibackendPublicService,
        private httpClient: HttpClient, 
        private svcError : OangnotificationsService,
        public svcStorage : OrbiBrowserStorageService) 
    {}

    getUserInfo()
    {
        return this._userInfo;
    }

    getTenantInfo()
    {
        return this._tenantInfo;
    }

    getProfileData()
    {
        return this._profileData;
    }

    get profileinfo$() { return this._profileinfo$.asObservable(); }
    get profiledata$() { return this._profiledata$.asObservable(); }
    get userprefs$() { return this._userprefs$.asObservable(); }
    
    public subscribeObs(fnName: string, urlForCtxt:string, obsHttpEv : Observable<any>, bsVal: ReplaySubject<OrbiApiResult>, bsLoading: BehaviorSubject<any>) {
        let ctxtName = `orbiprofile/${fnName}`;
        bsLoading.next({ isLoading: true, msg: "", ctxt: ctxtName });
        let urlForCtxt2 = urlForCtxt;
        obsHttpEv.subscribe({
            complete: () => {
            },
            next: (val: any) => {
                let body : OrbiApiResult = val.body;                
                bsVal.next(body);
                bsLoading.next({ isLoading: false, msg: "", ctxt: ctxtName });                
            },
            error: (err:any) => {
                bsLoading.next({ isLoading: false, msg: "", ctxt: ctxtName });
                console.error(fnName + ": " + JSON.stringify(err)); 
                throwError(err);
            }
        });
    }

    public async loadActiveOrgName() {
        let defaultOrgName = await this.svcStorage.readItem(OrbiBrowserStorageItemId.DefaultOrgName);
        if (defaultOrgName && "string" == typeof defaultOrgName.orgName) {
            this._orgName = defaultOrgName.orgName;
        } 

        return this._orgName;
    }

    public async setActiveOrgName(orgName:string | undefined) : Promise<void> {
        if ("string" == typeof orgName) {
            this._orgName = orgName;         
            let myVal : ProfileStorageState = { stateVersion : "2022m09d12_ProfileStorageState", orgName : orgName };       
            await this.svcStorage.storeItem(OrbiBrowserStorageItemId.DefaultOrgName, myVal);
        } else {
            // this can happen, e.g. if a user loses access to the org which was previous active
            this._orgName = undefined;
            await this.svcStorage.storeItem(OrbiBrowserStorageItemId.DefaultOrgName, undefined);
        }
    }

    public getActiveOrgName() : string | undefined {
        return this._orgName;
    }

    public apiProfileInfo() {
        var fnName = "apiProfileInfo(";
        var url : string = this.API_ROUTES.getprofileinfo();
        var obsHttpEv : Observable<HttpEvent<OrbiApiResult>> = this.httpClient.get<OrbiApiResult>(url, OrbiHttpOptions.getHttpOptions());
        this.subscribeObs(fnName, url, obsHttpEv, this._profileinfo$, OrbiRequestBase.getLoadingBs());
    }

    public apiProfileData() {
        var fnName = "apiProfileData(";
        var url : string = this.API_ROUTES.getprofiledata();
        var obsHttpEv : Observable<HttpEvent<OrbiApiResult>> = this.httpClient.get<OrbiApiResult>(url, OrbiHttpOptions.getHttpOptions());
        this.subscribeObs(fnName, url, obsHttpEv, this._profiledata$, OrbiRequestBase.getLoadingBs());
    }

    public apiProfileUpdate(updateProfile:any) {
        var fnName = "apiProfileUpdate(";
        var url : string = this.API_ROUTES.postprofiledata();
        var opts : any = OrbiHttpOptions.getHttpOptions();
        return this.httpClient.post<any>(url, updateProfile, opts);
    }

    public apiGetUserBrowserStorageKey() {
        var fnName = "apiGetUserBrowserStorageKey(";
        var url : string = this.API_ROUTES.userprefs();
        var obsHttpEv : Observable<HttpEvent<any>> = this.httpClient.get<any>(url, OrbiHttpOptions.getHttpOptions());
        this.subscribeObs(fnName, url, obsHttpEv, this._userprefs$, OrbiRequestBase.getLoadingBs());
    }
}
