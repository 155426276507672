import { OrbiAuditResultBpEffectiveness, OrbiAuditResultExplain, OrbiAuditResultRelevance, OrbiAuditResultTest, OrbiAuditResultTestGrouping, OrbiObjectType } from "@danclarke2000/gitrospectdto";
import { OanDebugutils } from "../utils/OanDebugutils";
import { OanObjectStuff } from "../utils/oanobjectstuff";

class Impl
{
    public auditSectionHeaders : any = {};
    public auditRelevanceCatalog : any = {};
    public auditTestCatalog : any = {};
// this is a way to retire checks
    private auditTestHidden : any = {};
    private auditTestLabels : any = {};
    private auditTestLabelsSub : any = {};
    

    constructor() {
        // not clear what this gets used for..
        this.auditTestCatalog[OrbiAuditResultTest.Repo_TooManyPrdBranches] = { AccessControl: false, Authorization:false, ChangeControl:true, ContinuousDeployment:false };
        this.auditTestCatalog[OrbiAuditResultTest.Repo_BranchNameConvention] = { AccessControl: false, Authorization:false, ChangeControl:true, ContinuousDeployment:false };
        this.auditTestCatalog[OrbiAuditResultTest.Repo_Dk_AllKeysRecent] = { AccessControl: true, Authorization:false, ChangeControl:false, ContinuousDeployment:false };
        this.auditTestCatalog[OrbiAuditResultTest.Repo_Dk_AllDeployKeysReadOnly] = { AccessControl: true, Authorization:false, ChangeControl:false, ContinuousDeployment:false };
        this.auditTestCatalog[OrbiAuditResultTest.Repo_Bp_Defaultbranchnobp] = { AccessControl: false, Authorization:false, ChangeControl:true, ContinuousDeployment:false };
        this.auditTestCatalog[OrbiAuditResultTest.Repo_Bp_NoBpOnNonPrdBranches] = { AccessControl: false, Authorization:false, ChangeControl:true, ContinuousDeployment:false };
        this.auditTestCatalog[OrbiAuditResultTest.Repo_Bp_AmbiguousBranchProtection] = { AccessControl: false, Authorization:false, ChangeControl:true, ContinuousDeployment:false };
        this.auditTestCatalog[OrbiAuditResultTest.Repo_Bp_DefaultBranch_Effective] = { AccessControl: false, Authorization:false, ChangeControl:true, ContinuousDeployment:false };
        this.auditTestCatalog[OrbiAuditResultTest.Repo_Bp_Effective] = { AccessControl: false, Authorization:false, ChangeControl:true, ContinuousDeployment:false };
        this.auditTestCatalog[OrbiAuditResultTest.Repo_Bp_PatternWithWildcard] = { AccessControl: false, Authorization:false, ChangeControl:true, ContinuousDeployment:false };

        // audit relevance catalog
        this.auditRelevanceCatalog['en'] = {};
        this.auditRelevanceCatalog['en'][OrbiAuditResultRelevance.Relevant] = "Relevant";
        this.auditRelevanceCatalog['en'][OrbiAuditResultRelevance.NotRelevant] = "Not relevant";
        this.auditRelevanceCatalog['en'][OrbiAuditResultRelevance.AffectsOrgAcc] = "Affects the github organization access control";
        this.auditRelevanceCatalog['en'][OrbiAuditResultRelevance.AffectsProd] = "Affects prod";
        this.auditRelevanceCatalog['en'][OrbiAuditResultRelevance.ProdRepo] = "Prod repo";
        this.auditRelevanceCatalog['en'][OrbiAuditResultRelevance.ProdBranch] = "Prod branch";
        this.auditRelevanceCatalog['en'][OrbiAuditResultRelevance.PublicRepo] = "Public repo";
        this.auditRelevanceCatalog['en'][OrbiAuditResultRelevance.PR_MergedToPrdBrn] = "Merged to prod branch";
        this.auditRelevanceCatalog['en'][OrbiAuditResultRelevance.PR_ChangeIsSignificant] = "Significant change";

        // audit section headers
        this.auditSectionHeaders['en'] = {};
        this.auditSectionHeaders['en'][OrbiObjectType.Global] ="Global",
        this.auditSectionHeaders['en'][OrbiObjectType.GithubWebhookCallback] ="Github event",
        this.auditSectionHeaders['en'][OrbiObjectType.GithubCommitsForRepo] ="Commit checks",
        this.auditSectionHeaders['en'][OrbiObjectType.GithubRepoAccessControl] = "Github permission checks";
        this.auditSectionHeaders['en'][OrbiObjectType.GithubPrsForRepo] = "Pull request checks";
        this.auditSectionHeaders['en'][OrbiObjectType.GithubReleaseForRepo] = "Github release checks";
        this.auditSectionHeaders['en'][OrbiObjectType.GithubRepo] = "Github repositories checks";
        this.auditSectionHeaders['en'][OrbiObjectType.GithubEnvironment] = "Github environment checks";
        this.auditSectionHeaders['en'][OrbiObjectType.GithubRepo+"Public"] = "Public repositories checks";
        this.auditSectionHeaders['en'][OrbiObjectType.GithubSecret] = "Github secrets checks";
        this.auditSectionHeaders['en'][OrbiObjectType.GithubUser] = "Github user checks";

        // hide some tests so not shown to user
        this.auditTestHidden[OrbiAuditResultTest.SomeTest] = true;

        // these labels are used 
        this.auditTestLabels['en'] = {};
        //this.auditTestLabels['en'][OrbiAuditResultTest.AuditEvent] ="Audit event",
        this.auditTestLabels['en'][OrbiAuditResultTest.AlwaysFinding] = "",
        this.auditTestLabels['en'][OrbiAuditResultTest.SomeTest] ="Audit event some test",
        this.auditTestLabels['en'][OrbiAuditResultTest.Commit_hasAssociatedPr] ="Commit is not associated to a PR.  Commits should be merged to prod branches via pull requests rather than pushed directly.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Commit_forcedPush] = "This commit is a forced push. Forced pushes overwrite data on the remote repository, resulting in loss of history. ",
        this.auditTestLabels['en'][OrbiAuditResultTest.Commit_mergeWithoutPr] = "Commit is merged without a PR. ",
        this.auditTestLabels['en'][OrbiAuditResultTest.Permission_ACLHasDirectUser]="This permission refers directly to a user, best practice is to define permissions with reference to teams.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Permission_ACLHasDirectUserWhoIsNotAdmin]="This permission refers directly to a user who is not admin, best practice is to define permissions with reference to teams.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Org_Githubappauthz] = "A github app was authorized.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Org_Githubappconfig] = "A github app was reconfigured.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Org_Mktpurchase] = "A marketplace puchase ocurred.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Org_Action] = "This action affects the organization.";
        this.auditTestLabels['en'][OrbiAuditResultTest.PR_AuthorAssoc]="This PR author is not an org member.";
        this.auditTestLabels['en'][OrbiAuditResultTest.PR_Review_IsApproved]="This PR has not been approved.  Pull request approvals are a good way to validate that the change was properly reviewed.";
        this.auditTestLabels['en'][OrbiAuditResultTest.PR_Review_SodSubmitterApprover]="The PR has the same submitter and reviewer, so the review is not independent.";
        this.auditTestLabels['en'][OrbiAuditResultTest.PR_Review_RequestedIndendent]=this.auditTestLabels['en'][OrbiAuditResultTest.PR_Review_SodSubmitterApprover];
        this.auditTestLabels['en'][OrbiAuditResultTest.PR_Review_hasComments]="This PR has not been commented indiciating it was not properly reviewed.";
        this.auditTestLabels['en'][OrbiAuditResultTest.DataAcquisitionOk] = "Problem to acquire data"; 
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_TooManyPrdBranches] = "The repo has too high number of production branches to effectively manage change."; 
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_PrdRepoWithNoPrdBranches] = "The repo is a production repo but has no production branches.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_BranchNameConvention] = "This repo has branch names which violate the naming convention.";
        
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_HasSecretScanning]  = "This repo has no secret scanning workflow.",
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_HasStaticAnalysis] = "This repo has no static analysis workflow.",
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_HasCodeOwners] = "This repo doesnt define codeowners.",
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Dk_AllKeysRecent] = "This repo has oustanding deploy keys which are old and may have been compromised.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Dk_AllDeployKeysReadOnly] = "This repo has deploy keys which allow modification without proper access control.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Bp_Defaultbranchnobp] = "This repo has no branch protection on the default branch.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Bp_None] = "This repo does not define branch protection.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Bp_LiveBranch_Effective] = "This repo does not have effective branch protection.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Bp_DefaultBranch_Effective] = "This repo has ineffective branch protection on the default branch.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Bp_Effective] = "This repo has ineffective branch protection on one or more live branches.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Bp_NoBpOnNonPrdBranches] = "This repo has branch protection on non-live branches, which can allow a bypass of security settings relating to environment secrets.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Bp_AmbiguousBranchProtection] = "This repo has ambiguous branch protection patterns which may cause surprises in effectiveness.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Bp_PatternWithWildcard] = "This repo has wildcards in branch protection patterns which may cause surprises in effectiveness.";
        this.auditTestLabels['en'][OrbiAuditResultTest.RepoEnv_hasDeploymentBranchPolicy] = "This repo environment object does not have a correct deployment branch policy.";
        this.auditTestLabels['en'][OrbiAuditResultTest.RepoEnv_hasSecrets] = "This repo environment object does not have define secrets.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Action] = "This affects repos.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Member] = "This affects repo authorization.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Branch_Creation] = "A branch was created.",
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Branch_Deletion] = "A branch was deleted.",
        this.auditTestLabels['en'][OrbiAuditResultTest.Repo_Fork_Exists] = "A fork has been created or modified.",
        
        this.auditTestLabels['en'][OrbiAuditResultTest.Secrets_IsEnvSecret] = "This github secret is not part of a github environment which means that it is not protected.";
        this.auditTestLabels['en'][OrbiAuditResultTest.Secrets_IsRotated] = "This github secret has not been rotated.";
        this.auditTestLabels['en'][OrbiAuditResultTest.User_Ok] = "This user is not compliant.";
        this.auditTestLabels['en'][OrbiAuditResultTest.User_HasName] = "This user has not set their real name in their profile which makes it harder to track the person real world identity is.";
        this.auditTestLabels['en'][OrbiAuditResultTest.User_HasCompany] = "This user has not set their company name in their profile which makes it harder to track the person real world identity is.";
        this.auditTestLabels['en'][OrbiAuditResultTest.User_HasEmail] = "This user has not set their email in their profile which makes it harder to track the person real world identity is.";
        this.auditTestLabels['en'][OrbiAuditResultTest.User_hasPublicKeysSsh] = "This user has public ssh keys which allow them to make changes to files in git without 2FA or other access policy.";
        this.auditTestLabels['en'][OrbiAuditResultTest.User_hasPublicKeysGpg] = "This user has public gpg keys which allow them to make changes to files in git without 2FA or other access policy.";
        this.auditTestLabels['en'][OrbiAuditResultTest.User_hasPublicKeysSgn] = "This user has public signing keys which allow them to sign commits.";
        this.auditTestLabels['en'][OrbiAuditResultTest.User_hasPublicKeysSshRotated] = "This user has public ssh keys which are not rotated.";
        this.auditTestLabels['en'][OrbiAuditResultTest.User_hasPublicKeysGpgRotated] = "This user has public gpg keys which are not rotated.";
        this.auditTestLabels['en'][OrbiAuditResultTest.User_hasPublicKeysSgnRotated] = "This user has not rotated their signing keys.";      
        
        this.auditTestLabels['en'][OrbiAuditResultTest.Commit_hasAssociatedPr] = "This commit has no associated PR.";      
        this.auditTestLabels['en'][OrbiAuditResultTest.Commit_forcedPush] = "This commit is a forced push that overrides normal process.";      

        this.auditTestLabels['en'][OrbiAuditResultTest.Release_Generic] = "A release has been created.";      
        this.auditTestLabels['en'][OrbiAuditResultTest.Workflow_Generic] = "A workflow exists.";      
        this.auditTestLabels['en'][OrbiAuditResultTest.Workflowrun_Generic] = "A workflow run occurred.";      
        this.auditTestLabels['en'][OrbiAuditResultTest.Team_Mod] = "A team has been modified.";      
        this.auditTestLabels['en'][OrbiAuditResultTest.Team_Authz] = "The permisions of a team have been modified.";      
        this.auditTestLabels['en'][OrbiAuditResultTest.Team_Generic] = "An event related to a team.";      
        this.auditTestLabels['en'][OrbiAuditResultTest.Team_Member] = "Team membership has changed.";      
        this.auditTestLabels['en'][OrbiAuditResultTest.Deployment_Generic] = "A deployment has occurred.";      

        // explain strings
        this.auditTestLabelsSub['en'] = {};
        this.auditTestLabelsSub['en'][OrbiAuditResultExplain.GHAPP_Authzd] = "A github app has been authorized.",
        this.auditTestLabelsSub['en'][OrbiAuditResultTest.Repo_Bp_Effective] = "Rules not effective",
        this.auditTestLabelsSub['en'][OrbiAuditResultTest.Repo_Bp_ApprovingReviewCount] = "insufficient approving review count",
        this.auditTestLabelsSub['en'][OrbiAuditResultTest.Repo_Bp_DismissStaleReviews] = "stale reviews allowed",
        this.auditTestLabelsSub['en'][OrbiAuditResultTest.Repo_Bp_CodeownerReview] = "code owner review not required",
        this.auditTestLabelsSub['en'][OrbiAuditResultTest.Repo_Bp_IgnoreApprovalsFromContributers] = "reviews from contributers are allowed",
        this.auditTestLabelsSub['en'][OrbiAuditResultTest.Repo_Bp_AdminEnforced] = "not enforced for admins",
        this.auditTestLabelsSub['en'][OrbiAuditResultTest.Repo_Bp_RequireConversationResolution] = "conversations may be left open",
        this.auditTestLabelsSub['en'][OrbiAuditResultTest.Repo_Bp_ForcePush] = "force pushes are allowed",
        this.auditTestLabelsSub['en'][OrbiAuditResultTest.Repo_Bp_RequireSignedCommits] = "signed commits not required"
        this.auditTestLabelsSub['en'][OrbiAuditResultTest.Repo_Bp_AmbiguousBranchProtection] = "the rule pattern is ambiguous"
    }

    private getLocaleValue(ctxt:string, strDb:string, valObj:any, locale:string, valKey:string, breakOnNotFound : boolean)
    {
        let r : string = '';
        let localeStrs = valObj[locale];
        if (localeStrs) 
        {
            r = valObj[locale][valKey];
        }

        if (undefined == r) {
            if (breakOnNotFound) {
                OanDebugutils.debuggerWrapper(`Cannot find ${valKey} in ${strDb}`);
            }
        }

        return r;
    }

    isTestKeyHidden(testKey:string) 
    {
        return this.auditTestHidden[testKey];
    }

    getAuditTestKeysWithGrouping()
    {
        /*
        let auditKeyResults : any = {};
        Object.values(OrbiAuditResultTest).forEach( (currAuditTestKey:string) => {
            if (true != this.auditTestHidden[currAuditTestKey])
            {
                let [ grouping, testId ] : string[] = currAuditTestKey.split(/_(.*)/s);
                OanObjectStuff.ensureKeyExists(auditKeyResults, grouping, []);
                auditKeyResults[grouping].push(currAuditTestKey);
            }
        }); */

        let auditTestGrouping = OrbiAuditResultTestGrouping;
        return auditTestGrouping;
    }

    getAuditTestLabel(locale:string, auditTestKey:string, findingArgs?:any)
    {
        const fnName = 'getAuditTestLabel';
        let breakOnNotFound = false;
        let r = this.getLocaleValue(fnName, "this.auditTestLabels", this.auditTestLabels, locale, auditTestKey, breakOnNotFound);
        if (findingArgs) {
            switch (auditTestKey) {
                case OrbiAuditResultTest.Repo_Bp_Effective:
                    let myBpEffectiveness : OrbiAuditResultBpEffectiveness = <OrbiAuditResultBpEffectiveness>(findingArgs);
                    let tldrExplain = this.getLocaleValue(fnName, "this.auditTestLabelsSub", this.auditTestLabelsSub, locale, auditTestKey, false);
                    let explainStrs : string[] = Object.keys(myBpEffectiveness).map((currRulePattern:string) =>  {
                        let childExplains : string[] = Object.keys(myBpEffectiveness[currRulePattern]).map((currTestKey:string) => this.getLocaleValue(fnName, "this.auditTestLabelsSub", this.auditTestLabelsSub, locale, currTestKey, false));
                        let result = currRulePattern + ': ' + childExplains.join(',') 
                        + ". ";
                        return result;
                    });
                    r += " " + tldrExplain + "; ";
                    for (let i=0; i<explainStrs.length; ++i) {
                        r += `(${i+1}) ` + explainStrs[i]
                    }
                    r.slice(0, -1);
                    break;
            }
        }

        return r;
    }

    getAuditRelevanceLabel(locale:string, checksArea:string)
    {
        const fnName = 'getAuditRelevanceLabel';
        let breakOnNotFound = false;
        let r = this.getLocaleValue(fnName, "this.getAuditRelevanceLabel", this.auditRelevanceCatalog, locale, checksArea, breakOnNotFound);
        return r;        
    }

    getAuditSectionHeader(locale:string, checksArea:string)
    {
        const fnName = 'getAuditSectionHeader';
        let breakOnNotFound = false;
        let r = this.getLocaleValue(fnName, "this.auditSectionHeaders", this.auditSectionHeaders, locale, checksArea, breakOnNotFound);
        return r;        
    }
}


export class OanAnalyzerCatalog
{
    private static _impl : Impl = new Impl();

    public static getAuditTestKeys() : any
    {
        let r = Object.values(OrbiAuditResultTest).filter( (currTestKey:string) => true != OanAnalyzerCatalog._impl.isTestKeyHidden(currTestKey));
        return r;
    }

    public static getAuditTestKeyGroupings() : any
    {
        let r = OanAnalyzerCatalog._impl.getAuditTestKeysWithGrouping();
        return r;
    }

    public static getAuditTestLabel(locale:string, auditTestKey:string, findingArgs?:any) : any
    {
        let r = OanAnalyzerCatalog._impl.getAuditTestLabel(locale, auditTestKey, findingArgs);
        return r;
    }

    public static getAuditSectionHeader(locale:string, auditTestKey:string) : any
    {
        let r = OanAnalyzerCatalog._impl.getAuditSectionHeader(locale, auditTestKey);
        return r;
    }

    public static getAuditRelevanceLabel(locale:string, auditRelevanceKey:string) : any
    {
        let r = OanAnalyzerCatalog._impl.getAuditRelevanceLabel(locale, auditRelevanceKey);
        return r;
    }

    public static getImpacts(auditTestKey:string) : any
    {
        let impacts = OanAnalyzerCatalog._impl.auditTestCatalog[auditTestKey];
        return impacts;
    }
}
