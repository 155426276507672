import { Injectable } from '@angular/core';

import { OangError, OangErrorCode } from '../model/OangError';
import { OangEvent, OangEventCode } from '../model/OangEvent';

@Injectable({
  providedIn: 'root'
})
export class OangeventsService {
    private _targetsApp : any[] = [];
    private _targetsCookie : any[] = [];

    constructor() { 
    }

    private emitEvent(ctxt : string, targets : any[], code : OangEventCode, param? : any) {
        var e : OangEvent = new OangEvent(code, ctxt, param);
        
        targets.forEach( (el : any) => {
            el(e); 
        });
    }

    public emitEventApp(ctxt : string, code : OangEventCode, param? : any) {
        var myTargets : any[] = this._targetsApp;
        this.emitEvent(ctxt, myTargets, code, param);
    }

    public emitEventCookie(ctxt : string, code : OangEventCode, param? : any, debugMsg? : string) {
        var myTargets : any[] = this._targetsCookie;
        this.emitEvent(ctxt, myTargets, code, param);
    }

    public registerTargetApp(name : string, cb : (ev : OangEvent) => void) { 
        this._targetsApp.push(cb);
    }

    public registerTargetCookie(name : string, cb : (ev : OangEvent) => void) { 
        this._targetsCookie.push(cb);
        if (1 < this._targetsCookie.length) { 
            alert("registerTargetCookie.lenght=" +  this._targetsCookie.length);
        }
    }
}
