<p>Welcome to gitrospect ! </p> 

<!-- 
<app-notification-pagealert *ngIf="uiState.hasSummaryAlert">
    <ngb-alert   [type]="uiState.hasSummaryAlertType" (closed)="uiState.dismissPageAlert($event)">
        <h4 class="summary">{{ uiState.hasSummaryAlertSummary }}</h4>
        <p *ngFor="let currDescLine of uiState.hasSummaryAlertDesc">{{ currDescLine }}</p>
        <button *ngIf="uiState.hasSummaryAlertShowButton['Reload']"  class="btn btn-primary" (click)="uiState.reloadPage()">Reload</button> 
        <button *ngIf="uiState.hasSummaryAlertShowButton['SignUp']"  class="btn btn-primary" (click)="uiState.signupOrg()">Sign-up</button> 
        <a *ngIf="uiState.hasSummaryAlertShowButton['GithubReports']" class="btn btn-primary" href="/mwgithub">GitHub reports</a>
    </ngb-alert>
</app-notification-pagealert>        
-->
<div class="row mb-4 mx-0">
    <div class="col-2 dashboard-sidebar">
        <!-- SIDEBAR  -->

        <!-- ngbNav #githubContent="ngbNav" [(activeId)]="tabState.tabActive" (navChange)="navChange($event)" --> 
        <ul class="nav-pills" orientation="vertical">        
            <li class="nav-link orbi-nav-link-title"><a routerLink="/mwgithubdashboard">Dashboard</a></li>
            <li class="nav-link orbi-nav-link-title"><a routerLink="/mwgithub" >Github reports</a></li>
            
            <!-- (click)="this.tabState.setActiveTab(this.navItemMetricsTopLists) 
                    'topmetrics':   this.navItemMetricsTopLists,
                'codingmetricsbyuser' : this.navItemCodingMetricsByUser, 
                'codingmetricsbyrepo' : this.navItemCodingMetricsByRepo, 
                'reviewingmetricsbyrepo' : this.navItemReviewingMetrics
            -->
            <li class="nav-link orbi-nav-link-title"><a 
                [routerLink]="['/mwgithubmetrics/topmetrics']">Top metrics</a></li>

            <li class="nav-link orbi-nav-link-title"><a 
                [routerLink]="['/mwgithubmetrics/codingmetricsbyuser']">Coding metrics by users</a></li>

            <li class="nav-link orbi-nav-link-title"><a 
                [routerLink]="['/mwgithubmetrics/codingmetricsbyrepo']">Coding metrics by repo</a></li>
            <li class="nav-link orbi-nav-link-title"><a 
                [routerLink]="['/mwgithubmetrics/reviewingmetricsbyrepo']">Reviewing metrics by repo</a></li>        
        </ul>
    </div>

    <!-- 
        -- Dashboard
    -->

    <div class="col-10 dashboard-main">        
        <div class="row">
            <div class="col-12 dashboard-main__filters">
                <div class="inner-filters">                                 
                    <!-- start filter row 1  -->
                    <div class="row" >
                        <div class="col-12 d-flex " (click)="uiState.setTabStateMetricFiltersCollapse($event,!tabStateMetricFilters.isCollapsed, 'd-flex' )">                           
                            <div class="orbi-filters-tabs navbar" >                                    
                                <ul ngbNav #ngbNavMetricFilter="ngbNav"  [(activeId)]="uiState.qfMetricFilterState.selectedTab" class="nav-tabs"
                                    (click)="uiState.qfMetricFilterStateChange('tabId', $event)">
                                    <li [ngbNavItem]="tabStateMetricFilters.navItemUsers" class="nav-item active" (click)="uiState.setTabStateMetricFiltersCollapse($event,false, tabStateMetricFilters.navItemUsers)">
                                        <a ngbNavLink >{{tabStateMetricFilters.titleForTabUsers}} : {{uiState.qfMetricFilterState.selectedUsersOut.length}} of {{tabStateMetricFilters.allUserNames.length}} selected</a>
                                        <ng-template ngbNavContent> 
                                            <h2 class="orbi-section-title">Users:</h2>              
                                            <app-orbiwidgetnameselector
                                                #tabStateMetricFiltersUsers
                                                [allpossiblevalues]="tabStateMetricFilters.allUserNames"
                                                [inselectedvalues]="uiState.qfMetricFilterState.selectedUsersIn"
                                                (outselectedvalues)="uiState.qfMetricFilterState.selectedUsersOut"
                                                (change)="uiState.qfMetricFilterStateChange('users', $event)"
                                                >
                                            </app-orbiwidgetnameselector>                    
                                        </ng-template>
                                    </li> 
                                    <li [ngbNavItem]="tabStateMetricFilters.navItemTeams" class="nav-item" (click)="uiState.setTabStateMetricFiltersCollapse($event,false, tabStateMetricFilters.navItemTeams)">
                                        <a ngbNavLink>{{tabStateMetricFilters.titleForTabTeams}} : {{uiState.qfMetricFilterState.selectedTeamsOut.length}} of {{tabStateMetricFilters.allTeamNames.length}} selected</a>
                                        <ng-template ngbNavContent>
                                            <h2 class="orbi-section-title">Teams: </h2>
                                            <app-orbiwidgetnameselector
                                                #tabStateMetricFiltersTeams
                                                [allpossiblevalues]="tabStateMetricFilters.allTeamNames"
                                                [selectedvaluesin]="uiState.qfMetricFilterState.selectedTeamsIn"
                                                (selectedvaluesout)="uiState.qfMetricFilterState.selectedTeamsOut"
                                                (change)="uiState.qfMetricFilterStateChange('teams', $event)">
                                            </app-orbiwidgetnameselector>  
                                        </ng-template>
                                    </li>        
                                    <li [ngbNavItem]="tabStateMetricFilters.navItemRepos" class="nav-item" (click)="uiState.setTabStateMetricFiltersCollapse($event,false, tabStateMetricFilters.navItemRepos)">
                                        <a ngbNavLink>{{tabStateMetricFilters.titleForTabRepos}} : {{uiState.qfMetricFilterState.selectedReposOut.length}} of {{tabStateMetricFilters.allRepoNames.length}} selected</a>
                                        <ng-template ngbNavContent>
                                            <h2 class="orbi-section-title">Repositories: </h2>
                                            <app-orbiwidgetnameselector
                                                #tabStateMetricFiltersRepos
                                                [allpossiblevalues]="tabStateMetricFilters.allRepoNames"
                                                [inselectedvalues]="uiState.qfMetricFilterState.selectedReposIn"
                                                (outselectedvalues)="uiState.qfMetricFilterState.selectedReposOut"
                                                (change)="uiState.qfMetricFilterStateChange('repos', $event)">
                                            </app-orbiwidgetnameselector>  
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="tabStateMetricFilters.navItemDates" class="nav-item" (click)="uiState.setTabStateMetricFiltersCollapse($event,false, tabStateMetricFilters.navItemDates)">
                                        <a ngbNavLink>{{tabStateMetricFilters.titleForTabDates}} : {{uiState.qfMetricDateFilter.dateLabel}}</a>
                                        <ng-template ngbNavContent>
                                            <h2 class="orbi-section-title">Dates: </h2>    
                                            <app-orbiwidgetdateselector 
                                                #tabStateMetricFiltersDates
                                                [quickValue]="uiState.qfMetricDateFilter!.quickValue"
                                                [dateSelectorType]="uiState.qfMetricDateFilter!.dateSelectorType"
                                                [inThePastTypeThing]="uiState.qfMetricDateFilter!.inThePastTypeThing"
                                                [inThePastNumThings]="uiState.qfMetricDateFilter!.inThePastNumThings"
                                                [fromDate]="uiState.qfMetricDateFilter!.fromDate"
                                                [toDate]="uiState.qfMetricDateFilter!.toDate"
                                                (change)="uiState.qfMetricFilterStateChange('dates', $event)">                                        
                                            </app-orbiwidgetdateselector>
                                        </ng-template>
                                    </li>
                                </ul>                                    
                            </div>           
                            <div class="orbi-toggle-filter ml-auto">
                                <h4 class="mr-2">{{tabStateMetricFilters.isCollapsed ? 'Show' : 'Collapse' }} panel </h4>
                                <button class="btn btn-light" type="button" (click)="uiState.setTabStateMetricFiltersCollapse($event, !tabStateMetricFilters.isCollapsed, 'togglebtn')"><app-orbiicon iconName="faCaretDown"></app-orbiicon></button>
                            </div>               
                        </div>
                        <div class="col-12">
                            <div [ngbCollapse]="tabStateMetricFilters.isCollapsed" [ngbNavOutlet]="ngbNavMetricFilter" class="orbi-filters-pane collapse navbar-collapse">
                                <p> Content area for accordion </p>
                            </div>
                        </div>
                    </div>
                </div> <!-- inner filters -->
            </div>
        
        <div class="row dashboard-shortcut mb-4">
            <div class="col-12 mb-3">
                <div class="dash-tile">
                    <hr>
                    Quick filter 
                    <span>&nbsp;Users and teams:&nbsp;
                        <span  class="btn-group" role="group" ngbRadioGroup  aria-label="Quick filter status">
                            <ng-container *ngFor="let option of quickFilterUserSelector | keyvalue: uiState.sortNot">
                                <label ngbButtonLabel [ngClass]="{active: option.key == uiState.qfMetricFilterState.selectedUsersQfValue}"  class="btn btn-primary" [for]="option.key" >                            
                                    <input ngbButton type="radio" [value]="option.key" class="btn-check" [id]="option.key" 
                                        [(ngModel)]="uiState.qfMetricFilterState.selectedUsersQfValue"
                                        (ngModelChange)="uiState.qfMetricQuickFilterStateChange('users', $event)">
                                    <app-orbiicon  [iconName]="quickFilterIcons[option.key]" [title]="option.value"></app-orbiicon>{{option.value}}</label>
                            </ng-container>
                        </span>
                    </span>

                    <!--
                    <span>&nbsp;Teams:&nbsp;
                        <span  class="btn-group" role="group" ngbRadioGroup  aria-label="Quick filter status">
                            <ng-container *ngFor="let option of quickFilterTeamSelector | keyvalue">
                                <label ngbButtonLabel [ngClass]="{active: option.key == uiState.qfMetricFilterState.selectedTeamsQfValue}" class="btn btn-primary" [for]="option.key" >                            
                                    <input ngbButton type="radio" [value]="option.key" class="btn-check" [id]="option.key" 
                                        [(ngModel)]="uiState.qfMetricFilterState.selectedTeamsQfValue"
                                        (ngModelChange)="uiState.qfMetricQuickFilterStateChange('teams', $event)">
                                    <app-orbiicon  [iconName]="quickFilterIcons[option.key]" [title]="option.value"></app-orbiicon>{{option.value}}</label>
                            </ng-container> 
                        </span>
                    </span> -->

                    <span>&nbsp;Repository set&nbsp;         
                        <span class="btn-group" role="group" ngbRadioGroup aria-label="Quick filter status">        
                            <ng-container *ngFor="let option of quickFilterRepoSelector | keyvalue">
                                <label ngbButtonLabel [ngClass]="{active: option.key == uiState.qfMetricFilterState.selectedReposQfValue}"  class="btn btn-primary" [for]="option.key" >                            
                                    <input ngbButton type="radio" [value]="option.key" class="btn-check" [id]="option.key" 
                                        [(ngModel)]="uiState.qfMetricFilterState.selectedReposQfValue"
                                        (ngModelChange)="uiState.qfMetricQuickFilterStateChange('repos', $event)">
                                    <app-orbiicon [iconName]="quickFilterIcons[option.key]" title="Show all"></app-orbiicon>{{option.value}}</label>
                            </ng-container>                
                        </span>
                    </span>

                    <span>&nbsp;Dates:&nbsp; 
                        <span  class="btn-group" role="group" ngbRadioGroup aria-label="Quick filter status">
                            <ng-container *ngFor="let option of quickFilterDateSelector | keyvalue">
                                <label ngbButtonLabel [ngClass]="{active: option.key == uiState.qfMetricFilterState.selectedDateQfValue}" class="btn btn-primary" [for]="option.key" >                            
                                    <input ngbButton type="radio" [value]="option.key" class="btn-check" [id]="option.key" 
                                        [(ngModel)]="uiState.qfMetricFilterState.selectedDateQfValue"
                                        (ngModelChange)="uiState.qfMetricQuickFilterStateChange('dates', $event)">
                                    <app-orbiicon  [iconName]="quickFilterIcons[option.key]" [title]="option.value"></app-orbiicon>{{option.value}}</label>
                            </ng-container>
                        </span>
                    </span>
                </div>
                <hr>
                    <label ngbButtonLabel class="btn btn-primary" for="btnRefreshWidgetDataFiltered" >                            
                        <input ngbButton type="button" class="btn-check" id="btnRefreshWidgetDataFiltered" 
                                        (click)="updateWidgetDataFiltered()">
                        <app-orbiicon iconName="faSync" title="Refresh"></app-orbiicon>Refresh
                    </label>
            </div>            
        </div> 
    </div>
    <div class="col-12 dashboard-main pr-4">        
        <ng-container *ngIf="0 == uiState.qfMetricFilterState.selectedTeamsOut.length && 0 == uiState.qfMetricFilterState.selectedUsersOut.length">
            <ngb-alert [dismissible]="false" type="warning">There are no selected users or teams.  You likely want to select some users or teams above in order to see associated data.</ngb-alert>
        </ng-container>
        <ng-container *ngIf="0 == uiState.qfMetricFilterState.selectedReposOut.length">
            <ngb-alert [dismissible]="false" type="warning">There are no selected repos.  You likely want to select some repos above in order to see associated data.</ngb-alert>
        </ng-container>
        <ng-container>
            <ngb-alert [dismissible]="false" type="info">Showing data for {{uiState.qfMetricFilterState.selectedUsersOut.length}} users, {{uiState.qfMetricFilterState.selectedTeamsOut.length}} teams, across {{uiState.qfMetricFilterState.selectedReposOut.length}} repos.</ngb-alert>
        </ng-container>
        <h2 class="orbi-section-title mb-4">{{activeUiLabels?.title}}</h2>        
            
            <div *ngIf="tabState.activeTab == navItemMetricsTopLists">
                <div class="top-list-parameter"> 
                    Top list cutoff: {{ uiState.topListsSelector.cutoffPercentage }} %: 
                    <ng-container> 
                        <label ngbButtonLabel class="" for="cutoffPercentage" >                            
                            <input type="range" class="form-range" min="0" max="100" [step]="uiState.topListsSelector.cutoffStep" 
                                [(ngModel)]="uiState.topListsSelector.cutoffPercentage" 
                                (change)="updateWidgetDataFiltered()"
                                id="cutoffPercentage">
                        </label>
                    </ng-container>
                </div>
                <div class="top-list-parameter"> 
                    Focus period for top lists: 
                    <ng-container *ngFor="let option of topListsDatePeriod | keyvalue">
                        <label ngbButtonLabel [ngClass]="{active: option.key == uiState.topListsSelector.freqPeriod}" class="btn btn-primary" [for]="option.key" >                            
                            <input ngbButton type="radio" [value]="option.key" class="btn-check" [id]="option.key" 
                                [(ngModel)]="uiState.topListsSelector.freqPeriod"
                                (ngModelChange)="updateWidgetDataFiltered()">
                            <app-orbiicon  [iconName]="quickFilterIcons[option.key]" [title]="option.value"></app-orbiicon>{{option.value}}</label>
                    </ng-container>
                </div>
            </div>

            <div class="metrics-holder">      
                <div *ngFor="let chartItem of widgetData" class="card card-metrics">
                    <app-orbiwidgetgraph
                        [graphName]="chartItem.meta.metricName"
                        [legend]="true"
                        [data]="chartItem.chartdata"
                        [type]="'bar'"
                        [chartOptions]="chartItem.chartOptions"
                    >
                    </app-orbiwidgetgraph>                   
                </div>
            </div>
            
            <div>
                widgetData
                <pre>
                {{ widgetData | json }}
                </pre>
            </div>


        <!-- 
                <div class="card-header">
                    <h5 class="card-title">Repository breakdown</h5>
                    <p class="card-text">Which repositories are private, public, archived</p>
                </div>
                 <div class="card-body">
                    
                    <canvas baseChart type="pie" [data]="polardata" [options]="options"></canvas>
                 </div>
                 
            </div>
            <div class="card chart-2">
                <div class="card-header">
                    <h5 class="card-title">Card title 2</h5>
                    <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                 <div class="card-body">
                    
                 </div>
                 
            </div>
            <div class="card chart-3">
                <div class="card-header">
                    <h5 class="card-title">Card title 3</h5>
                    <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                 <div class="card-body">
                    <canvas baseChart type="line" [data]="linedata" [options]="options"></canvas>
                 </div>
                 
            </div>
            <div class="card chart-4">
                <div class="card-header">
                    <h5 class="card-title">Card title 4</h5>
                    <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                 <div class="card-body">
                    Body
                 </div>
                 
            </div>
            <div class="card chart-5">
                <div class="card-header">
                    <h5 class="card-title">Card title 5</h5>
                    <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                 <div class="card-body">
                    Body
                 </div>                 
            </div>
            <div class="card chart-6">
                <div class="card-header">
                    <h5 class="card-title">Card title 6</h5>
                    <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                 <div class="card-body">
                    body
                 </div>
                                 
            </div>
        </div> -->
    </div>

</div>  
