import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-goodbye',
  templateUrl: './goodbye.component.html',
  styleUrls: ['./goodbye.component.scss']
})
export class GoodbyeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
        window.location.href = window.location.hostname;    
    }, 2500);
  }
}
