import { environment } from "src/environments/environment";

export class OanDebugutils {
    public static isDebuggerActive : boolean = environment.debugger_active;

    public static debuggerWrapperOnCondition(condBreakWhenTrue:boolean, ...varargs:any[]) {
        if (OanDebugutils.isDebuggerActive && condBreakWhenTrue) {
            debugger;
        }
    }

    public static debuggerWrapper(...varargs:any[]) {
        if (OanDebugutils.isDebuggerActive) {
            debugger;
        }
    }
}
