<div class="form-group">
    <div class="orbi-filters-list mb-2" *ngFor="let currValue of this.uiState.widgetTextInputs; let i = index">
        <input type="text" [formControl]="uiState.frmControls[currValue.key]" class="form-control" [pattern]="this.validationPattern" autocomplete="off">    
        <a class="input-group-text" (click)="uiEvents.onTextMinus($event, currValue)"><app-orbiicon iconName="faTimes"></app-orbiicon> </a>
        <!-- <div style="display:block" class="valid-feedback">
            Looks good!
        </div> -->
        <div style="display:block" *ngIf="uiState.frmControls[currValue.key].invalid" class="invalid-feedback">
            Please ensure the value uses allowed characters; <i>{{this.validationPattern}}</i>
        </div>
    </div> 
    <div class="col-12 pl-0 mt-2">
        <a class="btn btn-primary" (click)="uiEvents.onPlus($event)"><app-orbiicon iconName="faPlus"></app-orbiicon> Add</a>
    </div>
    <hr>
</div>


