import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParamsOptions, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs'; 
import { Observable, from, of, throwError } from 'rxjs'; 
import { catchError, finalize, map, multicast, pluck, publishReplay, refCount, retry, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { OrbiHttpOptions, OrbiHttpResponse } from "src/app/cmn/svc/orbibackend.types";
import { Router } from '@angular/router';
import { OangnotificationsErrorId, OangnotificationsService } from 'src/oang/svc/oangnotifications.service';
import { OanHttpUtils } from '../cmn/utils/oanhttputils';
import { OanDebugutils } from '../cmn/utils/OanDebugutils';


@Injectable({
    providedIn: 'root'
})
export class OrbibackendPublicService {

    readonly ctxt : string = "Bes_Public";

    private _amILoggedInRequestInProgress : boolean = false;
    private _amILoggedInValue : boolean = false;
    private _amILoggedIn$ = new BehaviorSubject<any>({ isInit:false, amILoggedIn : false });

    private tenantData : any;
    private svcUrls : any;
    /* private pubApiBase : string | undefined;
    private privApiBase : string | undefined;
    private profileApiBase : string | undefined;    */

    private apiRootPubBe : string | undefined;
    private apiRootPrivBe : string | undefined;
    private composeUrlPublicBe(path:string) {
        if (! this.apiRootPubBe) {
            this.apiRootPubBe = environment.urlPublicbe;
        }

        return this.apiRootPubBe + path;
    };
    private readonly API_ROUTES = {
        getLookup: () => this.composeUrlPublicBe(`/pubapi/lookup`),
        getProfileAuthInfo: () => this.composeUrlPublicBe(`/pubapi/authinfo`),
        getProfileLogout: () => this.composeUrlPublicBe(`/pubapi/logout`)
    }

    constructor(private  httpClient: HttpClient, private svcError : OangnotificationsService) 
    { }
  
    private svcurls : any = undefined;
    public async getTenantInfo() : Promise<any> {
        let r = await this._loadTeanntInfo().toPromise();
        this.svcurls = r?.body?.data?.svcurls;
        if (undefined == this.svcurls) 
        {
            console.error("Problem with svcurls; svcurls=" + JSON.stringify(this.svcurls));
            throw new Error("Tenant init error; no backend");
        }

        return this.svcurls;
    }


    private getUrl(key:string){
        if (! this.svcUrls || ! this.svcUrls[key]) {
            OanDebugutils.debuggerWrapper(".?.");            
        }

        return this.svcUrls[key];
    }
    public getUrlbasePrivebe() {
        return this.getUrl('mwgithub');
    }

    public getUrlbaseProfileapi() {
        return this.getUrl('profileapi');        
    }

    public getUrlbaseTenantSettings() {
        return this.getUrl('tenantsettings');        
    }

    private _loadTeanntInfo() : Observable<any> { 
        var ctxt = this.ctxt + "._loadTeanntInfo";
        var url = this.API_ROUTES.getLookup();
        var opts : any = OrbiHttpOptions.getHttpOptions();

        let myenvid = environment.envid;
        let envfileid = environment.fileid;
        let urlPublicbe = environment.urlPublicbe;
        let urlPrivbe = environment.urlPrivbe;

        var reqJson : Partial<any> = {
            envfileid: envfileid,
            envid: myenvid,
        };

        if (myenvid == "local")
        {
            reqJson.envid = "local";
            reqJson.localflags = {                 
//                    "base": "https://bekoahstg.soc2tools.com",
                    "urlPublicbe" : urlPublicbe,
                    "urlPrivbe" : urlPrivbe
                    /*
                    "login": `${environment.urlPublicbe}/pubapi/login`,
                    "logout": (islocalmwgithubonly) ? undefined : "http://localhost:12997",
                    "profileapi": "http://localhost:13200/profileapi",
                    "mwgithub": "http://localhost:13200/mwgithub",
                    "tenantsettings": "http://localhost:13200/tenantsettings" */
            };
        }

        let r : Observable<any | undefined> = this.httpClient.post<any>(url, reqJson, opts).pipe(
            tap( (resp : any | undefined) => {
                if (OanHttpUtils.isHttpSuccess(resp.body)) 
                {            
                    this.tenantData = resp.body;    
                    this.svcUrls = resp.body?.data?.svcurls;
                    if ( ! this.svcUrls 
                        || ! this.svcUrls.login || ! this.svcUrls.logout 
                        || ! this.svcUrls.mwgithub)
                    {
                        console.error("_loadTeanntInfo: Incomplete tenant info " + JSON.stringify(this.svcUrls));
                        throw new Error("Incomplete tenant information ");    
                    }
                }
                else
                {
                    console.error("_loadTeanntInfo: Invalid tenant info " + resp.status);
                    throw new Error("Could not obtain tenant information");
                }
            }),
            catchError( (error : HttpErrorResponse) => {
                this.svcError.addPageAlertError(OangnotificationsErrorId.Error_AppTenantNotConfigured, {
                    context: ctxt, 
                    error: error
                });
                throwError(error);
                return EMPTY;
            })
        );

        return r;
    }    



    public amILoggedIn() : Observable<any> {
        // private _amILoggedInRequestInProgress : boolean = false;
        // private _amILoggedIn$ = new BehaviorSubject<any>({ amILoggedIn : false });


        var ctxt = this.ctxt + ".amILoggedIn";
        var url = this.API_ROUTES.getProfileAuthInfo();
        var opts : any = OrbiHttpOptions.getHttpOptions();
        if (false == this._amILoggedInRequestInProgress) {
            this._amILoggedInRequestInProgress = true;
            var r : Observable<any | undefined> = this.httpClient.get<any>(url, opts);
            r.subscribe({ 
                complete: () => {
                }, error: (o  : any) => { 
                    this._amILoggedInValue = false;
                    this._amILoggedInRequestInProgress = false; 
                    this._amILoggedIn$.next({ amILoggedIn : this._amILoggedInValue } );
                    console.log(`${ctxt} error - ${JSON.stringify(o)}`); 
                },
                next: (o : any) => {                    
                    this._amILoggedInValue = o.body.data.isLoggedIn;
                    this._amILoggedInRequestInProgress = false; 
                    this._amILoggedIn$.next({ isInit:true, amILoggedIn : this._amILoggedInValue } );
                }
            }); 
        }

        return this._amILoggedIn$.asObservable();
    }

    public doLogout(myWindow : any) : void {
        var ctxt = this.ctxt + ".doLogout";
        var url = this.API_ROUTES.getProfileLogout();
        myWindow.location.href = url;
    }    
}
