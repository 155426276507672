
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

import { UiObj } from 'src/app/cmn/ui/UiObj';
import { OanLodashWrap } from 'src/app/cmn/utils/oanlodashwrap';
import { OrbiiconComponent } from '../orbiicon/orbiicon.component';
import { OrbiApiEntrypointKey, OrbiFilterStateInterface, OrbiResultsFilterEnum, ApiMwgithubResult, ApiMwgithubRepo, ApiMwgithubPullrequest, EntityMwgithubaccesscontrol_PermissionTargetType, OrbiAuditResultTest, OrbiAuditResultImpact, OrbiObjectType, ApiPageState_init, ApiAuditResult } from '@danclarke2000/gitrospectdto';
import { OrbiresultsfilterComponent, OrbiResultsFilterMetadataFlagsLabels, OrbiresultsfilterOp } from '../orbiresultsfilter/orbiresultsfilter.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColDefDataType } from '../githubcolumndefs';
import { OanDebugutils } from 'src/app/cmn/utils/OanDebugutils';
import { OanAnalyzerCatalog } from 'src/app/cmn/analyzer/OanAnalyzerCatalog';
import { OrbidebugService } from 'src/app/svc/orbidebug.service';
import { OrbiAuditResultsManagerService } from 'src/app/svc/orbiauditresultsmanager.service';
import { OrbiRequestMwgithubApi } from 'src/app/cmn/svc/orbirequestmwgithubapi';

const MaxColWidth = 20;
const MinColWidth = 10;


enum OrbiResultsTableClassNames 
{
    ResultsColSortable = "orbi-results-col-sortable",
    ResultsColFilterable = "orbi-results-col-filterable",
    ResultsColHidden = "orbi-results-col-hidden",
    ResultsColActiveSortAsc = "orbi-results-col-activesort-asc",
    ResultsColActiveSortDesc = "orbi-results-col-activesort-desc",
    ResultsColActiveFilterable = "orbi-results-col-activefilter",
}

class CellValueLinkArray
{
    linkText! : string;
    linkHref! : string;
} 

export class OrbiCellValue 
{
    val! : string;
    link? : string;
    linkArray? : CellValueLinkArray[];
    linkTarget? : string;
    copiable! : boolean;
    exportcsv! : boolean;
    cellClasses : any | undefined;
    auditValueCalc : any | undefined;

    public static create(collDef : any) : OrbiCellValue {
        let r : OrbiCellValue = { 
            val : "", 
            auditValueCalc : undefined,
            link : undefined,
            linkArray : undefined,
            linkTarget : undefined,
            copiable : collDef.copiable,
            exportcsv : collDef.exportcsv,
            cellClasses : {}
        };

        if ("string" == typeof collDef?.group?.class) {
            r.cellClasses[collDef.group.class] = true;
        }

        return r;
    }
};

    // filterDataType! : string;
export class OrbiHdr {
    orbiHdrId! : number;
    title! : string;
    hdrClasses : Partial<any> = {};
    isSortable : boolean = false;
    isFilterable : boolean = false;
    isHidden : boolean = false;
    isExportsCsv : boolean = false;
    dataType : ColDefDataType | undefined = undefined;
    filterOp : OrbiresultsfilterOp = OrbiresultsfilterOp.Unknown;
    allowedValues : any;
    copiable! : boolean;
    copied! : boolean;
    linkTarget!:string;


    colWidth : number = 50;
    colVisible : boolean = true;
    ngStyleObj : any = {};
    mousedownClientX : number = -1;

    constructor(hdrIndex:number, hdrTitle:string, isExportsCsv:boolean, dataType:ColDefDataType, filterOp:any) {
        this.hdrClasses = {};
        this.allowedValues = {};
        this.isSortable = false;
        this.isFilterable = false;
        this.orbiHdrId = hdrIndex;
        this.title = hdrTitle;
        this.isExportsCsv=isExportsCsv;
        this.dataType = dataType;
        this.filterOp = filterOp;

        this.colWidth = 10;
        switch (this.dataType) {
            case ColDefDataType.Textlong:
                this.colWidth = 10;
                break;

            case ColDefDataType.Textshort:
                this.colWidth = 10;
                break;

            case ColDefDataType.Numeric:
                this.colWidth = 10;
                break;

            case ColDefDataType.Date:
                this.colWidth = 10;
                break;
        }
        
        this.colVisible = true;
        this.ngStyleObj = {};
        this.updateNgStyleObj();
    }

    updateNgStyleObj() {
        this.ngStyleObj = { "width": this.colWidth + 'rem', "max-width": this.colWidth + 'rem', "min-width": this.colWidth + 'rem'};
    }

    dragstart($event : DragEvent)  {
        this.mousedownClientX = $event.clientX;
    }
    
    dragend($event : DragEvent) {
        let mouseDragSize : number = $event.clientX - this.mousedownClientX;
        let newColWidth = this.colWidth + mouseDragSize;
        console.log(`dragend newColWidth=${newColWidth}, $event.clientX=${$event.clientX},  this.mousedownClientX=${this.mousedownClientX}`);
        if (newColWidth < MinColWidth) {
            newColWidth = MinColWidth;
        } else if (newColWidth > MaxColWidth) {
            newColWidth = MaxColWidth;
        }

        this.colWidth = newColWidth;
        this.updateNgStyleObj();
    }
}

export enum OrbiRowAttribute {    
    Relevance = "relevanceCol",
    Status = "statusCol",
    Impact = "impactCol"
}

export enum OrbiRowAttributeClassPrefix {
    Relevance = "relevance",
    Status = "results",
    Impact = "impact"
}

export enum OrbiRowAttributeClassSuffix {
    dontknow = "dontknow",
    na = "na",
    true = "true",
    false = "false"
}

export interface OrbiRowColMeta {
    cssclass : {
        [clsname:string]: boolean 
    },
    reasons : any[],
    meta : {
        [attrname:string]: any 
    }
}

export interface OrbiRowMeta {
    isLive?: boolean | undefined;
    hasFindings?:boolean | undefined;
}

export interface OrbiRow {
    orbiRowId : string | number;
    objectid? : string;
    objectidlogical? : string;
    rowMeta : OrbiRowMeta;
    rowClasses : OrbiRowColMeta;
    impactCol : OrbiRowColMeta;
    statusCol : OrbiRowColMeta;
    relevanceCol : OrbiRowColMeta;
    isFilteredIn : boolean;
    cells : Partial<OrbiCellValue>[];
};

export class OrbiRow_Helper {
    public static factory(rowId:string, objectid:string|undefined, objectidlogical:string|undefined) : OrbiRow {
        let r : OrbiRow = {
            orbiRowId : rowId,
            objectid : objectid,
            objectidlogical : objectidlogical,
            rowMeta : {
                isLive : undefined,
                hasFindings : undefined
            },
            rowClasses : { 
                cssclass : {
                    "results-dontknow": true,
                    "relevance-dontknow": true  
                },
                reasons : [],
                meta: {},
            },
            impactCol : {
                cssclass : {
                    "impact-dontknow": true 
                },
                reasons : [],
                meta: {},
            },
            statusCol : { 
                cssclass : {
                    "results-dontknow": true 
                },
                reasons : [],
                meta: {},
            },
            relevanceCol : { 
                cssclass : {
                    "relevance-dontknow": true 
                },
                reasons : [] as any[],
                meta: {},
            },
            isFilteredIn : true,
            cells : [] as Partial<OrbiCellValue>[]
        };

        return r;
    }

    public static setClass(rowObj:OrbiRow, family:OrbiRowAttribute, clsPrefix:OrbiRowAttributeClassPrefix | string, clsSuffix:OrbiRowAttributeClassSuffix) {
        // remove other classes in this family
    
        Object.keys(rowObj.rowClasses.cssclass).filter((key:string)=>key.startsWith(clsPrefix)).forEach((key:string) => {
            delete rowObj.rowClasses.cssclass[key];
        }); 

        let colMeta : OrbiRowColMeta = <OrbiRowColMeta>(rowObj[family as keyof OrbiRow]);
        Object.keys(colMeta.cssclass).filter((key:string)=>key.startsWith(clsPrefix)).forEach((key:string) => {
            delete colMeta.cssclass[key];
            delete rowObj.rowClasses.cssclass[key];
        });

        let currClass = clsPrefix + '-' + clsSuffix;
        colMeta.cssclass[currClass] = true;
        rowObj.rowClasses.cssclass[currClass] = true;
    }

    public static addReason(rowObj:OrbiRow, family:OrbiRowAttribute, reasonKey:string, reasonValue:boolean) {
        let colMeta : OrbiRowColMeta = <OrbiRowColMeta>(rowObj[family as keyof OrbiRow]);
        let mappedRelevanceClassName = reasonKey.replace(".", "_");
        OrbiRow_Helper.setClass(rowObj, family, mappedRelevanceClassName, 
            (reasonValue) ? OrbiRowAttributeClassSuffix.true : OrbiRowAttributeClassSuffix.false);
        rowObj.relevanceCol.reasons.push({reasonClass:`class-${reasonKey}`, reasonText:OanAnalyzerCatalog.getAuditRelevanceLabel("en", reasonKey) });
    }
}

@Component({
  selector: 'app-orbiresultstablenative',
  templateUrl: './orbiresultstablenative.component.html',
  styleUrls: ['./orbiresultstablenative.component.scss']
})
export class OrbiresultstablenativeComponent implements OnInit {
    public static refreshNumberCtr : number = 0;
    public debugJsonStringify(s : any) {
        /*
        let a=s[0];
        let b=s[1];
        */
        
        let r = '';
        try {
            r = JSON.stringify(s); // JSON.stringify(s);
        } catch (e:any) {
        }
        return r;
    }

    constructor(private cdRef:ChangeDetectorRef, 
        private svcAuditResultsMgr : OrbiAuditResultsManagerService,
        private modalService : NgbModal, private svcDebug: OrbidebugService) { 
        this.resultsrenderer.cmpThis = this;
    }

    ngOnInit(): void {
    }

    private applyFilter() {
        if (! this.filterStateVars) {
            return;
        }

        for (let currIndex = 0; currIndex < this.renderedTableData.length; ++currIndex) 
        {
            let currRowRender : OrbiRow = this.renderedTableData[currIndex];
            if (this._textFilterResults && 3 < this._textFilterResults.length) 
            {
                let currRowMatches = false;
                for (let currColIndex=0; false == currRowMatches && currColIndex < currRowRender.cells.length; ++currColIndex) 
                {
                    let currColDef = this.tableHeaders[currColIndex];
                    let currCell : Partial<OrbiCellValue> = currRowRender.cells[currColIndex];
                    if (currCell && currCell.val 
                        && "string" == currColDef.filter) 
                    {                        
                        if ("string" == typeof currCell.val) {
                            currRowMatches = currRowMatches || currCell.val.includes(this._textFilterResults);
                        } else if ('number' == typeof currCell.val) {
                            currRowMatches = currRowMatches || parseInt(this._textFilterResults) == currCell.val;
                        }
                    }                    
                }

                currRowRender.isFilteredIn = currRowMatches;                
            }
            else
            {
                currRowRender.isFilteredIn = true;
            }

            if (currRowRender.isFilteredIn) 
            {
                let isFilteredIn = true;
                if (true == isFilteredIn)
                {
                    switch(this.filterStateVars.auditfocusSelector)
                    {
                
                        case   OrbiResultsFilterEnum.auditonlyfail:
                            isFilteredIn = (true === currRowRender.rowMeta.hasFindings);
                            break;
                        
                        case   OrbiResultsFilterEnum.auditonlypass:
                            isFilteredIn = (true !== currRowRender.rowMeta.hasFindings);
                            break;

                        case   OrbiResultsFilterEnum.auditdontknow:
                            isFilteredIn = (undefined === currRowRender.rowMeta.hasFindings);
                            break;

                        case   OrbiResultsFilterEnum.auditall:
                            isFilteredIn = true;
                            break;
                    }     
                }

                currRowRender.isFilteredIn = isFilteredIn;
            }
        }
    }

    @Input() tableHeaders : any[] = []; // decorate the property with @Input()
    @Input() tableData : any[] = []; // decorate the property with @Input()
    @Input() viewAuditResults : any | undefined = undefined; // decorate the property with @Input()
    private _textFilterResults? : string = undefined;
    @Input() set textFilterResults(newVal:string) 
    {
        this._textFilterResults = newVal;
        this.applyFilter();
    }

    @Input() filterStateVars : OrbiFilterStateInterface | undefined; 

    private _statusFilterResults? : OrbiFilterStateInterface | undefined; 
    @Input() set statusFilter(newVal:OrbiFilterStateInterface) {
        // this is a bit unnecessary in theory as filterstatevars is bound, but it's a way for parent component to trigger a filter update
        this._statusFilterResults = newVal;
        this.applyFilter();
    } 

    @Output() orbiResultsTableUistate : EventEmitter<any> = new EventEmitter<any>();
    public numFilteredOutObjects : number = 0;
    public numShownObjects  : number = 0;
    //@Output()  @Output() 
    public renderedTableHeaders : OrbiHdr[] = [];  
    public renderedTableData : OrbiRow[] = [];

    public renderedFilteredTableData : OrbiRow[] = [];
    public renderedFilterCols : string[] = [];
    public filterFn : (inObjs : any[]) => any[] = this.defaultFilter;
    public sortFn : (inObjs : any[], isSortAsc : boolean) => void = this.defaultSort;  
    public filterModalRef : any = undefined;
    private sortabledefault : OrbiHdr | undefined = undefined;
    public statusCol : OrbiHdr | undefined = undefined;
    public relevanceCol : OrbiHdr | undefined = undefined;
    public impactsCol : OrbiHdr | undefined = undefined;
    public objsWithNoAuditResults : any = {};
    private myLastKnownObjectType : OrbiObjectType | undefined = undefined;
    // trigger update 
    public debugCheckUnknownResults() 
    {
        let myOrphanObjs = this.objsWithNoAuditResults;
        let myRefAuditResults = this.svcAuditResultsMgr.getAuditResultsForObjectType(this.myLastKnownObjectType!);

        // @ts-ignore
        let myReq = OrbiRequestMwgithubApi.createApiRequest(`debugcheckorphanobjs`, undefined, undefined, this._auditresults$, OrbiApiEntrypointKey.auditresults, 
        this.filterStateVars, true);                
        myReq.debugGetApiPages(ApiPageState_init());
        OrbiRequestMwgithubApi.debugSearchCache<ApiAuditResult>(new RegExp(`^${OrbiApiEntrypointKey.auditresults}.*GithubPrsForRepo`), myOrphanObjs);
    }

    public triggerRenderAuditResults(myObjType?:OrbiObjectType) 
    {
        let myAuditResults = undefined;
        if (myObjType) {
            this.myLastKnownObjectType = myObjType;
            myAuditResults = this.svcAuditResultsMgr.getAuditResultsForObjectType(myObjType);
            /*
            let numViewAuditResultsKeys = Object.keys(this.viewAuditResults).length;
            let numMyAuditResultsKeys = Object.keys(myAuditResults).length;
            if (numViewAuditResultsKeys != numMyAuditResultsKeys) {
                OanDebugutils.debuggerWrapperOnCondition( true, []);
            }
            */
        }

        this.renderAuditResults("triggerRenderAuditResults");       
    }

    // this is triggered by setting activeObjs in OanNavTabState.setObjsAndFilter
    ngOnChanges(changes: SimpleChanges) 
    {
        let fnName="ngOnChanges";
        for (const propName in changes) 
        {
            const changedProp = changes[propName];
            const to = changedProp.currentValue;

            switch (propName)
            {
            case "tableData":
                if (Array.isArray(to))
                {
                    setTimeout(() => {
                        this.renderedTableHeaders = [];
                        this.renderedFilteredTableData = [];
                        if (0 < to.length) {
                            this.renderTableHdrs();
                            this.renderTable();           
                            this.renderAuditResults("tableData");             
                        }
                    });                    
                }
                break;
                
            case "viewAuditResults":
                if (Array.isArray(to))
                {
                    setTimeout(() => {
                        this.renderAuditResults("viewAuditResults");
                    });     
                }
            }
        }
    }

    copyValueToClipboard(tooltip:any, currCol : any, currObj : any) {
        // ngbToolkit doesnt support updating text in performance way
        var val= currObj.val;
        navigator.clipboard.writeText(val).then(() => {
            // tooltip.close();
            currCol.copied = true;
            // tooltip.open();
            setTimeout(() => {
                currCol.copied = undefined;
            // tooltip.close();
            }, 3000);
        }, function(e) {
            console.error("copyValueToClipboard; Unable to write to clipboard: " + JSON.stringify(e));
        });
    }
  
    private getClassListAsArray(classCollectorObj:any, colDefClasses:string) : void
    {
        var classNames : string[] = colDefClasses.split(" ");
        classNames.forEach( (cn:string) => {
            var currCn = cn.trim();
            if (0 != currCn.length) 
            {
                classCollectorObj[currCn] = true;
            }
        });
    }

    defaultFilter(inObjs : any[]) : any[] {
        return inObjs;
    }

    defaultSort(inObjs : any[], isSortAsc : boolean) {
    }

    // remove active class
    private removeActiveFilterClass() {
        this.renderedTableHeaders.forEach( (currHdr:OrbiHdr) => {
            currHdr.hdrClasses[OrbiResultsTableClassNames.ResultsColActiveFilterable] = false;
        });
    }

    //
    //// render table headers
    // 
    private renderTableHdrs() 
    {
        if (Array.isArray(this.tableHeaders) && 0 < this.tableHeaders.length)
        {
            this.renderedTableHeaders = [];
            for (let currHdrIndex=0; currHdrIndex < this.tableHeaders.length; ++currHdrIndex) 
            {
                let currHdrDef = this.tableHeaders[currHdrIndex];
                let currHdr : OrbiHdr = new OrbiHdr(currHdrIndex, currHdrDef.title, currHdrDef.exportcsv, 
                    currHdrDef.group?.dataType, currHdrDef.filterop);

                if (currHdrDef.classNames && currHdrDef.classNames.thClass) {
                    this.getClassListAsArray(currHdr.hdrClasses, currHdrDef.classNames.thClass);
                }

                currHdr.hdrClasses['wbheader'] = true;
                if (currHdrDef.group?.class) {
                    currHdr.hdrClasses['wbheader'+currHdrDef.group.class] = true;
                } else {
                    OanDebugutils.debuggerWrapper(`renderTableHdrs - hdrDef without group`);
                }

                if (currHdr.dataType) 
                {
                    currHdr.hdrClasses[currHdr.dataType] = true;
                }

                if (currHdrDef.sortable) 
                {
                    currHdr.isSortable = true;
                    currHdr.hdrClasses[OrbiResultsTableClassNames.ResultsColSortable] = true;
                    if (currHdrDef.sortabledefault)
                    {
                        this.sortabledefault = currHdr;
                    } 
                }

                if (currHdrDef.filter) 
                {
                    currHdr.isFilterable = true;
                    currHdr.hdrClasses[OrbiResultsTableClassNames.ResultsColFilterable] = true;
                }

                if (currHdrDef.hidden) 
                {
                    currHdr.isHidden = true;
                    currHdr.hdrClasses[OrbiResultsTableClassNames.ResultsColHidden] = true;
                }

                currHdr.copiable = currHdrDef.copiable ?? false;

                // currHdr.hdrClasses = Object.keys(currHdr.hdrClassesArray).join(' ');
                this.renderedTableHeaders.push(currHdr);            
            }
        }
        else
        {
            // should not happen
            OanDebugutils.debuggerWrapper(".?.");
        }
    }

    private renderTable() 
    {
        this.renderedTableData = [];
        this.renderedFilterCols = [];
        for (let currTableDataIndex=0; currTableDataIndex < this.tableData.length; ++currTableDataIndex)
        {
            let currRowObj : any = this.tableData[currTableDataIndex];
            let currRowRender : OrbiRow = OrbiRow_Helper.factory(currRowObj.id, currRowObj.obj?.identifier, currRowObj.obj?.idlogical);            
            currRowRender.rowMeta.isLive = currRowObj.obj?.synthetic.isLive;
            for (let currColIndex=0; currColIndex < this.tableHeaders.length; ++currColIndex) 
            {
                let currColDef = this.tableHeaders[currColIndex];
                let currRowVal : Partial<OrbiCellValue> = OrbiCellValue.create(currColDef);
                
                switch(currColDef.val.obj)
                {
                    case 'auditest':
                        currRowVal.auditValueCalc = {
                            obj : currRowObj,
                            path : currColDef.val.path,
                            args : currColDef.val.args,
                            currRowVal : currRowVal
                        }
                        break;

                    case 'arraylinks':
                    {
                        let arrayLinkVals = OanLodashWrap.interpolateLoDash(this, currRowObj, 'get', currColDef.val.path);
                        if (arrayLinkVals && Array.isArray(arrayLinkVals) && 0 < arrayLinkVals.length)
                        {
                            currRowVal.linkArray = [];
                            for (let arrayLinkValsIndex=0; arrayLinkValsIndex < arrayLinkVals.length; ++arrayLinkValsIndex)
                            {
                                let currLinkVal = arrayLinkVals[arrayLinkValsIndex];
                                let currHref : string = OanLodashWrap.interpolateLoDash(this, currLinkVal, 'get', currColDef.val.transform.url);
                                let currText : string =  OanLodashWrap.interpolateLoDash(this, currLinkVal, 'get', currColDef.val.transform.text);
                                currRowVal.linkArray.push({ linkText : currText, linkHref : currHref });
                            }
                        }
                        break;
                    }
                    case 'arraylinksprnumbers':
                    {
                        let arrayLinkVals = OanLodashWrap.interpolateLoDash(this, currRowObj, 'get', currColDef.val.path);
                        if (arrayLinkVals && Array.isArray(arrayLinkVals) && 0 < arrayLinkVals.length)
                        {
                            currRowVal.linkArray = [];
                            for (let arrayLinkValsIndex=0; arrayLinkValsIndex < arrayLinkVals.length; ++arrayLinkValsIndex)
                            {
                                let currLinkVal = arrayLinkVals[arrayLinkValsIndex];
                                let currHref : string = `https://www.github.com/${currRowObj.obj.repoContainer.orgName}/${currRowObj.obj.repoContainer.repoName}/pull/${currLinkVal}`
                                let currText : string =  currLinkVal;
                                currRowVal.linkArray.push({ linkText : currText, linkHref : currHref });
                            }
                        }
                    }
                    break;
                   
                    default:
                        {
                            currRowVal.val = OanLodashWrap.interpolateLoDash(this, currRowObj, currColDef.val.obj, currColDef.val.path, currColDef.val.transform, currColDef.val.args, undefined);
                            if (currColDef.link) {
                                currRowVal.linkTarget = currColDef.link.target;
                                currRowVal.link = this.datarender.getColDefLinkTarget(currRowObj, currColDef.link);
                            }
                        }
                        break;
                }

                if (currColDef.filter) 
                {
                    this.renderedFilterCols.push(currColDef.title);
                }

                let currColDefRendered = this.renderedTableHeaders[currColIndex];
                currColDefRendered.allowedValues[(currRowVal.val) ? currRowVal.val : '-'] = currRowVal.val;
                if (undefined == currRowVal) {
                    OanDebugutils.debuggerWrapper("Rendered data mismatch - undefined row cell");
                }
                currRowRender.cells.push(currRowVal);
            }

            if (currRowRender.cells.length != this.tableHeaders.length) {
                OanDebugutils.debuggerWrapper("Rendered data mismatch");
            }

            this.renderedTableData.push(currRowRender);
        }

        setTimeout(() => {
            this.renderedFilteredTableData = this.filterFn(this.renderedTableData);

            if (undefined != this.filterStateVars || undefined != this.filterStateVars)
            {
                this.applyFilter();
            }
            
            if (this.sortabledefault)
            {
                this.sorter.sortBy(this.sortabledefault);
            }

            this.numShownObjects = this.renderedFilteredTableData.filter((row:OrbiRow)=>row.isFilteredIn).length;
            this.numFilteredOutObjects = this.tableData.length - this.numShownObjects;
            this.orbiResultsTableUistate.emit({
                numTableData: this.tableData.length,
                numShownObjects: this.numShownObjects,
                numFilteredOutObjects: this.numFilteredOutObjects                
            });
        });
    }

    private renderAuditResults(caller:string)
    {
        let fnName = 'renderAuditResults';
        this.statusCol = new OrbiHdr(0, 'Status', true, ColDefDataType.Textshort, OrbiresultsfilterOp.Match);                    
        this.relevanceCol = new OrbiHdr(0, 'Relevance', true, ColDefDataType.Textshort, OrbiresultsfilterOp.Metadata); 
        this.impactsCol = new OrbiHdr(0, 'Impacts', true, ColDefDataType.Textshort, OrbiresultsfilterOp.Metadata);                    

        this.objsWithNoAuditResults = {};
        if (Array.isArray(this.renderedTableData) && this.viewAuditResults && 0 < Object.keys(this.viewAuditResults).length) {
            this.renderedTableData.forEach((currRow:OrbiRow) => {
                // let interestingRows = [ /FOUW-playground/, /cyber-compliance/, /rnd-first-day-drop-off/ ];
                let interestingRows = [] as any[];
                if (interestingRows.find((ir:RegExp) => ir.test(currRow.objectidlogical!))) { debugger; }

                let currRowId : string | number = currRow.orbiRowId;
                if (currRowId && undefined != this.viewAuditResults)
                {
                    delete this.objsWithNoAuditResults[currRowId];
                    if (undefined != this.viewAuditResults[currRowId]) 
                    {
                        let myAuditResult = this.viewAuditResults[currRowId];
                        let executedtests = myAuditResult.auditresult.resultsobj.executedtests;
                        let findingsObject = myAuditResult.auditresult.resultsobj.findings;
                        
                        if (findingsObject && 0 < Object.keys(executedtests).length) {
                            // currRow.statusCol.auditresult = ! Object.keys(myAuditResult.auditresult.resultsobj.findings).some((currKey:string) => true == myAuditResult.auditresult.resultsobj.findings[currKey].v);
                            // let strResultClass = (currRow.statusCol.auditresult) ? 'results-true' : 'results-false';
                            // currRow.rowClasses[strResultClass] = true;
                            // currRow.statusCol.cssclass = { [strResultClass] : true };

                            // any true findings means audit failed
                            let auditResult = ! Object.keys(findingsObject).some((currKey:string) => true == findingsObject[currKey].v);                            
                            OrbiRow_Helper.setClass(currRow, OrbiRowAttribute.Status, OrbiRowAttributeClassPrefix.Status, 
                                auditResult ? OrbiRowAttributeClassSuffix.true : OrbiRowAttributeClassSuffix.false);
                            currRow.rowMeta.hasFindings = ! auditResult;
                        } else  {
                            // some rows are not audit relevant e.g. not production repo (this is set by function setAuditRelevant)
                            //                            currRow.statusCol.cssclass = { ['results-na'] : true };
                            //                          currRow.rowClasses['results-na'] = true;
                            currRow.rowMeta.hasFindings = undefined;
                            OrbiRow_Helper.setClass(currRow, OrbiRowAttribute.Status, OrbiRowAttributeClassPrefix.Status, OrbiRowAttributeClassSuffix.na);                            
                        }

                        /*
                        currRow.relevanceCol.cssclass = {
                            "relevance-na" : true
                        }; */
                        if (myAuditResult.auditresult.resultsobj.relevance) 
                        {
                            currRow.relevanceCol.reasons = [];
                            let isRelevant = false;
                            let myRel = Object.keys(myAuditResult.auditresult.resultsobj.relevance).forEach((currKey:string) => {
                                if (true == myAuditResult.auditresult.resultsobj.relevance[currKey])
                                {
                                    isRelevant = true;
                                    let reasonValue = myAuditResult.auditresult.resultsobj.relevance[currKey];
                                    OrbiRow_Helper.addReason(currRow, OrbiRowAttribute.Relevance, currKey, reasonValue);         

                                        // currRow.relevanceCol.cssclass["relevance-true"] = true;    
                                        // delete myAuditResult.auditresult.resultsobj.relevance["relevance-false"];
                                    // } 
                                    /* 
                                    else if (false == myAuditResult.auditresult.resultsobj.relevance[currKey]) {
                                        currRow.relevanceCol.cssclass["relevance-false"] = true;    
                                    } */

                                    /*
                                    let mappedRelevanceClassName = currKey.replace(".", "_") + '-' + 
                                    currRow.relevanceCol.cssclass[mappedRelevanceClassName] = myAuditResult.auditresult.resultsobj.relevance[currKey];                                
                                    currRow.relevanceCol.reasons.push({reasonClass:`class-${currKey}`, reasonText:OanAnalyzerCatalog.getAuditRelevanceLabel("en", currKey) });
                                    */
                                }
                            });

                            OrbiRow_Helper.setClass(currRow, OrbiRowAttribute.Relevance, OrbiRowAttributeClassPrefix.Relevance, 
                                isRelevant ? OrbiRowAttributeClassSuffix.true : OrbiRowAttributeClassSuffix.false);                            

                        }

                        // impacts                     
                        let myFlags = myAuditResult.auditresult.resultsobj.impacts;
                        currRow.impactCol.meta.rowFlags = [];
                        if (myFlags) 
                        {
                            if (0 < Object.keys(myFlags).length)
                            { 
                                currRow.impactCol.meta.rowFlags = Object.keys(myFlags).map((o:string) => {
                                        let cls = 'metadata-na';
                                        if (false === myFlags[o] || true === myFlags[o]) {
                                            cls = (myFlags[o]) ? 'metadata-impact' : 'metadata-ok' ;
                                        }
                                        let r = {
                                            label: OrbiResultsFilterMetadataFlagsLabels[o],
                                            key: o,
                                            classname : cls,
                                            value: myFlags[o]
                                        }
                
                                        return r;
                                });
                                currRow.impactCol.reasons = currRow.impactCol.meta.rowFlags.filter((rf:any) => rf.value);
                            }
                            else
                            {
                                let impactKey = OrbiAuditResultImpact.NoImpact;
                                currRow.impactCol.meta.rowFlags = [
                                    {
                                        label: OrbiResultsFilterMetadataFlagsLabels[impactKey],
                                        key: impactKey,
                                        classname : 'metadata-ok',
                                        value: true
                                    }
                                ]
                            }   
                        }                    

                        // explain message
                        let findingExplains = [''];
                        if (myAuditResult.auditresult.resultsobj.findings) {
                            findingExplains = Object.keys(myAuditResult.auditresult.resultsobj.findings).map((currFindingKey:string) => {
                                let r = '';
                                if (myAuditResult.auditresult.resultsobj.findings[currFindingKey].v) {
                                    let currTestKey : OrbiAuditResultTest = <OrbiAuditResultTest>(currFindingKey);   
                                    let findingArgs =  myAuditResult.auditresult.resultsobj.findings[currFindingKey].a;                         
                                    let newR = OanAnalyzerCatalog.getAuditTestLabel("en", currTestKey, findingArgs);
                                    if (undefined != newR) {
                                        // sometimes this is not defined (probably a mistake)
                                        r = newR;
                                    } else {
                                        debugger;
                                        console.log('no key for ' + currTestKey);
                                        OanAnalyzerCatalog.getAuditTestLabel("en", currTestKey, findingArgs);
                                    }
                                } 

                                return r;
                            })
                        }
                        currRow.impactCol.reasons = findingExplains.filter((currStr:string) =>  {
                            if ("string" == typeof currStr && 0 < currStr.length) {
                                return true;
                            } else {
                                return false;
                            };
                        });

                        // test values
                        currRow.cells.forEach((currCell:Partial<OrbiCellValue>) => {
                            if (undefined != currCell.auditValueCalc)
                            {
                                let testCodes : OrbiAuditResultTest[] = <OrbiAuditResultTest[]>(currCell.auditValueCalc.args);
                                let newVal = this.resultsrenderer.getAuditTest(currRow, currCell, testCodes);
                                currCell.val = newVal;
                            }
                        });                    
                    }
                    else 
                    {
                        // no findings object for this row, whats going on ?
                        currRow.rowMeta.hasFindings = undefined;
                        if (Array.isArray(currRow)) { debugger; }
                        if ("object" != typeof currRow) { debugger; }
                        // @ts-ignore
                        if (0 == currRow) { debugger; }
                        this.objsWithNoAuditResults[currRowId] = { objectid: currRow.objectid, objectidlogical: currRow.objectidlogical };
                    }
                }
            });

            let missingRowIds = Object.keys(this.objsWithNoAuditResults);
            if (0 != missingRowIds.length) {
                console.log(`Missing ${missingRowIds.length} audit findings refreshNumberCtr=${OrbiresultstablenativeComponent.refreshNumberCtr++} `);
            } else {
                console.log(`All finding records loaded refreshNumberCtr=${OrbiresultstablenativeComponent.refreshNumberCtr++} `);
            }
            
            console.log(`${missingRowIds.join(',')}`); 
            console.table(missingRowIds); 
        }

        if (undefined != this.filterStateVars || undefined != this.filterStateVars)
        {
            this.applyFilter();
        }
    }

    //
    ////
    // 
    public filter : any = new class
    {
        constructor(private cmpThis: OrbiresultstablenativeComponent) {}

        filterBy(currHdr:OrbiHdr) {            
            if (currHdr.hdrClasses[OrbiResultsTableClassNames.ResultsColActiveFilterable]) 
            {
                // this is deselecting the existing filter
                currHdr.hdrClasses[OrbiResultsTableClassNames.ResultsColActiveFilterable] = false;
                this.cmpThis.filterFn = this.cmpThis.defaultFilter;
                this.cmpThis.renderedFilteredTableData = this.cmpThis.renderedTableData;
                this.cmpThis.filterModalRef = undefined;
            }
            else 
            {
                this.cmpThis.filterModalRef = this.cmpThis.modalService.open(OrbiresultsfilterComponent);
                this.cmpThis.filterModalRef.componentInstance.filterOp = currHdr.filterOp;
                this.cmpThis.filterModalRef.componentInstance.colIndex = currHdr.orbiHdrId;
                this.cmpThis.filterModalRef.componentInstance.name = currHdr.title;
                this.cmpThis.filterModalRef.componentInstance.allowedValues = currHdr.allowedValues; 
                this.cmpThis.filterModalRef.componentInstance.filteredEvent
                    .subscribe({
                        next: (value: any) => {
                            this.cmpThis.filterFn = value.filterFn;
                            this.cmpThis.renderedFilteredTableData = this.cmpThis.filterFn(this.cmpThis.renderedTableData)
                        },
                        error: (err:any) => {
                            console.error(`filterBy: ${err}`);
                        }
                    });

                this.cmpThis.removeActiveFilterClass();
                currHdr.hdrClasses[OrbiResultsTableClassNames.ResultsColActiveFilterable] = true;
                this.cmpThis.filterModalRef.componentInstance.onInputChanges();
            }
        }
    }(this);

    //
    //// sorting
    //
    public sorter : any = new class
    {
        constructor(private cmpThis: OrbiresultstablenativeComponent) {}

        sortBy(currHdr:OrbiHdr) 
        {            
            
            let defaultSort : string = OrbiResultsTableClassNames.ResultsColActiveSortDesc;
            let oppositeSort : string = OrbiResultsTableClassNames.ResultsColActiveSortAsc;
            if (currHdr.hdrClasses[defaultSort] || currHdr.hdrClasses[oppositeSort]) 
            {
                if (currHdr.hdrClasses[defaultSort]) 
                {
                    currHdr.hdrClasses[defaultSort] = false;
                    currHdr.hdrClasses[oppositeSort] = true;
                } 
                else 
                {
                    currHdr.hdrClasses[defaultSort] = true;
                    currHdr.hdrClasses[oppositeSort] = false;
                }
            }
            else 
            {
                // other sorts to become not active
                this.cmpThis.renderedTableHeaders.forEach((otherHdr:OrbiHdr) => {
                    otherHdr.hdrClasses[defaultSort] = false;
                    otherHdr.hdrClasses[oppositeSort] = false;    
                });

                // init sort
                currHdr.hdrClasses[defaultSort] = true;
                currHdr.hdrClasses[oppositeSort] = false;
            }

            let isSortAsc = currHdr.hdrClasses[OrbiResultsTableClassNames.ResultsColActiveSortAsc];
            this.cmpThis.sortFn = (inObjs : any[], isSortAsc:boolean) => 
                {
                    let cellIndex = currHdr.orbiHdrId;
                    let ascVal = (isSortAsc) ? -1 : 1;
                    inObjs.sort( (lhs:OrbiRow, rhs:OrbiRow) => {
                        let r = 0; // elements are equal
                        let lhsVal = lhs.cells[cellIndex].val;
                        let rhsVal = rhs.cells[cellIndex].val;
                        if (lhsVal == undefined || rhsVal == undefined) {      
                            // undefined is relationally ordered less than everything
                            r = (lhsVal == rhsVal) ? 0 : (lhsVal == undefined) ? ascVal : -1*ascVal;
                        } else if (lhsVal != rhsVal) {                            
                            let natSort = lhsVal < rhsVal;
                            r = (natSort) ? ascVal : -1*ascVal;
                        }

                        return r;
                    });
                };
            this.cmpThis.sortFn(this.cmpThis.renderedFilteredTableData, isSortAsc);
        }
    }(this);

    //
    //// results table rendering
    // 
    public datarender : any = 
    {
        getColDefLinkTarget(obj: any, currColLink : any) 
        {
            let fnName = "getColDefLinkTarget";
            let val = "";
            if (undefined == currColLink.type) {
                // this is a cludge to have objrelative as default behaviour when defining columns
                currColLink.type = "objrelative";
            }

            switch (currColLink.type) {                
                case "objrelative":
                    let cd = { 
                        val : { 
                            obj : "get",
                            path : currColLink.href
                        }
                    };
                    val = OanLodashWrap.interpolateLoDash(this, obj, cd.val.obj, cd.val.path, undefined, undefined, undefined);
                    break;

                case "reporelative":
                    val = `https://github.com/${currColLink.href}`;                    
                    val = val.replace("${orgName}", OanLodashWrap.lodashGet(obj, 'obj.patched.orgName'));
                    val = val.replace("${repoName}", OanLodashWrap.lodashGet(obj, 'obj.patched.repoName'));
                    break;

            default:
                console.error(`${fnName} unknown type=${currColLink.type}`);
            }

            return val;
        },

        getRepoBranchProtectionRule(repo:UiObj<ApiMwgithubRepo>) : string 
        {
            var r : string = "<ul>";
            // @ts-ignore
            repo.obj.repo.branchProtectionRules.nodes.forEach(element => {
                r+="<li>"  + JSON.stringify(element) + "</li>";
                // .pattern + "; reqiuresReview=" + element.requiresApprovingReviews + ", requiresCodeOwnerReviews=" + element.requiresCodeOwnerReviews + ", restrictsPushes=" + element.restrictsPushes  +  "</li>";
            });
            r += "</ul>";
            return r;
        },

        getRepoBranchProtectAttr(repo : UiObj<ApiMwgithubRepo>, attrName : string) : string {
            var r : string = '';
            if (repo.obj.repo.defaultBranchRef && repo.obj.repo.defaultBranchRef.refUpdateRule) {
                r = repo.obj.repo.defaultBranchRef.refUpdateRule[attrName];
            }

            return r;
        },

        getRelMgmtIdentifer(curr : UiObj<any>) : string {
            var strId : string = '';
            if (curr.obj) {
                var repoName = (curr.obj.repository) ? curr.obj.repository.name : '';
                var tagName = (curr.obj.tag) ? curr.obj.tag.name : '';
                strId = `${repoName}/${tagName}`;
            }
            return strId;
        },

        getWorkflowIdentifer(curr : UiObj<any>) : string {
            var strId : string = '';
            if (curr.obj) {
                var repoName = (curr.obj.repoName) ? curr.obj.repoName : '';
                var wfName = (curr.obj.workflowName) ? curr.obj.workflowName : '';
                strId = `${repoName}/${wfName}`;
            }
            return strId;
        },

        getWorkflowRunIdentifer(curr : UiObj<any>) : string {
            var strId : string = '';
            if (curr.obj) {
                var repoName = (curr.obj.repoName) ? curr.obj.repoName : '';
                var brName = (curr.obj.head_branch) ? curr.obj.head_branch : '';
                var wfName = (curr.obj.name) ? curr.obj.name : '';
                var wfrNum = (curr.obj.run_number) ? curr.obj.run_number : '';
                strId = `${repoName}/${brName}/${wfName}/${wfrNum}`;
            }
            return strId;
        },

        getPrMergedAt(currPr : ApiMwgithubPullrequest) : string {
            var r = "";
            if (currPr.pr.merged) {
                r = currPr.pr.mergedAt;
            }
            return r;
        },

        getPrMergedBy(currPr : ApiMwgithubPullrequest) : string {
            var r = "";
            if (currPr.pr.merged) {
                r = currPr.pr.mergedBy.login;
            }
            return r;
        },

        getPrMergeCommit(currPr : UiObj<ApiMwgithubPullrequest>) : string {
            var r = "";
            if (currPr.obj.pr.merged) {
                var tokens : string[] = currPr.obj.pr.mergeCommit.url.split("/")

                // @ts-ignore
                currPr.prCommitId = tokens[tokens.length-1];
                r = tokens[tokens.length-1];
            }

            return r;
        },

        getPrAttrValueListAsString(obj : UiObj<any>, attrList:string[]) : string {
            // this function pretends to be generic; but it's fragile - it's only called for 
            // [ 'synthetic', 'reviewsApprovers', 'author', 'login' ], [ 'synthetic', 'reviewsCommenters', 'author', 'login' ]
            let rvals : any = {};
            if (obj.obj[attrList[0]]) {
                if (Array.isArray(obj.obj[attrList[0]]) || "object" == typeof obj.obj[attrList[0]] ) {
                    let v : any = obj.obj[attrList[0]];
                    let vNodes : any = v?.nodes;
                    if (Array.isArray(vNodes)) {
                        vNodes.forEach( (element:any) => {
                            let attrObj : string = attrList[1];
                            if (element[attrObj]) { 
                                let attrVal = (attrList.length > 2) ? attrList[2] : null;
                                if (attrVal) {
                                    let key = element[attrObj][attrVal];
                                    rvals[key] = true;
                                } else {
                                    let key = element[attrObj];
                                    rvals[key] = true;
                                }
                            }
                        });
                    }
                } else if (1 < attrList.length) {
                    
                }
            }
                        
            var r : string = Object.keys(rvals).join(' ');
            return r
        },

        getPrFileListAsString(obj : UiObj<any>) : string {
            var r = "";
            obj.obj.files.nodes.forEach( (element:any) => {
                r += element.path + " ";
            });
            return r;
        },

        getPrCommentListAsString(obj: UiObj<any>) : string {
            var r : string = ''; 
            if (obj.obj && obj.obj.comments && 0 < obj.obj.comments.totalCount) {
                obj.obj.comments.nodes.forEach((element:any) => { 
                    r += `<p>author:${element.author.login}; body:${element.bodyText}</p>`;                    
                });
            }

            return r;
        },

        getDeploymentBranchPolicy(obj:UiObj<any>)
        {
            let result = 'none';
            if (obj.obj?.environment?.deployment_branch_policy)
            {
                let dbp = obj.obj.environment.deployment_branch_policy;
                if (dbp.protected_branches)
                {
                    result = 'Protected branches';
                }
                else if (dbp.custom_branch_policies)
                {
                    result = 'Custom branch policies';
                }
                else
                {
                    // none
                }
            }
            
            return result;
        },


        enumPrincipalType(obj : UiObj<any>, attrName : string) 
        {
            let r : string = ''; 
            let enumVal = OanLodashWrap.lodashGet(obj, attrName[0]);
            switch (enumVal)
            {
                case "EntityMwgithubaccesscontrol_PrincipalType.GithubUser":
                    r = "User"; break;

                case "EntityMwgithubaccesscontrol_PrincipalType.GithubTeam":
                    r = "Team"; break;
            }

            return r;
        },

        enumPermissionTargetType(obj : UiObj<any>, attrName : string) 
        {
            let r : string = ''; 
            let enumVal = OanLodashWrap.lodashGet(obj, attrName[0]);
            switch (enumVal)
            {
                case EntityMwgithubaccesscontrol_PermissionTargetType.GithubOrg:
                    r = "Org"; break;

                case EntityMwgithubaccesscontrol_PermissionTargetType.GithubRepo:
                    r = "Repo"; break;

                case EntityMwgithubaccesscontrol_PermissionTargetType.GithubSite:
                    r = "Site"; break;
            }

            return r;
        },

        getJsonStringifyObject(obj:any) : string {
            return JSON.stringify(obj);
        },

        getJsonStringify(obj:UiObj<any>, args:any[]) : string {
            let result : string = '';
            if (! Array.isArray(args) || 0 == args.length )
            {
                result= JSON.stringify(obj.obj);  
            }
            else
            {
                let pathToNode : string = args[0];
                let pathToAttr : string = (1 < args.length) ? args[1] : undefined;
                let nodes = OanLodashWrap.lodashGet(obj, pathToNode);
                if (pathToAttr) 
                {
                    result = nodes.map( (n:any)=>OanLodashWrap.lodashGet(n,pathToAttr)).join('\n');
                }
                else
                {
                    result = JSON.stringify(nodes);
                }
            }

            return result;
        },

        renderNodes(obj : UiObj<any>, args:string[]) {
            let r : string = '';
            let pathToNode : string = args[0];
            let pathToAttr : string = args[1];
            let nodes = OanLodashWrap.lodashGet(obj, pathToNode);
            let nodeAttrs = [];
            if (Array.isArray(nodes)) {
                nodeAttrs = nodes.map((n) => OanLodashWrap.lodashGet(n, pathToAttr));
                r = nodeAttrs.join(' ');
            }

            return r;
        },
    };

    resultsrenderer : any = {
        cmpThis : undefined as OrbiresultstablenativeComponent | undefined,       
        getAuditTest(currRow:OrbiRow, rowCell : Partial<OrbiCellValue>, attrName : string[]) {
            let r : string = '';
            let rowObj : UiObj<any> = rowCell.auditValueCalc.obj;
            let myViewAuditResults = this.cmpThis.viewAuditResults;
            if (myViewAuditResults && 0 < Object.keys(myViewAuditResults).length) {
                if (rowObj && rowCell) {
                    let currAr = myViewAuditResults[rowObj.id]
                    let currArTestFinding = Object.keys(currAr.auditresult.resultsobj.findings)
                                                .filter((currKey:string) => currAr.auditresult.resultsobj.findings[currKey])
                                                .some((r:string) => attrName.includes(r));
                    
                    delete rowCell.cellClasses!['wbaudittest-pass'];
                    delete rowCell.cellClasses['wbaudittest-fail']
                    delete rowCell.cellClasses!['wbaudittest-na'];

                    // lack of a finding is a pass
                    if (! currArTestFinding) {
                        let testRan = currAr.auditresult.resultsobj.executedtests && attrName.some((currAttrName:string) => currAr.auditresult.resultsobj.executedtests[currAttrName]);
                        if (testRan) {
                            r = 'Pass';
                            rowCell.cellClasses!['wbaudittest-pass'] = true;
                        } else {
                            r = 'Na';
                            rowCell.cellClasses!['wbaudittest-na'] = true;
                        }
                    } else {
                        r = 'Fail';
                        rowCell.cellClasses['wbaudittest-fail'] = true;
                    }
                } else {
                    debugger;
                }
            }

            return r;
        },

    };
}
