

import { OangError } from './OangError';

export enum OangEventCode {
    APP_SPINNER_START = "APP_SPINNER_START",
    APP_SPINNER_STOP = "APP_SPINNER_STOP",


    COOKIE_PROJET_ADD = "COOKIE_PROJET_ADD"        
};

export class OangEvent  {
    private _code : OangEventCode;
    private _ctxt : string; 
    private _param? : any; 

    constructor(code : OangEventCode, ctxt : string, param? : any) {
        this._ctxt = ctxt; 
        this._code = code
        this._param = param; 
    }

    public getCode() : OangEventCode {
        return this._code;
    }
}
