import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable, Injector } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DecimalPipe} from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';
import { NgChartsModule } from 'ng2-charts';



import { HttpClientModule, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpStatusCode, HttpUserEvent } from '@angular/common/http';
// import {NgxPaginationModule} from 'ngx-pagination'; 
import { Route, Router, RouterModule, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

import { EMPTY, fromEventPattern, Observable, of, throwError } from 'rxjs';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
// import { FormlyModule } from '@ngx-formly/core';
// import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';

// import { AngularSplitModule } from 'angular-split';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { OrbigithubService } from './svc/orbigithub.service';
import { OrbiAppStateService } from './svc/orbiappstate.service';

// import { EntityComponent } from './entity/entity.component';
// import { DynamicformComponent } from './dynamicform/dynamicform.component';
// import { CookieComponent, CookieComponentModalAdd } from './cookie/cookie.component';
 
import { OanPipeArrayMap, OanPipeFilterByAttrNameAndValue, OanPipeFilterWithCallback, OanPluckPipe } from '../../src/oang/cmn/OanPipeFilterWithCallback';
// import {  Orbiformlyx_card } from './orbiformlyx/orbiformlyx_card.wrapper';
// import {  Orbiformlyx_field_repeat } from 'src/app/orbiformlyx/orbiformlyx_field_repeat.component';
// import { CookiePrjComponent } from './cookie-analyzer-old/cookie-prj.component';
// import { OrbibackendPublicService } from './svc/orbibackend.public.service';
import { GoodbyeComponent } from './goodbye/goodbye.component';
import { GithubComponent } from './github/github.component';
import { GithubdashboardComponent } from './github/githubdashboard/githubdashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { TenantsettingsComponent } from './tenantsettings/tenantsettings.component';
import { OrbiRequestBase } from './cmn/svc/orbirequest';
import { OrbidebugService } from './svc/orbidebug.service';
import { OrbiiconComponent } from './github/orbiicon/orbiicon.component';
import { OrbiresultstablenativeComponent } from './github/orbiresultstablenative/orbiresultstablenative.component';
import { OrbiresultsfilterComponent } from './github/orbiresultsfilter/orbiresultsfilter.component';
import { OangnotificationsErrorId, OangnotificationsService } from 'src/oang/svc/oangnotifications.service';
import { OrbiprofileService } from './svc/orbiprofile.service';
import { OrbiwidgetnameselectorComponent } from './cmn/widget/orbiwidgetnameselector.component';
import { OrbiwidgetradioComponent } from './cmn/widget/orbiwidgetradio.component';
import { OrbiwidgettextboxlistComponent } from './cmn/widget/orbiwidgettextboxlist.component';
import { OrbiBrowserStorageService } from './svc/orbibrowserstorage.service';
import { Orbistartuprequestsservice } from './svc/orbistartuprequests.service';
import { GitrospectSignupFlowComponent } from './github/gitrospectsignupflow/gitrospectsignupflow.component';
import { GithubmetricsComponent } from './github/githubmetrics/githubmetrics.component';
import { OrbiwidgetgraphComponent } from './cmn/widget/orbiwidgetgraph.component';
import { OrbiwidgetdateselectorComponent } from './cmn/widget/orbiwidgetdateselector.component';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
    private cbSpinnerSignalError? : (httpStatus:number, httpErr : HttpErrorResponse) => void ;
    private _thisAppComponent : AppComponent | undefined;

    constructor(private router: Router,
        private readonly svcError : OangnotificationsService,
        private svcStartuprequestsservice : Orbistartuprequestsservice ) { 
        this.cbSpinnerSignalError = undefined;
    }

    private handleAuthnError(err: HttpErrorResponse, caught : Observable<HttpEvent<any>>) : string  {
        //handle your auth error or rethrow     
        let fnName = 'handleAuthnError';
        console.error(`handleAuthError: Http call failed status=${err.status}, url=${err.url}, ${err.message}`);
        OrbiRequestBase.getHttpErrorBs().next(err);

        if (err.status === 401 || err.status === 403) {
            //navigate /delete cookies or whatever
            let msg = `Auth error - reauthenticating `;
            this.svcError.addPageAlertError(OangnotificationsErrorId.Error_HttpAuth, {
                context: fnName, 
                msg: msg});
            AppComponent.thisAppComponent?.onSigninGeneral(undefined);
            // this.router.navigateByUrl(`/login`);
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return msg;
            
            //EMPTY; // or EMPTY may be appropriate here
        } else {
            let msg = `Unknown auth error ` + JSON.stringify(err);
            this.svcError.addPageAlertError(OangnotificationsErrorId.Error_HttpAuth, {
                context: fnName, 
                msg: msg});
            return msg;
        }
    }

    private handleAuthzError(err: HttpErrorResponse, caught : Observable<HttpEvent<any>>) : string  {
        let msg = '';
        let fnName = 'handleAuthzError';
        //handle your auth error or rethrow     
        let urlBits : string[] | undefined = err.url?.split('/');
        if (Array.isArray(urlBits) && 3 < urlBits.length && 'mwgithub' == urlBits[3] && 'healthcheck' == urlBits[4])
        {
            this.svcStartuprequestsservice.healthCheckAuthzError(err);
            throw err;
        }
        else 
        {
            msg = `Authorization error; please ask the administrator.   `;
            this.svcError.addPageAlertError(OangnotificationsErrorId.Error_HttpAuth, {
                context: fnName, 
                msg: msg});
        }
        
        return msg;
    }

    private handleError(err: HttpErrorResponse, caught : Observable<HttpEvent<any>>): string 
    // Observable<HttpEvent<any>> 
    {
        //handle your auth error or rethrow     
        console.error(`handleError: Http call failed status=${err.status}, url=${err.url}, ${err.message}`);
        OrbiRequestBase.getHttpErrorBs().next(err);

        let msg = '';
        let ctxt = '';
        let code = OangnotificationsErrorId.Error_HttpUnknown;
        let isError = true;
        switch (err.status)
        {
            case HttpStatusCode.PayloadTooLarge:
                msg = `The response data is too large, try using a smaller time period or reference fewer repositories`;
                code = OangnotificationsErrorId.Info_PayloadTooLarge;
                ctxt = 'While getting data';
                isError = false;
                break;

            default:
                msg = `Network error ` + err.message;
                code = OangnotificationsErrorId.Error_HttpUnknown;
                ctxt = 'HttpErrorInterceptor.handleError';
        }
            
        if (isError) {
            this.svcError.addPageAlertError(code, {
                context: ctxt, 
                msg: msg
            });
        } else {
            this.svcError.addPageAlertInfo(code, {
                context: ctxt,
                msg: msg
            });
        }

        return msg;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
        // const authReq = req.clone({headers: req.headers.set(Cookie.tokenKey, Cookie.getToken())});
        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
        //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
        return next.handle(req).pipe(
            catchError((err:any, caught: Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>>) => {
                    let msg : string = 'Unknown network errror'; 
                    switch (err.status)
                    {
                        case 401:
                            this.handleAuthnError(err, caught);                    
                            msg = "attempting login";
                            break;
                        case 403:
                            this.handleAuthzError(err, caught);  
                            msg = "Not authorized";
                            break;
                        default:
                            msg = this.handleError(err, caught);
                    }
                    
                    return throwError(msg);
                })
            ); 
    }
} 

@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        /* if (request && request.url) {
            var u : URL = new URL(request.url);
            new URL(request.url).pathname.match(/pubapi/)
            if  */
        request = request.clone({
            withCredentials: true
        });

        return next.handle(request);
    }
}
export let OrbiAppInjector: Injector;

export const OrbiAppComponentRoutes = {
    Default : '',
    Default2 : '/',
    Home : 'home',
    GithubReports : 'mwgithub',
    GithubDashboard : 'mwgithubdashboard',
    GithubMetrics : 'mwgithubmetrics',
    Profile : 'profile',
    TenantSettings : 'orgsettings',
    Goodbye : 'goodbye'
}

@NgModule({
  declarations: [
    AppComponent,
   // EntityComponent,
   // DynamicformComponent,
    // CookieComponent, CookieComponentModalAdd, 
    OanPipeFilterByAttrNameAndValue,
    OanPipeFilterWithCallback,
    OanPipeArrayMap,
    OanPluckPipe,
   // Orbiformlyx_field_repeat,
    // CookiePrjComponent,
    GoodbyeComponent,
    GithubComponent,
    GithubdashboardComponent,
    GithubmetricsComponent,
    ProfileComponent,
    TenantsettingsComponent,
    OrbiiconComponent,
    OrbiresultstablenativeComponent,
    OrbiresultsfilterComponent,
    OrbiwidgetdateselectorComponent,
    OrbiwidgetnameselectorComponent,
    OrbiwidgetradioComponent,
    OrbiwidgettextboxlistComponent,
    OrbiwidgetgraphComponent,
    GitrospectSignupFlowComponent
  ],
  imports: [
    // AngularSplitModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ClipboardModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
    [
        { path: OrbiAppComponentRoutes.Default, redirectTo: 'mwgithubdashboard', pathMatch: 'full' },
        // { path: OrbiAppComponentRoutes.GithubReports, component: GithubComponent },
        { path: OrbiAppComponentRoutes.GithubReports, children:[ {path: '', component: GithubComponent}, {path: ':tabId', component: GithubComponent} ] },
        { path: OrbiAppComponentRoutes.GithubDashboard, children:[ {path: '', component: GithubdashboardComponent}, {path: ':tabId', component: GithubdashboardComponent} ]  },
        { path: OrbiAppComponentRoutes.GithubMetrics, children:[ {path: '', component: GithubmetricsComponent}, {path: ':tabId', component: GithubmetricsComponent} ]  },
        { path: OrbiAppComponentRoutes.Profile, component: ProfileComponent },
        { path: OrbiAppComponentRoutes.TenantSettings, children:[ {path: '', component: TenantsettingsComponent}, {path: ':tabId', component: TenantsettingsComponent} ] },        
        { path: OrbiAppComponentRoutes.Goodbye, component: GoodbyeComponent }
    ], {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64] // [x, y]
    }),
    ToastrModule.forRoot(), // ToastrModule added

    // chart.js
    NgChartsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ {
        provide: HTTP_INTERCEPTORS,
        useClass: WithCredentialsInterceptor,
        multi: true
    }, {
        provide: HTTP_INTERCEPTORS,
        useFactory: function(router: Router, svcError :OangnotificationsService,svcStartupRequests :Orbistartuprequestsservice ) {
            return new HttpErrorInterceptor(router, svcError, svcStartupRequests);
        },
        multi: true,
        deps: [Router, OangnotificationsService]
    },
    DecimalPipe,
    OangnotificationsService,
    OrbiAppStateService,
    OrbidebugService,
    OrbigithubService,
    OrbiprofileService,
    OrbiBrowserStorageService,
    Orbistartuprequestsservice
//    OrbibackendPublicService,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { 
    constructor(library: FaIconLibrary, private injector: Injector) {
        OrbiAppInjector = this.injector;
        // Add an icon to the library for convenient access in other components
        library.addIconPacks(fas);
      }    
}
