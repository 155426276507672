import { ApiFormCnst_BranchProdDefType, ApiFormCtrlId_Ten_Assets, OrbiSettingNvpName } from "@danclarke2000/gitrospectdto";
import { OrbiAppInjector } from "src/app/app.module";
import { OrbistartuprequestsL0Interface, OrbistartuprequestsL1Interface, OrbistartuprequestsL2Interface, OrbistartuprequestsL3Interface, Orbistartuprequestsservice } from "src/app/svc/orbistartuprequests.service";
import { OanDebugutils } from "../utils/OanDebugutils";

class CheckerIsBranchInteresting
{
    constructor(private myListSettingsBase : any)
    {}

    public isInteresting(branchName : string) : boolean
    {
        let r : boolean = false;
        let myDefaultInterestingBranchNames = ["master","main"];
        let branchDefType = (this.myListSettingsBase) ? this.myListSettingsBase[ApiFormCtrlId_Ten_Assets.LiveBranchDefType] : undefined;
        let branchDefTypeValue = (undefined != branchDefType) ? branchDefType.selectedvalue : undefined;
        switch (branchDefTypeValue)
        {
            case ApiFormCnst_BranchProdDefType.ByBranchAll:
                r = true;
                break;
            
            case ApiFormCnst_BranchProdDefType.ByNameConv: {
                let mySelectedValues = this.myListSettingsBase[ApiFormCtrlId_Ten_Assets.LiveBranchDefByNameConv]?.selectedvalues;
                if (Array.isArray(mySelectedValues)) {
                    r = mySelectedValues.includes(branchName);
                }
                break;                
            }

            case ApiFormCnst_BranchProdDefType.ByPattern:
                OanDebugutils.debuggerWrapper("CheckerIsBranchInteresting.isInteresting TBD not impemented");
                console.error(`CheckerIsBranchInteresting.isInteresting TBD not impemented`);
                break;        
                        
            // @ts-ignore
            default:            
                console.warn(`CheckerIsBranchInteresting.isInteresting LiveBranchDefType=${branchDefType}`);
            case ApiFormCnst_BranchProdDefType.ByDefault:
                r = myDefaultInterestingBranchNames.includes(branchName);
                break;
        }

        return r;
    }
}

export class OanAnalyzerParams
{
    private static startupRequestsL0State : OrbistartuprequestsL0Interface | undefined ;
    private static startupRequestsL1State : OrbistartuprequestsL1Interface | undefined ;
    private static startupRequestsL2State : OrbistartuprequestsL2Interface | undefined ;
    private static startupRequestsL3State : OrbistartuprequestsL3Interface | undefined ;
    private static checkerIsBranchInteresting : CheckerIsBranchInteresting | undefined ;
    private static reposummaryMap : any = {};
    public static async init(startupReqStates : any[]) {
        // ### we've refactored to L2 is L3
        OanAnalyzerParams.startupRequestsL0State = <OrbistartuprequestsL0Interface>startupReqStates[0];
        OanAnalyzerParams.startupRequestsL1State = <OrbistartuprequestsL1Interface>startupReqStates[1];
        OanAnalyzerParams.startupRequestsL2State = <OrbistartuprequestsL2Interface>startupReqStates[2];     
        OanAnalyzerParams.startupRequestsL3State = <OrbistartuprequestsL3Interface>startupReqStates[3];     
        OanAnalyzerParams.reposummaryMap = {};
        OanAnalyzerParams.startupRequestsL3State.reposummaryList.forEach((reposummary:any) => {
            OanAnalyzerParams.reposummaryMap[reposummary.repoName] = reposummary;
        });

        let settingsLiveBranch : any = OanAnalyzerParams.startupRequestsL3State?.settingsLiveBranch[OrbiSettingNvpName.GithubLiveProductionAssets_base];
        OanAnalyzerParams.checkerIsBranchInteresting = new CheckerIsBranchInteresting(settingsLiveBranch);   
    }

    public static DefaultDebounceTime : number = 400;

    /*###*/
    public static MaxNumProdBranches : number = 10;
    public static BranchNamingConvRe : RegExp = /^[\w-.\/]+$/;
    public static OrbiProdBranchesLiveAssets : any = {};
    // public static OrbiRepoTopics : string[] = []; // ["cicd-aws", "cicd-aws-stg", "used-prod"]; 
    //public static OrbiProdBranches : string[] = []; // ["master", "main", "dev"]; 
    // public static OrbiProdRepositories : string[] = []; // ['cma-service', 'cma-service-i18n', 'customer-service', 'customer-service-admin-web', 'customer-service-chuck-cli', 'customer-service-eligibility-s3-lambda', 'cyber-aqua', 'data-helm-airflow', 'data-helm-datahub', 'data-helm-kafka', 'data-helm-spark-operator', 'data-k8s', 'data-kafka-apps-config', 'data-kafka-connect-docker', 'data-kafka-producer-lambda', 'data-kafka-vesa-stream', 'data-tf-core', 'data-tf-datadog', 'data-tf-kafka-api-gw', 'data-tf-lake', 'devops-github-actions', 'devops-helm-app', 'devops-k8s', 'devops-tf-core', 'devops-tf-datadog', 'evermind-today-explore-recsys', 'foundations-app-service', 'foundations-serverless', 'foundations-static-assets', 'foundations-webapp', 'foundations-webapp-static-assets', 'foundations-website', 'github_actions_test', 'habits-website', 'io-connectors', 'koa-testing-metrics', 'koahealth-website', 'licensebat', 'mgm-test', 'mindset-app-service', 'mindset-app-service-apigateway', 'mindset-dashboard-service', 'mindset-dashboard-web', 'mindset-static-assets', 'mindset-website', 'perspectives-static-assets', 'proxy-service', 'research-dashboard-service', 'research-dashboard-web', 'research-static-assets', 'rnd-contextual-bandits', 'rnd-habits-analysis', 'rnd-habits-jobs', 'rnd-ml-experiments-stg', 'rnd-package-template', 'rnd-recsys', 'rnd-recsys-data-service', 'rnd-recsys-eval', 'rnd-recsys-features-scala', 'rnd-recsys-jobs', 'rnd-service-template', 'rnd-stress-detection-api'];
    

    public static SecretRotationPeriodDays : number = 90;
    
    public static isProdRepo(repo:any | string)
    {
        let r = false;
        if ("object" == typeof repo) {
            if (undefined == repo.synthetic?.isLive) {
                OanDebugutils.debuggerWrapper("isProdRepo - invalid object");
            }
            if (true == repo.synthetic?.isLive) {
                r = true;
            }
        } else if ("string" == typeof repo) {
            // here  we err on side of caution
            let objR : boolean = true;
            let repoNameIsLive = this.reposummaryMap[repo]?.isLive;
            if (undefined != repoNameIsLive) {
                objR = repoNameIsLive;
            } else {
                // this is typically because the repo is so old it doesnt have commit within review period,
                // however it could also arise if there is a bug (e.g. wrong attribute beind sent).. so one to watch
                objR = false;                
            } 

            r = objR;
        } else {
            OanDebugutils.debuggerWrapper(".?.");;;
        }

        return r;
    }
    
    public static alreadyWarnedAboutProdBranchConfig : boolean = false;
    public static isProdBranchName(branchNames:string[] | string)
    {
        let isAuditRelevant = false;
        if (OanAnalyzerParams.checkerIsBranchInteresting) {
            if (Array.isArray(branchNames)) {
                for (let i = 0; i < branchNames.length; i++) {
                    let branchName : string = branchNames[i];
                    if (OanAnalyzerParams.checkerIsBranchInteresting.isInteresting(branchName)) {
                        isAuditRelevant = true;
                        break;
                    }
                }
            } else {
                isAuditRelevant = OanAnalyzerParams.checkerIsBranchInteresting.isInteresting(branchNames);
            }
        } else {
            isAuditRelevant = true;
        }

        return isAuditRelevant;
    }
    
    // TBD
    // we should set some rules in config and default to true
    public static isPrSignificantChange(prObj:any)
    {
        let r = true;
        return r;

        r = false;
        let reForFilenames = /^\.github|\.tf$|\.yaml/;
        let myFiles = prObj.files.nodes;
        for (let i=0; i< myFiles.length && false == r; ++i) {
            let currFile = myFiles[i];
            r = reForFilenames.test(currFile.path);                            
        }

        return r;
    }
}
