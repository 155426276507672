<div class="row mx-0">
    <div class="col-2 dashboard-sidebar">
        <!-- SIDEBAR  -->

        <!-- ngbNav #githubContent="ngbNav" [(activeId)]="tabState.tabActive" (navChange)="navChange($event)" --> 
        <ul class="nav-pills" orientation="vertical">            
            <li class="nav-link orbi-nav-link-title"><a href="/mwgithubdashboard">Dashboard</a></li>
            <li class="nav-link orbi-nav-link-title">Github events</li>
                <li class="nav-link orbi-nav-link-subtitle" [ngClass]="this.tabState.tabMeta[this.navItemWebhookEventsOrganization].class"
                    [ngClass]="{active: navItemWebhookEventsOrganization===this.tabState.activeTab}"
                ><a  [routerLink]="['/mwgithub/eventsorgs']">Webhooks [organization] </a>
                    <div class="badge-holder text-right" *ngIf="this.tabState.tabMeta[this.navItemWebhookEventsOrganization].hasBadgeData">
                        <span class="badge badge-pill badge-danger"><app-orbiicon iconName="faExclamationTriangle" placement="top tooltipClass="my-custom-class ngbTooltip="Important events"></app-orbiicon>{{this.tabState.tabMeta[this.navItemWebhookEventsOrganization].numFail}}</span>
                    </div>
                </li>    
                <li class="nav-link orbi-nav-link-subtitle" [ngClass]="this.tabState.tabMeta[this.navItemWebhookEventsSourceCode].class"
                    [ngClass]="{active: navItemWebhookEventsSourceCode===this.tabState.activeTab}"
                ><a [routerLink]="['/mwgithub/eventssourcecode']">Webhooks [source code]</a>
                    <div class="badge-holder text-right" *ngIf="this.tabState.tabMeta[this.navItemWebhookEventsSourceCode].hasBadgeData">
                        <span class="badge badge-pill badge-danger"><app-orbiicon iconName="faExclamationTriangle" placement="top tooltipClass="my-custom-class ngbTooltip="Important events"></app-orbiicon>{{this.tabState.tabMeta[this.navItemWebhookEventsSourceCode].numFail}}</span>
                    </div>
                </li>   
                <li class="nav-link orbi-nav-link-subtitle" [ngClass]="this.tabState.tabMeta[this.navItemWebhookEventsProjMgmt].class"
                    [ngClass]="{active: navItemWebhookEventsProjMgmt===this.tabState.activeTab}"><a 
                    [routerLink]="['/mwgithub/eventsprojmgmt']">Webhooks [project mgmt]</a>
                    <div class="badge-holder text-right" *ngIf="this.tabState.tabMeta[this.navItemWebhookEventsProjMgmt].hasBadgeData">
                        <span class="badge badge-pill badge-danger"><app-orbiicon iconName="faExclamationTriangle" placement="top tooltipClass="my-custom-class ngbTooltip="Important events"></app-orbiicon>{{this.tabState.tabMeta[this.navItemWebhookEventsProjMgmt].numFail}}</span>
                    </div>
                </li>      
                <li class="nav-link orbi-nav-link-subtitle" 
                    [ngClass]="this.tabState.tabMeta[this.navItemWebhookEventsCiCd].class"
                    [ngClass]="{active: navItemWebhookEventsCiCd===this.tabState.activeTab}">
                    <a [routerLink]="['/mwgithub/eventscicd']">Webhooks [ci/cd]</a>
                    <div class="badge-holder text-right" *ngIf="this.tabState.tabMeta[this.navItemWebhookEventsCiCd].hasBadgeData">
                        <span class="badge badge-pill badge-danger"><app-orbiicon iconName="faExclamationTriangle" placement="top tooltipClass="my-custom-class ngbTooltip="Important events"></app-orbiicon>{{this.tabState.tabMeta[this.navItemWebhookEventsCiCd].numFail}}</span>
                    </div>
                </li>     
            <li class="nav-link orbi-nav-link-title">Posture management</li>
            <li class="nav-link orbi-nav-link-subtitle" 
                [ngClass]="this.tabState.tabMeta[this.navItemRepos].class"
                [ngClass]="{active: navItemRepos===this.tabState.activeTab}">
                <a [routerLink]="['/mwgithub/repositories']"> Repositories</a>
                <!-- (click)="this.setActiveTabWrapper(this.navItemRepos)">Repositories</a>-->
                <div class="badge-holder text-right" *ngIf="this.tabState.tabMeta[this.navItemRepos].hasBadgeData">
                    <span class="badge badge-pill badge-success"><app-orbiicon iconName="faCheck" placement="top tooltipClass="my-custom-class ngbTooltip="Repositories"></app-orbiicon>{{this.tabState.tabMeta[this.navItemRepos].numPass}}</span>
                    <span class="badge badge-pill badge-danger"><app-orbiicon iconName="faExclamationTriangle"></app-orbiicon>{{this.tabState.tabMeta[this.navItemRepos].numFail}}</span>
                </div>
            </li>   
            <!-- 
            <li class="nav-link orbi-nav-link-subtitle" [ngClass]="this.tabState.tabMeta[this.navItemBranchProtection].class"><a 
                (click)="this.tabState.setActiveTab(this.navItemBranchProtection)">Branch protection</a>
                <div class="badge-holder text-right" *ngIf="this.tabState.tabMeta[this.navItemBranchProtection].hasBadgeData">
                    <span class="badge badge-pill badge-success"><app-orbiicon iconName="faCheck" placement="top tooltipClass="my-custom-class ngbTooltip="Users"></app-orbiicon>{{this.tabState.tabMeta[this.navItemBranchProtection].numPass}}</span>
                    <span class="badge badge-pill badge-danger"><app-orbiicon iconName="faExclamationTriangle"></app-orbiicon>{{this.tabState.tabMeta[this.navItemBranchProtection].numFail}}</span>
                </div>
            </li>
        -->                  
            <li class="nav-link orbi-nav-link-subtitle"
                [ngClass]="{active: navItemReposPublic===this.tabState.activeTab}"
                ><a [routerLink]="['/mwgithub/publicrepositories']" routerLinkActive="active">Public Repositories</a></li>
                <!-- (click)="this.setActiveTabWrapper(this.navItemReposPublic)">Public repositories</a>
            </li>    -->        
            <li class="nav-link orbi-nav-link-title">Change Management</li>
            <li class="nav-link orbi-nav-link-subtitle" 
                [ngClass]="this.tabState.tabMeta[this.navItemPrs].class"
                [ngClass]="{active: navItemPrs===this.tabState.activeTab}">
                <a [routerLink]="['/mwgithub/pullrequests']">Pull Requests</a>
                <!--(click)="this.setActiveTabWrapper(this.navItemPrs)"--> 
                <div class="badge-holder text-right"  *ngIf="this.tabState.tabMeta[this.navItemPrs].hasBadgeData">
                    <span class="badge badge-pill badge-success"><app-orbiicon iconName="faCheck" placement="top tooltipClass="my-custom-class ngbTooltip="Pull requests"></app-orbiicon>{{this.tabState.tabMeta[this.navItemPrs].numPass}}</span>
                    <span class="badge badge-pill badge-danger"><app-orbiicon iconName="faExclamationTriangle"></app-orbiicon>{{this.tabState.tabMeta[this.navItemPrs].numFail}}</span>
                </div>                
            </li>
            <li class="nav-link orbi-nav-link-subtitle" 
                [ngClass]="this.tabState.getTabMeta(this.navItemCommits).class"
                [ngClass]="{active: navItemCommits===this.tabState.activeTab}">
                <a [routerLink]="['/mwgithub/commits']">Commits</a>
                <!-- (click)="this.setActiveTabWrapper(this.navItemCommits)" -->
                <div class="badge-holder text-right" *ngIf="this.tabState.tabMeta[this.navItemCommits].hasBadgeData">
                    <span class="badge badge-pill badge-success"><app-orbiicon iconName="faCheck" placement="top tooltipClass="my-custom-class ngbTooltip="Commits"></app-orbiicon>{{this.tabState.tabMeta[this.navItemCommits].numPass}}</span>
                    <span class="badge badge-pill badge-danger"><app-orbiicon iconName="faExclamationTriangle"></app-orbiicon>{{this.tabState.tabMeta[this.navItemCommits].numFail}}</span>
                </div>
            </li>                  
            <li class="nav-link orbi-nav-link-subtitle"  
                [ngClass]="this.tabState.tabMeta[this.navItemReleaseMgmt].class"
                [ngClass]="{active: navItemReleaseMgmt===this.tabState.activeTab}"><a
                    [routerLink]="['/mwgithub/releases']">Release Management</a>
                <!-- (click)="this.setActiveTabWrapper(this.navItemReleaseMgmt)" -->
                <div class="badge-holder text-right" *ngIf="this.tabState.tabMeta[this.navItemReleaseMgmt].hasBadgeData">
                    <span class="badge badge-pill badge-success"><app-orbiicon iconName="faCheck" placement="top tooltipClass="my-custom-class ngbTooltip="Releases"></app-orbiicon>{{this.tabState.tabMeta[this.navItemReleaseMgmt].numPass}}</span>
                    <span class="badge badge-pill badge-danger"><app-orbiicon iconName="faExclamationTriangle"></app-orbiicon>{{this.tabState.tabMeta[this.navItemReleaseMgmt].numFail}}</span>
                </div>
            </li>
            <li class="nav-link orbi-nav-link-title">Access Control</li>
            <li class="nav-link orbi-nav-link-subtitle" 
                [ngClass]="this.tabState.tabMeta[this.navItemUsers].class"
                [ngClass]="{active: navItemUsers===this.tabState.activeTab}">
                    <a [routerLink]="['/mwgithub/users']">Users</a>
            </li>
            <li class="nav-link orbi-nav-link-subtitle" [ngClass]="this.tabState.tabMeta[this.navItemTeams].class"
                [ngClass]="{active: navItemTeams===this.tabState.activeTab}"><a 
                [routerLink]="['/mwgithub/teams']">Teams</a>                    
            </li>
            <li class="nav-link orbi-nav-link-subtitle" [ngClass]="this.tabState.tabMeta[this.navItemTeamMembers].class"
                [ngClass]="{active: navItemTeamMembers===this.tabState.activeTab}">
                <a [routerLink]="['/mwgithub/teammembership']">Team membership 
            </a>
        </li>            
            <li class="nav-link orbi-nav-link-subtitle"  [ngClass]="this.tabState.tabMeta[this.navItemAccessControl].class"
                [ngClass]="{active: navItemAccessControl===this.tabState.activeTab}"><a 
                [routerLink]="['/mwgithub/accesscontrol']">Permission register
                </a>
            </li>    
    <!--                             
            <li class="nav-link orbi-nav-link-title">Code analysis</li>
            <li class="nav-link orbi-nav-link-subtitle" [ngClass]="this.tabState.tabMeta[this.navItemCodeAnalysisVulns].class"><a 
                (click)="this.tabState.setActiveTab(this.navItemCodeAnalysisVulns)"><del>Vulnerability alerts</del></a></li>
            <li class="nav-link orbi-nav-link-subtitle"><a><del>DLP Data discovery</del></a></li>
            <li class="nav-link orbi-nav-link-subtitle"><a><del>Secret scanning</del></a></li>
            -->
            <li class="nav-link orbi-nav-link-title">Continuous deployment</li>    
            <li class="nav-link orbi-nav-link-subtitle" 
                [ngClass]="{active: navItemEnvironments===this.tabState.activeTab}"
            ><a [routerLink]="['/mwgithub/environments']">Environments</a></li> 
            <li class="nav-link orbi-nav-link-subtitle" 
                [ngClass]="{active: navItemSecrets===this.tabState.activeTab}"
            ><a [routerLink]="['/mwgithub/secrets']">Github secrets</a></li>        
            <li class="nav-link orbi-nav-link-subtitle"  
                [ngClass]="this.tabState.tabMeta[this.navItemWorkflows].class"
                [ngClass]="{active: navItemWorkflows===this.tabState.activeTab}">
                <a [routerLink]="['/mwgithub/workflows']">Workflows</a></li>
    <!-- 
                <li class="nav-link orbi-nav-link-subtitle"   [ngClass]="this.tabState.tabMeta[this.navItemWorkflowRuns].class"><a
                (click)="this.setActiveTabWrapper(this.navItemWorkflowRuns)"><del>Workflow runs</del></a></li>
    
            <li class="nav-link orbi-nav-link-title">Settings</li>
            <li class="nav-link orbi-nav-link-subtitle"><a (click)="onSettings($event, 'EntityMwsettingsGroup_githubreportsettings')">Report settings</a></li>
            <li class="nav-link orbi-nav-link-subtitle"><a (click)="onSettings($event, 'EntityMwsettingsGroup_GithubAuditChecks')">Checks settings</a></li>         
    -->
        </ul>
    </div>
    <div class="col-10 dashboard-main">        
        <div class="row">
            <div class="col-12 dashboard-main__filters" [hidden]="uiState.abTestTopSelector">
                <div class="inner-filters">                                 
                    <!-- start filter row 1  -->
                    <div class="row" >
                        <div class="col-12 d-flex " (click)="uiState.setTabStateGlobalFiltersCollapse($event,!tabStateGlobalFilters.isCollapsed, 'd-flex' )">                           
                            <div class="orbi-filters-tabs navbar" >       
                                <ul><li class="nav-item">                                
                                    <button class="btn btn-light" type="button" (click)="uiState.setTabStateGlobalFiltersCollapse($event, !tabStateGlobalFilters.isCollapsed, 'togglebtn')"><app-orbiicon iconName="faCaretDown"></app-orbiicon></button>
                                </li></ul>
                                <ul ngbNav #ngbNavGlobalFilter="ngbNav"  [(activeId)]="tabStateGlobalFilters.tabActive" class="nav-tabs">
                                    <li [ngbNavItem]="tabStateGlobalFilters.navItemOrg" class="nav-item active" (click)="uiState.setTabStateGlobalFiltersCollapse($event, undefined, tabStateGlobalFilters.navItemOrg)">
                                        <a ngbNavLink >{{tabStateGlobalFilters.titleForTabOrg}} : {{uiState.qfFilterState.stateVars.orgName}}</a>
                                        <ng-template ngbNavContent> 
                                            <h2 class="orbi-section-title">Github org:
                                            <div ngbDropdown class="d-inline-block ">
                                                <button type="button" class="dropdown-toggle btn btn-light" ngbDropdownToggle>{{uiState.qfFilterState.stateVars.orgName}}</button>
                                                <div class="dropdown-menu-right dropdown-menu" ngbDropdownMenu>
                                                  <button *ngFor="let frt of uiState.orgDropDownPossibleValues" ngbDropdownItem (click)="uiState.setOrgDropChoice(frt)">{{frt}}</button>                                                  
                                                </div>
                                            </div>    

                                            <h2 class="orbi-section-title">Included repositories:</h2> 
                                            <span>{{tabStateGlobalFilters.repoNamesForReport.length}} repositories.  </span>
                                            <i>See repository settings for more info</i>                                            
                                        </h2>                                       
                                        </ng-template>
                                    </li> 
                                    <li [ngbNavItem]="tabStateGlobalFilters.navItemRepo" class="nav-item" (click)="uiState.setTabStateGlobalFiltersCollapse($event, undefined, tabStateGlobalFilters.navItemRepo)">
                                        <a ngbNavLink>{{tabStateGlobalFilters.titleForTabRepo}}</a>
                                        <ng-template ngbNavContent>
                                            <div class="orbi-filters-list">
                                                <ul>
                                                    <h3>Selection by topic</h3>
                                                    <app-orbiwidgetnameselector
                                                        #tabStateFilterRepositoriesByTopic
                                                        [allpossiblevalues]="tabStateGlobalFilters.repoTopics"
                                                        [inselectedvalues]="tabStateGlobalFilters.repoTopicsSelectedIn"
                                                        (selectedvaluesChange)="tabStateGlobalFilters.onChangeSelectedRepos('tabStateFilterRepoTopics', $event)"
                                                        >
                                                    </app-orbiwidgetnameselector>                    
                                                </ul>
                                                <ul>
                                                    <h3>Selection by individual repository</h3>
                                                    <app-orbiwidgetnameselector
                                                        #tabStateFilterRepositories
                                                        [allpossiblevalues]="tabStateGlobalFilters.repoNamesForReportForSelectReposWidget"
                                                        [inselectedvalues]="tabStateGlobalFilters.repoNamesSelectedIn"
                                                        (selectedvaluesChange)="tabStateGlobalFilters.onChangeSelectedRepos('tabStateFilterRepositories', $event)"
                                                        >
                                                    </app-orbiwidgetnameselector>                    
                                                    <!-- 
                                                    <li *ngFor="let frt of tabStateGlobalFilters.repoNamesForReport">
                                                        <button class="btn btn-filter btn-sm"> {{frt.repoName}}</button>
                                                    </li> -->
                                                </ul>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li 
                                        [ngbNavItem]="tabStateGlobalFilters.navItemDate" class="nav-item" 
                                        (click)="uiState.setTabStateGlobalFiltersCollapse($event, undefined, tabStateGlobalFilters.navItemDate)">
                                        <a ngbNavLink><app-orbiicon class="mr-2" iconName="faCalendar"></app-orbiicon>Dates: {{uiState.qfFilterState.stateVarsDate.dateLabel}}</a>
                                        <ng-template ngbNavContent>
                                            <h2 class="orbi-section-title">Select your Dates:</h2>
                                            <p>Please select the date range for your search.</p>
                                            <hr> 
                                            <app-orbiwidgetdateselector *ngIf="uiState.qfFilterState?.stateVarsDate"
                                                #tabStateFiltersDates
                                                [quickValue]="uiState.qfFilterState.stateVarsDate.quickValue"
                                                [dateSelectorType]="uiState.qfFilterState.stateVarsDate.dateSelectorType"
                                                [inThePastTypeThing]="uiState.qfFilterState.stateVarsDate.inThePastTypeThing"
                                                [inThePastNumThings]="uiState.qfFilterState.stateVarsDate.inThePastNumThings"
                                                [fromDate]="uiState.qfFilterState.stateVarsDate.fromDate"
                                                [toDate]="uiState.qfFilterState.stateVarsDate.toDate"       
                                                (change)="uiStateQfFilterStateChange(this, 'dates', $event)">                                        
                                            </app-orbiwidgetdateselector> 
                                        </ng-template>
                                    </li>                                       
                                </ul>                                    
                            </div>           
                            <div class="orbi-toggle-filter ml-auto">
                                <h4 class="mr-2">{{tabStateGlobalFilters.isCollapsed ? 'Show' : 'Collapse' }} panel </h4>
                                <button class="btn btn-light" type="button" (click)="uiState.setTabStateGlobalFiltersCollapse($event, !tabStateGlobalFilters.isCollapsed, 'togglebtn')"><app-orbiicon iconName="faCaretDown"></app-orbiicon></button>
                            </div>               
                        </div>
                        <div class="col-12">
                            <div [ngbCollapse]="tabStateGlobalFilters.isCollapsed" [ngbNavOutlet]="ngbNavGlobalFilter" class="orbi-filters-pane collapse navbar-collapse">
                                <p> Content area for accordion </p>
                            </div>
                        </div>
                    </div>
                </div> <!-- inner filters -->
            </div>
        </div> 
        <div class="row dashboard-shortcut mb-4" [hidden]="uiState.abTestTopSelector">
            <div class="col-12 mb-3">
                <div class="dash-tile">
                    <hr>
                    Quick filter 
                    <span>&nbsp;Repository set&nbsp;         
                        <span class="btn-group" role="group" ngbRadioGroup aria-label="Quick filter status">        
                            <ng-container *ngFor="let option of quickFilterRepoSelector | keyvalue">
                                <label ngbButtonLabel [ngClass]="{active: option.key == uiState.qfFilterState.stateVars.repositorySelector}" class="btn btn-primary" [for]="option.key" >                            
                                    <input ngbButton type="radio" [value]="option.key" class="btn-check" [id]="option.key" 
                                        [(ngModel)]="uiState.qfFilterState.stateVars.repositorySelector"
                                        (ngModelChange)="uiStateQfFilterStateChange(this, 'repositorySelector', $event)">
                                    <app-orbiicon [iconName]="quickFilterIcons[option.key]" title="Show all"></app-orbiicon>{{option.value}}</label>
                            </ng-container>                
                        </span>
                    </span>
                    <span>&nbsp;Status&nbsp;
                        <span  class="btn-group" role="group" ngbRadioGroup  aria-label="Quick filter status">
                            <ng-container *ngFor="let option of quickFilterStatusSelector | keyvalue">
                                <label ngbButtonLabel [ngClass]="{active: option.key == uiState.qfFilterState.stateVars.auditfocusSelector}" class="btn btn-primary" [for]="option.key" >                            
                                    <input ngbButton type="radio" [value]="option.key" class="btn-check" [id]="option.key" 
                                        [(ngModel)]="uiState.qfFilterState.stateVars.auditfocusSelector"
                                        (ngModelChange)="uiStateQfFilterStateChange(this, 'auditfocusSelector', $event)">
                                    <app-orbiicon  [iconName]="quickFilterIcons[option.key]" [title]="option.value"></app-orbiicon>{{option.value}}</label>
                            </ng-container>
                        </span>
                    </span>

                    <span>&nbsp;Date range:&nbsp; 
                        <span  class="btn-group" role="group" ngbRadioGroup aria-label="Quick filter status">
                            <ng-container *ngFor="let option of quickFilterDateSelector | keyvalue">
                                <label ngbButtonLabel [ngClass]="{active: option.key == uiState.qfFilterState.stateVarsDate.quickValue}" class="btn btn-primary" [for]="option.key" >                            
                                    <input ngbButton type="radio" [value]="option.key" class="btn-check" [id]="option.key" 
                                        [(ngModel)]="uiState.qfFilterState.stateVarsDate.quickValue"
                                        (ngModelChange)="uiStateQfFilterStateChange(this, 'qfFilterStateRadioDateSelector', $event)">
                                    <app-orbiicon  [iconName]="quickFilterIcons[option.key]" [title]="option.value"></app-orbiicon>{{option.value}}</label>
                            </ng-container>
                        </span>
                    </span>
                </div>
            </div>     

            <!-- tabStateGlobalFilters.repoNamesSelectedOut -->
            <div class="table-scroll-holder" *ngIf="uiState.qfFilterState.stateVars.orgName && 0 < this.tabStateGlobalFilters.repoNamesForReport.length">
                <table class="table orbi-table table-row-hover">
                    <tbody>
                        <tr><td class="quickFilterSummaryCol"><span class="impacts-col-explainmsg">{{tabStateGlobalFilters.repoNamesSelectedOut.length}} selected repoNamesSelectedOut:</span></td><td class="quickfilterreposel resultsColMaxWidth"><span>{{tabStateGlobalFilters.repoNamesSelectedOut | json}}</span></td></tr>
                        <tr><td class="quickFilterSummaryCol"><span class="impacts-col-explainmsg">{{tabStateGlobalFilters.repoNamesForReport.length}} selected repositories:</span></td><td class="quickfilterreposel resultsColMaxWidth"><span>{{tabStateGlobalFilters.repoNamesForReport | oanpluck:"repoName" | json}}</span></td></tr>
                        <tr><td class="quickFilterSummaryCol">During period:</td><td class="quickfilterreposel resultsColMaxWidth"><span><span>{{uiState.qfFilterState.stateVarsDate.dateLabel}}</span></span></td></tr>
                    </tbody>
                </table>
            </div>       
        </div>
       
        <!-- {{uiState.qfFilterState.stateVarsDate|json}} -->
        
        <div class="row">
             <div class="col-12 dashboard-main__results pt-4">

                <div class="row">
                    <div class="col">
                        <h2 class="orbi-section-title">Workbook '{{this.uiState.workbookLabelActiveTab}}'</h2>                     
                    </div>
                </div>      
<!--                <form>-->
                        <!-- Refresh & Search panel  -->
                    <div class="row">
                        <div class="col-12">
                            <ul class="orbi-results-header-list mb-3">
                              
                                <li>
                                    <!-- [disabled]="! tabStateGlobalFilters.filterNotApplied[this.tabState.activeTab]" -->
                                    <button type="button" class="btn btn-primary" aria-label="Load"                                         
                                        (click)="tabState.onSync($event)">
                                    Update View
                                        <app-orbiicon class="ml-2" iconName="faSync"></app-orbiicon></button>
                                </li>
                                <li>
                                    <button type="button" class="btn btn-primary" aria-label="Export" (click)="this.onExport($event, GithubColumnDefs.colDefs[this.tabState.activeTab], this.tabState.activeObjs)">
                                        Export to CSV
                                        <app-orbiicon class="ml-2" iconName="faFileExport"></app-orbiicon></button>
                                </li>     
                                <!--                           
                                <li><span>
                                    <button class="btn btn-primary" type="button"
                                        (click)="this.uiState.chooseColumns($event, this.tabState.activeTab)">
                                        <app-orbiicon class="mr-2" iconName="faColumns"></app-orbiicon>Choose table columns</button>
                                </span></li>
                            -->
                                <li class="ml-auto">
                                    <span class="breadcrumb-item align-items-center">            
                                        <span>Filter results:</span>
                                        <div class="input-group">                                        
                                            <input type="text" class="form-control" id="filterRepoName-text" aria-describedby="search-text"                                                 
                                                placeholder="Filter results"  
                                                [(ngModel)]="model.textFilterResults" [ngbTypeahead]="model.textFilterResultsTypeahead" 
                                                autofocus>
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <app-orbiicon iconName="faSearch"></app-orbiicon>
                                                    </span>
                                                </div>
                                            </div>    
                                    </span> 
                                </li>
                            </ul>
                        </div>
                    </div>     
                    
                    <hr>
                    
                    <!-- Filters recap panel  -->
                    <div class="row">
                        <div class="col">                        
                            <ul class="orbi-results-filters-recap mb-4">
                                <li>Last github sync at:  <span>{{this.tabState.activeObjsLastSync?.toDateFriendlyDate}}</span></li>
                                <li>Time: <span>{{this.tabState.activeObjsLastSync?.toDateFriendlyTime}}</span></li>
                                <li>Repos checked: <span>{{this.tabState.activeObjsDoneCount}}</span></li>
                                <li>From: <span>{{uiState.dteSelectionLabel}}</span></li>   
                                <li class="ml-2">Showing: <span>{{this.orbiresultstablenative.numShownObjects}} of {{uiState?.activeTabPageState.collectionSize}} results</span> 
                                </li>                             
                                <!--                                 <li>Sync Errors: <span>{{this.tabState.activeObjsFailedCount}}</span></li> -->
                            </ul>
                        </div>
                    </div>

                    <p *ngIf="0 == this.tabState.activeObjsBeforeFilter.length">
                        <ngb-alert [dismissible]="false" type="info">There are no results for this organization.</ngb-alert>
                    </p>

                    <p *ngIf="0 < this.tabState.activeObjsBeforeFilter.length && 0 == this.orbiresultstablenative.numShownObjects">
                        <ngb-alert [dismissible]="false" type="info">There may be results hidden by the pass/fail filter or the all/live filter.</ngb-alert>
                    </p>
                    
                    <app-orbiresultstablenative #orbiresultstablenative
                        [filterStateVars]="uiState.qfFilterState.stateVars"
                        [tableHeaders]="GithubColumnDefs.colDefs[this.tabState.activeTab]"
                        [tableData]="this.tabState.activeObjs"
                        [viewAuditResults]="this.tabState.activeAuditResults"
                        (orbiResultsTableUistate)="uiState.updateResultsTableMeta($event)"
                        ></app-orbiresultstablenative>
                    <ul>
                        <!-- 
                        <li>     
                            <button [disabled]="! this.uiState.activeTabPageState.paginationHasMoreData" class="btn btn-filter btn-sm selected" (click)="this.uiState.paginationPageChange()">Load more data</button>
                        </li> -->
                        <li class="ml-2">Showing: <span>{{this.uiState.resultsTableMeta.numShownObjects}} of {{this.uiState.resultsTableMeta.numShownObjects}} results</span></li>
                    </ul>

                    <!--
                    <ngb-pagination *ngIf="undefined != this.uiState.activeTabPageState.hasPagination"
                        [(page)]="this.uiState.activeTabPageState.currentPage"
                        [pageSize]="this.uiState.activeTabPageState.pageSize"
                        [boundaryLinks]="true"
                        [collectionSize]="this.uiState.activeTabPageState.collectionSize"
                        (pageChange)="this.uiState.paginationPageChange()"></ngb-pagination>
                    -->
                    <!-- table footer -->
                    <div class="row">
                    
                    </div>
                <!-- </form> -->
            </div> 
        </div>
  </div>


<!-- content modal -->
<ng-template #contentmodal let-c="close" >
    <div class="modal-header">
        <h4 class="modal-title">Repository CSV</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Results for: {{ model.getGithubRepoFilter() }}, {{_repos.length }} repos, {{_prs.length }} prs </p>
        <p><button ngxClipboard [cbContent]="uiState.strReposExport" type="button" class="btn btn-primary" aria-label="clipboard">
            <app-orbiicon iconName="faClipboard"></app-orbiicon></button> </p>
        <pre>{{ uiState.strReposExport }} </pre>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="c()">Close</button>
      </div>
</ng-template>

