
    <!-- Date selection panel  -->
    <div class="date-selection">
        
        <div ngbDropdown class="d-inline-block mr-4">
            <button class="btn btn-light btn-light--border" id="dropdown-globalfilters-date-type" ngbDropdownToggle>{{model.dteSelection.changeGlobalfiltersdatetypelabel}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdown-globalfilters-date-type">
                <button ngbDropdownItem (click)="model.dteSelection.onChangeGlobalfiltersdatetype($event, this, true, false)">Is in the past</button>
                <button ngbDropdownItem (click)="model.dteSelection.onChangeGlobalfiltersdatetype($event, this, false, true)">Is in the range</button>
            </div>
        </div>
        
        <div *ngIf="model.dteSelection.isTypeInThePast" 
            class="globalfilter-date-inthepast">
            <div class="form-group orbi-w-3char mr-2 mb-0">                                  
                <input type="text" 
                    class="form-control" 
                    [formControl]="frmCtrlValuesFilter"
                    maxlength="3" 
                    >
            </div>
            
            <div ngbDropdown class="d-inline-block mr-4">
                <button class="btn btn-light btn-light--border" id="dropdown-globalfilters-date-typeofthings" ngbDropdownToggle>{{model.dteSelection.selectedLabelForDateInPastTypeThing}}</button>
                <div ngbDropdownMenu aria-labelledby="dropdown-globalfilters-date-type">
                    <button ngbDropdownItem *ngFor="let intptt of model.dteSelection.inThePastTypeThings| keyvalue "
                        (click)="model.dteSelection.onChangeInThePastTypeThing($event, this, intptt.key, intptt.value)">{{($any(intptt.value).label)}}</button>
                </div>
            </div>                                                    
        </div>                                                                                                 
        
        <div *ngIf="model.dteSelection.isTypeInRange" class="globalfilter-date-intherange">
            <div class="input-group mr-4">
                <label for="globalfilter-date-intherange-fromdate" class="mr-2">From:</label>
                <input id="globalfilter-date-intherange-fromdate" class="form-control" 
                    ngbDatepicker
                    placeholder="YYYY-MM-DD"
                    (click)="fromDatePicker.toggle()"
                    (dateselect)="model.dteSelection.onDateSelection(this, $event)" 
                    (ngModelChange)="model.dteSelection.onDateSelection(this, $event)" 
                    name="fromDatePicker" 
                    [(ngModel)]="model.dteSelection.dteRange.fromDate"  #fromDatePicker="ngbDatepicker" />
                <div class="input-group-append" >
                    <button class="btn btn-primary calendar" (click)="fromDatePicker.toggle()" type="button">
                        <app-orbiicon iconName="faCalendar"></app-orbiicon>
                    </button>
                </div>
            </div>         
            <div class="input-group">
                <label for="globalfilter-date-intherange-todate" class="mr-2">To date:</label>
                <input id="globalfilter-date-intherange-todate" class="form-control" 
                    ngbDatepicker
                    placeholder="YYYY-MM-DD"
                    (click)="toDatePicker.toggle()" name="toDatePicker" [(ngModel)]="model.dteSelection.dteRange.toDate" 
                    (dateselect)="model.dteSelection.onDateSelection(this, $event)" 
                    (ngModelChange)="model.dteSelection.onDateSelection(this, $event)" 
                    #toDatePicker="ngbDatepicker" />
                <div class="input-group-append">
                    <button class="btn btn-primary calendar" (click)="toDatePicker.toggle()" type="button">
                        <app-orbiicon iconName="faCalendar"></app-orbiicon>
                    </button>              
                </div>
            </div>                                                                                             
        </div>
    </div>
    <!-- End Date selection panel  -->
