import { Component, isDevMode, NgZone, OnInit } from '@angular/core';
import { NgbActiveModal, NgbConfig, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { TemplateRef } from "@angular/core";
import {KeyValue,JsonPipe} from '@angular/common';

import { NgxSpinnerService } from "ngx-spinner";
import { OrbibackendPublicService } from './svc/orbibackend.public.service';
import { faCoffee, faCog, faGrinTongueSquint, faQuestion } from '@fortawesome/free-solid-svg-icons';

import { OrbigithubService } from './svc/orbigithub.service';

import { OangeventsService } from 'src/oang/svc/oangevents.service';
import { OangnotificationsErrorId, OangnotificationsService } from 'src/oang/svc/oangnotifications.service';
import { HttpErrorInterceptor, OrbiAppComponentRoutes } from './app.module';
import { HttpErrorResponse } from '@angular/common/http';
import { OrbiRequest, OrbiRequestBase } from './cmn/svc/orbirequest';
import { environment } from 'src/environments/environment';
import { OrbidebugService } from './svc/orbidebug.service';
import { OanLodashWrap } from './cmn/utils/oanlodashwrap';
import { OrbiprofileService } from './svc/orbiprofile.service';
import { OanHttpUtils } from './cmn/utils/oanhttputils';
import { OanDebugutils } from './cmn/utils/OanDebugutils';
// import { ComponentFirstloadDependencies, ComponentFirstloadDependenciesCallbackInterface, ComponentFirstloadDependenciesProperties } from './cmn/ComponentFirstloadDependencies';
import { OrbiBrowserStorageService } from './svc/orbibrowserstorage.service';
import { OrbistartuprequestsL0Interface, OrbistartuprequestsL1Interface, OrbistartuprequestsL2Interface, Orbistartuprequestsservice, OrbistartuprequestsTenantNotReady } from './svc/orbistartuprequests.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GitrospectSignupFlowComponent } from './github/gitrospectsignupflow/gitrospectsignupflow.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public static thisAppComponent : AppComponent | undefined;
    title = 'orbidash3';
    readonly ctxt : string =   "AppComponent";
    public faCoffee = faCoffee;
    public faCog = faCog;
    public faQuestion = faQuestion;
    private environment = environment;
    viewPanelTenantIdIsExpanded : boolean = false;

    public _features : any = {
        dev : true
    };

    public _appCmpNotificationState : any = {
        showNotAGithubOrgWarning : undefined,
    }
    public cmpNotificationsEvents : any = {
        dismiss : (event:any) => { 
            this.cmpNotificationsEvents.showNotAGithubOrgWarning = false;
        }
    }

    public _uiState : any = 
    {
        cmpThis : null,
        showSignupButton : true,
        logoUrl : "../assets/img/icon-logo.svg",
        logoUrlWhite : "../assets/img/icon-logo-white.svg",
        uiFlags : {
            amILoggedIn : false,
            amILoggedInName : "",
            showSideOverlay : false,
        },
        userData : {},
        userInfo : {},
        userInfoAccessibleOrgs : [],
        userInfoOrgsWithoutAccess : [],
        appInstallUrl : "" as string,
        isLocalMode: environment.isLocal,
        acessibleOrgs : [],
        orgDropDownPossibleValues : [],
        orgName : "",
        orgNameUpdate : async (event:any,orgObject:any) => {
            if (orgObject.targetObjAccessible) {
                await this.svcProfile.setActiveOrgName(orgObject.targetObj);
                window.location.reload();
            } else {
                this.onSignupStep0();
            }
        },
        orgNameInstall: async (event:any,orgName:string) => {
            window.location.href = this._uiState.appInstallUrl;
        },
        checkObj(obj:any) {
            let so = JSON.stringify(obj);
            OanDebugutils.debuggerWrapper(".?.");
            return so;
        },
        isActiveTopLink(el:HTMLElement) {
            debugger;
        }
    };

    
    public model : any = {};
    public activeComponent : keyof typeof OrbiAppComponentRoutes | undefined;
    public activeRouteUrl : string | undefined;
    constructor(private router: Router,
        private spinner: NgxSpinnerService,
        private modalService : NgbModal,
        private readonly svcDebug : OrbidebugService,
        private readonly svcApiPub : OrbibackendPublicService,
        private readonly svcGithub : OrbigithubService,
        private readonly svcProfile : OrbiprofileService,
        private svcOangEvents : OangeventsService,
        public svcNotifier : OangnotificationsService,
        public svcStorage : OrbiBrowserStorageService,
        private svcStartuprequestsservice : Orbistartuprequestsservice )
    {
        AppComponent.thisAppComponent = this;
        this.subscibeBusyObservers();
        OanLodashWrap.init(console, svcDebug);

        if (OanHttpUtils.isPublicWebsite())
        {
            this._uiState.showSignupButton = false;
            this._uiState.logoUrl = "../assets/img/icon-logo-orbi.svg";
            this._uiState.logoUrlWhite = "../assets/img/icon-logo-orbi.svg";
        }

        if (window.location.host) {
            let host = window.location.host;
            let hostCmps = host.split('.');
            if (1 < hostCmps.length) {
                if (3 > hostCmps.length) {
                    // probably naked domain so we add in www
                    let newHost = "www." + host;
                    window.location.href = window.location.href.replace(host, newHost);
                } else {
                    // probably something.gitrospect.com or something.soc2toosl.com
                }
            } else {
                // probably localhost
            }
        }

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))  
            .subscribe((event: Event) => {                         
                this.activeComponent = undefined;       
                this.activeRouteUrl = (event as NavigationEnd).url;
                let activeComponentUrlCmps = ("string" == typeof this.activeRouteUrl) ? this.activeRouteUrl.split('/') : [];       
                let activeComponentUrl = (1 <= activeComponentUrlCmps.length) ? activeComponentUrlCmps[1] : undefined;
                let activeComponentUrlWoParams = activeComponentUrl?.split('?')[0];
                if (activeComponentUrlWoParams) {
                    for (let key in OrbiAppComponentRoutes) {
                        let myKey : keyof typeof OrbiAppComponentRoutes = <keyof typeof OrbiAppComponentRoutes>(key);
                        let value = OrbiAppComponentRoutes[myKey];
                        if (value && value == activeComponentUrlWoParams) {
                            this.activeComponent = myKey;
                            break;
                        }
                    }
                }

                /* this is the case 
                if (! this.activeRouteUrl || ! this.activeComponent) {
                    debugger;
                } */
            });
    }

    private requestList : any[] = [];
    subscibeBusyObservers() 
    {
        this.svcGithub.getLoadingObs().subscribe( (val : any) => {
            if (val) {
                if (true == val.isLoading) {
                    this.requestList.push(val);
                    this.spinnerShow(val);
                } else {
                    this.spinnerDismiss(val);
                    var res = this.requestList.filter(obj => {
                        var match = (obj.reqId == val.reqId);
                        if (match) {
                            obj.closed = true;
                        }
                        return match;
                    });                    
                }
            }
        });

        OrbiRequestBase.getHttpErrorObs().subscribe( (httpError:HttpErrorResponse) => {
            // there's a problem here in that these http errors get retried resuting in multiple calls to spinner dismiss in case of error for same req
            // but this part is hard to untangle and there's no user impact
            if (null != httpError)
            {
                this.spinnerDismiss({ status: undefined,  isLoading: false, ctxt: 'getHttpErrorObs' });
                let res = this.requestList.filter(obj => {
                    let match = (obj.url == httpError.url);
                    if (match) {
                        obj.httpError = httpError;
                        obj.closed = true;
                    }
                    return match;
                });
            }
        });
    }

    ngOnInit() 
    {
        console.log(`AppComponent.ngOnInit - ${environment.fileid}`);    
        this.svcStartuprequestsservice.startupRequestsL0State$.subscribe({
            next: (state:OrbistartuprequestsL0Interface) => {
                const msgNotLoggedIn : string = 'Not Signed in';
                if (state.isInit) 
                {
                    this.svcGithub.apiInit();
                    this._uiState.uiFlags.amILoggedIn = state.amILoggedIn;
                    if (this._uiState.uiFlags.amILoggedIn)
                    {
                        this.svcNotifier.removePagealert({ errorId: OangnotificationsErrorId.Error_AppNotLoggedIn });
                    } else {
                        this._uiState.uiFlags.amILoggedIn = false;
                        this._uiState.uiFlags.amILoggedInName = "";
                        // alert("NOT LOGGEDIN !\namIloggedin (error)= " + JSON.stringify(o));
                        this.svcNotifier.addPageAlertError(OangnotificationsErrorId.Error_AppNotLoggedIn, { type: 'danger', msg: msgNotLoggedIn  } );
                    }

                    this._uiState.appInstallUrl = state.svcurls.appInstallUrl;
                }
            }, error: (err) => {
                OanDebugutils.debuggerWrapperOnCondition( true, err);
            }
        });

        this.svcStartuprequestsservice.startupRequestsL1State$.subscribe({
            next: (state:OrbistartuprequestsL1Interface) => {

                if (state.isInit) 
                {                    
                    this._uiState.userInfo = state.userInfo;
                    this._uiState.uiFlags.amILoggedInName = (state.userInfo) ? state.userInfo?.email : 'Not Signed in';    
                    // 
                    this._uiState.orgDropDownPossibleValues = [];
                    this._uiState.orgName = this.svcProfile.getActiveOrgName();
                    if (this._uiState.orgName) {
                        let isUserResource = state.userInfo?.preferred_username && state.userInfo?.preferred_username == this._uiState.orgName;
                        let isAccessible = Array.isArray(state.accessibleresources) ? state.accessibleresources.some((acr:any) => acr.targetObject == this._uiState.orgName) : false;
                        this._uiState.orgDropDownPossibleValues.push({ targetObj: this._uiState.orgName, targetObjAccessible:isAccessible, isUserResource:isUserResource} );
                    }

                    let acessibleOrgs : string[] = [];
                    if (Array.isArray(state.accessibleresources) 
                        && 0 < state.accessibleresources.length)
                    {
                        acessibleOrgs.push(...state.accessibleresources);
                    }

                    this._uiState.userInfoOrgsWithoutAccess = state.userInfo?.userOrgs;
                    if (Array.isArray(state.accessibleresources) && 0 < state.accessibleresources.length)
                    {   
                        this._uiState.userInfoAccessibleOrgs = state.accessibleresources.filter((acr:any) => acr.targetObject != state?.userInfo?.preferred_username);
                        let strUaiOrgs = this._uiState.userInfoAccessibleOrgs.map((uiao:any) => uiao.targetObject);
                        this._uiState.userInfoOrgsWithoutAccess = this._uiState.userInfoOrgsWithoutAccess.filter((org:string) => ! strUaiOrgs.includes(org));
                        acessibleOrgs = acessibleOrgs.concat(state.accessibleresources);
                    }
                    else 
                    {
                        this._uiState.userInfoAccessibleOrgs = [];
                    }

                    this._uiState.acessibleOrgs = acessibleOrgs;
                    this._uiState.userInfoAccessibleOrgs.forEach((element:any) => {
                        let targetObj = element.targetObject;
                        if (! this._uiState.orgDropDownPossibleValues.some((currODDPV:any) => currODDPV.targetObj == targetObj)) {       
                            let isUserResource = state.userInfo?.preferred_username && state.userInfo?.preferred_username == targetObj;
                            this._uiState.orgDropDownPossibleValues.push({ targetObj: targetObj, targetObjAccessible:true, isUserResource:isUserResource });
                        }
                    });

                    this._uiState.userInfoOrgsWithoutAccess.forEach((org:string) => {
                        let isUserResource = state.userInfo?.preferred_username && state.userInfo?.preferred_username == org;
                        this._uiState.orgDropDownPossibleValues.push({ targetObj: org, targetObjAccessible:false, isUserResource:isUserResource });
                    });

                    // do some checks on active org
                    this.cmpNotificationsEvents.showNotAGithubOrgWarning = false;
                }
            }, error: (err) => {
                OanDebugutils.debuggerWrapperOnCondition( true, err);
            }
        });  

        this.svcStartuprequestsservice.startupRequestsL2State$.subscribe({
            next: (state:OrbistartuprequestsL2Interface) => {

                if (state.isInit) 
                {
                    if (! state.isTargetGithubOrg && undefined == this.cmpNotificationsEvents.showNotAGithubOrgWarning) {
                        this.cmpNotificationsEvents.showNotAGithubOrgWarning = true;                    
                    }
                }
            }, error: (err) => {
                OanDebugutils.debuggerWrapperOnCondition( true, err);
            }
        });        

        this.svcStartuprequestsservice.startupRequestsTenantNotReady$.subscribe({
            next: (state:OrbistartuprequestsTenantNotReady) => {
                if (state.isInit) 
                {
                    this.router.navigate(['mwgithubdashboard']);
                }
            }, error: (err) => {
            }
        });

        this.svcStartuprequestsservice.doStartupRequestsL0();
    }
    
    private _spinnerModalCount : number = 0;
    readonly _spinnerModalOptions :  any = 
    {
        backdrop : 'static',
        windowClass: 'modal-spinner',
        keyboard : false
    };
    
    spinnerShow(val : any) : void 
    {
        if (0 == this._spinnerModalCount /* undefined === this._spinnerModalRef */) 
        { 
            this._spinnerModalCount = 1;
            setTimeout(() => {
                this.model.spinnerMsg = val.msg;
                this.spinner.show();
            });             
        } else {
            ++this._spinnerModalCount;
        } 
    }

    spinnerDismiss(val : any) : void 
    {
        --this._spinnerModalCount;
        OanDebugutils.debuggerWrapperOnCondition(0 > this._spinnerModalCount, "spinnerDismiss negative counter");
        if (0 >= this._spinnerModalCount) {
            setTimeout(() => {
                this.model.spinnerMsg = "";
                this.spinner.hide();
            });

            this._spinnerModalCount = 0;
        }
    }

    public filterModalSignupRef : any = undefined;
    async onSignupStep0(event?: MouseEvent) 
    {
        this.filterModalSignupRef = this.modalService.open(GitrospectSignupFlowComponent);
        this.filterModalSignupRef.componentInstance.orgName = undefined;
        this.filterModalSignupRef.componentInstance.appInstallUrl = this._uiState.appInstallUrl;
    }

    public static inOnSigninGeneral = false;
    async onSigninGeneral(event?: MouseEvent) 
    {
        if (false == AppComponent.inOnSigninGeneral) 
        {
            AppComponent.inOnSigninGeneral = true;
            let svcUrls : any = await this.svcApiPub.getTenantInfo();
            let loginurl = svcUrls.login;
            let queryParams = "?";
            if (window.location.hostname=="localhost") 
            {
                queryParams += "env=" + encodeURIComponent(window.location.host);
                queryParams += "&page=mwgithub&context=null";
            } else {
                queryParams += "&page=mwgithub&context=null";
            }

            let targeturl = loginurl + queryParams;
            console.log("onSigninGeneral.getTenantInfo.subscribe: targeturl" + targeturl);

            // show spinner while browser reloads
            this.spinnerShow({ status: undefined,  isLoading: true, msg : `Signing in...`, url:targeturl, ctxt: 'onSigninGeneral'});
            window.location.href=targeturl;
        }
        else
        {
            console.log(" onSigninGeneral - skipping signin event ");
        }
    }

    onLogout(event?: MouseEvent) {
        this.svcApiPub.doLogout(window); 
    }

    testModel = {
        message : 'blah balh', 
        pageAlertTypes : [ 'success', 'info', 'warning', 'danger', 'primary', 'secondary', 'light', 'dark' ],
        toastClassenames : [ 'bg-danger text-light', 'bg-success text-light', '' ],
        toastClassenamesSelected : 'bg-danger text-light',
        pageAlertTypeSelected : 'success',

    };

    onTestToastAlert() {
        console.log("onTestPageToastAlert()");
        this.svcNotifier.addToastAlertError(OangnotificationsErrorId.Error_Test, { 
            classname : this.testModel.toastClassenamesSelected, 
            msg: this.testModel.message });
    }

    onTestPageAlert() { 
        console.log("onTestError()");
        this.svcNotifier.addPageAlertError(OangnotificationsErrorId.Error_Test, { 
            type : this.testModel.pageAlertTypeSelected,
            msg: this.testModel.message 
        });
    }

}
