import { Component, EventEmitter, forwardRef, Inject, Input, LOCALE_ID, OnInit, Output, Provider, SimpleChanges, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { OrbiConstants, OrbiSettingDTO, OrbiSettingGroupName, OrbiSettingGroupName_allvalues, OrbiSettingNvpName } from '@danclarke2000/gitrospectdto';
import { OrbisettingsService } from 'src/app/svc/orbisettings.service';
import {KeyValue} from '@angular/common';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrbiwidgetValue } from './orbiwidgetcmn';
import { OanDebugutils } from '../utils/OanDebugutils';

class Impl
{
 
           
}

const frmCtrlIdRadio = 'orbiwidgetradio_frmCtrlIdRadio';

interface OrbiwidgetradioUiState 
{
    cmpThis : OrbiwidgetradioComponent;
    possiblevalues : OrbiwidgetValue[];
    value : OrbiwidgetValue | undefined;
}
  
@Component({
  selector: 'app-orbiwidgetradio',
  templateUrl: './orbiwidgetradio.component.html',
  styleUrls: ['./orbiwidgetradio.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: OrbiwidgetradioComponent,
    multi: true,
  }]
})
export class OrbiwidgetradioComponent implements OnInit, ControlValueAccessor {
    public static readonly className = "orbiwidgetradio";
    // public frmCtrlIdLiveProdDefinitionTypeForValuesFilters : string | undefined = frmCtrlIdRadio;
    
    /*
    @Input() label? : string = '(unknown)';
    @Input() description? : string = ''; */
    @Input() frmCtrlIdLink : string = '';  
    @Input() possiblevalues : string[] = [];    
    @Input() possiblelabels : string[] = [];
    @Input() keyOfSelectedValue : string = '';
    
    public debugJsonStringify(s : any[]) {
        return ''; // JSON.stringify(s);
    }
    
    public frmCtrlRadio : UntypedFormControl;  

    public model : any = {};

    public uiState : OrbiwidgetradioUiState = {    
        cmpThis : this,        
        possiblevalues : [],
        value : undefined,
    }

    onTestClick($event:any, currValue:any) {
        debugger;
    }

    constructor(@Inject(LOCALE_ID) public locale: string,
        private calendar: NgbCalendar,
        private fb : UntypedFormBuilder,
        public svcSettings : OrbisettingsService) 
    {
        this.frmCtrlRadio = this.fb.control('');
        this.frmCtrlRadio.valueChanges.subscribe((selectedValue:string)  => {
            this.model.selectedvalue = selectedValue;
            this.onChange(this.model);
        }); 
    }

    public isActive(currValue:any) 
    {
        let currValueKey = currValue.key; 
        let r : boolean = false;
        if (this.model.selectedvalue) {
            r = currValueKey == this.model.selectedvalue; 
        }
        
        return r;
    }

    public onInputChanges() 
    {
        //@ts-ignore
        this.uiState.possiblevalues = [];
        if (Array.isArray(this.possiblevalues) && Array.isArray(this.possiblelabels) 
            && this.possiblelabels.length == this.possiblevalues.length
            && 0 < this.possiblevalues.length) {
            for (let i=0; i < this.possiblevalues.length; ++i)
            {
                if (! this.possiblelabels[i] || ! this.possiblevalues[i])
                {
                    // something wrong
                    OanDebugutils.debuggerWrapper(".?.");
                }

                let no : OrbiwidgetValue = { label: this.possiblelabels[i], key: this.possiblevalues[i], visible:true, selected:false, selectedvalues:[] } ;
                this.uiState.possiblevalues.push(no);
/*
                if (no.key == this.keyOfSelectedValue)
                {
                    this.uiState.value = no;
                }*/
            }            
        } else {
            // check # labels = #values.length
            //  
            OanDebugutils.debuggerWrapper(".?.");            
        }        
    } 

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onInputChanges();
    }    

    ngAfterViewInit(): void {                
    }


    /*
    * reactive form control interface */
    onChange = (values:string[]) => {};
    onTouched = () => {};
    touched = false;
    disabled = false;

    writeValue(values: any) {
        if (values.type != OrbiwidgetradioComponent.className) {
            OanDebugutils.debuggerWrapper(".?.");
        }

        this.model = values;
        let newVal = this.model.selectedvalue;
        this.frmCtrlRadio.setValue(newVal);
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        } 
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
}
