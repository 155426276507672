
/*
Syntax
<a target="_blank|_self|_parent|_top|framename">
Attribute Values
Value	Description
_blank	Opens the linked document in a new window or tab
_self	Opens the linked document in the same frame as it was clicked (this is default)
_parent	Opens the linked document in the parent frame
_top	Opens the linked document in the full body of the window
framename	Opens the linked document in the named iframe */

import { UiObj } from "../cmn/ui/UiObj";
import { OanLodashWrap } from "../cmn/utils/oanlodashwrap";
import { OrbidebugService } from "../svc/orbidebug.service";
import { OrbiAuditResultRelevance, OrbiAuditResultTest } from '@danclarke2000/gitrospectdto';
import { GithubComponent } from "./github.component";
import { OrbiresultsfilterOp } from "./orbiresultsfilter/orbiresultsfilter.component";
import { OrbiReportType } from "../cmn/ui/UiLabels";


const classForIdentity = "wbidentity";
const classForAuditResult = "wbauditresult";
const classForAuditRelevant = "wbauditrelevant";
const classForAuditTest = "wbaudittest" ;
const classForAuditAttribute = "wbauditattribe";
const classForAuditSynthetic = "wbauditsynthetic" ;

enum ColDefGroup {
    Identity = 'identity',
    Blank = 'blank',
    AuditResult = 'auditresult',
    AuditRelevant = 'auditrelevant',
    AuditTest = 'audittest',
    Attribute = 'attribute',
    Date = 'coldate',
    Sythentic = 'Synthetic',
    Payload = 'Payload'
};

export enum ColDefDataType {
    Date = 'orbidate',
    Numeric = 'orbinumeric',
    Textshort = 'orbitext-short',
    Textlong = 'orbitext-long',
    Json = 'orbijson',
};

export class GithubColumnDefs {

    public static isFilterCol(colDef : any) {
        var r : boolean = false;
        if (colDef.group 
            && (colDef.group.class == classForAuditResult
                || colDef.group.class == classForAuditRelevant
                || colDef.group.class == classForAuditTest)) {
            r = true;
        }

        return r;
    };

    public static getClassForGroup(colDef : any, obj? : any) : string {
        var className : string = "";
        if (colDef.group) {
            className = colDef.group.class;
            if (colDef.group.dataType) {
                className = colDef.group.dataType;
            }
        }

        return className;
    };

    public static getColDef(groupName : ColDefGroup, dataType? : ColDefDataType) : any {
        var r;
        switch (groupName) {
            case ColDefGroup.Blank: r = { title: "", class : "", dataType: ColDefDataType.Textshort };
            break;

            case ColDefGroup.Identity: r = { title: "", class : classForIdentity, dataType: ColDefDataType.Textlong  };
            break;

            case ColDefGroup.AuditResult: r=  { title: "audit result", class : classForAuditResult, dataType: ColDefDataType.Numeric  };
            break;

            case ColDefGroup.AuditRelevant: r=  { title: "audit relevant", class : classForAuditRelevant, dataType: ColDefDataType.Numeric  };
            break;

            case ColDefGroup.AuditTest: r = { title: "audit test", class : classForAuditTest, dataType: ColDefDataType.Numeric  };
            break;

            case ColDefGroup.Attribute: r = { title: "attribute", class : "wbattribute", dataType: ColDefDataType.Numeric  }; 
            break;

            case ColDefGroup.Date: r = { title: "date", class : "wbdate", dataType: ColDefDataType.Date  }; 
            break;

            case ColDefGroup.Sythentic: r = { title: "attribute", class : "wbsynthetic", dataType: ColDefDataType.Textshort  }; 
            break;

            case ColDefGroup.Payload: r = { title: "payload", class : "wbpayload", dataType: ColDefDataType.Json  }; 
            break;
        }

        if (dataType) {
            r.dataType = dataType;
        }

        return r;
    };


        // these keys correspond to activeTab values which are the navItem strings e.g. navItemCodeAnalysisVulns
    public static colDefs : any = undefined;
    public static initColDefsStatic() : any {
        var myColDefs : any = {}; 
        myColDefs[OrbiReportType.GithubRepo] = [ 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Identity),  
                        title: "#", exportcsv: true, val : { obj: "get", path: "obj.repo.name"}, 
                        link: { type: "objrelative", target: "_blank", href: "obj.repo.url" },
                        sortable: true, copiable: true, filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch
                    }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Date), title: "Created at", exportcsv: true, val : { obj: "get", path: "obj.repo.createdAt" }, sortable: true }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Date), title: "Updated at", exportcsv: true, val : { obj: "get", path: "obj.repo.updatedAt" }, sortable: true }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Date), title: "Pushed at", exportcsv: true, val : { obj: "get", path: "obj.repo.pushedAt" }, sortable: true }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Effective branch protection", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_Bp_Effective, OrbiAuditResultTest.Repo_Bp_None] }  }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Branch naming convention", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_BranchNameConvention] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Deploykeys read only", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_Dk_AllDeployKeysReadOnly] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Deploy keys recent", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_Dk_AllKeysRecent] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Default branch protected", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_Bp_Defaultbranchnobp] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Branch protection on non prod branches", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_Bp_NoBpOnNonPrdBranches] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Ambiguous branch protection", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_Bp_AmbiguousBranchProtection] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Missing branch protection", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_Bp_None] } },                     
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Branch protection wildcards", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_Bp_PatternWithWildcard] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Has secret scanning", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_HasSecretScanning] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Has static analysis", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_HasStaticAnalysis] } },                     
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Has code owners", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_HasCodeOwners] } },                                         
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Repo topics", exportcsv: true, 
                    val : { obj: "ui", path: "datarender.renderNodes", args: ["obj.repo.repositoryTopics", "topic.name"] }, 
                    filter:"string", filterop:OrbiresultsfilterOp.SubStrMatch },                     
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Default branch", exportcsv: true, 
                    val : { obj: "get", path: "obj.repo.defaultBranchRef" }, 
                    filter:"string", filterop:OrbiresultsfilterOp.SubStrMatch }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "# Deploy keys", exportcsv: true, 
                    val : { obj: "get", path: "obj.repo.deployKeys.length" } }, 
                
                    /*
                
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP allows deletions", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['allowsDeletions'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP allows force pushes", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['allowsForcePushes'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP required approving review count", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['requiredApprovingReviewCount'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP requires code owner reviews", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['requiresCodeOwnerReviews'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP requires conversation resolution", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['requiresConversationResolution'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP requires linear history", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['requiresLinearHistory'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP requires signatures", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['requiresSignatures'] } },
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Num deploy key", exportcsv: true, val : { obj: "get", path: "obj.repo.deployKeys.totalCount" } }
                */
            ];

            myColDefs[GithubComponent.NavItemReposPublic] = [ 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Identity),  
                        title: "#", exportcsv: true, val : { obj: "get", path: "obj.repo.name"}, 
                        link: { type: "objrelative", target: "_blank", href: "obj.repo.url" },
                        sortable: true, copiable: true, filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch
                    }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Has branch protection", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_Bp_None] } },
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Effecive branch protection", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Repo_Bp_Effective] } },                                         
                {group: GithubColumnDefs.getColDef(ColDefGroup.Date), title: "Created at", exportcsv: true, val : { obj: "get", path: "obj.repo.createdAt" }, sortable: true }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Date), title: "Updated at", exportcsv: true, val : { obj: "get", path: "obj.repo.updatedAt" }, sortable: true }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Date), title: "Pushed at", exportcsv: true, val : { obj: "get", path: "obj.repo.pushedAt" }, sortable: true }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Num deploy key", exportcsv: true, val : { obj: "get", path: "obj.repo.deployKeys.totalCount" } }
            ];

            /*
            myColDefs[GithubComponent.NavItemBranchProtection] = [ 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Identity),  
                        title: "#", exportcsv: true, val : { obj: "get", path: "obj.repo.name"}, 
                        link: { type: "objrelative", target: "_blank", href: "obj.repo.url" },
                        sortable: true, copiable: true, filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch
                    }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Date), title: "Created at", exportcsv: true, val : { obj: "get", path: "obj.repo.createdAt" }, sortable: true }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Date), title: "Updated at", exportcsv: true, val : { obj: "get", path: "obj.repo.updatedAt" }, sortable: true },                 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditResult), 
                        title: "compliance ok", exportcsv: true, 
                        filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                        val : { obj: "ui", path: "datarender.getAuditResult", args: [] }, 
                    }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Effectice prod branch protection", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "ui", path: "datarender.getAuditTest", args: ['OrbiAuditResultTest.Repo_Bp_IneffectiveBranchProtection'] }  }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Branch naming convention", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "ui", path: "datarender.getAuditTest", args: ['OrbiAuditResultTest.Repo_BranchNameConvention'] } }, 
                    /*
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Branch protection pattern", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['pattern'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP allows deletions", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['allowsDeletions'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP allows force pushes", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['allowsForcePushes'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP required approving review count", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['requiredApprovingReviewCount'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP required status check contexts", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['requiredStatusCheckContexts'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP requires code owner reviews", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['requiresCodeOwnerReviews'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP requires conversation resolution", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['requiresConversationResolution'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP requires linear history", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['requiresLinearHistory'] } }, 
                {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "BP requires signatures", exportcsv: true, val : { obj: "ui", path: "datarender.getRepoBranchProtectAttr", args: ['requiresSignatures'] } },                
                
            ];*/

        myColDefs[OrbiReportType.GithubPullRequest] = [ 
            /*{group: GithubColumnDefs.getColDef(ColDefGroup.Identity, ColDefDataType.Numeric), title: "#", exportcsv: true, 
                val : { obj: "get", path: "obj.identifier"}, 
                link: { type: "objrelative", target: "_blank", href: "obj.pr.url" }, filter: "string",
                sortable: true }, */
            {group: GithubColumnDefs.getColDef(ColDefGroup.Identity, ColDefDataType.Textlong), title: "repoName", exportcsv: true, 
                val : { obj: "get", path: "obj.repoContainer.repoName"},                 
                sortable: true, filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch },                     
            {group: GithubColumnDefs.getColDef(ColDefGroup.Identity, ColDefDataType.Numeric), title: "prNumber", exportcsv: true, 
                val : { obj: "get", path: "obj.pr.number"},      
                link: { type: "objrelative", target: "_blank", href: "obj.pr.url" },           
                sortable: true, filter: "string", filterop:OrbiresultsfilterOp.SubStr },                                     
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Merged at", exportcsv: true, 
                val : {  obj: "get", path: "obj.pr.mergedAt", args: [] }, sortable: true }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Commit #", exportcsv: true, 
                val : { obj: "ui", path: "datarender.getPrMergeCommit", args: []  }, copiable: true }, 
                
                /*
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "releasetag", exportcsv: true, 
                val : { obj: "get", path: "obj.synthetic.releaseTagName"}  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "release tag hash", exportcsv: true, 
                val : { obj: "get", path: "obj.synthetic.releaseTagHash"}  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "release url", exportcsv: true, 
                val : { obj: "get", path: "obj.synthetic.releaseTagUrl"}  }, 
            */
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "title", exportcsv: true, 
                val : { obj: "get", path: "obj.pr.title"}  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Submitter", exportcsv: true, 
                val : { obj: "get", path: "obj.pr.mergedBy.login"}, sortable:true,
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Created at", exportcsv: true, 
                val : { obj: "get", path: "obj.createdAt"}  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Base ref name", exportcsv: true, 
                val : { obj: "get", path: "obj.pr.baseRef", args: [] }, sortable:true,
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Head ref name", exportcsv: true, val : { obj: "get", path: "obj.pr.headRef", args: [] }, sortable:true,
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Merged at", exportcsv: true, val : {  obj: "get", path: "obj.pr.mergedAt", args: [] }  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Merged by", exportcsv: true, val : { obj: "get", path: "obj.pr.mergedBy.login", args: [] }  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "State", exportcsv: true, val : { obj: "get", path: "obj.pr.state"}, sortable:true,
                filter: "string", filterop:OrbiresultsfilterOp.Match },   
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Review decision", exportcsv: true, val : { obj: "get", path: "obj.pr.reviewDecision"}, sortable:true,
                filter: "string", filterop:OrbiresultsfilterOp.Match },    
                
                /* TBD
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Approved by", exportcsv: true, val : { obj: "ui", path: "datarender.getPrAttrValueListAsString", args: [ 'reviewsApprove', 'author', 'login' ] }  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Approved at", exportcsv: true, val : { obj: "ui", path: "datarender.getPrAttrValueListAsString", args: [ 'reviewsApprove', 'publishedAt', null ] } }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Reviewed by", exportcsv: true, val : { obj: "ui", path: "datarender.getPrAttrValueListAsString", args: [ 'reviews', 'author', 'login' ] }  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Reviewed at", exportcsv: true, val : { obj: "ui", path: "datarender.getPrAttrValueListAsString", args: [ 'reviews', 'publishedAt', null ] } }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Files", exportcsv: true, val : { obj: "ui", path: "datarender.getPrFileListAsString", args: [] }  },
            // {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Comments", exportcsv: false, val : { obj: "ui", path: "getPrCommentListAsString", args: [] }  }
            */
        ];

        myColDefs[OrbiReportType.GithubCommit] = [ 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Identity), title: "oid", exportcsv: false, val : { obj: "get", path: "obj.commit.commitOid"}, 
                    link: { type: "objrelative", target: "_blank", href: "obj.commit.url" },                    
                    sortable: true,
                    copiable: true }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Identity),  
                        title: "repo", exportcsv: true, val : { obj: "get", path: "obj.repoContainer.repoName"}, 
                        link: { type: "objrelative", target: "_blank", href: "obj.url" },
                        sortable: true,
                        filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch   
                    }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "pushed date", exportcsv: true, val : {  obj: "get", path: "obj.commit.pushedDate", 
                args: [] }, sortable: true, sortabledefault:true }, 

            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "# branches", exportcsv: true, val : { obj: "get", path: "obj.commit.branchNames.length"}, 
                filter:"number", filterop:OrbiresultsfilterOp.Match },                                         
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "branches", exportcsv: true, val : { obj: "arraystring", path: "obj.commit.branchNames"}, 
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch },                     

            /*
            TBD this is not useful for koa but maybe useful in another company
            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "has Feature Branch", exportcsv: true, val : { obj: "ui", path: "getAuditTest", args: ['hasFeatureBranch'] }  }, 
            */
            
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "author", exportcsv: true, val : { obj: "get", path: "obj.commit.author"}, 
             filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "# prs", exportcsv: true, val : { obj: "get", path: "obj.commit.associatedPullRequests.length"}, 
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "prs", exportcsv: true, 
                                                                        val : { 
                                                                            obj: "arraylinksprnumbers", path: "obj.commit.associatedPullRequests", 
                                                                            transform : { text: "number", url: "url"} 
                                                                        }  }, 

            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "message", exportcsv: false, val : { obj: "get", path: "obj.commit.message"}  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "url", exportcsv: true, val : { obj: "get", path: "obj.commit.url"},
                link: { type: "objrelative", target: "_blank", href: "obj.commit.url" },                                    
                copiable: true  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "additions", exportcsv: true, val : { obj: "get", path: "obj.commit.additions"} }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "deletions", exportcsv: true, val : { obj: "get", path: "obj.commit.deletions"} }
        ];

        myColDefs[OrbiReportType.GithubCodeAnalysisVulns] = [ 
            /*
            TBD
            ### add severity < bug bar & has jira ticket
            ### mkae reponame wide enough to readonly
            ### flag rpos without dependabot
            */
            {group: GithubColumnDefs.getColDef(ColDefGroup.Identity), title: "GHSA", exportcsv: false, val : { obj: "get", path: "obj.securityAdvisory.ghsaId"}, 
                    link: { type: "reporelative", target: "_blank", href: "${orgName}/${repoName}/security/dependabot" },                     
                    sortable: true,
                    copiable: true }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Identity),  
                        title: "repo", exportcsv: true, val : { obj: "get", path: "obj.patched.repoName"}, 
                        link: { type: "reporelative", target: "_blank", href: "${orgName}/${repoName}" },
                        sortable: true,
                        filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch   
                    }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "severity", exportcsv: true, val : { obj: "get", path: "obj.securityVulnerability.severity"}, sortable:true,
                filter: "string", filterop:OrbiresultsfilterOp.Match     },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "cvss score", exportcsv: true, val : { obj: "get", path: "obj.securityAdvisory.cvss.score"}, sortable:true,
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "published at", exportcsv: true, val : { obj: "get", path: "obj.securityAdvisory.publishedAt"}, sortable:true }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "description", exportcsv: false, val : { obj: "get", path: "obj.securityAdvisory.description"} }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "dismisser ", exportcsv: true, val : { obj: "get", path: "obj.dismisser.login"} },             
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "dismissed date", exportcsv: true, val : { obj: "get", path: "obj.dismissedAt"}, 
                filter: "date", filterop:OrbiresultsfilterOp.SubStrMatch },             
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "dismiss reason ", exportcsv: false, val : { obj: "get", path: "obj.dismissReason"} }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Sythentic), title: "package name", exportcsv: true, val : { obj: "get", path: "obj.securityVulnerability.package.name"}, sortable:true,
                filter: "string", filterop:OrbiresultsfilterOp.SubStr },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "versions", exportcsv: true, val : {  obj: "get", path: "obj.securityVulnerability.vulnerableVersionRange", args: [] }, sortable: true }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "updated date", exportcsv: true, val : {  obj: "get", path: "obj.securityVulnerability.updatedAt", args: [] }, sortable: true }, 
        ]

        myColDefs[OrbiReportType.GithubReleaseTag] = [ 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Identity), title: "number", exportcsv: false, val : { obj: "get", path: "obj.idlogical"}, 
                link: { type: "objrelative", target: "_blank", href: "obj.release.url" },                    
                sortable: true }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Identity), title: "repo", exportcsv: true, val : { obj: "get", path: "obj.repoContainer.repoName"}, sortable:true,
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "tagName", exportcsv: true, val : { obj: "get", path: "obj.release.tagName"}, copiable:true, sortable:true,
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch   }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Published at", exportcsv: true, val : { obj: "get", path: "obj.release.publishedAt"}, sortable:true  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "author", exportcsv: true, val : { obj: "get", path: "obj.release.authorLogin"}, sortable:true  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "resourcePath", exportcsv: false, val : { obj: "get", path: "obj.release.resourcePath"} }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Identity), title: "tag", exportcsv: true, val : { obj: "get", path: "obj.release.tagId"}, copiable:true  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Updated at", exportcsv: true, val : { obj: "get", path: "obj.release.updatedAt"}  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "url", exportcsv: false, val : { obj: "get", path: "obj.release.url" }, 
                link:{ type: "objrelative", target: "_blank", href: "obj.release.url" }  },              
        ];

        myColDefs[OrbiReportType.GithubUser] = [
            
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity), 
                link: { type: "objrelative", target: "_blank", href: "obj.user.url" },
                title: "login", exportcsv: false, val : { obj: "get", path: "obj.user.login"}  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "has name", exportcsv: true, 
                val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.User_HasName] },
                filter: "boolean", filterop:OrbiresultsfilterOp.Match  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "has company", exportcsv: true, 
                val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.User_HasCompany] },
                filter: "boolean", filterop:OrbiresultsfilterOp.Match  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "has email", exportcsv: true, 
                val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.User_HasEmail] },
                filter: "boolean", filterop:OrbiresultsfilterOp.Match  },                 
            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "has public keys", exportcsv: true, 
                val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.User_hasPublicKeysGpg, OrbiAuditResultTest.User_hasPublicKeysSsh ] },
                filter: "boolean", filterop:OrbiresultsfilterOp.Match  },                
            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "has rotated keys", exportcsv: true, 
                val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.User_hasPublicKeysSshRotated, OrbiAuditResultTest.User_hasPublicKeysGpgRotated, OrbiAuditResultTest.User_hasPublicKeysSgnRotated]  },
                filter: "boolean", filterop:OrbiresultsfilterOp.Match  },                                
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch,
                title: "Name", exportcsv: true, val : { obj: "get", path: "obj.user.name"}  }, 
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch,
                title: "Email", exportcsv: true, val : { obj: "get", path: "obj.user.email"}  }, 
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch,
                title: "Company", exportcsv: true, val : { obj: "get", path: "obj.user.company" }  }, 
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Orgs", exportcsv: true, val : { obj: "arraystring", path: "obj.user.orgs" }  }, 
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "# Ssh keys", exportcsv: true, val : { obj: "get", path: "obj.user.numSshKeys" }  }, 
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Oldest ssh key", exportcsv: true, val : { obj: "get", path: "obj.user.oldestSshKeyDate" }  },                 
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "# Gpg keys", exportcsv: true, val : { obj: "get", path: "obj.user.numGpgKeys" }  }, 
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Oldest gpg key", exportcsv: true, val : { obj: "get", path: "obj.user.oldestGpgKeyDate" }  }, 
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "# Signing keys", exportcsv: true, val : { obj: "get", path: "obj.user.numSshSigningKeys" }  }, 
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Oldest signing key", exportcsv: true, val : { obj: "get", path: "obj.user.oldestSshSigningKeyDate" }  },                 
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Created at", exportcsv: true, val : { obj: "get", path: "obj.user.createdAt" } }, 
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Updated at", exportcsv: true, val : { obj: "get", path: "obj.user.updatedAt" } },        
        ];

        myColDefs[OrbiReportType.GithubTeam] = [
            
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity), 
                link: { type: "objrelative", target: "_blank", href: "obj.team.html_url" },
                title: "slug", exportcsv: true, val : { obj: "get", path: "obj.team.slug"},
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch,
                copiable: true,   },   
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Name", exportcsv: true, val : { obj: "get", path: "obj.team.name"},
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch,
                copiable: true,   },          
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Parent team", 
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch,
                exportcsv: true, val : { obj: "get", path: "obj.team.parentSlug"}  },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), 
                title: "Description", exportcsv: true, val : { obj: "get", path: "obj.team.description"}  },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), 
                title: "# repos", exportcsv: true, val : { obj: "get", path: "obj.team.numRepositories"}, sortable: true   },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), 
                title: "# members", exportcsv: true, val : { obj: "get", path: "obj.team.numMembers"}, sortable: true   },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "# child teams", exportcsv: true, 
                val : { obj: "get", path: "obj.synthetic.numChildteams"},
                sortable: true   }     
        ],

        myColDefs[GithubComponent.NavItemTeamMembers] = [
            // ### need to break out data differently            
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity), 
                link: { type: "objrelative", target: "_blank", href: "obj.url" },
                title: "slug", filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch, 
                exportcsv: false, val : { obj: "get", path: "obj.slug"}  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Team name", 
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch,
                exportcsv: true, val : { obj: "get", path: "obj.name"}  },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Member", 
                filter: "string", filterop:OrbiresultsfilterOp.SubStrMatch, 
                exportcsv: true, val : { obj: "get", path: "obj.memberName" } }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Member type", 
                filter: "string", filterop:OrbiresultsfilterOp.Match, 
                exportcsv: true, val : { obj: "get", path: "obj.memberType" } }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), title: "Member role", 
                filter: "string", filterop:OrbiresultsfilterOp.Match, 
                exportcsv: true, val : { obj: "get", path: "obj.memberRole" } },                 
        ],

        myColDefs[OrbiReportType.GithubWorkflow] = [
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity),                 
                title: "#", exportcsv: false, val : { obj: "get", path: "obj.workflow.workflowName"},
                link: { type: "objrelative", target: "_blank", href: "obj.workflow.workflowHtmlUrl" },  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Identity), title: "Workflow qualified name", exportcsv: false, 
                val : { obj: "get", path: "obj.idlogical"}, 
                link: { type: "objrelative", target: "_blank", href: "obj.workflow.workflowHtmlUrl" },                    
                sortable: true },                 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Workflow name", exportcsv: true, val : { obj: "get", path: "obj.workflow.workflowName", args: [] }, sortable:true  },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Workflow file", exportcsv: true, val : { obj: "get", path: "obj.synthetic.workflowFile", args: [] }, sortable:true  },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Workflow path", exportcsv: true, val : { obj: "get", path: "obj.workflow.workflowPath", args: [] }, sortable:true  },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Date),
                title: "Updated at", exportcsv: true, val : { obj: "get", path: "obj.workflow.workflowUpdatedAt", args: [] }, sortable:true  },            
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Repository name", exportcsv: true, val : { obj: "get", path: "obj.repoContainer.repoName", args: [] }, sortable:true  },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "html_url", exportcsv: true, val : { obj: "get", path: "obj.workflow.workflowHtmlUrl", args: [] },
                link: { type: "objrelative", target: "_blank", href: "obj.workflow.workflowHtmlUrl" },  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Url", exportcsv: true, val : { obj: "get", path: "obj.workflow.workflowActionsUrl" },
                link: { type: "objrelative", target: "_blank", href: "obj.workflow.workflowActionsUrl" }, }
        ];

        myColDefs[OrbiReportType.GithubWorkflowRun] = [
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity),                 
                title: "#", exportcsv: false, val : { obj: "get", path: "obj.id"}  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Identity), title: "Workflow run qualified name", exportcsv: false,  
                val : { obj: "ui", path: "datarender.getWorkflowRunIdentifer"}, 
                link: { type: "objrelative", target: "_blank", href: "obj.html_url" },                    
                sortable: true },                
            {title: "Started at", exportcsv: true, val : { obj: "get", path: "obj.run_started_at", args: [] }, sortable: true  },
            {title: "Event", exportcsv: true, val : { obj: "get", path: "obj.event", args: [] }, },
            {title: "Repository name", exportcsv: true, val : { obj: "get", path: "obj.repoName", args: [] }, sortable:true  },
            {title: "Branch", exportcsv: true, val : { obj: "get", path: "obj.head_branch", args: [] }, sortable:true },
            {title: "Sha", exportcsv: true, val : { obj: "get", path: "obj.head_sha", args: [] }, copiable:true },
            {title: "Workflow name", exportcsv: true, val : { obj: "get", path: "obj.name", args: [] }, sortable:true },
            {title: "Run conclusion", exportcsv: true, val : { obj: "get", path: "obj.conclusion", args: [] },  },
            {title: "Run status", exportcsv: true, val : { obj: "get", path: "obj.status", args: [] }  },
            {title: "Event", exportcsv: true, val : { obj: "get", path: "obj.event", args: [] },   },            
            {title: "Run number", exportcsv: true, val : { obj: "get", path: "obj.run_number", args: [] }  },
            {title: "html_url", exportcsv: true, val : { obj: "get", path: "obj.html_url", args: [] }, link: { type: "objrelative", target: "_blank", href: "obj.html_url" }  }
        ];


        myColDefs[GithubComponent.NavItemAccessControl] = [
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity),                 
                title: "#", exportcsv: true, val : { obj: "get", path: "obj.identifier"},
                link: { type: "objrelative", target: "_blank", href: "obj.synthetic.modifiedObjectUrl" },
                copiable: true  },         
            {
                group: GithubColumnDefs.getColDef(ColDefGroup.Date),
                title: "Sampled at", exportcsv: true, val : { obj: "get", path: "obj.updatedAt", args: [] }, 
                sortable:true, sortabledefault:true },     

            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "No direct user", exportcsv: true, 
                val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Permission_ACLHasDirectUser] }  },                 
            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "No direct user except admin", exportcsv: true, 
                val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Permission_ACLHasDirectUserWhoIsNotAdmin] }  },                 

            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Target", exportcsv: true, val : { obj: "get", path: "obj.accesscontrol.permissionTargetId", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },      
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Target type", exportcsv: true, val : { obj: "ui", path: "datarender.enumPermissionTargetType", args: ["obj.accesscontrol.permissionTargetType"] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },      

            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Principal", exportcsv: true, val : { obj: "get", path: "obj.accesscontrol.principalId", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },                  
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Principal type", exportcsv: true, val : { obj: "ui", path: "datarender.enumPrincipalType", args: ["obj.accesscontrol.principalType"] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },      

            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Permission admin", exportcsv: true, val : { obj: "get", path: "obj.synthetic.permValues.admin", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },                  
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Permission maintain", exportcsv: true, val : { obj: "get", path: "obj.synthetic.permValues.maintain", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },                  
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Permission triage", exportcsv: true, val : { obj: "get", path: "obj.synthetic.permValues.triage", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },                  
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Permission push", exportcsv: true, val : { obj: "get", path: "obj.synthetic.permValues.push", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },                  
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Permission pull", exportcsv: true, val : { obj: "get", path: "obj.synthetic.permValues.pull", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },                  
        ];

        myColDefs[OrbiReportType.GithubWebhookEventOrg] = [
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity),                 
                title: "#", exportcsv: true, val : { obj: "get", path: "obj.idlogical"},
                link: { type: "objrelative", target: "_blank", href: "obj.synthetic.modifiedObjectUrl" },
                copiable: true  },         
            { group: GithubColumnDefs.getColDef(ColDefGroup.Date),
                title: "Recorded at", exportcsv: true, val : { obj: "get", path: "obj.webhookevent.eventReceivedAt", args: [] }, 
                sortable:true, sortabledefault:true },     
            { group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Event type", exportcsv: true, val : { obj: "get", path: "obj.webhookevent.eventtype", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },       
            { group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Action", exportcsv: true, val : { obj: "get", path: "obj.webhookevent.actionverb", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },      
            { group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Principal", exportcsv: true, val : { obj: "get", path: "obj.webhookevent.principal", args: [] }, sortable:true,copiable: true,
                filter: "string", filterop:OrbiresultsfilterOp.Match,
                link: { type: "objrelative", target: "_blank", href: "obj.webhookevent.principalUrl" }, },      
            { group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Principal type", exportcsv: true, val : { obj: "get", path: "obj.webhookevent.principalType", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },                  
            { group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Target", exportcsv: true, val : { obj: "get", path: "obj.webhookevent.targetObject", args: [] }, sortable:true,copiable: true,
                filter: "string", filterop:OrbiresultsfilterOp.Match,
                link: { type: "objrelative", target: "_blank", href: "obj.webhookevent.targetObjectUrl" }, },      
            { group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Target type", exportcsv: true, val : { obj: "get", path: "obj.webhookevent.targetObjectType", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },      
            { group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Target path", exportcsv: true, val : { obj: "get", path: "obj.webhookevent.targetObjectPath", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },      
            { group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Authorizer", exportcsv: true, val : { obj: "get", path: "obj.webhookevent.sender", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },      
            { group: GithubColumnDefs.getColDef(ColDefGroup.Attribute),
                title: "Authorizer type", exportcsv: true, val : { obj: "get", path: "obj.webhookevent.senderType", args: [] }, sortable:true,copiable: true,filter: "string", filterop:OrbiresultsfilterOp.Match },     
        ];
        myColDefs[OrbiReportType.GithubWebhookEventSourceCode] = myColDefs[OrbiReportType.GithubWebhookEventOrg];
        myColDefs[OrbiReportType.GithubWebhookEventProjMgmt] = myColDefs[OrbiReportType.GithubWebhookEventOrg];
        myColDefs[OrbiReportType.GithubWebhookEventCiCd] = myColDefs[OrbiReportType.GithubWebhookEventOrg];
        
        myColDefs[OrbiReportType.GithubEnvironment] = [
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity),                 
                title: "#", exportcsv: true, val : { obj: "get", path: "obj.idlogical"},
                link: { type: "objrelative", target: "_blank", href: "obj.environment.html_url" },
                copiable: true  },
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity), 
                title: "Repository name", exportcsv: true, val : { obj: "get", path: "obj.repoContainer.repoName", args: [] }, 
                sortable:true  },            
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity), 
                link: { type: "objrelative", target: "_blank", href: "obj.environment.html_url" },
                title: "name", exportcsv: true, val : { obj: "get", path: "obj.environment.envName"}  }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Date), title: "Created at", exportcsv: true, val : { obj: "get", path: "obj.environment.envCreatedAt" }, sortable: true }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Date), title: "Updated at", exportcsv: true, val : { obj: "get", path: "obj.environment.envUpdatedAt" }, sortable: true },                 
            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Has deployment policy", exportcsv: true, 
                filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.RepoEnv_hasDeploymentBranchPolicy] }  },                            
            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), title: "Has secrets", exportcsv: true, 
                filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.RepoEnv_hasSecrets] }  },                                            
            { group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), 
                title: "Num secrets", exportcsv: false, val : { obj: "get", path: "obj.synthetic.secretNames.length"},
                sortable:true  },                 
            
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), 
                title: "Deployment branch policy", exportcsv: true, 
                val : { obj: "ui", path: "datarender.getDeploymentBranchPolicy", args: [] }, sortable:true,
                copiable: false, filter: "string", filterop:OrbiresultsfilterOp.Match },
        ];

        myColDefs[OrbiReportType.GithubSecret] = [
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity),                 
                title: "#", exportcsv: true, val : { obj: "get", path: "obj.idlogical"},
                link: { type: "objrelative", target: "_blank", href: "obj.secret.html_url" },
                copiable: true  },
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), 
                    title: "Repository name", exportcsv: true, 
                    val : { obj: "get", path: "obj.repoContainer.repoName", args: [] }, 
                    link: { type: "objrelative", target: "_blank", href: "obj.secret.repoUrl" },
                    sortable:true  },                        
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity), 
                link: { type: "objrelative", target: "_blank", href: "obj.payload.html_url" },
                title: "Secret name", exportcsv: true, val : { obj: "get", path: "obj.secret.secretName"}  }, 
            { group: GithubColumnDefs.getColDef(ColDefGroup.Identity), 
                    title: "Environment name", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.SubStrMatch,
                    val : { obj: "get", path: "obj.secret.envName"}  },                    
            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), 
                title: "Is environment secret", exportcsv: true, 
                filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Secrets_IsEnvSecret] }  },                
            {group: GithubColumnDefs.getColDef(ColDefGroup.AuditTest), 
                    title: "Is secret rotated", exportcsv: true, 
                    filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                    val : { obj: "auditest", path: "resultsrenderer.getAuditTest", args: [OrbiAuditResultTest.Secrets_IsRotated] }  },                            
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), 
                title: "Secret type", exportcsv: true, 
                filter: "boolean", filterop:OrbiresultsfilterOp.Match,
                val : { obj: "get", path: "obj.secret.secretType", args: [] }, 
                sortable:true  },                              
            {group: GithubColumnDefs.getColDef(ColDefGroup.Attribute), 
                    title: "Days since rotation", exportcsv: true, 
                    val : { obj: "get", path: "obj.synthetic.daysSinceLastRotation", args: [] }, 
                    sortable:true  },                                                
            {group: GithubColumnDefs.getColDef(ColDefGroup.Date), 
                title: "Created at", exportcsv: true, val : { obj: "get", path: "obj.secret.created_at" }, sortable: true }, 
            {group: GithubColumnDefs.getColDef(ColDefGroup.Date), 
                title: "Updated at", exportcsv: true, val : { obj: "get", path: "obj.secret.updated_at" }, sortable: true },             
        ];

        GithubColumnDefs.colDefs = myColDefs;
    }

    private static initColFilter(resultsFilter:any, currColDef:any) {
        resultsFilter.filterState[currColDef.key].filterSelectedValues = {};
        currColDef.filter.possiblevalues.forEach((element : string) => {
            resultsFilter.filterState[currColDef.key].filterSelectedValues[element] = undefined;
        });
    }

    public static initColDefs(/*resultsFilter:ColDefFilter*/) {
        GithubColumnDefs.initColDefsStatic();
        var colDefsKeys : string[] = Object.keys(GithubColumnDefs.colDefs);
        var colDefKey = 314000;
        colDefsKeys.forEach( (currColDefKey:string) => {
            var currColsDefs : any[] = GithubColumnDefs.colDefs[currColDefKey];
            currColsDefs.forEach((currColDef:any) => {
                currColDef.key = colDefKey++;

                currColDef.classNames = {};
                currColDef.classNames.thClass = `${GithubColumnDefs.getClassForGroup(currColDef)}`
                currColDef.classNames.metadataClass = `${currColDef.classNames.thClass}`;

                /*
                if (GithubColumnDefs.isFilterCol(currColDef)) 
                {
                    currColDef.filter = { type: 'boolean', possiblevalues: [ 'true', 'false' ] };
                    this.initColFilter(resultsFilter, currColDef);/*
                    currColDef.filter.possiblevalues.forEach((element : string) => {
                        resultsFilter.filterState[currColDef.key].filterSelectedValues[element] = undefined;
                    });  * /
                } else if (currColDef.filter) {
                    // this is a kind of short hand, set filter to true in column def and this gets setup
                    // when we run the filter the first time this gets populated based on the objects
                    currColDef.filter = { type: 'string', possiblevalues: [] }; 
                } */

                /*
                // init col defs                                                
                GithubColumnDefs.colDefs.repos.forEach( (colElement:any) => {
                    colElement.localfilters = { hasTrue: true, hasFalse: true };
                }); */
                
            });
        });
    }
}

export class ColDefFilter 
{
    public filterState : any = {};
    private isNewObjs : boolean = true;

    public setIsNewObjs(newVal:boolean) {
        this.isNewObjs = newVal;
    }

    public constructor(private svcDebug: OrbidebugService, private thisObjForUi:any) {}

    /* 
    filterSelected<T>(obj: UiObj<T>) : boolean {
        return obj.selected;
    };

    filterApplyLocalFilter(coldefs : any[], obj: UiObj<any>) : boolean {
        var applyLocalFilter = false;
        
        for (var colDefIndex = 0; colDefIndex < coldefs.length && false == applyLocalFilter; ++colDefIndex) {
            var element = coldefs[colDefIndex];
            var localfilterKeys = Object.keys(element.localfilters);
            var localfilterValues = Object.values(element.localfilters);

            var colVal = OanLodashWrap.interpolate(obj, element);

            for (var lfFalseIndex = 0; lfFalseIndex < localfilterValues.length && false == applyLocalFilter; ++lfFalseIndex) {
                if (false == localfilterValues[lfFalseIndex]) {
                    var colKey = localfilterKeys[lfFalseIndex];

                    //@ts-ignore
                    applyLocalFilter = ('hasTrue' == colKey && true == colVal)  || ('hasFalse' == colKey && false == colVal) ;
                }
            }
        }

        return applyLocalFilter;
    }; */

    public colFilter<T>(colDefs : any, obj: UiObj<T>) : boolean {
        var uiStateObj = this.thisObjForUi.uiState;

        var r = true;
        for (var i = 0; true == r && i < colDefs.length; ++i) {
            if (colDefs[i].filter) 
            {
                var currColDef = colDefs[i];
                var filterSelectedVals = this.filterState[currColDef.key].filterSelectedValues;                
                var filterPossibleVals = currColDef.filter.possiblevalues;
                var objVal : boolean = <boolean>(<unknown>OanLodashWrap.interpolate(uiStateObj, obj, currColDef));
                if (this.isNewObjs && currColDef.filter.type != 'boolean' && -1 == filterPossibleVals.indexOf(objVal)) {
                    filterPossibleVals.push(objVal);
                }

                if (filterSelectedVals && 0 < Object.keys(filterSelectedVals).length) 
                {
                    if ('boolean' == colDefs[i].filter.type) 
                    {
                        var allowedValues : boolean[] = [];                                
                        for (var currI=0; currI<filterPossibleVals.length; ++currI) {
                            if (filterSelectedVals[filterPossibleVals[currI]]) {
                                allowedValues.push(JSON.parse(filterPossibleVals[currI]));
                            }
                        }

                        // TBD uiState is a kind of wrapper that allows html to get values, but could be simplified
                        if (0 < allowedValues.length) {
                            // if ('true' === objVal || 'false' === objVal) {
                            //    var objValAsBool : boolean = ('true' === objVal);
                            var currValOk = (-1 != allowedValues.indexOf(objVal));
                            r = r && currValOk;
                        }       
                    } else {
                        var selectedPossibleValues = Object.entries(this.filterState[colDefs[i].key].filterSelectedValues).map( (el:any) => { if (el[1]) return el[0]; }).filter( (e:any) => e != undefined);
                        if (0 != selectedPossibleValues.length) {
                            var currValOk = (-1 != selectedPossibleValues.indexOf(objVal));
                            r = r && currValOk;
                        }
                    }
                }

                /*
                switch (currColDef.filter.type) 
                {
                    case "boolean": 
                        {
                            if (filterSelectedVals && 0 < Object.keys(filterSelectedVals).length) 
                            {
                                var allowedValues : boolean[] = [];                                
                                for (var currI=0; currI<filterPossibleVals.length; ++currI) {
                                    if (filterSelectedVals[filterPossibleVals[currI]]) {
                                        allowedValues.push(JSON.parse(filterPossibleVals[currI]));
                                    }
                                }

                                // TBD uiState is a kind of wrapper that allows html to get values, but could be simplified
                                if (0 < allowedValues.length) {
                                    // if ('true' === objVal || 'false' === objVal) {
                                    //    var objValAsBool : boolean = ('true' === objVal);
                                    var currValOk = (-1 != allowedValues.indexOf(objVal));
                                    r = r && currValOk;
                                }                                
                            }
                            break;
                        }

                    default:
                        {
                            var testVal = this.filterState[currColDef.key].filterSelectedValue;
                            if (undefined != testVal) {
                                var uiState = this.thisObjForUi.uiState;
                                if (! uiState) {
                                    this.svcDebug.logger.warn("uiState undefined in colFilter");
                                }
            
                                var objVal : boolean = <boolean>(<unknown> OanLodashWrap.interpolate(uiState, obj, currColDef));
                                r = (objVal == testVal);
                                if (! r) {
                                    break;
                                }
                            }
                        }
                }  */
            }
        }

        return r;
    }
}
