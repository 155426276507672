<div class="row mx-0">
    <div class="col-2 dashboard-sidebar">
        <!-- SIDEBAR  -->

        <!-- ngbNav #githubContent="ngbNav" [(activeId)]="tabState.tabActive" (navChange)="navChange($event)" --> 
    <ul class="nav-pills" orientation="vertical">       
        <span *ngIf="! uiState.isPublicWebsite"> 
            <li  class="nav-link orbi-nav-link-title" >Administration</li>    
            <li class="nav-link orbi-nav-link-subtitle"
            [ngClass]="{active: navItemOrgProfile===this.tabState.activeTab}">
                <a (click)="this.tabState.setActiveTab(navItemOrgProfile)">Org profile</a></li>
            <li class="nav-link orbi-nav-link-subtitle" 
                [ngClass]="{active: navItemSubscriptionPlan===this.tabState.activeTab}">
                <a (click)="this.tabState.setActiveTab(navItemSubscriptionPlan)">Subscriptions & plans</a></li>                
            <li class="nav-link orbi-nav-link-subtitle" 
                [ngClass]="{active: navItemBillingSettings===this.tabState.activeTab}">
                <a (click)="this.tabState.setActiveTab(navItemBillingSettings)">Billing settings</a></li>            
        </span>
            <li class="nav-link orbi-nav-link-title">Operations</li>    
            <li class="nav-link orbi-nav-link-subtitle"
                [ngClass]="{active: navItemUserMgmt===this.tabState.activeTab}">
                <a  (click)="this.tabState.setActiveTab(navItemUserMgmt)">User management</a></li>

            <!-- App settings --> 
            <li class="nav-link orbi-nav-link-title">App settings</li>    
            <li class="nav-link orbi-nav-link-subtitle" 
                [ngClass]="{active: navItemAuditChecks===this.tabState.activeTab}">
                <a (click)="this.tabState.setActiveTab(navItemAuditChecks)">Enabled analyzer checks</a></li>
            <li class="nav-link orbi-nav-link-subtitle"
                [ngClass]="{active: navItemLiveProductionAssetsRepos===this.tabState.activeTab}">
                <a  (click)="this.tabState.setActiveTab(navItemLiveProductionAssetsRepos)">Repository sets</a></li>
            <li class="nav-link orbi-nav-link-subtitle"
                [ngClass]="{active: navItemLiveProductionAssetsBranches===this.tabState.activeTab}">
                <a  (click)="this.tabState.setActiveTab(navItemLiveProductionAssetsBranches)">Branch sets</a></li>
            <li class="nav-link orbi-nav-link-subtitle"
                [ngClass]="{active: navItemReportSettings===this.tabState.activeTab}">
                <a (click)="this.tabState.setActiveTab(navItemReportSettings)">Report settings</a></li>
        </ul>
    </div>


    <div class="col-10 dashboard-main">
        <div class="row">
            <div class="col-12 dashboard-main__intro-panel">
                <div class="inner-filters">
                    <div class="row">
                        <div class="col d-flex flex-wrap mb-3">
                            <h2 class="orbi-section-title">{{tabState.activeTabContentAreaMeta?.title}}</h2>
                            <i>{{tabState.activeTabContentAreaMeta?.desc}}</i>
                        </div>
                    </div>                
                    <!-- start filter row 1  -->
                    <div class="row">
                        <div class="col-12">                        
                            <div class="orbi-filters-tabs">                                    
                                <ul>
                                    <li *ngFor="let currSection of tabState.activeTabContentAreaMeta?.sectionHdrs">
                                        <a (click)="currSection.targetelement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })">{{currSection.label}}</a> | 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <!-- div for when no active tab -->
            <div [hidden]="this.tabState.activeTab" class="col-12 dashboard-main__results mt-4">
                ... loading
            </div>


            <!-- div for org profile -->
            <div [hidden]="navItemOrgProfile != this.tabState.activeTab" class="col-12 dashboard-main__results mt-4">

                <div class="row">
                    <!-- 
                    <app-orbiwidgettextboxlist
                        #selectLiveRepoTopics
                        label="blah"
                        description="some desc"
                        [formControlName]="model.assetsLiveProdAreas[frmCtrlId.LiveProdByPatternValues].ctrl" 
                        validationPattern="[a-z]*"
                        validationPatternDescription="Only alphanumic characters are allowed.">
                    </app-orbiwidgettextboxlist>
                -->
                </div>      
                    <!-- Refresh & Search panel  -->
                <div class="row">
                    <div class="col-12">
                        <p> this was Refresh & Search panel   ul class="orbi-results-header-list" </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">                                        
                        <p> Filters recap panel  </p>
                    </div>
                </div>
                <div class="table-scroll-holder">
                    <p>Table holder navItemOrgProfile</p>
                    <button type="submit" (click)="uiEvents.clickCheckvalue(this.tabState.activeTab, $event)" class="btn btn-primary mr-2">Check value</button>
                </div>
            </div>


            <!-- div for subs and plans -->
            <div [hidden]="navItemSubscriptionPlan != this.tabState.activeTab"  class="col-12 dashboard-main__results mt-4">

                <div class="row">
                    <div class="col">
                        <h2 class="orbi-section-title">Subscriptions & plans {{this.tabState.activeTab}}</h2>                     
                    </div>
                </div>      
                    <!-- Refresh & Search panel  -->
                <div class="row">
                    <div class="col-12">
                        <p> this was Refresh & Search panel   ul class="orbi-results-header-list" </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">                                        
                        <p> Filters recap panel  </p>
                    </div>
                </div>
                <div class="table-scroll-holder">
                    <p>Table holder navItemSubscriptionPlan</p>
                </div>
            </div>

        
            <!-- div for billing settings -->
            <div [hidden]="navItemBillingSettings != this.tabState.activeTab"  class="col-12 dashboard-main__results mt-4">

                <div class="row">
                    <div class="col">
                        <h2 class="orbi-section-title">Billing settings {{this.tabState.activeTab}}</h2>                     
                    </div>
                </div>      
                    <!-- Refresh & Search panel  -->
                <div class="row">
                    <div class="col-12">
                        <h2 class="orbi-section-title">Plans and Pricing</h2>
                    </div>
                </div>
                <div class="row justify-content-center my-4">
                    <div class="col-auto orbi-bill-cycle">
                        <h3 class="orbi-section-subtitle mb-0 mr-3">Billing Cycle: </h3>
                        <ul class="orbi-cycle-select">
                            <li><button class="btn btn-primary mr-2">Monthly</button></li>
                            <li><button class="btn btn-light btn-light--border">Yearly</button></li>
                        </ul>
                    </div>
                </div>
                <div class="row justify-content-center orbi-pricing-panel mb-5">
                    <div class="col flex-grow-0">
                        <div class="card pricing-card pricing-card--price1">      
                            <div class="card-header">
                                <h4 class="card-title">Free</h4>
                                <h5 class="card-price">0&euro; <span>per month</span></h5>
                              </div>                      
                            <div class="card-body">                              
                              <p class="card-text">For personal use</p>
                              <hr>
                              <ul class="list-group list-group-flush mb-4">
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon> Feature Name</li>
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon>  Feature Name</li>
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon>  Feature Name</li>
                                <li class="list-group-item disabled"><fa-icon [icon]="['fas', 'times']"></fa-icon> Feature Name</li>
                                <li class="list-group-item disabled"><fa-icon [icon]="['fas', 'times']"></fa-icon> Feature Name</li>
                              </ul>
                              <a href="#" class="btn btn-primary btn-lg w-100">Choose Plan</a>
                            </div>
                          </div>
                    </div>
                    <div class="col flex-grow-0">
                        <div class="card pricing-card pricing-card--price2">      
                            <div class="card-header">
                                <h4 class="card-title">Professional</h4>
                                <h5 class="card-price">25&euro; <span>per month</span></h5>
                              </div>                      
                            <div class="card-body">                              
                              <p class="card-text">For Small Business, up to 100 users</p>
                              <hr>
                              <ul class="list-group list-group-flush mb-4">
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon>  Feature Name</li>
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon>  Feature Name</li>
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon>  Feature Name</li>
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon>  Feature Name</li>
                                <li class="list-group-item disabled"><fa-icon [icon]="['fas', 'times']"></fa-icon> Feature Name</li>
                              </ul>
                              <a href="#" class="btn btn-primary btn-lg w-100">Choose Plan</a>
                            </div>
                          </div>
                    </div>
                    <div class="col flex-grow-0">
                        <div class="card pricing-card pricing-card--price3">      
                            <div class="card-header">
                                <h4 class="card-title">Entreprise</h4>
                                <h5 class="card-price">150&euro; <span>per month</span></h5>
                              </div>                      
                            <div class="card-body">                              
                              <p class="card-text">For personal use</p>
                              <hr>
                              <ul class="list-group list-group-flush mb-4">
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon>  Feature Name</li>
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon>  Feature Name</li>
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon>  Feature Name</li>
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon>  Feature Name</li>
                                <li class="list-group-item"><app-orbiicon iconName="faCheck"></app-orbiicon>  Feature Name</li>
                              </ul>
                              <a href="#" class="btn btn-primary btn-lg w-100">Choose Plan</a>
                            </div>
                          </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-12 col-md-8 offset-md-2">
                        <h2 class="orbi-section-title mb-2">FAQ's</h2>
                        <div class="faq-row">
                            <div class="faq__head">
                                <h2 class="orbi-section-subtitle">How long is a piece of string ?</h2>
                                <button class="btn btn-light" type="button"><app-orbiicon iconName="faCaretDown"></app-orbiicon></button>
                            </div>
                            <div class="faq__answer">
                                <p class="faq__text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia nulla rem hic blanditiis laudantium eveniet corporis modi tempora cum, suscipit ipsum dolorum, eos voluptate necessitatibus consequatur? Vel exercitationem aspernatur architecto.
                                Quisquam a, distinctio quam, itaque temporibus animi magnam ratione quo pariatur placeat accusamus optio cum et tempore dolore ad nulla quibusdam totam. Placeat illum expedita quae obcaecati aliquam facere nulla.</p>
                            </div>
                        </div>

                        <div class="faq-row">
                            <div class="faq__head">
                                <h2 class="orbi-section-subtitle">How long is a piece of Github ?</h2>
                                <button class="btn btn-light" type="button"><app-orbiicon iconName="faCaretDown"></app-orbiicon></button>
                            </div>
                            <div class="faq__answer">
                                <p class="faq__text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia nulla rem hic blanditiis laudantium eveniet corporis modi tempora cum, suscipit ipsum dolorum, eos voluptate necessitatibus consequatur? Vel exercitationem aspernatur architecto.
                                Quisquam a, distinctio quam, itaque temporibus animi magnam ratione quo pariatur placeat accusamus optio cum et tempore dolore ad nulla quibusdam totam. Placeat illum expedita quae obcaecati aliquam facere nulla.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        

            <!-- div for billing settings -->
            <div [hidden]="navItemUserMgmt != this.tabState.activeTab"  class="col-12 dashboard-main__results mt-4">

                <div class="row">
                    <div class="col">
                        <h2 class="orbi-section-title">User mgmt {{this.tabState.activeTab}}</h2>                     
                    </div>
                </div>      
                    <!-- Refresh & Search panel  -->
                <div class="row">
                    <div class="col-12">
                        <p> this was Refresh & Search panel   ul class="orbi-results-header-list" </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">                                        
                        <p> Filters recap panel  </p>
                    </div>
                </div>
                <div class="table-scroll-holder">
                    <p>Table holder navItemUserMgmt</p>
                </div>
            </div>
                    
            <!-- div for navItemAuditChecks settings -->
            <div [hidden]="navItemAuditChecks != this.tabState.activeTab"  class="col-12 dashboard-main__settings mt-4">
                <form [formGroup]="model.formGroupAuditChecks">     
                    <!-- Div for main body of analyzer check settings -->
                    <div class="row mb-4" *ngFor="let currTestArea of model.auditCheckAreas">
                        <div class="col-12">
                            <!-- Section header -->
                            <h4 class="orbi-section-title">{{currTestArea.areaLabel}} </h4>                     
                        </div>
                        <div class="col-12" *ngFor="let currTestControl of currTestArea.areaChecks">                        
                            <div   class="row btn-group no-gutters" role="group" aria-label="Radio Buttons">
                                   <p> {{currTestControl.label}}</p>
                                  
                                    <div class="button-holder app-setting-buttons">
                                        <span [ngClass]="(currTestControl.ctrl.value == currValue.valuekey) ? 'active' : ''" class="btn btn-filter" *ngFor="let currValue of currTestControl.possiblevalues">
                                            <input type="radio" class="d-none" [formControl]="currTestControl.ctrl" [value]="currValue.valuekey" [name]="currTestControl.key" [id]="currValue.id" autocomplete="off">
                                            <label [for]="currValue.id">{{currValue.valuelabel}}</label>
                                        </span> 
                                    </div>

                                    <!--
                                    <button (click)="onTestClick($event, currTestControl)">Test</button>
                                    -->
                            </div>             
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col">                                        
                            <button type="submit" (click)="uiEvents.clickSubmit(this.tabState.activeTab, $event)" class="btn btn-primary">Save</button>
                            <button type="submit" (click)="uiEvents.clickCancel(this.tabState.activeTab, $event)" class="btn">Cancel</button>
                        </div>
                    </div>
                </form>                                     
            </div>

             <!-- div for navItemLiveProductionAssets settings -->
            <div [hidden]="navItemLiveProductionAssetsRepos != this.tabState.activeTab"  class="col-12 dashboard-main__settings mt-4">                                   
                <form [formGroup]="model.formAssetsAffectingLiveProdRepos">     
                        <div #livedefsrepo class="row mb-4">                            
                            <div  class="col"
                                *ngIf="model.assetsLiveProdAreasRepos[frmCtrlId.LiveProdDefinitionTypeForRepos] as ctrlLiveProdDefType">
                                <h4 class="w-100">Repositories that affect production</h4>
                                <div class="row btn-group" role="group" aria-label="Radio Buttons">
                                    <div class="col">
                                       <p> {{ctrlLiveProdDefType.label}}</p>
                                        <app-orbiwidgetradio
                                            [frmCtrlIdLink]="frmCtrlId.LiveProdDefinitionTypeForRepos"
                                            [label]=""
                                            description=""
                                            [formControlName]="frmCtrlId.LiveProdDefinitionTypeForRepos"
                                            [possiblevalues]="ctrlLiveProdDefType.possiblevalues"
                                            [possiblelabels]="ctrlLiveProdDefType.possiblelabels">
                                        </app-orbiwidgetradio>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                                
                            <!-- Container div for selecting live assets, only one of child divs shown  -->
                            <div class="col-12">
                                <!-- div for when user wants to select by clicking repo topics -->
                                <div  class="row btn-group" role="group" aria-label="Radio Buttons" 
                                *ngIf="frmCtrlId.ctrlRadioLiveProdDefTypeForReposByTopic == model.formAssetsAffectingLiveProdRepos.controls[frmCtrlId.LiveProdDefinitionTypeForRepos].value.selectedvalue"
                                >               
                                    <div class="col-12">                     
                                        <p>Select github topics which are used for live repositories.</p>
                                        <i>By selecting here the repositories which affect live environment or the production environment, it is easier to prioritise observations against those reopsitories.  If you have a large number of repositories you may find it easier to use a topic or a series of wildcards </i>

                                        <app-orbiwidgetnameselector
                                            #selectLiveRepoTopics
                                            [formControlName]="frmCtrlId.LiveProdReposByTopic"                                
                                            [allpossiblevalues]="this.model.repoTopics">
                                        </app-orbiwidgetnameselector>                                    
                                    </div>
                                </div>
                            </div>

                            <!-- div for when user wants to select by clicking repo names -->
                            <div class="col-12">
                                <div  class="row" 
                                *ngIf="frmCtrlId.ctrlRadioLiveProdDefTypeForReposByIndNames == model.formAssetsAffectingLiveProdRepos.controls[frmCtrlId.LiveProdDefinitionTypeForRepos].value.selectedvalue"
                                >                  
                                    <div class="col-12">
                                        <p>Individual names of live github repositories</p>
                                    </div>
                                    <div class="col-12">
                                        <app-orbiwidgetnameselector
                                            #selectLiveRepoNames
                                            [formControlName]="frmCtrlId.LiveProdReposByIndName"                                                                
                                            [allpossiblevalues]="this.model.repos">
                                        </app-orbiwidgetnameselector>                                    
                                    </div>  
                                </div>
                            </div>

                            <!-- div for when user wants to select by defining regex for repo names 
                            <div class="col-12">
                                <div  class="row btn-group" role="group" aria-label="Radio Buttons" 
                                *ngIf="frmCtrlId.ctrlRadioLiveProdDefTypeForReposByPattern == model.formAssetsAffectingLiveProdRepos.controls[frmCtrlId.LiveProdDefinitionTypeForRepos].value.selectedvalue"
                                >       
                                    <div class="col-12">
                                        <p>Regular expressions that identify live github repositories</p>
                                        <app-orbiwidgettextboxlist
                                            #selectLiveRepoTopics                                        
                                            [formControlName]="frmCtrlId.LiveProdReposByPattern"                                         
                                            validationPattern=".+"
                                            validationPatternDescription="">
                                        </app-orbiwidgettextboxlist> 
                                    </div>
                                </div>
                            </div> -->

                            <!-- div for when user wants to select by clicking repo names -->
                            <div class="col-12">
                                    <div  class="row"  
                                    *ngIf="frmCtrlId.ctrlRadioLiveProdDefTypeForReposAll == model.formAssetsAffectingLiveProdRepos.controls[frmCtrlId.LiveProdDefinitionTypeForRepos].value.selectedvalue"
                                    >                                    
                                        <div class="col-12">
                                            <b>All repos</b>                                        
                                            <p>If you have many Legacy, PoC or non-production repositories then these repositories will also get security alerts which can make it hard to prioritise</p>
                                        </div>
                                    </div>
                            </div>
                            <!-- end repositories affecting production -->
                        </div>
                    
                        <hr>
                        <div>
                            <div style="display:block" class="invalid-feedback" [hidden]="! uiState.assetsLiveProdAreasReposErrors.errorByTopicYouMustSelectOneTopic">
                                You must select at least one topic.
                            </div>
                            <div style="display:block" class="invalid-feedback" [hidden]="! uiState.assetsLiveProdAreasReposErrors.errorByTopicYouMustSelectOneRepositoryName">
                                You must select at least one repository name.
                            </div>
                            <div style="display:block" class="invalid-feedback" [hidden]="! uiState.assetsLiveProdAreasReposErrors.errorByTopicYouMustSelectOneRegexPattern">
                                You must select at least one regex pattern.
                            </div>                                                        
                        </div>
                        <div class="row mt-4">
                            <div class="col">                                        
                                <button type="submit" (click)="uiEvents.clickCheckvalue(this.tabState.activeTab, $event)" class="btn btn-primary mr-2">Check value</button>
                                <button type="submit" (click)="uiEvents.clickSubmit(this.tabState.activeTab, $event)" class="btn btn-primary mr-2">Save</button>
                                <button type="submit" (click)="uiEvents.clickCancel(this.tabState.activeTab, $event)" class="btn">Cancel</button>
                            </div>
                        </div>
                </form>       
            </div>
            
            <!-- div for navItemLiveProductionAssets settings -->
            <div [hidden]="navItemLiveProductionAssetsBranches != this.tabState.activeTab"  class="col-12 dashboard-main__settings mt-4">    
                <form [formGroup]="model.formAssetsAffectingLiveProdBranches">     
                    <div #livedefsbranches class="row mb-4">
                        <div class="col-12">
                            <h4 class="w-100">Branches that affect production</h4>
                            <p>How do you identify which branches are relevant to production or live environments.  This allows the tool to distinguish between live branches & feature branches</p>
                        </div>
                        <div  class="col-12 row btn-group  no-gutters" role="group" aria-label="Radio Buttons" 
                            *ngIf="model.assetsLiveProdAreasBranches[frmCtrlId.LiveProdDefinitionTypeForBranches] as ctrlLiveProdDefType">
                            <!-- Radio button so user can select 1 of 4 ways to define assets -->
                            <app-orbiwidgetradio
                                [frmCtrlIdLink]="frmCtrlId.LiveProdDefinitionTypeForBranches"
                                [label]=""
                                description=""
                                [formControlName]="frmCtrlId.LiveProdDefinitionTypeForBranches"
                                [possiblevalues]="ctrlLiveProdDefType.possiblevalues"
                                [possiblelabels]="ctrlLiveProdDefType.possiblelabels">
                            </app-orbiwidgetradio>    

                            <!-- div for when user wants to select by clicking default -->
                            <div  class="col-12"  class="row btn-group" role="group" aria-label="Radio Buttons" 
                            *ngIf="frmCtrlId.ctrlRadioLiveProdDefTypeForBranchesDefault == model.formAssetsAffectingLiveProdBranches.controls[frmCtrlId.LiveProdDefinitionTypeForBranches].value.selectedvalue"
                            >
                                
                                <p><i>The default branches are main, master and are checked by the tool to give visibility over changes and posture management that affect prodution.  Other branches will be considered to be not affecting production and potential issues will be less visibile.
                                If this doesnt match your context, use naming convention to define which branch names are to be considered sensitive.</i></p>
                                
                            </div>        

                            <!-- div for when user wants to select by clicking branches naming convention -->
                            <div  class="col-12"  class="row btn-group" role="group" aria-label="Radio Buttons" 
                            *ngIf="frmCtrlId.ctrlRadioLiveProdDefTypeForBranchesByNamingConv == model.formAssetsAffectingLiveProdBranches.controls[frmCtrlId.LiveProdDefinitionTypeForBranches].value.selectedvalue"
                            >
                                <i>Enter here the names of branches which are used to manage live or production environments.</i>
                                <div  class="row btn-group" role="group" aria-label="Radio Buttons" >
                                    <app-orbiwidgettextboxlist
                                        #selectLiveRepoTopics
                                        nameLabel="Branch names"
                                        [frmCtrlIdLink]="frmCtrlId.LiveProdBranchesByNamingConv"
                                        validationPattern="^[\w-\/]+$"
                                        [formControlName]="frmCtrlId.LiveProdBranchesByNamingConv">
                                    </app-orbiwidgettextboxlist>    
                                </div>
                            </div>     
                                                            
                            <!-- div for when user wants to select by clicking branches naming convention -->
                            <div  class="col-12"  class="row btn-group" role="group" aria-label="Radio Buttons" 
                            *ngIf="frmCtrlId.ctrlRadioLiveProdDefTypeForBranchesByPattern == model.formAssetsAffectingLiveProdBranches.controls[frmCtrlId.LiveProdDefinitionTypeForBranches].value.selectedvalue"
                            >
                                <i>Enter here the names of regular expressions that match branch names that affect live or production environments.</i>
                                <div  class="row btn-group" role="group" aria-label="Radio Buttons" >
                                    <app-orbiwidgettextboxlist
                                        #selectLiveRepoTopics
                                        nameLabel="Branch regex patterns"
                                        [formControlName]="frmCtrlId.LiveProdBranchesByPattern"                                >
                                    </app-orbiwidgettextboxlist>    
                                </div>
                            </div>            
                            
                            <div  class="row btn-group" role="group" aria-label="Radio Buttons" 
                            *ngIf="frmCtrlId.ctrlRadioLiveProdDefTypeForBranchesAll == model.formAssetsAffectingLiveProdBranches.controls[frmCtrlId.LiveProdDefinitionTypeForBranches].value.selectedvalue"
                            >                                    
                                <b>All branches</b>                                        
                                <p>This means you will get configuration alerts for things like branch protection, code woners also on development branches, which can make it hard to prioritise</p>
                            </div>
                        </div>           
                    </div>
                    <hr>
                    <div>
                        <div style="display:block" class="invalid-feedback" [hidden]="! uiState.assetsLiveProdAreasBranchesErrors.errorByTopicYouMustDefineSomeNames">
                            You must enter the names allowed for production branches in your naming convention.
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col">                                        
                            <button type="submit" (click)="uiEvents.clickCheckvalue(this.tabState.activeTab, $event)" class="btn btn-primary mr-2">Check value</button>
                            <button type="submit" (click)="uiEvents.clickSubmit(this.tabState.activeTab, $event)" class="btn btn-primary mr-2">Save</button>
                            <button type="submit" (click)="uiEvents.clickCancel(this.tabState.activeTab, $event)" class="btn">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- div for report settings -->
            <div [hidden]="navItemReportSettings != this.tabState.activeTab"  class="col-12 dashboard-main__results mt-4">

                <div class="row">
                    <div class="col">
                        <h2 class="orbi-section-title">Report settings {{this.tabState.activeTab}}</h2>                     
                    </div>
                </div>      
                    <!-- Refresh & Search panel  -->
                <div class="row">
                    <div class="col-12">
                        <app-orbisettingswidget #orbisettingswidget> </app-orbisettingswidget>
                        <p> this was Refresh & Search panel   ul class="orbi-results-header-list" </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">                                        
                        <p> Filters recap panel  </p>
                    </div>
                </div>
                <div class="table-scroll-holder">
                    <p>Table holder navItemReportSettings</p>
                </div>
            </div>
            <!-- no more sections -->
        </div>        
    </div>
</div>




