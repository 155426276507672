// keep in sync with src/model/modelconstants.ts
export class OrbiApiContstants {
    public static readonly TenantIdMaxLength = 32;
    public static readonly UuidMaxLength = 40;
    public static readonly GithubMaxRepoNameLength = 100;
    public static readonly GithubMaxNameLength = 255;
    public static readonly GithubMaxShaLength = 255;
    public static readonly MaxEmailLength = 320;

    public static readonly MaxEnumValue = 64;
    public static readonly MaxUrlLength = 2048;
    public static readonly MaxSimplyJsonLength = 255;
    public static readonly MaxPayloadLength = 2048;
}

