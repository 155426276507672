import { OrbidebugService } from "src/app/svc/orbidebug.service";
import { get as loGet, invoke as loInvoke } from 'lodash-es';


export class OanLodashWrap 
{
    constructor( ) 
    {}

    private static logger:any = undefined;
    private static svcDebug: OrbidebugService;

    public static init(logger : any, debugHandler:OrbidebugService) {
        OanLodashWrap.logger = logger;
        OanLodashWrap.svcDebug = debugHandler;
    }

    private static callDbgWrapper(p : unknown) {
        if (this.svcDebug) {
            this.svcDebug.debugHandler(p);
        }
    }

    public static lodashGet(obj : any, path : string) {
        var val = loGet(obj, path);
        return val;
    }

    public static interpolateLoDash(paramThis:any, o : any, type:string, path : string, transform? : any, args? : any[], hardError? : boolean) {
        var val = '';
        switch (type) {
            case "get":
                val = loGet(o, path);
                break;
            case "ui":
                /* this = .uiState here */
                val = loInvoke(paramThis, path, o, args);
                break; 
            case "arraystring": {
                var arr : any[] = loGet(o, path); 
                if (Array.isArray(arr)) {
                    val = arr.join(' ');
                } else {
                    debugger;
                    val = '';
                }
                
                break;
            }
            case "arraylinks": {
                var arr : any[] = loGet(o, path); 
                if (arr && transform && transform.text) {
                    var mapped : string[] = arr.map( (el) => loGet(el, transform.text));
                    if (0 < mapped.length) {
                        val = mapped.join(' ');
                    }
                } else {
                    console.warn("interpolateLoDash arraylinks transform undefined");
                } 
                break;
            }
        }

        if (!val && hardError) {
            var msg = `interpolateLoDash unknown attr ${path} on ${JSON.stringify(o)}`;
            this.callDbgWrapper(msg);
        }

        return val;
    }

    public static interpolate(paramThis:any, o : any, cd : any, hardError? : boolean) : string {
        var val= this.interpolateLoDash(paramThis, o, cd.val.obj, cd.val.path, cd.val.transform, cd.val.args, hardError);
        return val;
    }

}