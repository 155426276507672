
import { OrbiObjectType } from '@danclarke2000/gitrospectdto';
import { OanDebugutils } from '../utils/OanDebugutils';
import { OrbiReportType } from './UiLabels';

export class UiObj<T> {

    obj : T;
    selected: boolean;
    additional: any;
    cssclass : any = {};

    // TBD this is set by the observer in component, it shouldl be its own subscriber 
    private static _releasemgmts : any = {} as any;
    public static setReleaseMgmts(releasemgmts : any[]) {
        var organizedReleaseMgmts : any = {};
        for (var i=0; i<releasemgmts.length; ++i) {
            var currRm = releasemgmts[i];
            if (currRm && currRm.repository && currRm.repository.name) {
                var currRmName = currRm.repository.name;
                if (undefined == organizedReleaseMgmts[currRmName]) {
                    organizedReleaseMgmts[currRmName] = [];
                }

                var publishDate = new Date(currRm.publishedAt);
                currRm.dtePublishedAt = publishDate;    
                organizedReleaseMgmts[currRmName].push(currRm);
            }
        }

        // sort each array by date 
        Object.keys(organizedReleaseMgmts).forEach( (currKey:string) => {
            organizedReleaseMgmts[currRmName].sort(function(a:any,b:any){
                // most recent first 
                return b.dtePublishedAt - a.dtePublishedAt;
              });
        });

        UiObj._releasemgmts = organizedReleaseMgmts;
    }

    constructor(index:number, public readonly id:number|string, private keylogical:string, obj:T, private  objtype:OrbiObjectType, private reporttype:OrbiReportType) {
        this.obj = obj;
        this.selected = false;
        this.additional = {};
        if (! this.id) 
        {
            OanDebugutils.debuggerWrapper(".?.");
            console.error(`Unexpected id value for object ${this.objtype} reporttype=${this.reporttype}`);
        }
    }

    public static getSelectedInnerObjs(uiobjs : UiObj<any>[]) {
        var filteredObjs = uiobjs.reduce( function (res : any[], currEl : UiObj<any>) {
            if (currEl.selected) {
                res.push(currEl.obj);
            }
            return res;
        }, []);
        
        return filteredObjs;
    } 
};
