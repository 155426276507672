import { Injectable } from '@angular/core';
import { catchError, debounceTime, delay, filter, mergeMap, retryWhen, switchMap, tap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OanDebugutils } from '../cmn/utils/OanDebugutils';

  

@Injectable({
  providedIn: 'root'
})
export class OrbidebugService {

    public logger : any = { ...console};

    debugHandler(val:any) : string {
        this.logger.warn("debugHandler");
        // OanDebugutils.debuggerWrapper(".?.")
        return "";
        // return JSON.stringify(val);
        
    }

    public debugHandlerWithCtxt(ctxt:string, val?:any) : void {
        let varStr = (val) ? val : "";
        this.logger.warn(`debugHandler ${ctxt} ${varStr}`);
        OanDebugutils.debuggerWrapper(".?.");        
    }

    public callDbgWrapperIfCondition(ctxt:string, p : unknown, c:boolean) {
        if (c) {
            this.debugHandlerWithCtxt(ctxt, p);
        }
    }
}
