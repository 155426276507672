import { Injectable, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OanDebugutils } from '../cmn/utils/OanDebugutils';
import { OrbiprofileService } from './orbiprofile.service';

// minor should be "compatible" with other minor on same version
// major difference means we delete the old data (in future we can define some migration)
const ExpectedStorageFormatVersionMajor = 2;
const ExpectedStorageFormatVersionMinor = 1;
export enum OrbiBrowserStorageItemId 
{
    StorageVersionFormat = 'OrbiBrowserStorageItemId.StorageVersionFormat',
    SecureStorageEnabled = 'OrbiBrowserStorageItemId.ss1',
    DefaultOrgName = 'OrbiBrowserStorageItemId.DefaultOrgName',
    QfFilterStateVars = 'OrbiBrowserStorageItemId.qfFilterStateVars',
    QfFilterStateVarsDate = 'OrbiBrowserStorageItemId.qfFilterStateVarsDate',
    QfMetricFilterStateVars = 'OrbiBrowserStorageItemId.qfMetricFilterStateVars',
    QfMetricFilterStateDateVars = 'OrbiBrowserStorageItemId.qfMetricFilterStateDateVars',

}
const OrbiBrowserStorageItemId_all = [ OrbiBrowserStorageItemId.StorageVersionFormat, OrbiBrowserStorageItemId.DefaultOrgName, OrbiBrowserStorageItemId.QfFilterStateVars ];


interface OrbiBrowserStorageEncItem
{
    encKeyId:string;
    propKey : string;
    b64EncPayload : string
}

class CryptoWrapper
{
    private subtle: SubtleCrypto;
    constructor()
    {
        this.subtle = window.crypto.subtle;
    }

    public static base64ToArrayBuffer(b64BinaryData:string) : ArrayBuffer 
    {
        const binaryData = window.atob(b64BinaryData);
        //let binaryDataResponse = Buffer.from(b64BinaryData, "base64");
        // let binaryData : ArrayBuffer = await binaryDataResponse.arrayBuffer()
        // return binaryDataResponse.buffer;

        
        const len = binaryData.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryData.charCodeAt(i);
        }
        return bytes.buffer;
    }
}

@Injectable({
    providedIn: 'root'
  })
  export class OrbiBrowserStorageService 
  {
    
    private cbGithubLoaders : any[] = [];
    private storageKeyInfo : any | undefined = undefined;    
    private cryptoVals : any | undefined = undefined;        
    
    private NotEncryptedPropKeys : any = {
        [OrbiBrowserStorageItemId.StorageVersionFormat] : true,
        [OrbiBrowserStorageItemId.SecureStorageEnabled] : true,
    };
    constructor() 
    {}

    public async setUserPrefsFromProfileApi(userPrefs:any) : Promise<boolean> {
        let isComplete = false;
        if (userPrefs && userPrefs.storageKeyInfo) {
            this.storageKeyInfo = userPrefs.storageKeyInfo;
            let keyAlgorithm : KeyAlgorithm = { name:'AES-GCM'};
            let keyData = await CryptoWrapper.base64ToArrayBuffer(this.storageKeyInfo.keyVal);
            let keyUsages : KeyUsage[] = ['encrypt', 'decrypt'] ;
            let cryptoKey = await crypto.subtle.importKey("raw", keyData, keyAlgorithm, false, keyUsages);
            this.cryptoVals = {
                cryptoKey : cryptoKey,
                cryptoIv : await CryptoWrapper.base64ToArrayBuffer(this.storageKeyInfo.ivVal)
            }

            isComplete = true;

            // check current state
            let localStorageState : any = {};
            for (let itemId of OrbiBrowserStorageItemId_all) {
                    let curr = await this.readItem(itemId); 
                    localStorageState[itemId] = curr;                
            }
            
            const expectedSsSetting = environment.enablesecurestorage;
            let myStorageFormatVersion = await this.readItem(OrbiBrowserStorageItemId.StorageVersionFormat);
            let mySecureStorageSetting = await this.readItem(OrbiBrowserStorageItemId.SecureStorageEnabled);
            if (undefined != myStorageFormatVersion && undefined != mySecureStorageSetting) 
            {                
                let versionMajor = myStorageFormatVersion.major;
                let versionMinor = myStorageFormatVersion.minor;
                if (mySecureStorageSetting.ss1 != expectedSsSetting || versionMajor != ExpectedStorageFormatVersionMajor) {
                    OrbiBrowserStorageItemId_all.map(async (itemId:string) => {
                        await this.storeItem(itemId,undefined); 
                    });

                    localStorage.clear();
                }                    
            } else {
                localStorage.clear();
            }
            localStorage.setItem(OrbiBrowserStorageItemId.StorageVersionFormat, JSON.stringify({ major:ExpectedStorageFormatVersionMajor, minor:ExpectedStorageFormatVersionMinor }));
            localStorage.setItem(OrbiBrowserStorageItemId.SecureStorageEnabled, JSON.stringify({ ss1:expectedSsSetting }));
        }

        return isComplete;
    }

    //  ciphertext; ArrayBuffer[254] 0,-33,86...-83,14,101
    public async encryptOrDecrypt(payload:ArrayBuffer, isEncrypt:boolean) : Promise<ArrayBuffer> {

        let output : ArrayBuffer;
        let output2 : ArrayBuffer;
        try {
            let secureStorageEnabled = (environment.enablesecurestorage) ? environment.enablesecurestorage : false;
            if (secureStorageEnabled) {
                if (isEncrypt) {
                    output = await crypto.subtle.encrypt(
                            { name: 'AES-GCM', iv: this.cryptoVals.cryptoIv }, 
                            this.cryptoVals.cryptoKey,
                            payload);
                } else {
                    output = await crypto.subtle.decrypt(
                        { name: 'AES-GCM', iv: this.cryptoVals.cryptoIv }, 
                        this.cryptoVals.cryptoKey,
                        payload);
                }    
            } else {
                output = payload;
            }
        } catch (error) {
            debugger;
            console.warn(error);
            throw error;
        }

        return output;
    }

    public async storeItem(propKey:string,propValue:any) 
    {        
        if (this.storageKeyInfo && this.storageKeyInfo.keyVal && this.storageKeyInfo.ivVal) {
            var encTextToUint8Arr = new TextEncoder(); // always utf-8
            let bufferToEnc = encTextToUint8Arr.encode(JSON.stringify(propValue));
            const  encPayload : ArrayBuffer = await this.encryptOrDecrypt(bufferToEnc, true);
            let uint8ViewencPayload = new Uint8Array(encPayload);
            let encEncPayload : string = window.btoa(String.fromCharCode.apply(null, Array.from(uint8ViewencPayload)));
            let storageItem : OrbiBrowserStorageEncItem =
            {
                encKeyId:this.storageKeyInfo.keyId,
                propKey : propKey,
                b64EncPayload : encEncPayload
            }
            localStorage.setItem(OrbiBrowserStorageItemId.StorageVersionFormat, JSON.stringify({ major:ExpectedStorageFormatVersionMajor, minor:ExpectedStorageFormatVersionMinor }));
            localStorage.setItem(propKey, JSON.stringify(storageItem));
        } else {
            debugger;
        }
    }

    public async readItem(propKey:string) 
    {        
        let r : any = undefined;
        let step:string = 'init'
        if (this.storageKeyInfo && this.storageKeyInfo.keyVal && this.storageKeyInfo.ivVal) {
            let myJsonStorageItem : string | null= localStorage.getItem(propKey);
            if ("string" == typeof myJsonStorageItem)
            {
                // var txtEncoder = new TextEncoder(); 
                let txtDecoder = new TextDecoder(); 
                try {
                    step = 'parseItem';
                    let myStorageItem : OrbiBrowserStorageEncItem = JSON.parse(myJsonStorageItem);
                    if (true != this.NotEncryptedPropKeys[propKey])
                    {
                        step = 'before parse envelope';
                        let strEncodedEncryptedPayload = myStorageItem.b64EncPayload;
                        if ("string" == typeof strEncodedEncryptedPayload && 0 < strEncodedEncryptedPayload.length) {
                            step = 'before b64 decode';
                            let uintEncryptedPayload :ArrayBuffer = CryptoWrapper.base64ToArrayBuffer(strEncodedEncryptedPayload);
                            if (uintEncryptedPayload && 0 < uintEncryptedPayload.byteLength) {
                                step = 'before decrypt';
                                const uintPayload : ArrayBuffer = await this.encryptOrDecrypt(uintEncryptedPayload, false);            
                                if (uintPayload && 0 < uintPayload.byteLength) {
                                    step = 'before b64 decode 2';
                                    let strPayload = txtDecoder.decode(uintPayload);
                                    step = 'before json parse decrypt payload';
                                    r = JSON.parse(strPayload);
                                } else {
                                    console.warn(`storage readItem; propKey: ${propKey}; step: ${step}; decrypt empty`);
                                    r = undefined;
                                }
                            }
                            else {
                                console.warn(`storage readItem; propKey: ${propKey}; step: ${step}; b64 decode empty`);
                                r = undefined;
                            }
                        } else {
                            console.warn(`storage readItem; propKey: ${propKey}; step: ${step}; missing or empty payload`);
                            r = undefined;
                        }
                    } 
                    else 
                    {
                        step = 'returning unencrypted item';
                        r = myStorageItem;
                    }
                } 
                catch (error) 
                {
                    console.warn(`storage readItem; propKey: ${propKey}; step: ${step}; error: ${error}`);
                }
            }
            else 
            {
                if (undefined != myJsonStorageItem) 
                {
                    console.warn(`storage readItem; propKey: ${propKey}; step: ${step}; error: Unexpected object type ${typeof myJsonStorageItem}`);
                }                
            }
        } 
        else 
        {
            console.warn(`storage readItem; propKey: ${propKey}; step: ${step}; error: Not initialized`);
        }  

        return r;
    }

  }
  