import { Directive, ElementRef, HostListener, Inject, Injectable, Input, LOCALE_ID } from "@angular/core";
import { Router } from "@angular/router";
import { OrbiAppComponentRoutes } from "../app.module";
import { OrbidebugService } from "./orbidebug.service";

export interface OrbiAppComponentState {
    activeTab : string;
}

export interface OrbiAppState {
    componentState : Map<string, OrbiAppComponentState>;
}

/*
@Directive({
  selector: '[orbiDeepLink]'
})
export class OrbiAppStateDeepLinkDirective {
    constructor(
        private router: Router,
        private svcAppState: OrbiAppStateService, 
        private el: ElementRef) {
    }

    @Input('orbiDeepLink')
    public orbiDeepLink: string | undefined;

    @HostListener('click', ['$event']) 
    onClick(e: any) {      
        let [componentId, tabId] = this.orbiDeepLink?.split('/') ?? [];
        if (undefined != componentId && undefined != tabId) {
            this.svcAppState.setNextActiveTab(componentId as keyof typeof OrbiAppComponentRoutes, tabId);
            this.router.navigate([componentId]);
            e.preventDefault();
            e.stopPropagation();
        }
    }
} */

@Injectable({
    providedIn: 'root'
})
export class OrbiAppStateService {
    private _componentDb : Map<keyof typeof OrbiAppComponentRoutes, OrbiAppComponentState> = new Map<keyof typeof  OrbiAppComponentRoutes, OrbiAppComponentState>();
    public registerComponent(componentId : keyof typeof OrbiAppComponentRoutes, tabIds : string[], defaultActiveTab : string) 
    {
        if (undefined == this._componentDb.get(componentId)) {
            this._componentDb.set(componentId, {activeTab: defaultActiveTab});
        }        
    }

    /*
    public setNextActiveTab(componentId : keyof typeof OrbiAppComponentRoutes, tabId : string) {
        let componentState : OrbiAppComponentState | undefined= this._componentDb.get(componentId);
        if (undefined != componentState) {
            componentState.activeTab = tabId;
        } else {
            this._componentDb.set(componentId, {activeTab: tabId});
        }
    }

    public getNextActiveTab(componentId : keyof typeof OrbiAppComponentRoutes) : string | undefined {
        let componentState : OrbiAppComponentState | undefined= this._componentDb.get(componentId);
        return componentState?.activeTab ?? undefined;
    }
    */

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private svcDebug: OrbidebugService)
    {}


}

