import { HttpStatusCode } from '@angular/common/http';
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiMwGhMetric, ApiMwgithubResult, ApiMwgithubTeam, ApiPageStateInterface, ApiPageState_init, EntityMetricRecorderFrequency, EntityMetricRecorderMetricName, EntityMetricRecorderPrincipalType, MetricDataPointBom, MetricDataPointBySomethingType, OrbiFilterState, OrbiFilterStateInterface, OrbiObjectType, OrbiResultsFilterEnum } from '@danclarke2000/gitrospectdto';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';

//@ts-ignore
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { OangNavTabState } from 'src/app/cmn/ui/OanNavTabState';
import { UiLabels } from 'src/app/cmn/ui/UiLabels';
import { OanDebugutils } from 'src/app/cmn/utils/OanDebugutils';
import { OanHttpUtils } from 'src/app/cmn/utils/oanhttputils';
import { OrbiwidgetdateselectorComponent, OrbiwidgetdateselectorDateType, OrbiwidgetdateselectorInThePastTypeThing, OrbiwidgetdateselectorState } from 'src/app/cmn/widget/orbiwidgetdateselector.component';
import { OrbiBrowserStorageItemId, OrbiBrowserStorageService } from 'src/app/svc/orbibrowserstorage.service';
import { OrbidebugService } from 'src/app/svc/orbidebug.service';
import { OrbigithubService } from 'src/app/svc/orbigithub.service';
import { OrbiprofileService } from 'src/app/svc/orbiprofile.service';
import { OrbistartuprequestsL1Interface, OrbistartuprequestsL2Interface, OrbistartuprequestsL3Interface, Orbistartuprequestsservice, OrbistartuprequestsTenantNotReady } from 'src/app/svc/orbistartuprequests.service';
import { GitrospectSignupFlowComponent } from '../gitrospectsignupflow/gitrospectsignupflow.component';
// import { Color, Label } from 'ng2-charts';


enum ShowPanelId {
    Init = "ShowPanelIds.Init",
    AccessibleResources  = "ShowPanelIds.AccessibleResources",
    TenantNotReady  = "ShowPanelIds.TenantNotReady",
    TenantAuthz  = "ShowPanelIds.TenantAuthz",
    TenantImporting  = "ShowPanelIds.TenantImporting",
    Shipshape  = "ShowPanelIds.panelTenantShipshape",
    Dashboard = "ShowPanelIds.Dashboard"
}


enum MetricsTabIds {
    defaultTab = "navitem_synthetic_toplists",
    // navItemSummaryMetrics = "navitem_synthetic_summarymetrics",
    navItemMetricsTopLists = "navitem_synthetic_metricstoplists",
    navItemCodingMetricsByUser = "navitem_synthetic_codingmetricsbyuser",
    navItemCodingMetricsByRepo = "navitem_synthetic_codingmetricsbyrepo",
    navItemReviewingMetrics = "navitem_synthetic_reviewingmetrics",
    navItem2ndOrderMetrics = "navitem_synthetic_2ndordermetrics",
}

let baseMetricsForTopLists =  [EntityMetricRecorderMetricName.PrCreate, EntityMetricRecorderMetricName.PrMerge, EntityMetricRecorderMetricName.CommitPush];
let baseMetricsForCodingMetricsByUser =  [EntityMetricRecorderMetricName.PrCreate, EntityMetricRecorderMetricName.PrMerge, EntityMetricRecorderMetricName.CommitPush];
let baseMetricsForCodingMetricsByRepo =  [EntityMetricRecorderMetricName.PrCreate, EntityMetricRecorderMetricName.PrMerge, EntityMetricRecorderMetricName.CommitPush];
let baseMetricsForReviewingMetrics =  [EntityMetricRecorderMetricName.PrEnrich, EntityMetricRecorderMetricName.PrReview];
let baseMetricsFor2ndOrderMetrics =  [EntityMetricRecorderMetricName.PrOpenLongerThan7Days,EntityMetricRecorderMetricName.PrOpenLongerThan30Days,EntityMetricRecorderMetricName.PrCancelled,EntityMetricRecorderMetricName.IssueCancelled,];
const g_TabWidgets : any = {
    [MetricsTabIds.navItemMetricsTopLists] :  [ ...baseMetricsForTopLists.map((metricName:EntityMetricRecorderMetricName) => ({metricName: metricName, 
                                                                        principalType: EntityMetricRecorderPrincipalType.GhUser})),
                                                ...baseMetricsForTopLists.map((metricName:EntityMetricRecorderMetricName) => ({metricName: metricName, 
                                                                        principalType: EntityMetricRecorderPrincipalType.GhRepo}))],
    [MetricsTabIds.navItemCodingMetricsByUser] : baseMetricsForCodingMetricsByUser.map((metricName:EntityMetricRecorderMetricName) => ({metricName: metricName, 
                                                                        principalType: EntityMetricRecorderPrincipalType.GhUser})),
    [MetricsTabIds.navItemCodingMetricsByRepo] : baseMetricsForCodingMetricsByRepo.map((metricName:EntityMetricRecorderMetricName) => ({metricName: metricName, 
                                                                        principalType: EntityMetricRecorderPrincipalType.GhRepo})),
    [MetricsTabIds.navItemReviewingMetrics] : [ ...baseMetricsForReviewingMetrics.map((metricName:EntityMetricRecorderMetricName) => ({metricName: metricName, 
                                                                            principalType: EntityMetricRecorderPrincipalType.GhUser})),
                                                    ...baseMetricsForReviewingMetrics.map((metricName:EntityMetricRecorderMetricName) => ({metricName: metricName, 
                                                                            principalType: EntityMetricRecorderPrincipalType.GhRepo}))],    
    [MetricsTabIds.navItem2ndOrderMetrics] : baseMetricsFor2ndOrderMetrics.map ((metricName:EntityMetricRecorderMetricName) => ({metricName: metricName, 
        principalType: EntityMetricRecorderPrincipalType.GhRepo}))  
}

export interface SettingsUser 
{
    "id": number,
    "createdAt": string,
    "ghorgname": string,
    "updatedAt": string,
    "orgmemberid": number,
    "orgmemberurl": string,
    "orgmembertype": string,
    "orgmemberlogin": string,
    "orgmembersiteadmin": boolean
};
export interface SettingsTeam 
{
    "id": number,
    "name": string,
    "slug": string,
    "parentId": number,
    "ghorgname": string,
    "orgteamid": number,
    "orgteamurl": string 
};

const enum WidgetType {
    BarChart = "Bar",
};
const MetricNameWidgetMap = {
}

const WidgetDefaults = {
    [WidgetType.BarChart] : {
        core : {
        },
        geometry : {
        },
        generalOptions : {
            animation: false,
            responsive: true,
        },
        datasetOptions : {
            /*
            barThickness: 6,
            maxBarThickness: 8, */
        },
        plugins : {
            legend: {   
                display: false,
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
            }
        },
        specificOptions : {
        },
    }
}

/*
const MetricNameWidgetype : Record<EntityMetricRecorderMetricName,WidgetType> = {
    [EntityMetricRecorderMetricName.PrCreate] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.PrMerge] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.PrEnrich] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.PrReview] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.PrCancelled] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.CommitPush] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.IssueCreate] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.IssueMerge] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.IssueEnrich] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.IssueReview] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.IssueCancelled] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.PrNumCommitByPr] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.PrLifetimeByOrg] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.PrLifetimeByRepo] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.ActionsByRepo] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.ReleasesByRepo] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.PrOpenLongerThan7Days] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.PrOpenLongerThan30Days] : WidgetType.BarChart,
    [EntityMetricRecorderMetricName.BranchesNumActiveByMonth]  : WidgetType.BarChart
};
*/

interface OrbiMetricsFilterInterface {
    selectedTab : string | undefined;
    selectedTeamsIn : string[];
    selectedTeamsOut : string[];
    selectedReposIn : string[];
    selectedReposOut : string[];
    selectedUsersIn : string[];
    selectedUsersOut : string[];
    selectedUsersQfValue : OrbiResultsFilterEnum;
    selectedTeamsQfValue : OrbiResultsFilterEnum;
    selectedReposQfValue : OrbiResultsFilterEnum;
    selectedDateQfValue : OrbiResultsFilterEnum;

    /*
    selectedDateSelectorType : OrbiwidgetdateselectorDateType;
    selectedDateSelectorITPTT : OrbiwidgetdateselectorInThePastTypeThing;
    selectedDateSelectorITPNumThings : number;
    selectedDateFromDate : Date | undefined;
    selectedDateToDate : Date | undefined; */
}

const OrbiMetricsFilterInterface_InitValue : OrbiMetricsFilterInterface = {
    selectedTab : MetricsTabIds.defaultTab,
    selectedTeamsIn : [] as string[],
    selectedTeamsOut : [] as string[],
    selectedReposIn : [] as string[],
    selectedReposOut : [] as string[],
    selectedUsersIn : [] as string[],
    selectedUsersOut : [] as string[],
    selectedUsersQfValue : OrbiResultsFilterEnum.usersall,
    selectedTeamsQfValue : OrbiResultsFilterEnum.teamsall,
    selectedReposQfValue : OrbiResultsFilterEnum.repoall,
    selectedDateQfValue : OrbiResultsFilterEnum.dateweek1,
    /*
    selectedDateSelectorType : OrbiwidgetdateselectorDateType.Invalid,
    selectedDateSelectorITPTT : OrbiwidgetdateselectorInThePastTypeThing.Invalid,
    selectedDateSelectorITPNumThings : 0,
    selectedDateFromDate : undefined,
    selectedDateToDate : undefined */
}

class GithubmetricsComponentTabState // extends OangNavTabState<GithubmetricsComponent> 
{
    private cmpThis : GithubmetricsComponent;
    public activeTab : MetricsTabIds | undefined;

    constructor(cmpThis : GithubmetricsComponent, defaultTab : MetricsTabIds, validTabs : string[], svcDebug: OrbidebugService) 
    {
        this.cmpThis = cmpThis;        
     //   super(validTabs, svcDebug, cmpThis, undefined);
    }

    public onSync($event : any) : void {     }
    public refreshData() : void { }

    public activeTabWidgets : EntityMetricRecorderMetricName[] = [];
    public setActiveTabPreppedData ()  {
        if (this.activeTab && 'string' == typeof this.activeTab && 0 < this.activeTab.length) {
            switch (this.activeTab) {
                case MetricsTabIds.navItemMetricsTopLists:
                    this.cmpThis.uiState.preppedDataForActiveTab = this.cmpThis.uiState.preppedDataTopMetrics[this.cmpThis.uiState.topListsSelector.freqPeriod];
                    break;

                case MetricsTabIds.navItemCodingMetricsByUser:
                    this.cmpThis.uiState.preppedDataForActiveTab = this.cmpThis.uiState.preppedDataSortedUserMetrics;
                    break;

                case MetricsTabIds.navItemCodingMetricsByRepo:
                    this.cmpThis.uiState.preppedDataForActiveTab = this.cmpThis.uiState.preppedDataSortedRepoMetrics;
                    break;

                case MetricsTabIds.navItemReviewingMetrics:
                    debugger; // not implemented
                    this.cmpThis.uiState.preppedDataForActiveTab = this.cmpThis.uiState.preppedDataReviewingMetrics;
                    break;
            }
        }

        if (undefined == this.cmpThis.uiState.preppedDataForActiveTab) {
            this.cmpThis.uiState.preppedDataForActiveTab = {};
        }
    }

    public setActiveTab (strTabId:MetricsTabIds)  {
        let localeId : string = this.cmpThis.locale.split('-')[0];
        if (undefined != strTabId && strTabId != this.activeTab) {                    
            this.activeTab = strTabId;
            this.activeTabWidgets = g_TabWidgets[this.activeTab];
            this.cmpThis.activeUiLabels = this.cmpThis.uiLabels[localeId]?.contentArea[this.activeTab];
            this.cmpThis.updateWidgetDataFiltered();
        }

        this.setActiveTabPreppedData(); 
    } 
}

class Helper {
    public static getPreppedId(startupData : OrbistartuprequestsL3Interface, metricdata : any, jsonId : string) : string {
        let r : string = '';
        if (0 < Object.keys(metricdata.dictionary).length) {
            debugger;
        } else {
            let [ prefix, objid ] = [jsonId.substring(0, 3), jsonId.substring(3)];

            switch (prefix) {
                case 'uid': {
                    let myUser = startupData.aggregates.usersById[objid];
                    r = myUser?.orgmemberlogin;                    
                    break;
                }

                case 'rid': {
                    let myRepo = startupData.aggregates.reposById[objid];
                    r = myRepo?.repoName;
                    break;
                }
            }
        }

        if (undefined == r) {
            debugger;
        } 

        return r;
    }

    public static createdNestedObjectIfNotExist(obj: any, key: string[], initValue : any) {
        let innerObj = obj;
        key.forEach((k:string, i:number) => {
            if (innerObj[k] === undefined) {
                if (i == key.length - 1) {
                    innerObj[k] = initValue;
                } else {
                    innerObj[k] = {};
                }                
            }

            innerObj = innerObj[k];
        });
    }

    public static datediffToEntityMetricRecorderFrequency(dteToday:Date, dteRefDate:Date) {
        let dateDiffMs = dteToday.getTime() - dteRefDate.getTime();
        let dateDiffDays = dateDiffMs / (1000 * 60 * 60 * 24);
        let r : EntityMetricRecorderFrequency | undefined = undefined;
        if (dateDiffDays <= 7) {
            r = EntityMetricRecorderFrequency.Daily;
        } else if (dateDiffDays <= 30) {
            r = EntityMetricRecorderFrequency.Weekly;
        } else if (dateDiffDays <= 365) {
            r = EntityMetricRecorderFrequency.Monthly;
        }
        return r;
    }
}
class GithubmetricsDependenciesLoader
{
    private static ghmetricsRequestState : any = { init:false, teams:false, storage:false };
    public static ghmetricsRequests$ = new BehaviorSubject<any>(GithubmetricsDependenciesLoader.ghmetricsRequestState);
    public static signalSiTodoBien() {
        if (false == Object.keys(GithubmetricsDependenciesLoader.ghmetricsRequestState).some(k => false == GithubmetricsDependenciesLoader.ghmetricsRequestState[k])) {
            GithubmetricsDependenciesLoader.ghmetricsRequests$.next(GithubmetricsDependenciesLoader.ghmetricsRequestState);
        }
    }

    public static async loadDependencies(cmpThis:GithubmetricsComponent,svcGithub:OrbigithubService) : Promise<void>
    {
        /*
        svcGithub.teams$.subscribe({
            next: (val:ApiMwgithubResult) => {
                cmpThis.tabStateMetricFilters.allUserNames = [] as any[],
                cmpThis.tabStateMetricFilters.allTeamNames = [] as any[],
        
                cmpThis._teams = [];
                cmpThis._teamsByMember = [];
                if (val && OanHttpUtils.isHttpSuccess(val.httpStatus) && val.data && Array.isArray(val.data))
                {
                    cmpThis._teams = val.data;
                    val.data.forEach((team:ApiMwgithubTeam) => {
                        if (team.team.teamMembers && Array.isArray(team.team.teamMembers)) {
                            cmpThis._teamsMember[team.team.slug] = team.team.teamMembers.map((member:any) => member.login);
                            team.team.teamMembers.forEach((member:any) => {
                                if (! cmpThis._teamsByMember[member.login]) {
                                    cmpThis._teamsByMember[member.login] = [];
                                }
                                cmpThis._teamsByMember[member.login].push(team.team.slug);
                            });
                        }
                    });

                    cmpThis.tabStateMetricFilters.allUserNames = Object.keys(cmpThis._teamsByMember);
                    cmpThis.tabStateMetricFilters.allTeamNames = cmpThis._teams.map((team:ApiMwgithubTeam) => team.team.slug);

                    setTimeout(async () => {
                        
                }                
            },
            error: (err) => {
                console.log(`Erorr in dashboard teams ${err}`);
            }
        }); 
        
                svcGithub.apiGetTeams(myFilterState, pageState);
                */
        let myQfFilterSavedDateState = await cmpThis.svcStorage.readItem(OrbiBrowserStorageItemId.QfMetricFilterStateDateVars);
        let myQfFilterSavedState = await cmpThis.svcStorage.readItem(OrbiBrowserStorageItemId.QfMetricFilterStateVars);    
        if (myQfFilterSavedState) {
            cmpThis.uiState.qfMetricFilterState_HasSavedState = true;
            cmpThis.uiState.qfMetricFilterState = myQfFilterSavedState;
        } else {
            cmpThis.uiState.qfMetricFilterState_HasSavedState = false;
            cmpThis.uiState.qfMetricFilterState.selectedUsersIn = cmpThis.tabStateMetricFilters.allUserNames;
            cmpThis.uiState.qfMetricFilterState.selectedTeamsIn = cmpThis.tabStateMetricFilters.allTeamNames;   
            cmpThis.uiState.qfMetricFilterState.selectedReposIn = cmpThis.tabStateMetricFilters.allRepoNames;     
        }

        if (myQfFilterSavedDateState) {
            cmpThis.uiState.qfMetricDateFilter = OrbiwidgetdateselectorComponent.getInitValues(cmpThis.locale, cmpThis.calendar, myQfFilterSavedDateState);
            cmpThis.uiState.qfMetricFilterDateState_HasSavedState = true;
        } else {
            cmpThis.uiState.qfMetricFilterDateState_HasSavedState = true;
        }

        if (cmpThis.uiState.qfMetricFilterState && ! cmpThis.uiState.qfMetricFilterState?.selectedTab) {
            cmpThis.uiState.qfMetricFilterState.selectedTab = MetricsTabIds.defaultTab;
        }

        GithubmetricsDependenciesLoader.ghmetricsRequestState.storage = true;
        GithubmetricsDependenciesLoader.signalSiTodoBien();    
    
        GithubmetricsDependenciesLoader.ghmetricsRequestState.init = true;
        GithubmetricsDependenciesLoader.ghmetricsRequestState.teams = true;
        GithubmetricsDependenciesLoader.signalSiTodoBien();

        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(cmpThis.uiState.qfApiFilterState));
        let pageState : ApiPageStateInterface = ApiPageState_init()
        myFilterState.orgName = cmpThis.uiState.orgName;

    }
}

@Component({
  selector: 'app-githubmetrics',
  templateUrl: './githubmetrics.component.html',
  styleUrls: ['./githubmetrics.component.scss']
})
export class GithubmetricsComponent implements OnInit {
    @ViewChild("ngbNavMetricFilter") public ngbNavMetricFilter : any; // Get a reference to the ngbNav
    @ViewChild('tabStateMetricFiltersUsers', { static: false }) public tabStateMetricFiltersUsers : any;
    @ViewChild('tabStateMetricFiltersTeams', { static: false }) public tabStateMetricFiltersTeams : any;
    @ViewChild('tabStateMetricFiltersRepos', { static: false }) public tabStateMetricFiltersRepos : any;
    @ViewChild("tabStateFiltersDates") public tabStateFiltersDates : OrbiwidgetdateselectorComponent | undefined; // Get a reference to the ngbNav

    public readonly navItemMetricsTopLists : MetricsTabIds = MetricsTabIds.navItemMetricsTopLists;
    public readonly navItemCodingMetricsByUser : MetricsTabIds = MetricsTabIds.navItemCodingMetricsByUser;
    public readonly navItemCodingMetricsByRepo : MetricsTabIds = MetricsTabIds.navItemCodingMetricsByRepo;
    public readonly navItemReviewingMetrics : MetricsTabIds = MetricsTabIds.navItemReviewingMetrics;
    public static tabStateNavItems : MetricsTabIds[] = [];
    public static tabStateNavItemShortcuts : Record<string, MetricsTabIds> = {}
        
    public tabState : GithubmetricsComponentTabState;

    private startupRequestsL1State : OrbistartuprequestsL1Interface | undefined;
    private startupRequestsL2State : OrbistartuprequestsL2Interface | undefined;
    private startupRequestsL3State : OrbistartuprequestsL3Interface | undefined;
    public filterModalSignupRef : any = undefined;

    public quickFilterUserSelector : Record<string,string> = {
        [OrbiResultsFilterEnum.usersall] : "All", 
        [OrbiResultsFilterEnum.userscustom] : "By user", 
        [OrbiResultsFilterEnum.teamscustom] : "By team", 
        [OrbiResultsFilterEnum.usersnone]: "None",
    };

    public quickFilterTeamSelector : Record<string,string> = {
        [OrbiResultsFilterEnum.teamsall] : "All", 
        [OrbiResultsFilterEnum.teamsnone]: "None",
    };


    public quickFilterRepoSelector : Record<string,string> = {
        [OrbiResultsFilterEnum.repoall] : "All", 
        [OrbiResultsFilterEnum.repolive]: "Live",
        [OrbiResultsFilterEnum.reposnone]: "None"
    };

    public quickFilterDateSelector : Record<string,string> = {
        [OrbiResultsFilterEnum.dateweek1] : "Last Week", 
        [OrbiResultsFilterEnum.dateweek5]: "Last Month",
        [OrbiResultsFilterEnum.datecustom]: "Custom",
    };

    public quickFilterIcons : Record<string,string> = {
        [OrbiResultsFilterEnum.repoall] : "faFilter", 
        [OrbiResultsFilterEnum.repolive]: "faShieldAlt",
        [OrbiResultsFilterEnum.auditall] : '', 
        [OrbiResultsFilterEnum.auditonlypass]: "faCheck",
        [OrbiResultsFilterEnum.auditonlyfail]: "faExclamationTriangle",
        [OrbiResultsFilterEnum.dateweek1] : "faCheck", 
        [OrbiResultsFilterEnum.dateweek5]: "faExclamationTriangle",
        [OrbiResultsFilterEnum.datecustom]: "faExclamationTriangle",
    };

    public topListsDatePeriod : Record<EntityMetricRecorderFrequency,string> = {
        [EntityMetricRecorderFrequency.Daily] : "Last days", 
        [EntityMetricRecorderFrequency.Weekly]: "Last weeks",
        [EntityMetricRecorderFrequency.Monthly]: "Last months",
    };

    public activeUiLabels = undefined as any;
    public uiLabels = {
        'en' : {
            contentArea : {
                [MetricsTabIds.navItemMetricsTopLists] : {
                    navItemTitle : 'Top Lists',
                    title : 'Top Lists',
                },
                [MetricsTabIds.navItemCodingMetricsByUser] : {
                    navItemTitle : 'Coding metrics by user',
                    title : 'Coding metrics by user',
                },
            }
        }
    } as any;

    public uiState = {
        cmpThis : this,
        orgName : undefined as string | undefined,
        dteSelectionLabel : '',
        qfMetricFilterState_HasSavedState : true,
        qfMetricFilterDateState_HasSavedState : true,
        qfMetricFilterState : OrbiMetricsFilterInterface_InitValue,
        qfApiFilterState : undefined as OrbiFilterStateInterface | undefined,
        qfMetricDateFilter : undefined as unknown as OrbiwidgetdateselectorState, 
        topListsSelector : {
            cutoffPercentage : 100,
            cutoffStep : 20,
            freqPeriod : EntityMetricRecorderFrequency.Weekly,
        },
        principalDevs : {} as any,
        principalRepos : {} as any,
        preppedDataUserMetrics : {} as any,
        preppedDataRepoMetrics : {} as any,
        preppedDataReviewingMetrics : {} as any,
        preppedDataTopMetrics : {} as any,
        preppedDataForActiveTab : {} as any,
        preppedDataSortedRepoMetrics : {} as any,
        preppedDataSortedUserMetrics : {} as any,
        sortNot(a: any, b: any): number { return 0; } ,
        setTabStateMetricFiltersCollapse($event:any, newState:boolean, tag:string) {
            if ($event) {
                $event.stopPropagation();
            }

            this.cmpThis.tabStateMetricFilters.isCollapsed = newState;
        },
        qfMetricFilterStateChangeDebounceTimerId : undefined as any,
        qfMetricFilterStateChange(ctrlId:string|undefined, $event:any|undefined) {
            if (ctrlId) {
                switch(ctrlId) {
                    case 'users': this.qfMetricFilterState.selectedUsersQfValue = OrbiResultsFilterEnum.invalid; break;
                    case 'teams': this.qfMetricFilterState.selectedTeamsQfValue = OrbiResultsFilterEnum.invalid; break;
                    case 'repos': this.qfMetricFilterState.selectedReposQfValue = OrbiResultsFilterEnum.invalid; break;
                    case 'dates': {
                        let dteState : OrbiwidgetdateselectorState = $event; 
                        if (dteState.isValid) 
                        {
                            if (dteState.isDateChange) {
                                this.qfMetricDateFilter.fromDate = dteState.fromDate;
                                this.qfMetricDateFilter.toDate = dteState.toDate;
                            } 
                            
                            this.qfMetricDateFilter.dateLabel = dteState.dateLabel;
                            this.qfMetricDateFilter.quickValue = dteState.quickValue;
                            this.qfMetricDateFilter.dateSelectorType = dteState.dateSelectorType;
                            this.qfMetricDateFilter.inThePastTypeThing = dteState.inThePastTypeThing;
                            this.qfMetricDateFilter.inThePastNumThings = dteState.inThePastNumThings;
                        }
                        break;
                    }
                }
            } else {
                this.qfMetricFilterState.selectedUsersQfValue = OrbiResultsFilterEnum.invalid;
                this.qfMetricFilterState.selectedTeamsQfValue = OrbiResultsFilterEnum.invalid;
                this.qfMetricFilterState.selectedReposQfValue = OrbiResultsFilterEnum.invalid;
            }

            if ($event) {
                if (0 != this.qfMetricFilterStateChangeDebounceTimerId) {
                    clearTimeout(this.qfMetricFilterStateChangeDebounceTimerId);
                }
                this.qfMetricFilterStateChangeDebounceTimerId = setTimeout( () => {
                    this.cmpThis.svcStorage.storeItem(OrbiBrowserStorageItemId.QfMetricFilterStateDateVars, this.qfMetricDateFilter);
                    this.cmpThis.svcStorage.storeItem(OrbiBrowserStorageItemId.QfMetricFilterStateVars, this.qfMetricFilterState);
                    this.qfMetricFilterStateChangeDebounceTimerId = undefined;
                }, 3000);
            }

            // apply change
            this.cmpThis.updateWidgetDataFiltered();
        },
        qfMetricQuickFilterStateChange(ctrlId:string, $event:any|undefined) {
            if ($event) {
                this.qfMetricDateFilter.quickValue = this.qfMetricFilterState.selectedDateQfValue;
                if (0 != this.qfMetricFilterStateChangeDebounceTimerId) {
                    clearTimeout(this.qfMetricFilterStateChangeDebounceTimerId);
                }
                this.qfMetricFilterStateChangeDebounceTimerId = setTimeout( () => {
                    this.cmpThis.svcStorage.storeItem(OrbiBrowserStorageItemId.QfMetricFilterStateDateVars, this.qfMetricDateFilter);
                    this.cmpThis.svcStorage.storeItem(OrbiBrowserStorageItemId.QfMetricFilterStateVars, this.qfMetricFilterState);
                    this.qfMetricFilterStateChangeDebounceTimerId = undefined;
                }, 3000);
            }

            switch (ctrlId) {
                case 'users':
                    switch (this.qfMetricFilterState.selectedUsersQfValue) {
                        case OrbiResultsFilterEnum.usersall:
                            this.setTabStateMetricFiltersCollapse(undefined, true, 'd-flex' );
                            this.qfMetricFilterState.selectedUsersIn = this.cmpThis.tabStateMetricFilters.allUserNames;
                            this.qfMetricFilterState.selectedTeamsIn = this.cmpThis.tabStateMetricFilters.allTeamNames;
                            break;
                        case OrbiResultsFilterEnum.userscustom:
                            this.setTabStateMetricFiltersCollapse(undefined, false, 'd-flex' );
                            this.qfMetricFilterState.selectedTab = this.cmpThis.tabStateMetricFilters.navItemUsers;
                            this.qfMetricFilterState.selectedTeamsIn = [];
                            break;
                        case OrbiResultsFilterEnum.teamscustom:
                            this.setTabStateMetricFiltersCollapse(undefined, false, 'd-flex' );
                            this.qfMetricFilterState.selectedTab = this.cmpThis.tabStateMetricFilters.navItemTeams;
                            this.qfMetricFilterState.selectedUsersIn = [];
                            break;
                        case OrbiResultsFilterEnum.usersnone:
                            this.setTabStateMetricFiltersCollapse(undefined, false, 'd-flex' );
                            this.qfMetricFilterState.selectedUsersIn = [];
                            this.qfMetricFilterState.selectedTeamsIn = [];
                            break;
                        default:
                            debugger;
                            break;
                    }
                    break;

                case 'teams':
                    switch (this.qfMetricFilterState.selectedTeamsQfValue) {
                        case OrbiResultsFilterEnum.teamsall:
                            this.qfMetricFilterState.selectedTeamsIn = this.cmpThis.tabStateMetricFilters.allTeamNames;
                            break;
                        case OrbiResultsFilterEnum.teamsnone:
                            this.qfMetricFilterState.selectedTeamsIn = [];
                            break;
                        default:
                            debugger;
                            break;
                    }
                    break;

                case 'repos':
                    switch (this.qfMetricFilterState.selectedReposQfValue) {
                        case OrbiResultsFilterEnum.repoall:
                            this.qfMetricFilterState.selectedReposIn = this.cmpThis.tabStateMetricFilters.allRepoNames;
                            break;
                        case OrbiResultsFilterEnum.repolive:
                            this.qfMetricFilterState.selectedReposIn = this.cmpThis.tabStateMetricFilters.liveRepoNames;
                            break;
                        case OrbiResultsFilterEnum.reposnone:
                            this.qfMetricFilterState.selectedReposIn = [];
                            break;
                        default:
                            debugger;
                            break;
                    }
                    break;

                case 'dates':
                    if (undefined == this.cmpThis.tabStateFiltersDates) {
                        this.qfMetricDateFilter = OrbiwidgetdateselectorComponent.getInitValues(this.cmpThis.locale, this.cmpThis.calendar, this.qfMetricDateFilter);
                    }      
                    break;
            }
            // apply change
            this.cmpThis.updateWidgetDataFiltered();
        },
    };

    public tabStateMetricFilters = {
        cmpThis : this,
        tabActive : '',
        isCollapsed : true,
        navItemUsers :  "tabStateMetricsFitlersUsers",
        navItemTeams :  "tabStateMetricsFitlersTeams",
        navItemRepos :  "tabStateMetricsFitlersRepos",
        navItemDates :  "tabStateMetricsFitlersDates",
        titleForTabUsers : "Users",
        titleForTabTeams : "Teams",
        titleForTabRepos : "Repos",
        titleForTabDates : "Dates",

        allUserNames : [] as any[],
        allTeamNames : [] as any[],
        allRepoNames : [] as any[],
        liveRepoNames : [] as any[],
        repoTopicMap : {} as any,
        repoTopics : [] as string[],
    }

    public widgetData = [] as any[];

    private getBaseData()
    {
        let activeOrgName = this.svcProfile.getActiveOrgName();

        // download teams data
        /*
        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfApiFilterState));
        let pageState : ApiPageStateInterface = ApiPageState_init()
        myFilterState.orgName = activeOrgName;
*/

        // download metric data for dashboard
        if (activeOrgName) {
            // init objects
            this.uiState.preppedDataRepoMetrics = {} as any;
            this.uiState.preppedDataSortedRepoMetrics  = {} as any;
            this.uiState.preppedDataUserMetrics = {} as any;
            this.uiState.preppedDataSortedUserMetrics    = {} as any;
            this.uiState.preppedDataTopMetrics = {} as any;
            this.uiState.principalDevs = {} as any;
            this.uiState.principalRepos = {} as any;

            // by default get two months data 
            let currMonthRefDate = new Date();
            let currMonthNum = currMonthRefDate.getMonth();
            let strMonthCurr = `${currMonthRefDate.toISOString().slice(0,7)}-01`;
            let strMonthPrev = (currMonthNum > 0) ? `${new Date().getFullYear()}-${('0'+currMonthNum).slice(-2)}-01` : `${new Date().getFullYear()-1}-11-01`;
            [ strMonthCurr, strMonthPrev ].forEach( (strMonth:string) => {
                let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfApiFilterState));
                myFilterState.dateSelector = OrbiResultsFilterEnum.datecustom;
                myFilterState.fromDate = new Date(strMonth);
                myFilterState.orgName = activeOrgName;
                this.svcGithub.apiGetMetricData(activeOrgName!, myFilterState);  
            });             
        }
    }

    public updateWidgetDataFiltered() {
        this.widgetData = [];
        let [fromDate, toDate] = OrbiwidgetdateselectorComponent.getNormalizedValues(this.calendar, this.uiState.qfMetricDateFilter);
        let strFromDate = fromDate.toISOString().split('T')[0];
        let strToDate = toDate.toISOString().split('T')[0];
                    
        let myActiveTab : MetricsTabIds | undefined = this.tabState.activeTab;
        this.tabState.setActiveTabPreppedData();
        if (Array.isArray(this.tabState.activeTabWidgets)) {
            // let filteredMetricKeys = Object.keys(this.uiState.preppedDataForActiveTab).filter((metricName:string)=>this.tabState.activeTabWidgets.includes(metricName as EntityMetricRecorderMetricName));
            // filteredMetricKeys.forEach((metricName:string) => {
            // declare type AxisType = 'DateAxis' | 'ValueAxis' | 'PrincipalAxis';
            const dateAxis = 'DateAxis';
            const valueAxis = 'ValueAxis';
            const principalAxis = 'PrincipalAxis';
            let xAxisType = 'DateAxis';
            let yAxisType = 'ValueAxis';
            let stackedType = 'PrincipalAxis'

            let xAxisLabels = [] as string[];
            switch (xAxisType) {
                case dateAxis: {
                    let dteFromDate = new Date(strFromDate); let dteToDate = new Date(strToDate);
                    let dateDiff = 1 + Math.ceil((dteToDate.getTime() - dteFromDate.getTime()) / (1000*60*60*24));
                    do {
                        xAxisLabels.push(dteFromDate.toISOString().split('T')[0]);
                        dteFromDate.setDate(dteFromDate.getDate() + 1);
                        --dateDiff;
                    } while (dateDiff > 0);
                    break;
                }
                
                case principalAxis:
                    xAxisLabels = this.uiState.principalDevs;
                    break;
            }

            this.tabState.activeTabWidgets.forEach((metricMeta:any) => {
                let widgetType = metricMeta.widgetType as WidgetType ?? WidgetType.BarChart;
                let widgetOptions = WidgetDefaults[widgetType];
                if (metricMeta.metricName && metricMeta.principalType) {
                    let selectedTimeframe = EntityMetricRecorderFrequency.Monthly;
                    if (this.uiState.preppedDataForActiveTab[selectedTimeframe] && this.uiState.preppedDataForActiveTab[selectedTimeframe][metricMeta.metricName as EntityMetricRecorderMetricName]) {
                        let currWidgetData = this.uiState.preppedDataForActiveTab[selectedTimeframe][metricMeta.metricName as EntityMetricRecorderMetricName];

                        let widgetDataItem = {
                            meta : {
                                metricName : metricMeta.metricName,
                                type: metricMeta.principalType
                            },
                            chartOptions : widgetOptions,
                            chartdata : {
                                labels : xAxisLabels,
                                datasets : [] as any[],
                                /*
                                barPercentage: 0.5,
                                barThickness:0.5
                                */
                            }
                        };

                        // ugly cludge for top lists which dont have a refDate 
                        let currWidgetDataSubset = currWidgetData;
                        let avgDataset = {} as { [key:string] : { total : number, num : number, avg : number } };
                        widgetDataItem.chartdata.labels.forEach((label:string) => {
                            avgDataset[label] = { total : 0, num : 0, avg : 0 };
                        });
                        if (! [MetricsTabIds.navItemMetricsTopLists].includes(myActiveTab as MetricsTabIds)) {                            
                            // currWidgetDataSubset = currWidgetData.filter((wdi:any) => wdi.refDate >= strFromDate && wdi.refDate <= strToDate);
                            Object.keys(this.uiState.principalDevs).forEach((devId:string) => {           
                                if (Array.isArray(currWidgetData[devId]) && 0 < currWidgetData[devId].length) {
                                    let devIdData = currWidgetData[devId].filter((wdi:any) => wdi.refDate >= strFromDate && wdi.refDate <= strToDate);
                                    let newDataItemDev = {
                                        data: new Array(widgetDataItem.chartdata.labels.length),
                                        label: devId,
                                        stack: 'a'
                                    };

                                    let devIdIndex = 0;
                                    widgetDataItem.chartdata.labels.forEach((lbl:string,index:number, v:string[]) => {
                                        if (devIdIndex < devIdData.length && devIdData[devIdIndex].refDate < lbl) {
                                            // ffwd to data item in the date range
                                            devIdIndex = devIdData.findIndex((wdi:any) => wdi.refDate >= lbl);
                                        }

                                        if (-1 != devIdIndex && devIdIndex < devIdData.length) {
                                            if (devIdData[devIdIndex].refDate == lbl) {
                                                newDataItemDev.data[index] = devIdData[devIdIndex].data.value;
                                                let myAvgDataItem : { total : number, num : number, avg : number } | undefined = avgDataset[lbl];
                                                if (myAvgDataItem) {
                                                    myAvgDataItem!.total += newDataItemDev.data[index];
                                                    myAvgDataItem!.num++;
                                                } else {
                                                    debugger;
                                                }
                                                
                                                ++devIdIndex;
                                            } else if (devIdData[devIdIndex].refDate > lbl) {
                                                newDataItemDev.data[index] = 0;
                                            }                                              
                                        } else {
                                            newDataItemDev.data[index] = 0;
                                        }
                                    });
                                                                                
                                    if (true === this.uiState.qfMetricFilterState.selectedUsersOut.includes(devId)
                                        || (Array.isArray(this._teamsByMember[devId]) && true == this.uiState.qfMetricFilterState.selectedTeamsOut.some(st => this._teamsByMember[devId].includes(st)))) 
                                    {                        
                                        // is user a selected user or member of selected team
                                        widgetDataItem.chartdata.datasets.push(newDataItemDev);
                                    }                                      
                                }
                            });  
                            
                            // avgDataset
                            let newDataSetAvg = {
                                data: new Array(widgetDataItem.chartdata.labels.length),
                                label: 'Average',
                                stack: 'a',
                                type: 'line'
                            };
                            Object.keys(avgDataset).forEach((lbl:string) => {
                                let myAvgDataItem : { total : number, num : number, avg : number } | undefined = avgDataset[lbl];
                                myAvgDataItem.avg = myAvgDataItem.total / myAvgDataItem.num;
                            });
                            widgetDataItem.chartdata.labels.forEach((lbl:string,index:number, v:string[]) => {
                                let myAvgDataItem : { total : number, num : number, avg : number } | undefined = avgDataset[lbl];
                                if (myAvgDataItem) {
                                    newDataSetAvg.data[index] = myAvgDataItem.avg;
                                } else {
                                    debugger;
                                }
                            });
                            widgetDataItem.chartdata.datasets.push(newDataSetAvg);

                        } else {
                            // top list metric

                            let mappedData = Object.keys(currWidgetDataSubset).map((principalId:string) => 
                                ({ label: principalId, data: currWidgetDataSubset[principalId] }));
                            let sortedData = mappedData.sort((a:any,b:any) => b.data - a.data);
                            let cuttValue = this.uiState.topListsSelector.cutoffPercentage;
                            let trimmedAllData = sortedData;
                            if (cuttValue >= 0 && cuttValue < 100) {
                                let cuttValueIndex = Math.floor(sortedData.length * cuttValue / 100);
                                trimmedAllData = sortedData.slice(0, cuttValueIndex);                                
                            }

                            let wdiDatasets = [{
                                label: 'Top List',
                                data: trimmedAllData.map((wdi:any) => wdi.data)
                            }];
                            widgetDataItem.chartdata.labels = trimmedAllData.map((wdi:any) => wdi.label);
                            widgetDataItem.chartdata.datasets = wdiDatasets;
                        }

                        if (widgetDataItem.chartdata.datasets.length > 0) {
                            this.widgetData.push(widgetDataItem);
                        }
                    }
                } else {
                    console.warn(`No metric data for ${metricMeta.metricName} ${metricMeta.metricType}`);
                }
            });
        } else {
            // no tab widgets defined
            console.warn(`No widgets defined for tab ${myActiveTab}`);
        }
    }

    public _users : SettingsUser[] = [];
    public _teams : SettingsTeam[] = [];
    public _teamsByMember : any = {};
    public _teamsMember : any = {};
    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private router: ActivatedRoute,
        public calendar: NgbCalendar,
        private svcDebug: OrbidebugService,
        private modalService : NgbModal,
        private svcStartuprequests : Orbistartuprequestsservice,
        public svcStorage : OrbiBrowserStorageService,
        public svcProfile : OrbiprofileService, 
        public svcGithub : OrbigithubService 
    ) 
    { 
        GithubmetricsComponent.tabStateNavItems = [ this.navItemMetricsTopLists, this.navItemCodingMetricsByUser, this.navItemCodingMetricsByRepo, this.navItemReviewingMetrics ];
        GithubmetricsComponent.tabStateNavItemShortcuts = {
                // 'summary' : this.navItemSummaryMetrics, 
                'topmetrics':   this.navItemMetricsTopLists,
                'codingmetricsbyuser' : this.navItemCodingMetricsByUser, 
                'codingmetricsbyrepo' : this.navItemCodingMetricsByRepo, 
                'reviewingmetricsbyrepo' : this.navItemReviewingMetrics
        };

        let defaultTab = this.navItemCodingMetricsByUser;
        this.tabState = new GithubmetricsComponentTabState(this, defaultTab, GithubmetricsComponent.tabStateNavItems, this.svcDebug);

        this.svcStartuprequests.startupRequestsL2State$.pipe(
            switchMap( async (startupState:OrbistartuprequestsL2Interface) => {
                if (startupState.isInit) {
                    this.startupRequestsL2State = startupState;
                    this.uiState.orgName = this.svcProfile.getActiveOrgName();
                    this.uiState.qfApiFilterState!.orgName = this.uiState.orgName;
                }
            }
        )).subscribe();

        this.svcStartuprequests.startupRequestsL3State$.pipe(switchMap( async (startupState:OrbistartuprequestsL3Interface) => {
            if (startupState.isInit) {
                this.startupRequestsL3State = startupState;
                this._users = startupState.teamsAndMembers.members;                
                this._teams = startupState.teamsAndMembers.teams;
                this._teamsByMember = [];

                this.tabStateMetricFilters.repoTopics = startupState.repoTopics;
                this.tabStateMetricFilters.repoTopicMap = startupState.repoTopicMap;
                this.tabStateMetricFilters.allRepoNames = startupState.reposummaryList.map((repo:any) => repo.repoName);
                this.tabStateMetricFilters.liveRepoNames = startupState.reposummaryList.filter((repo:any) => repo.isLive).map((repo:any) => repo.repoName);
                this.tabStateMetricFilters.allUserNames = startupState.teamsAndMembers.members.map((member:any) => member.orgmemberlogin);
                this.tabStateMetricFilters.allTeamNames = this._teams.map((team:any) => team.slug);
                /*
                this._teams.forEach((team:ApiMwgithubTeam) => {
                    if (team.team.teamMembers && Array.isArray(team.team.teamMembers)) {
                        cmpThis._teamsMember[team.team.slug] = team.team.teamMembers.map((member:any) => member.login);
                        team.team.teamMembers.forEach((member:any) => {
                            if (! cmpThis._teamsByMember[member.login]) {
                                cmpThis._teamsByMember[member.login] = [];
                            }
                            cmpThis._teamsByMember[member.login].push(team.team.slug);
                        });
                    }
                }); */

                    

                if (false == this.uiState.qfMetricFilterState_HasSavedState) {
                    this.uiState.qfMetricFilterState.selectedReposIn = this.tabStateMetricFilters.allRepoNames;
                }
                this.uiState.qfMetricFilterStateChange(undefined, undefined);
                
                this.router.url.subscribe(() => {
                    let tabId = this.router.snapshot?.params?.tabId;
                    if (tabId && (GithubmetricsComponent.tabStateNavItems[tabId] || GithubmetricsComponent.tabStateNavItemShortcuts[tabId])) {
                        let realTabId = GithubmetricsComponent.tabStateNavItems[tabId] ?? GithubmetricsComponent.tabStateNavItemShortcuts[tabId];
                        this.setActiveTabWrapper(realTabId);
                    } else {
                        this.setActiveTabWrapper(defaultTab);
                    }
                });            

                await GithubmetricsDependenciesLoader.loadDependencies(this, this.svcGithub);
            }
            
            return startupState;
        })).subscribe();        
        
        GithubmetricsDependenciesLoader.ghmetricsRequests$.subscribe({
            next: (val:any) => {
                if (val.init) {
                    this.getBaseData();
                }
            },
            error: (err: any) => {
            }
        });
    }


    ngOnInit(): void {
        this.tabStateMetricFilters.tabActive = this.tabStateMetricFilters.navItemUsers;
        let qfInit : OrbiFilterStateInterface  = {
            locale: this.locale,
            orgName: undefined,
            repositorySelector: OrbiResultsFilterEnum.repoall,
            repositorySelectorCustomValue: undefined,
            auditfocusSelector: OrbiResultsFilterEnum.auditall,
            auditfocusSelectorCustomValue: undefined,
            dateSelector: OrbiResultsFilterEnum.dateweek5,
            fromDate: undefined,
            toDate: undefined,
            extraParamsNameValues: []
        } ;
        this.uiState.qfApiFilterState = qfInit;
        this.uiState.qfMetricDateFilter = OrbiwidgetdateselectorComponent.getInitValues(this.locale, this.calendar, {});

        this.svcGithub.metricdata$.subscribe({
            next: (val:ApiMwgithubResult) => {
                if (val.httpStatus != HttpStatusCode.EarlyHints) 
                {
                    if (val.data && Array.isArray(val.data)) {         
                        let dteToday = new Date();         
                        let metricsToUpdate = { } as any;
                        
                        val.data.forEach((item:ApiMwGhMetric) => {        
                            let topMetrics = this.uiState.preppedDataTopMetrics;
                            
                            if (item.metricdata) {
                                /*
                                switch (item.metric.principalType) {
                                    case EntityMetricRecorderPrincipalType.GhUser:                                    
                                        this.uiState.principalDevs[item.metric.principalId] = true;       
                                        metricsToUpdate = this.uiState.preppedDataUserMetrics;                                                                 
                                        break;
                                    case EntityMetricRecorderPrincipalType.GhRepo:
                                        this.uiState.principalRepos[item.metric.principalId] = true;
                                        metricsToUpdate = this.uiState.preppedDataRepoMetrics;
                                        break;
                                    default:
                                        return;
                                } */

                                let dteRef = new Date(item.metricdata.referenceDate);
                                let strRefDate = item.metricdata.referenceDate.split('T')[0];//?.toISOString().split('T')[0];
                                let dateDiffFreq : EntityMetricRecorderFrequency | undefined = Helper.datediffToEntityMetricRecorderFrequency(dteToday, dteRef);
                                if (! strRefDate || "object" != typeof item.metricdata || isNaN(dteRef.getTime()) || ! dateDiffFreq) { 
                                    return; 
                                }

                                // process dev metrics.. structure is a bit {
                                //  devId: {
                                //      d01: {
                                //            metricName01 : { count, value }
                                //            metricName02 : { count, value }
                                //      d02: {}...
                                // }
                                // 
                                if (item.metricdata.metricDataPointsByDeveloper) {
                                    Object.keys(item.metricdata.metricDataPointsByDeveloper).forEach((devId:string) => {
                                        let preppedId = Helper.getPreppedId(this.startupRequestsL3State!, item.metricdata, devId);                                                
                                        this.uiState.principalDevs[preppedId] = true;       
                                        let metricsToUpdate = this.uiState.preppedDataUserMetrics;   
                                        // this iterates over dayNums; d27
                                        Object.keys(item.metricdata.metricDataPointsByDeveloper[devId]).forEach((dayNum:string) => {
                                            // iterate over metric names
                                            let numDayNum : number = parseInt(dayNum.slice(1));
                                            let dteMdp = new Date(dteRef);
                                            dteMdp.setDate(dteMdp.getDate() + numDayNum);
                                            let strDteMdp = dteMdp.toISOString().split('T')[0];
                                            Object.keys(item.metricdata.metricDataPointsByDeveloper[devId][dayNum]).forEach((metricName:string) => {                                                
                                                let mdpBom : MetricDataPointBom = item.metricdata.metricDataPointsByDeveloper[devId][dayNum][metricName] as MetricDataPointBom;                                                
                                                Helper.createdNestedObjectIfNotExist(metricsToUpdate, [EntityMetricRecorderFrequency.Monthly, metricName, preppedId, strDteMdp], { op:'', count:0,value:0 } as MetricDataPointBom );
                                                metricsToUpdate[EntityMetricRecorderFrequency.Monthly][metricName][preppedId][strDteMdp] = mdpBom;
                                                // metricsToUpdate[EntityMetricRecorderFrequency.Monthly][metricName][devId].count += mdpBom.count;
                                                // metricsToUpdate[EntityMetricRecorderFrequency.Monthly][metricName][devId].value += mdpBom.value;
                                            });                                        
                                        });                                    
                                    });
                                }

                                if (item.metricdata.metricDataPointsByRepo) {
                                    Object.keys(item.metricdata.metricDataPointsByRepo).forEach((repoId:string) => {
                                        let preppedId = Helper.getPreppedId(this.startupRequestsL3State!, item.metricdata, repoId);
                                        this.uiState.principalRepos[preppedId] = true;       
                                        let metricsToUpdate = this.uiState.preppedDataRepoMetrics;   
                                        // this iterates over dayNums; d27
                                        Object.keys(item.metricdata.metricDataPointsByRepo[repoId]).forEach((dayNum:string) => {
                                            // iterate over metric names
                                            let numDayNum : number = parseInt(dayNum.slice(1));
                                            let dteMdp = new Date(strRefDate);
                                            dteMdp.setDate(dteRef.getDate() + numDayNum);
                                            let strDteMdp = dteMdp.toISOString().split('T')[0];
                                            Object.keys(item.metricdata.metricDataPointsByRepo[repoId][dayNum]).forEach((metricName:string) => {
                                                let mdpBom : MetricDataPointBom = item.metricdata.metricDataPointsByRepo[repoId][dayNum][metricName] as MetricDataPointBom;
                                                Helper.createdNestedObjectIfNotExist(metricsToUpdate, [EntityMetricRecorderFrequency.Monthly, metricName, preppedId, strDteMdp], { op:'', count:0,value:0 } as MetricDataPointBom );
                                                metricsToUpdate[EntityMetricRecorderFrequency.Monthly][metricName][preppedId][strDteMdp] = mdpBom;
                                                // metricsToUpdate[EntityMetricRecorderFrequency.Monthly][strRefDate][metricName][repoId].count += mdpBom.count;
                                                // metricsToUpdate[EntityMetricRecorderFrequency.Monthly][strRefDate][metricName][repoId].value += mdpBom.value;
                                            });                                        
                                        });
                                    });
                                }

                                        /*
                                Object.keys(item.metricdata.metric).forEach((key:string) => {
                                    if (Object.values(EntityMetricRecorderMetricName).includes(key as EntityMetricRecorderMetricName)) {
                                        // update core metric
                                        debugger;

                                        Helper.createdNestedObjectIfNotExist(metricsToUpdate, [key, item.metric.principalType, strRefDate, item.metric.principalId], 0);
                                        metricsToUpdate[key][item.metric.principalType][strRefDate][item.metric.principalId] += item.metric.metricdata.metric[key].value;

                                        // update top metric
                                        // let topInitValue = { principalId}                        
                                        Helper.createdNestedObjectIfNotExist(topMetrics, [EntityMetricRecorderFrequency.Daily, key, item.metric.principalType, item.metric.principalId], 0);
                                        Helper.createdNestedObjectIfNotExist(topMetrics, [EntityMetricRecorderFrequency.Weekly, key, item.metric.principalType, item.metric.principalId], 0);
                                        Helper.createdNestedObjectIfNotExist(topMetrics, [EntityMetricRecorderFrequency.Monthly, key, item.metric.principalType, item.metric.principalId], 0);
                                        switch (dateDiffFreq) {
                                            //@ts-ignore - we want this fallthrough so daily data included in weekly data but not vice-versa
                                            case EntityMetricRecorderFrequency.Daily:
                                                topMetrics[EntityMetricRecorderFrequency.Daily][key][item.metric.principalType][item.metric.principalId] += item.metric.metricdata.metric[key].value;
                                            
                                            //@ts-ignore - we want this fallthrough so daily data included in weekly data but not vice-versa
                                            case EntityMetricRecorderFrequency.Weekly:
                                                topMetrics[EntityMetricRecorderFrequency.Weekly][key][item.metric.principalType][item.metric.principalId] += item.metric.metricdata.metric[key].value;

                                            case EntityMetricRecorderFrequency.Monthly:
                                                topMetrics[EntityMetricRecorderFrequency.Weekly][key][item.metric.principalType][item.metric.principalId] += item.metric.metricdata.metric[key].value;
                                        }                                 
                                    }
                                });*/    
                            } else {
                                topMetrics[EntityMetricRecorderFrequency.Daily] = {};
                                topMetrics[EntityMetricRecorderFrequency.Weekly] = {};
                                topMetrics[EntityMetricRecorderFrequency.Monthly] = {};
                            }
                        });

                        // sort the data by strRefDate
                        [ { raw: this.uiState.preppedDataUserMetrics, sorted: this.uiState.preppedDataSortedUserMetrics }, 
                            { raw: this.uiState.preppedDataRepoMetrics, sorted: this.uiState.preppedDataSortedRepoMetrics }  ].forEach((item:any) => { 
                            Object.keys(item.raw).forEach((metricFreqKey:string) => {
                                // let metricFreqKeyEnum :EntityMetricRecorderFrequency = metricFreqKey;
                                Object.keys(item.raw[metricFreqKey]).forEach((metricNameKey:string) => {
                                    let timeFrame = metricFreqKey; // EntityMetricRecorderFrequency.Monthly;
                                    if (! item.sorted[timeFrame]) { item.sorted[timeFrame] = {}; }
                                    if (! item.sorted[timeFrame][metricNameKey]) { item.sorted[timeFrame][metricNameKey] = {}; }
                                    Object.keys(item.raw[metricFreqKey][metricNameKey]).forEach((principalId:string) => {
                                        item.sorted[timeFrame][metricNameKey][principalId] = Object.keys(item.raw[metricFreqKey][metricNameKey][principalId])
                                            .map((currRefDate:string) => ( { refDate : currRefDate, data : item.raw[metricFreqKey][metricNameKey][principalId][currRefDate] } ))
                                            .sort((a:any, b:any) => a.refDate > b.refDate ? 1 : -1);
                                                                                    
                                        /*
                                        item.sorted[metricNameKey][principalId] = { refDate: currRefDate, }; 
                                        
                                        Object.keys(item.raw[metricFreqKey][metricNameKey][principalId]
                                            .map((currRefDate:string) => ( { refDate : currRefDate, data : item.raw[metricFreqKey][metricNameKey][principalId][currRefDate] } ))
                                            .sort((a:any, b:any) => a.refDate > b.refDate ? 1 : -1);
                                            ) */
                                    });
                                });


                                /*
                                Object.keys(item.raw[metricNameKey]).forEach((principalTypeKey:string) => {
                                    item.sorted[metricNameKey][principalTypeKey] = 
                                        Object.keys(item.raw[metricNameKey][principalTypeKey])

                                        ### this is where it's screwed up
                                            .map((currRefDate:string) => ( { refDate : currRefDate, data : item.raw[metricNameKey][principalTypeKey][currRefDate] } ))
                                            .sort((a:any, b:any) => a.refDate > b.refDate ? 1 : -1);
                                }); */
                            });
                        });

                        this.updateWidgetDataFiltered();                        
                    }
                } // if early hints
            }, error: (err:any) => {
                debugger;
            }
        });

        this.router.url.subscribe(() => {
            let tabId = this.router.snapshot?.params?.tabId;
            switch (tabId) {

            }
        });            
    }

    setActiveTabWrapper(tabId: MetricsTabIds) {
        this.tabState.setActiveTab(tabId);
    }
}

/*
/*
    basicData: any;

    multiAxisData: any;

    multiAxisOptions: any;

    lineStylesData: any;

    basicOptions: any; 
    public polardata = {
        labels: [
          'Red',
          'Green',
          'Yellow',
          'Grey',
          'Blue'
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [11, 16, 7, 3, 14],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(75, 192, 192)',
            'rgb(255, 205, 86)',
            'rgb(201, 203, 207)',
            'rgb(54, 162, 235)'
          ]
        }]
      };

    linedata = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "My First dataset",
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1

          // backgroundColor: "#666"
        },
        {
            label: "My Second dataset",
            data: [165, 159, 180, 181, 156, 155, 140],
            fill: false,
            borderColor: 'rgb(192, 192, 00)',
            tension: 0.1

            // backgroundColor: "#666"
          }
      ]
    };
    options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
            display: true,
            text: 'Custom Chart Title'
        }
        }
    };
    */