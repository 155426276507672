import { Component, OnInit } from '@angular/core';
import { OrbiprofileService } from '../svc/orbiprofile.service';
import { NgxSpinnerService } from "ngx-spinner";
import { OangnotificationsErrorId } from 'src/oang/svc/oangnotifications.service';
import { OanDebugutils } from '../cmn/utils/OanDebugutils';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    constructor(
        private spinner: NgxSpinnerService,
        private readonly svcProfile : OrbiprofileService,
    ) 
    {
        this._uiState.cmpThis = this;
        this.uievents.cmpThis = this;
    }

    public _uiState : any = 
    {
        cmpThis : null,
        tenantInfo : undefined,
        userInfo : undefined,
        profileData : undefined,
        teammembers : [],        
        uiFlags : {
            amILoggedIn : false,
            amILoggedInName : "",
            showSideOverlay : false,
            modeedit : false
        },
    };

    public uievents : any = 
    {
        cmpThis : null,
        profileEditClick(ev:any) {
            this.cmpThis._uiState.uiFlags.modeedit = true;
        },
        profileEditSubmit(ev:any) {
            let payload : any = {
                "profiledata" : this.cmpThis._uiState.profileData,
                "projects" : this.cmpThis._uiState.projects,
                "teammembers" : this.cmpThis._uiState.teammembers
            };

            this.cmpThis.svcProfile.apiProfileUpdate(payload).subscribe({            
                complete: () => {
                    console.log("profileEditSubmit.complete");                    
                }, error: (o  : any) => { 
                    const msgNotLoggedIn : string = 'Could not save profile; ' + o.message;
                    this.svcError.addPageAlertError(OangnotificationsErrorId.Error_HttpUnknown, { type: 'danger', msg: msgNotLoggedIn  } );
                    console.log(`profileEditSubmit error - ${JSON.stringify(o)}`); 
                },
                next: (o : any) => {                  
                    // alert("NOT LOGGEDIN !\namIloggedin =false. " + JSON.stringify(o));
                    console.log(`profileEditSubmit ${JSON.stringify(o)}`);
                    this.cmpThis._uiState.uiFlags.modeedit = false;
                }
            });
        }
    };

    ngOnInit(): void {

        this.svcProfile.profiledata$.subscribe({
            complete: () => {
            },
            next: (val: any) => {
                this._uiState.tenantInfo = val.data?.datamap["tenantinfo"];
                this._uiState.userInfo = val.data?.datamap["userinfo"];
                this._uiState.profileData = val.data?.datamap["profiledata"];
                this._uiState.projects = val.data?.datamap["projects"];
                this._uiState.teammembers = val.data?.datamap["teammembers"];
            },
            error: (err:any) => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error(err);
            }
        });
    }
}
