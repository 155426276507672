<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Sign-up {{orgName}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="jumbotron signin-container">
      <div class="row no-gutters">
        <div class="col-12 mx-auto">
            <div class="text-center">
                <h2 class="orbi-section-title">GitHub installation process</h2>
                <p>Below is some explanation on the GitHub installation process.</p>
                <div class="button-holder d-flex justify-content-center">                    
                    <button class="btn btn-cancel mr-2" (click)="onSignupCancel()">Cancel</button>    
                    <button class="btn btn-primary" (click)="onSignup()">Continue Sign-up</button>    
                </div>
                <hr>
                <div class="img-holder">
                    <i>After continue, the GitHub installation screen looks like this.</i>
                    <img alt="Orbitrust logo" src="../assets/img/gitrospectgithubinstall.png">
                </div>
                
                <h3>Sign-Up vs Sign-in</h3>
                <p>Sign-Up equals Installation; Gitrospect will be installed in your GitHub organization, or your Github user account, and construct a view that helps you manage compliance, posture management and so on.</p>
                <p>Sign-In allows you to login and use Gitrospect to see whats going on for organizations where the organization member has already signed up and given you permissions.  You do not need to 'Sign-up' for Gitrospect to Sign-In, Gitrospect uses your GitHub delegation to know which organizations you have permission on.</p>
                <h3>Steps to install</h3>
                <p>The 'continue' button below takes you to GitHub.com, where you confirm the instllation of the Gitrospect application in your organization.</p>
                <p>The Gitrospect application requires readonly permissions as shown opposite in order to be able to do posture management for your GitHub organization, to give you transversal visibility across all the activity, to generate productivity metrics for your organiztaion and to detect breaches of compliance such as change management.  Gitrospect does not store any of your data or any personal data, only the meta data and aggregated metrics which are needed to perform these functions.</p>
                <p>In order to install Gitrospect you should be an adminsitrator in your GitHub organization.  If this is not the case you will have an opportunity on the next page to request an administrative installation.  In this case, you should follow-up with your GitHub administrator internally in your organization to ensure he sees the request.</p>
                <p>If you have any queries on these permissions, or if you would prefer a private installation, then please contact <a href="mailto:info@gitrospect.com">ingo@gitrospect.com</a></p>   
                <hr>         
                <div class="button-holder d-flex justify-content-center mb-2 mt-4">
                    <button class="btn btn-cancel mr-2" (click)="onSignupCancel()">Cancel</button>    
                    <button class="btn btn-primary" (click)="onSignup()">Continue Sign-up</button>
                </div>
                <p><a href="../termsandconditions">Terms and conditions</a> | <a href="../privacypolicy">Privacy policy</a></p>
            </div>
        </div>
      </div>        
  </div>
    
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light" data-dismiss="modal"  (click)="closeModal()">Cancel</button>
</div>