import { BehaviorSubject, EMPTY, Observable, of, PartialObserver, Subject, SubscriptionLike, throwError} from 'rxjs';
import { DecimalPipe} from '@angular/common';
import { catchError, debounceTime, delay, filter, mergeMap, retryWhen, switchMap, tap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { OrbiHttpOptions } from './orbibackend.types';
import { OangnotificationsService } from 'src/oang/svc/oangnotifications.service';
import { OanDebugutils } from '../utils/OanDebugutils';
import { ApiMwgithubResult } from '@danclarke2000/gitrospectdto';

const MaxNumRetries = 3;
const RetriesMsDelay = 50;

export enum  OrbiRequestState {
    New = "OrbiRequestState.New",
    InProgress = "OrbiRequestState.InProgress",
    Closed = "OrbiRequestState.Closed"   
};

export class OrbiRequestBase {   
    public requestId = Math.floor(1000000000 * Math.random());
    private static _requestQueueRetries : any = {};
    private static _requestQueue : OrbiRequestBase[] = [];
    public static _loading$ = new BehaviorSubject<any>(null);
    protected static _httpError$ = new BehaviorSubject<any>(null);
    public static  addRequest(newEl : OrbiRequestBase) : void {
        OrbiRequestBase._requestQueue.push(newEl);
    }

    public static pruneRequestQueue() : void {
        const arr = this._requestQueue.filter(item => item.state !== OrbiRequestState.Closed);
        this._requestQueue = arr;
        var isLoading = (0 != this._requestQueue.length);
        var msg = `Loading ${this._requestQueue.length} requests`;    
        var ctxtString = (0 != this._requestQueue.length) ? this._requestQueue[this._requestQueue.length-1].fnName : "";
    }

    public static getLoadingBs() { return OrbiRequestBase._loading$; }
    public static getLoadingObs() { return OrbiRequestBase._loading$.asObservable(); }

    public static getHttpErrorBs() { return OrbiRequestBase._httpError$; }
    public static getHttpErrorObs() { return OrbiRequestBase._httpError$.asObservable(); }

    public state : OrbiRequestState;
    public errs : any[];
    public retryCount : number;
    constructor(public fnName : string, public httpClient: HttpClient, public svcError : OangnotificationsService, public url : string) {
        this.state = OrbiRequestState.New;
        this.errs = [];
        this.retryCount = 0;
    }
}

export class OrbiRequest<T> extends OrbiRequestBase {
    static readonly PageSize : number = 100;
    static debugOn : boolean = false;
    public resultsobs : BehaviorSubject<T>;

    
    constructor(fnName : string, httpClient: HttpClient, svcError : OangnotificationsService, url : string, resultsObs : BehaviorSubject<T>) {
        super(fnName, httpClient, svcError, url);
        this.resultsobs = resultsObs;
    }

    public get(fnName?:string|undefined) {
        this.state = OrbiRequestState.InProgress;
        var obsHttpEv : Observable<HttpEvent<T>> = this.httpClient.get<T>(this.url, OrbiHttpOptions.getHttpOptions());
        this.subscribeObs(this.fnName, obsHttpEv);
    }

    public subscribeObs(callerFnName: string, obsHttpEv : Observable<any>) {
        var fnName = callerFnName + "/subscribeObs";
        OrbiRequestBase._loading$.next( { status: undefined,  isLoading: true, msg : `Loading...`, url:this.url, ctxt: fnName, reqId: this.requestId } );
        
        let myObjs : PartialObserver<any> = {            
            complete: () => {
                this.state = OrbiRequestState.Closed;                                
                OrbiRequestBase.pruneRequestQueue();
            },
            next: (val: any) => {
                let myCaller = callerFnName;
                this.resultsobs.next(val.body);
                OrbiRequestBase._loading$.next( { status: true, isLoading: false, msg : ``, ctxt: fnName, reqId: this.requestId } );
            },
            error: (err:any) => {
                OrbiRequestBase._loading$.next( { status: false, isLoading: false, msg : JSON.stringify(err), ctxt: fnName, reqId: this.requestId } );
                OanDebugutils.debuggerWrapper(".?.");
                ++this.retryCount;
                this.errs.push(err);
                throwError(err);
            } 
        };
        obsHttpEv.subscribe(myObjs);
    }
}
  
