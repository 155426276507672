import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { OanAnalyzerParams } from 'src/app/cmn/analyzer/OanAnalyzerParams';
import { OrbiRow } from '../orbiresultstablenative/orbiresultstablenative.component';
import { OrbiAuditResultImpact } from '@danclarke2000/gitrospectdto';

export enum OrbiresultsfilterOp
{
    Unknown = "(unknown)",
    SubStr = "OrbiresultsfilterOp.SubStr",
    Match = "OrbiresultsfilterOp.Match",
    Metadata = "OrbiresultsfilterOp.Metadata",
    SubStrMatch = "OrbiresultsfilterOp.SubStrMatch"
}


export const OrbiResultsFilterMetadataFlagsLabels : any  = {};
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.NoImpact] = "No findings";
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.CtrlAccessControl] = "Access control";
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.CtrlAuthorization] = "Authorization";
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.CtrlChangeControl] = "Change control";
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.DomainContinuousDeployment] = "Continuous deployment";
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.TLDRInformative] = "Informative",
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.TLDRIntegrity] = "Integrity",
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.TLDRConfidentiality] = "Confidentiality",
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.TLDRAvailability] = "Availability",
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.TLDRMarketplace] = "Marketplace",
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.CtrlPostureMgmt] = "Posture",
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.DomainPublicInformationLeaks] = "Leaks",
OrbiResultsFilterMetadataFlagsLabels[OrbiAuditResultImpact.DomainContinuousIntegration] = "Continuous integration"

/*
export class OrbiResultsFilterMetadataFlags 
{
    private flagsControls : any = {};
    public getFlagControl(strKey:OrbiResultsFilterMetadataFlagsKeys) {
        return this.flagsControls[strKey];
    }

    public setFlagControl(strKey:OrbiResultsFilterMetadataFlagsKeys) {
        this.flagsControls[strKey] = true;;
    }

    public mergeIncrementalFlagRelevance(rhs:OrbiResultsFilterMetadataFlags)
    {
        // if a glad on rhs is true, we merge it 
        // if we already have a probelm, we dont clear the flag because the problem not present on rhs
        Object.keys(rhs.flagsControls).forEach( (strKey:string) => {
            if (true == rhs.flagsControls[strKey]) {
                this.flagsControls[strKey] = true;
            }
        });
    }
};
*/
export interface OrbiResultsFilterMatchOption
{
    key:string;
    label: string; 
    val:string;
    selected:boolean;
    visible:boolean;
};


@Component({
  selector: 'app-orbiresultsfilter',
  templateUrl: './orbiresultsfilter.component.html',
  styleUrls: ['./orbiresultsfilter.component.scss']
})
export class OrbiresultsfilterComponent implements OnInit {
    OrbiresultsfilterOp = OrbiresultsfilterOp;
    
    @Input() name : string = '(unknown)';
    @Input() colIndex : number = -1;
    // @Input() filterDataType : string = '(unknown)';
    @Input() filterOp : OrbiresultsfilterOp = OrbiresultsfilterOp.Unknown;
    @Input() allowedValues : any = {};
    @Input() allowedObjs : Partial<any> = {};
    @Output() filterFn! : (allObjs : any[]) => any[];
    @Output() filteredEvent = new EventEmitter<any>();
    
    // existentialism    
    public isMatchFilter : boolean = false;
    public metadataFilterAllSelected : boolean = false;
    public matchOptions : OrbiResultsFilterMatchOption[] = []; 
    public substrmatchOptions : OrbiResultsFilterMatchOption[] = []; 
    public metadatamatchOptions : OrbiResultsFilterMatchOption[] = [
        { label: 'AccessControl', key:OrbiAuditResultImpact.CtrlAccessControl, val:'AccessControlVal', selected:true, visible:true  },
        { label: 'Authorization', key:OrbiAuditResultImpact.CtrlAuthorization, val:'AuthorizationVal',selected:true, visible:true  },
        { label: 'ChangeControl', key:OrbiAuditResultImpact.CtrlChangeControl, val:'ChangeControlVal', selected:true, visible:true  },
        { label: 'ContinuousDeployment', key:OrbiAuditResultImpact.DomainContinuousDeployment, val:'ContinuousDeploymentVal', selected:true, visible:true  },
        { label: 'isProdRepo', key:'isProdRepo', val:'isProdRepoVal', selected:false, visible:true  }
    ]; 
    public isSubStrFilter : boolean = false;
    public substrfilter : string = '';
    public substrfilterUpdate = new Subject<string>();
    public matchFilterUpdate = new Subject<any>();
    public substrmatchfilterstringUpdate = new Subject<string>();
    public substrmatchfilteroptionsUpdate = new Subject<any>();
    public metadatamatchfilteroptionsUpdate = new Subject<any>();

    private getIsModeAllowedObjsAsOpposedToValues() {
        let r : boolean = this.allowedObjs &&  0 < Object.keys(this.allowedObjs).length;
        return r;
    }

    constructor(public activeModal: NgbActiveModal) 
    {
        this.substrfilterUpdate.pipe(
            debounceTime(OanAnalyzerParams.DefaultDebounceTime),
            distinctUntilChanged())
            .subscribe(
                {
                    next: (value) => 
                    {
                        let v = {
                            name:"substrfilterchange", 
                            filterValue: value, 
                            filterFn:  (inObjs : any[]) => 
                            {
                                let r : any[] = [];
                                let filterValue = value;
                                r = inObjs.filter( (currObj:any) => {                                    
                                    let colVal : any = currObj.cells[this.colIndex];
                                    if (colVal && colVal.val) 
                                    {
                                        return (-1 != colVal.val.indexOf(filterValue));
                                    }
                                    else
                                    {
                                        console.error(`OrbiresultsfilterComponent.substrfilterchange `);
                                        return false;
                                    }                                        
                                });
                                return r;
                            }
                        }
                        this.filteredEvent.emit(v);
                    },
                    error: (err) => 
                    {
                        console.error(`OrbiresultsfilterComponent.substrfilterchange.error ${err}`);
                    }
                });

        this.matchFilterUpdate.pipe(debounceTime(OanAnalyzerParams.DefaultDebounceTime)).subscribe(
            {
                next: (value) => 
                {
                    let selectedValues : any;
                    let isModeAllowedObjsAsOpposedToVals = this.getIsModeAllowedObjsAsOpposedToValues();  
                    if (isModeAllowedObjsAsOpposedToVals) {
                        selectedValues = this.allowedObjs; 
                    } else {
                        selectedValues = this.matchOptions.filter( r => r.selected ).map( r => r.val );
                    }

                    let v = {
                        name:"matchfilterchange", 
                        filterValue: selectedValues, 
                        filterFn:  (inObjs : any[]) => 
                        {
                            let filterValue = value;
                            let r : any[] = inObjs.filter( (currObj:any) => {                                    
                                let colVal : any = currObj.cells[this.colIndex];
                                if (colVal) 
                                {
                                    let isKept = (-1 != selectedValues.indexOf(colVal.val));
                                    return isKept;
                                }
                                else
                                {
                                    console.error(`OrbiresultsfilterComponent.matchfilterchange `);
                                    return false;
                                }
                                
                            });
                            return r;
                        }
                    }
                    this.filteredEvent.emit(v);
                },
                error: (err) => 
                {
                    console.error(`OrbiresultsfilterComponent.matchfilterchange.error ${err}`);
                }
            });

        this.substrmatchfilterstringUpdate.pipe(
                debounceTime(OanAnalyzerParams.DefaultDebounceTime),
                distinctUntilChanged())
                .subscribe(
                    {
                        next: (value) => 
                        {
                            let isKept = new RegExp(value, 'i');
                            this.substrmatchOptions.forEach( (currObj : any) => {
                                currObj.visible = isKept.test(currObj.label);
                            });     
                        },
                        error: (err) => 
                        {
                            console.error(`OrbiresultsfilterComponent.matchfilterchange.error ${err}`);
                        }
                    });

        this.substrmatchfilteroptionsUpdate.pipe(debounceTime(OanAnalyzerParams.DefaultDebounceTime)).subscribe(
            {
                next: (value) => 
                {
                    let selectedValues = this.substrmatchOptions.filter( r => r.selected ).map( r => r.val );
                    let v = {
                        name:"substrmatchfilterchange", 
                        filterValue: selectedValues, 
                        filterFn:  (inObjs : any[]) => 
                        {
                            let r : any[] = inObjs.filter( (currObj:any) => {                                    
                                let colVal : any = currObj.cells[this.colIndex];
                                if (colVal) 
                                {
                                    let isKept = (-1 != selectedValues.indexOf(colVal.val));
                                    return isKept;
                                }
                                else
                                {
                                    console.error(`OrbiresultsfilterComponent.substrmatchfilteroptionsUpdate `);
                                    return false;
                                }                                
                            });
                            return r;
                        }
                    }
                    this.filteredEvent.emit(v);
                },
                error: (err) => 
                {
                    console.error(`OrbiresultsfilterComponent.matchfilterchange.error ${err}`);
                }
            });

        let metadatamatchOptions = this.metadatamatchOptions;
        this.metadatamatchfilteroptionsUpdate.pipe(debounceTime(OanAnalyzerParams.DefaultDebounceTime)).subscribe(
            {
                next: (cmpThis:OrbiresultsfilterComponent) => 
                {
                    let selectedValues : any;
                    let v = {
                        name:"metadatamatchfilterchange", 
                        filterValue: selectedValues, 
                        filterFn:  (inObjs : any[]) => 
                        {
                            let r : any[] = inObjs.filter( (currObj:OrbiRow) => {                                    
                                let kept = true;
                                let rowFlags : any[] = currObj.impactCol.meta.rowFlags;
                                let selectedRowFlags = cmpThis.metadatamatchOptions.filter(mmo => mmo.selected);
                                let matchAll = cmpThis.metadataFilterAllSelected;
                                if (matchAll) 
                                {
                                    // row is kept if all criteria is true
                                    kept = (0 < selectedRowFlags.length);
                                    selectedRowFlags.forEach( (curr) => {
                                        if (kept) {
                                            let matches = rowFlags.filter(rf => rf.key == curr.key);
                                            kept = 1 == matches.length && matches[0].value;
                                        }
                                    });
                                }
                                else
                                {
                                    // row is kept if any criteria is true
                                    kept = false;
                                    selectedRowFlags.forEach( (curr) => {
                                        if (! kept) {
                                            let matches = rowFlags.filter(rf => rf.key == curr.key);
                                            kept = 1 == matches.length && matches[0].value;
                                        }
                                    });
                                }

                                return kept;
                            });
                            return r;
                        }
                    }
                    this.filteredEvent.emit(v);
                },
                error: (err) => 
                {
                    console.error(`OrbiresultsfilterComponent.matchfilterchange.error ${err}`);
                }
            });
    }

    public onInputChanges() 
    {
        // 
        this.isSubStrFilter = false;
        this.isMatchFilter = false;
        let isModeAllowedObjsAsOpposedToVals = this.getIsModeAllowedObjsAsOpposedToValues();  
        switch (this.filterOp) 
        {
            case OrbiresultsfilterOp.SubStr:
                this.isSubStrFilter = true;
                break;

            case OrbiresultsfilterOp.Match:
                this.isMatchFilter = true;
                this.filterFn = this.matchFilter;
                this.matchOptions = [];
                if (isModeAllowedObjsAsOpposedToVals) {
                    Object.values(this.allowedObjs).forEach( (value : any) => {
                        this.matchOptions.push( { label: value.label, key:'key1', val:value.value, selected:value.selected, visible:true  } );
                    });
                } else {
                    Object.values(this.allowedValues).forEach( (value : any) => {
                        this.matchOptions.push( { label: value, key:'key2', val:value, selected:false, visible:true  } );
                    });
                }
                break;

            case OrbiresultsfilterOp.SubStrMatch:
                this.substrmatchOptions = [];
                Object.values(this.allowedValues).forEach( (value : any) => {
                    this.substrmatchOptions.push( { label: value, key:'key3', val:value, selected:false, visible:true } );
                });                
                break;

            default:
                this.filterFn = this.defaultFilter;
        }
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onInputChanges();
    }    
        
    closeModal() : void {
        this.activeModal.close('Close click')
    }

    defaultFilter(inObjs : any[]) : any[] {
        return inObjs;
    }

    matchFilter(inObjs : any[]) : any[] {
        return [];
    }
}
