import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {APP_BOOTSTRAP_LISTENER, Component, Inject, isDevMode, KeyValueDiffers, OnInit, Pipe, PipeTransform, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {formatDate} from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { OanPluckPipe } from 'src/oang/cmn/OanPipeFilterWithCallback';
import {merge, of, BehaviorSubject, Observable, OperatorFunction, Subject} from 'rxjs';

import { OrbigithubService } from '../svc/orbigithub.service';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';

import { OrbiFilterState, OrbiResultsFilterEnum, ApiMwgithubResult, ApiMwgithubRelease, ApiMwgithubRepo, ApiMwgithubPullrequest, GithubEventTypeCategory, OrbiObjectType, ApiPageStateInterface, ApiMwgithubCommit, ApiMwgithubWebhookevent, ApiMwgithubAccessControl, ApiMwgithubWorkflow, ApiMwgithubWorkflowrun, ApiMwgithubVulnAlert, ApiMwgithubUser, ApiMwgithubTeam, ApiMwgithubEnvironment, ApiMwgithubSecret, ApiPageState_init, ApiAuditResult, OrbiFilterStateInterface, ApiFormCtrlId_Ten_Assets, ApiFormCnst_RepoProdDefType, ApiFormCnst_BranchProdDefType  } from '@danclarke2000/gitrospectdto';                        
import { OrbiSettingGroupName, OrbiSettingNvpName } from '@danclarke2000/gitrospectdto';
import { OangRenderers } from 'src/oang/cmn/OanRenderers';

import { ColDefFilter, GithubColumnDefs } from './githubcolumndefs';
import { UiObj } from 'src/app/cmn/ui/UiObj';

import { OrbisettingsService } from '../svc/orbisettings.service';
import { OangNavTabState, OangNavTabStateActiveContentAreaHeader, OangNavTabStateActiveContentAreaMeta, OangNavTabStateActiveContentAreaMetaMap } from '../cmn/ui/OanNavTabState';
import { HttpEvent, HttpResponse, HttpStatusCode } from '@angular/common/http'; 
import { OanLodashWrap } from '../cmn/utils/oanlodashwrap';
import { OrbidebugService } from '../svc/orbidebug.service';
import { OanDebugutils } from '../cmn/utils/OanDebugutils';
import { OrbiCellValue, OrbiHdr, OrbiRow } from './orbiresultstablenative/orbiresultstablenative.component';
import { OrbiresultsfilterComponent, OrbiresultsfilterOp } from './orbiresultsfilter/orbiresultsfilter.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OanHttpUtils } from '../cmn/utils/oanhttputils';
import { OrbiprofileService } from '../svc/orbiprofile.service';
import { OangnotificationsErrorId, OangnotificationsService } from 'src/oang/svc/oangnotifications.service';
import { OangeventsService } from 'src/oang/svc/oangevents.service';
import { OanAnalyzerParams } from '../cmn/analyzer/OanAnalyzerParams';
import { OrbiBrowserStorageItemId, OrbiBrowserStorageService } from '../svc/orbibrowserstorage.service';
import { OrbistartuprequestsL1Interface, OrbistartuprequestsL2Interface, OrbistartuprequestsL3Interface, Orbistartuprequestsservice } from '../svc/orbistartuprequests.service';
import * as _ from 'lodash';
import { OrbiReportType, UiLabels } from '../cmn/ui/UiLabels';
import { OrbiAuditResultsManagerService } from '../svc/orbiauditresultsmanager.service';
import { OrbiAppComponentRoutes } from '../app.module';
import { ActivatedRoute } from '@angular/router';
import { OrbiwidgetdateselectorComponent, OrbiwidgetdateselectorDateType, OrbiwidgetdateselectorInThePastTypeThing, OrbiwidgetdateselectorState } from '../cmn/widget/orbiwidgetdateselector.component';

const DefaultOrderBy : string = "merged";
/*
const TopicNameDefault : string = "all";
const RepoSetDefault : string = "all";
export const TopicNameAll : string = "all";
export const TopicNameNone : string = "none";
const TopicNames : string[] = [ TopicNameAll, "cicd-aws", "cicd-aws-stg", "used-prod", "terraform" ]; */

enum GithubComponentPseudoView
{
    GithubComponentPseudoView_None,
    GithubComponentPseudoView_onGithubTeam,
    GithubComponentPseudoView_onGithubTeamMembers,
};

class GithubComponentTabState extends OangNavTabState<GithubComponent> 
{
    public pseudoView : GithubComponentPseudoView = GithubComponentPseudoView.GithubComponentPseudoView_None
    private initActiveObjsLastSync(srcData?:any) {
        var r = {
            toDate: '',
            objs : { 
                done : [],
                failed : []
            }
        };

        return r;
    }     
    
    public activeObjsLastSync : any = this.initActiveObjsLastSync();
    public activeObjsDoneCount : number = 0;
    public activeObjsFailedCount : number = 0;
    public lastSync : any = {};
    constructor(svcAuditResultsMgr : OrbiAuditResultsManagerService, validTabs : string[], svcDebug: OrbidebugService, cmpThis : any) 
    {
        super(validTabs, svcDebug, cmpThis, svcAuditResultsMgr);
    }

    public localFilterColDefWithFilter(colDef : any) {
        var r = GithubColumnDefs.isFilterCol(colDef); 
        return r;
    }

    public togleOverview(tabId : string) {
    } 

    public refreshData() : void { 
        if (this.activeTab) {
            // @ts-ignore
            this.cmpThis.uiState.workbookLabelActiveTab = UiLabels.workbookLabelsByTabId[this.activeTab];
            if (! this.cmpThis.uiState.workbookLabelActiveTab) {
                this.cmpThis.uiState.workbookLabelActiveTab = '';
                OanDebugutils.debuggerWrapper(`No label defened in for ${this.activeTab}`);
            }

            this.activeObjsLastSync= this.initActiveObjsLastSync();
            if (this.activeTab in this.lastSync) {
                this.activeObjsLastSync = this.lastSync[this.activeTab];
                
                if (!this.activeObjsLastSync || !this.activeObjsLastSync.errors 
                    || !this.activeObjsLastSync.objsDone || ! Array.isArray(this.activeObjsLastSync.objsDone)) 
                {
                    // OanDebugutils.debuggerWrapper(".?.");
                    console.warn("Unexpected value for " + JSON.stringify(this.activeObjsLastSync));
                } 
                else 
                {
                    this.activeObjsDoneCount = this.activeObjsLastSync.objsDone.length;
                    this.activeObjsFailedCount  = Object.keys(this.activeObjsLastSync.errors).length;
                    if (this.activeObjsLastSync.syncDate || this.activeObjsLastSync.toDate) {
                        let syncDate = (this.activeObjsLastSync.syncDate) ? this.activeObjsLastSync.syncDate : this.activeObjsLastSync.toDate;
                        var dteCmps : string[] = syncDate.split('T');
                        if (1 < dteCmps.length) {
                            this.activeObjsLastSync.toDateFriendlyDate = dteCmps[0];
                            this.activeObjsLastSync.toDateFriendlyTime = dteCmps[1].substr(0, 5);
                        }
                    }
                }
            }
            var uiStateFilter = undefined; // this.cmpThis.tabState.resultsFilter.colFilter.bind(this.cmpThis.tabState.resultsFilter, this.cmpThis.tabState.filterCols);
            switch (this.activeTab) {
                case this.cmpThis.navItemWebhookEventsOrganization: {        
                    let objsToFilter =       this.cmpThis._webhookeventsorg;
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.targetObject");
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break; 
                }

                case this.cmpThis.navItemWebhookEventsSourceCode: {
                    let objsToFilter =       this.cmpThis._webhookeventssourcecode;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.targetObject");
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break;
                }
                
                case this.cmpThis.navItemWebhookEventsProjMgmt: {
                    let objsToFilter =       this.cmpThis._webhookeventsprogmgmt;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.targetObject");
                    this.setObjsAndFilter(objsToFilter, objsToFilter);
                    break;
                }

                case this.cmpThis.navItemWebhookEventsCiCd: {
                    let objsToFilter =       this.cmpThis._webhookeventscicd;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.targetObject");
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break;
                }

                case this.cmpThis.navItemRepos:        {              
                    let objsToFilter =       this.cmpThis._repos;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.repo.name");            
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break;
                }
                case this.cmpThis.navItemReposPublic: {              
                    let objsToFilter =       this.cmpThis._repospublic;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.repo.name");            
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break;                    
                }

                case this.cmpThis.navItemPrs: {
                    let objsToFilter =       this.cmpThis._prs;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.repository.name");            
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break;   
                }
                case this.cmpThis.navItemCommits: {
                    let objsToFilter =       this.cmpThis._commits;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.patched.repoName");            
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break;                       
                }
                case this.cmpThis.navItemReleaseMgmt: {
                    let objsToFilter =       this.cmpThis._releasemgmts;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.repository.name");
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break;
                }
                case this.cmpThis.navItemUsers:
                    this.setObjsAndFilter(this.cmpThis._users, this.cmpThis._users/*, this.cmpThis.uiState.filterNone, uiStateFilter*/);
                    break;                      
                case this.cmpThis.navItemTeams:
                    this.setObjsAndFilter(this.cmpThis._teams, this.cmpThis._teams/*, this.cmpThis.uiState.filterNone, uiStateFilter*/);
                    break;   
                case this.cmpThis.navItemTeamMembers:
                    this.setObjsAndFilter(this.cmpThis._teamMembers, this.cmpThis._teamMembers/*, this.cmpThis.uiState.filterNone, uiStateFilter*/);
                    break;                                          
                case this.cmpThis.navItemAccessControl: {
                    let objsToFilter =       this.cmpThis._accesscontrols;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.permissionTargetId");
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break;        
                }
                case this.cmpThis.navItemCodeAnalysisVulns:
                    this.setObjsAndFilter(this.cmpThis._codeanalysisvulnalerts, this.cmpThis._codeanalysisvulnalerts/*, this.cmpThis.uiState.filterNone, uiStateFilter*/);
                    break;        
                case this.cmpThis.navItemWorkflows: {
                    let objsToFilter =       this.cmpThis._workflows;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.repoName");
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break;       
                }
                case this.cmpThis.navItemWorkflowRuns:                                                         
                    this.setObjsAndFilter(this.cmpThis._workflowruns, this.cmpThis._workflowruns/*, this.cmpThis.uiState.filterNone, uiStateFilter*/);
                    break; 
                case this.cmpThis.navItemEnvironments:  {
                    let objsToFilter =       this.cmpThis._githubenvironments;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.repoName");
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break; 
                }
                case this.cmpThis.navItemSecrets: {
                    let objsToFilter =       this.cmpThis._githubsecrets;         
                    let objsToSet = this.cmpThis.uiState.qfFilterState.applyFilterToObjects(objsToFilter, "obj.repoName");
                    this.setObjsAndFilter(objsToFilter, objsToSet);
                    break; 
                }
                default:
                    if ("string" == typeof this.activeTab && 0 < this.activeTab.length) {
                        let msg = `refreshData unrecognised tab ${this.activeTab}`;
                        OanDebugutils.debuggerWrapper(msg);
                        console.warn(msg);
                    }
            }
        }
        else 
        {
            // startup state
            this.setObjsAndFilter([], []);
        }
    }

    public someEvent($event? : any) : void {            
        debugger;
    }

    public onSync($event : any) : void {            
        let activeTab = this.cmpThis.tabState.activeTab;
        
        if (this.cmpThis.uiState.qfFilterState.hasValidOrgname())
        {
            switch (activeTab) {            
                case this.cmpThis.navItemWebhookEventsOrganization:
                    this.cmpThis.onGetWebhookEvents($event, activeTab, GithubEventTypeCategory.Organization);
                    break;
                case this.cmpThis.navItemWebhookEventsSourceCode:
                    this.cmpThis.onGetWebhookEvents($event, activeTab, GithubEventTypeCategory.Sourcecode);
                    break;
                case this.cmpThis.navItemWebhookEventsProjMgmt:
                    this.cmpThis.onGetWebhookEvents($event, activeTab, GithubEventTypeCategory.Projmgmt);
                    break;
                case this.cmpThis.navItemWebhookEventsCiCd:
                    this.cmpThis.onGetWebhookEvents($event, activeTab, GithubEventTypeCategory.CiCd);
                    break;                
                case this.cmpThis.navItemRepos:
                case this.cmpThis.navItemReposPublic:
                    this.cmpThis.onGetRepos($event, activeTab);                  
                    break;
                /*
                    this.cmpThis.onReposPublic($event, activeTab);
                    break;*/
                case this.cmpThis.navItemPrs:
                    this.cmpThis.onGetPrs($event, activeTab);
                    break;  
                case this.cmpThis.navItemCommits:
                    this.cmpThis.onGetCommits($event, activeTab);
                    break;  
                case this.cmpThis.navItemCodeAnalysisVulns:
                    this.cmpThis.onGetVulns($event, activeTab);
                    break;                                       
                case this.cmpThis.navItemReleaseMgmt:
                    this.cmpThis.onGetReleases($event, activeTab);
                    break;                           
                case this.cmpThis.navItemUsers:
                    this.cmpThis.onGetUser($event, undefined);
                    break;                      
                case this.cmpThis.navItemTeams:
                    this.cmpThis.onGetTeam($event, undefined);
                    break;        
                case this.cmpThis.navItemTeamMembers:
                    this.cmpThis.onGetTeamMembers($event, undefined);
                    break;                                      
                case this.cmpThis.navItemAccessControl:
                    this.cmpThis.onGetAccessControl($event, undefined);
                    break;                   
                    /*
                case this.cmpThis.navItemSettings:
                    this.cmpThis.onSettings($event);
                    break;                            */
                case this.cmpThis.navItemWorkflows:                                                         
                    this.cmpThis.onGetWorkflows($event, undefined);
                    break;    
                case this.cmpThis.navItemWorkflowRuns:                                                         
                    this.cmpThis.onGetWorkflowRuns($event, undefined);
                    break;     
                case this.cmpThis.navItemEnvironments:                                                         
                    this.cmpThis.onGithubEnvironments($event, undefined);
                    break;   
                case this.cmpThis.navItemSecrets: 
                    this.cmpThis.onGithubSecrets($event, undefined);
                    break;   
                default:
                    if ("string" == typeof activeTab && 0 < activeTab.length) {
                        let msg = `onSync unrecognised tab ${activeTab}`;
                        OanDebugutils.debuggerWrapper(msg);
                        console.warn(msg);
                    }
            }
        }
    }
}; 


@Component({
  selector: 'app-github',
  templateUrl: './github.component.html',
  styleUrls: ['./github.component.scss']
})
export class GithubComponent implements OnInit {
   
    GithubColumnDefs = GithubColumnDefs;
    healthcheckgithubcontroller$: Observable<ApiMwgithubResult>;
    reposcached$: Observable<ApiMwgithubResult>;
    accesscontrols$: Observable<ApiMwgithubResult>;
    codeanalysisvulnalerts$: Observable<ApiMwgithubResult>;
    reposexport$: Observable<string>;
    pullrequestscached$: Observable<ApiMwgithubResult>;
    commitscached$: Observable<ApiMwgithubResult>;    
    releasemgmtscached$: Observable<ApiMwgithubResult>;
    workflowscached$: Observable<ApiMwgithubResult>;
    workflowrunscached$: Observable<ApiMwgithubResult>;
    users$: Observable<ApiMwgithubResult>;
    teams$: Observable<ApiMwgithubResult>;
    githubsettingsGet$: Observable<ApiMwgithubResult>;    
    webhookevents$: Observable<ApiMwgithubResult>;
    auditresults$: Observable<ApiMwgithubResult>;
    githubenvironments$: Observable<ApiMwgithubResult>;
    githubsecrets$: Observable<ApiMwgithubResult>;

    _repos : UiObj<ApiMwgithubRepo>[] = [];
    _repospublic : UiObj<ApiMwgithubRepo>[] = [];
    _branchprotections : UiObj<any>[] = [];
    _accesscontrols : UiObj<any>[] = [];
    _codeanalysisvulnalerts : UiObj<any>[] = [];
    _prs : UiObj<ApiMwgithubPullrequest>[] = [];
    _commits : UiObj<any>[] = [];
    _releasemgmts : UiObj<any>[] = [];
    _workflows :  UiObj<any>[] = [];
    _workflowruns : UiObj<any>[] = [];
    _users : UiObj<any>[] = [];
    _teams : UiObj<any>[] = [];
    _teamMembers : UiObj<any>[] = [];
    // _settings? : UiObj<OrbiSettingDTO> = new UiObj<OrbiSettingDTO>(0, "0", undefined, OrbiReportType.OrbiSetting);
    // _tenantSettings : any[] = [];
    _tenantSettingsImportSync : any[] = [];
    _webhookeventsorg : any[] = [];
    _webhookeventssourcecode : any[] = [];
    _webhookeventsprogmgmt : any[] = [];
    _webhookeventscicd : any[] = [];
    _githubenvironments:any[] = [];
    _githubsecrets:any[] = [];
    _auditresults : any[] = [];

    public uiStateQfFilterStateChange(cmpThis:GithubComponent, radioName:string|undefined, $event:any|undefined) {
        let radioChanges : string[] = (radioName) ? [ radioName ] : [ 'repositorySelector', "auditfocusSelector", "qfFilterStateRadioDateSelector" ]  ; 
        let isValidChange : boolean = false;
        let isValidChangeDate : boolean = false;
        radioChanges.forEach((currRadioName:string) => {
            switch (currRadioName)
            {
                case 'init': {
                    isValidChange = true;
                    break;
                }
                case 'repositorySelector': {
                    isValidChange = true;
                    if ($event && this.uiState.qfFilterState.stateVars.repositorySelector != OrbiResultsFilterEnum.repoall) {
                        let toastMsg = `In settings, the configuration tells the app what 'live' means in your organiation.`;
                        if (this.uiState.explainStrCfgLiveRepos || this.uiState.explainStrCfgLiveBranches) {
                            if (this.uiState.explainStrCfgLiveRepos && this.uiState.explainStrCfgLiveBranches) {
                                toastMsg = `The configuration is that ${this.uiState.explainStrCfgLiveRepos} and ${this.uiState.explainStrCfgLiveBranches}.  ${toastMsg}` ;
                            } else if (this.uiState.explainStrCfgLiveRepos) {
                                toastMsg = `The configuration is that ${this.uiState.explainStrCfgLiveRepos}.  ${toastMsg}`;
                            } else {
                                toastMsg = `The configuration is that all repos are live, and ${this.uiState.explainStrCfgLiveBranches}.  ${toastMsg}`;
                            }
                        }

                        this.svcNotifier.showToastInfo(OangnotificationsErrorId.Info_FilterShowAllVsLiveChange, 
                            toastMsg, {
                                deepLinkTarget: OrbiAppComponentRoutes.TenantSettings +  `/${OrbiSettingGroupName.GithubLiveProductionAssetsRepos}`,
                                deepLinkText: `Settings | Live repos`
                            }, false, 0);
                    }

                    let detailPanelIsCollapsed = ($event != OrbiResultsFilterEnum.repocustom);
                    this.uiState.setTabStateGlobalFiltersCollapse(undefined, detailPanelIsCollapsed, 'togglebtn')
                    if (! detailPanelIsCollapsed) {
                        this.tabStateGlobalFilters.tabActive = this.tabStateGlobalFilters.navItemRepo;
                    }

                    if (Array.isArray(this.tabStateGlobalFilters.repoNamesForReport)) {                            
                        let isAll = ($event == OrbiResultsFilterEnum.repoall);
                        this.tabStateGlobalFilters.repoNamesForReport = this.tabStateGlobalFilters.repoNamesForReport.filter((currRepo:any) => isAll || currRepo.isLive);
                    } else {
                        this.tabStateGlobalFilters.repoNamesForReport = [];
                    }
                    this.tabStateGlobalFilters.repoNamesForReportForSelectReposWidget = this.tabStateGlobalFilters.repoNamesForReport.map((currRepo:any) => currRepo.repoName);
                    break;
                }

                case "auditfocusSelector": {
                    isValidChange = true;
                    if (this.orbiresultstablenative) {
                        this.orbiresultstablenative.statusFilter = this.uiState?.qfFilterState?.stateVars;
                        this.orbiresultstablenative.applyFilter();
                    }
                    break;
                }

                case "qfFilterStateRadioDateSelector":
                    // the component might not be loaded so we need to trigger the change here
                    if (OrbiResultsFilterEnum.datecustom == cmpThis.uiState.qfFilterState.stateVarsDate.quickValue) {
                        this.tabStateGlobalFilters.tabActive = this.tabStateGlobalFilters.navItemDate;
                        cmpThis.uiState.setTabStateGlobalFiltersCollapse(undefined, false, 'togglebtn')
                    } else {
                        cmpThis.uiState.qfFilterState.stateVarsDate = OrbiwidgetdateselectorComponent.getInitValues(cmpThis.locale, cmpThis.calendar, cmpThis.uiState.qfFilterState.stateVarsDate);
                    }
                    break;

                case "dates": {
                    let dteState : OrbiwidgetdateselectorState = $event;                         
                    if (dteState.isValid)
                    {
                        isValidChangeDate = true;
                        if (dteState.isDateChange) {
                            cmpThis.uiState.qfFilterState.stateVarsDate.fromDate = dteState.fromDate;
                            cmpThis.uiState.qfFilterState.stateVarsDate.toDate = dteState.toDate;
                        } 
                        
                        cmpThis.uiState.qfFilterState.stateVarsDate.dateLabel = dteState.dateLabel;
                        cmpThis.uiState.qfFilterState.stateVarsDate.quickValue = dteState.quickValue;
                        cmpThis.uiState.qfFilterState.stateVarsDate.dateSelectorType = dteState.dateSelectorType;
                        cmpThis.uiState.qfFilterState.stateVarsDate.inThePastTypeThing = dteState.inThePastTypeThing;
                        cmpThis.uiState.qfFilterState.stateVarsDate.inThePastNumThings = dteState.inThePastNumThings;
                        if (undefined == this.tabStateFiltersDates) {
                            this.uiState.qfFilterState.stateVarsDate = OrbiwidgetdateselectorComponent.getInitValues(this.locale, this.calendar, this.uiState.qfFilterState.stateVarsDate);
                        }
                    }
                    break;
                }

                default:
                    console.warn("qfFilterStateChange: unknown radioName: " + currRadioName);
                    OanDebugutils.debuggerWrapper(".?.");
            }

            if (isValidChange) {
                this.svcStorage.storeItem(OrbiBrowserStorageItemId.QfFilterStateVars, this.uiState.qfFilterState.stateVars);                    
            } 

            if (isValidChangeDate) {
                this.svcStorage.storeItem(OrbiBrowserStorageItemId.QfFilterStateVarsDate, cmpThis.uiState.qfFilterState.stateVarsDate);                    
            } 
        });

        this.tabState.refreshData();
    }

    public uiState : any = {    
        cmpThis : undefined,
        qfFilterState : undefined as OrbiFilterState | undefined,
        repoSelectionDesc : "",
        workbookLabelActiveTab : '',
        explainStrCfgLiveRepos : '',
        explainStrCfgLiveBranches : '',
        importhistory : undefined,
        activeTabPageState : {
            hasPagination : false,
            paginationHasMoreData : true,
            previousPage : 1,
            currentPage : 1,
            pageSize : 100,
            collectionSize : 0,
            tabId : '',
        },    
        resultsTableMeta : {
        },
        updateResultsTableMeta : ($event:any) => {
            this.uiState.resultsTableMeta = $event;
        },
        init (cmpThis : GithubComponent, locale:string) {
            this.cmpThis = cmpThis;
            this.qfFilterState = new class extends OrbiFilterState {
                private reposummaryMap : any = {};
                public stateVarsDate : OrbiwidgetdateselectorState | undefined;

                constructor(locale:string, private tabStateGlobalFilters:any) {
                    super(locale);                    
                }

                setRepoSummaryList(reposumaryList:any[]) {
                    this.reposummaryMap = {};
                    reposumaryList.forEach((reposummary:any) => {
                        this.reposummaryMap[reposummary.repoName] = reposummary;
                    });
                }

                applyFilterToObjects(objects: any[], pathToAttr: string): any[] {
                    let r : any[] = [];
                    switch (this.stateVars.repositorySelector)
                    {
                        case OrbiResultsFilterEnum.repoall:
                            r = objects;
                            break;

                        case OrbiResultsFilterEnum.repocustom:
                            r = objects.filter((obj:any) => this.tabStateGlobalFilters.repoNamesSelectedOut.includes(obj?.obj?.repoContainer.repoName));
                            break;

                        default:
                            r = objects.filter((obj:any) => obj?.obj?.synthetic.isLive);                            
                            break;
                    }

                    return r;
                }                
            }(locale, this.cmpThis.tabStateGlobalFilters);

            cmpThis.uiState.qfFilterState.stateVarsDate = OrbiwidgetdateselectorComponent.getInitValues(cmpThis.locale, cmpThis.calendar, cmpThis.uiState.qfFilterState.stateVarsDate);
        },
        paginationGetActiveTabPageState() {
            let activeTabPageState = this.activeTabPageState;
            return activeTabPageState;
        },
        paginationPageChange() {
            if (this.cmpThis.tabState && this.activeTabPageState) {                 
                let myPagestate : ApiPageStateInterface = this.cmpThis.tabState.getTabPagination(this.activeTabPageState.tabId);
                myPagestate.currentPage = this.activeTabPageState.currentPage;
                // this.cmpThis.tabState.onPageChange(myPagestate);
            }
        },
        debugger(arg:any, val?:unknown) {
            OanDebugutils.debuggerWrapper(".?.");

            var r  = val;
            if (undefined === r) {
                r = true;
            }

            return r;
        },

        isDevMode() {
            var r = isDevMode();
            return r;
        },

        setTabStateGlobalFiltersCollapse($event:any, newState:boolean | undefined, tag:string) {
            if ($event) {
                $event.stopPropagation();
            }

            if (undefined === newState) {
                let currActiveTab = this.cmpThis.tabStateGlobalFilters.tabActive;
                if (currActiveTab != tag) {
                    newState = true;
                } else {
                    newState = !this.cmpThis.tabStateGlobalFilters.isCollapsed;
                }
            } 
                
            this.cmpThis.tabStateGlobalFilters.isCollapsed = newState;
        },

        interpolateLoDash(o : any, type:string, path : string, transform? : string, args? : any[], hardError? : boolean) {
            var r = OanLodashWrap.interpolateLoDash(this, o, type, path, transform, args, hardError);
            return r;
        },

        interpolate(o : any, cd : any, hardError? : boolean) : string {            
            var val= this.interpolateLoDash(o, cd.val.obj, cd.val.path, cd.val.transform, cd.val.args, hardError, cd.val.transform);
            return val;
        },
                
        contentModalClose() { 
            this.strReposExport = "";
        },
        
        chooseColumns() {
            /*
            let tblHdrs : OrbiHdr[] = this.cmpThis.orbiresultstablenative.renderedTableHeaders;
            this.cmpThis.filterModalRef = this.cmpThis.modalService.open(OrbiresultsfilterComponent);
            // this.cmpThis.filterModalRef.componentInstance.filterDataType = currHdr.filterDataType;
            this.cmpThis.filterModalRef.componentInstance.filterOp = OrbiresultsfilterOp.Match;
            this.cmpThis.filterModalRef.componentInstance.colIndex = 1;
            this.cmpThis.filterModalRef.componentInstance.name = "Table columns";
            this.cmpThis.filterModalRef.componentInstance.allowedValues = undefined;
            
            this.cmpThis.filterModalRef.componentInstance.allowedObjs = tblHdrs.map( (tblHdr) => { 
                return { label: tblHdr.title, value: tblHdr.title, selected: tblHdr.colVisible } 
            } ); 
            this.cmpThis.filterModalRef.componentInstance.filteredEvent
                .subscribe({
                    next: (value: any) => {
                        let x = value;
//                        this.cmpThis.filterFn = value.filterFn;
  //                      this.cmpThis.renderedFilteredTableData = this.cmpThis.filterFn(this.cmpThis.renderedTableData)
                    },
                    error: (err:any) => {
                        console.error(`chooseColumns: ${err}`);
                    }
                });

            this.cmpThis.filterModalRef.componentInstance.onInputChanges();
            */ 
        },

        strReposExport : ''
    }; // end uiState

    public tabStateGlobalFilters = {
        cmpThis : null as any,
        tabActive : '',
        isCollapsed : true,
        globalFilterActive : "tabStateGlobalFitlersOrg",
        navItemOrg :  "tabStateGlobalFitlersOrg",
        navItemRepo :  "tabStateGlobalFitlersRepo",
        navItemDate :  "tabStateGlobalFitlersDate",

        titleForTabOrg : "Orgname",
        titleForTabRepo : "Repository",
        titleForTabDate : "Date",

        filterNotApplied : {} as any, 
        
        repoNamesForReport : [ ] as any[] ,
        repoNamesForReportForSelectReposWidget  : [ ] as string[] ,
        repoNamesSelectedIn : [ ] as string[] ,
        repoNamesSelectedOut : [ ] as string[] ,
        repoTopicMap : {} as any,
        repoTopics : [] as string[],
        repoTopicsSelectedIn : [] as string[],
        repoTopicsSelectedOut : [] as string[],
        // repoTopicsFavourites : [ ] as string[],
        // repoSets : [ ] as string[] ,
        // repoTopicsFixedFavourites : [] as string[],
        // repoLiveProductionAssets : {} as any,
        // repoBranchLiveProductionAssets : {} as any,
        orderBy : [ DefaultOrderBy ],

        init (cmpThis : any) {
            this.cmpThis = cmpThis;
            this.filterNotApplied[this.cmpThis.navItemWebhookEvents] = true;
            this.filterNotApplied[this.cmpThis.navItemRepos] = true;
            this.filterNotApplied[this.cmpThis.navItemReposPublic] = true;
            this.filterNotApplied[this.cmpThis.navItemPrs] = true;
            this.filterNotApplied[this.cmpThis.navItemCommits] = true;
            this.filterNotApplied[this.cmpThis.navItemReleaseMgmt] = true;
            this.filterNotApplied[this.cmpThis.navItemWorkflows] = true;
            this.filterNotApplied[this.cmpThis.navItemWorkflowRuns] = true;
            this.filterNotApplied[this.cmpThis.navItemUsers] = true;
            this.filterNotApplied[this.cmpThis.navItemTeams] = true;
            this.filterNotApplied[this.cmpThis.navItemTeamMembers] = true;
            this.filterNotApplied[this.cmpThis.navItemAccessControl] = true;
            this.filterNotApplied[this.cmpThis.navItemAccessControlDeployKeys] = true;
            this.filterNotApplied[this.cmpThis.navItemCodeAnalysisVulns] = true;
            this.filterNotApplied[this.cmpThis.navItemEnvironments] = true;
            this.filterNotApplied[this.cmpThis.navItemSettings] = true;

            this.cmpThis.model.selectedTopic = undefined;
            this.cmpThis.model.selectedRepoSet = undefined;
        },

        clearDate(dteAttr: string ) {
            // @ts-ignore
            this.cmpThis.model[dteAttr] = null;
        },

        getFilterNotApplied(tabId : string) {
            // not sure what this is supposed to achieve but it's stopping refresh of a view and there's a bug 
            // that makes you reload the page if a network error occurs 
            let r = this.filterNotApplied[tabId]; 
            r = true;
            return r;
        },        

        setFilterNotApplied(tabId : string, newVal : boolean) {
            this.filterNotApplied[tabId] = newVal;
        },        

        setFilterNotAppliedAll(newVal : boolean) {
            var keys = Object.keys(this.filterNotApplied);
            keys.forEach(k => this.filterNotApplied[k] = newVal);
        },

        /*
        clickBtnRepoTopic(ev:any,obj:any) {
            this.cmpThis.model.selectedTopic = obj;
            this.setFilterNotAppliedAll(true);
            this.cmpThis.uiState.qfFilterState.stateVars.repositorySelector = OrbiResultsFilterEnum.repotopic;
            this.cmpThis.uiState.qfFilterState.stateVars.repositorySelectorCustomValue = ev;
            this.cmpThis.onGetRepos(undefined, this.cmpThis.navItemRepos);
        },

        clickBtnRepoSet(ev:any,obj:any) {
            this.cmpThis.model.selectedRepoSet = obj;
            this.setFilterNotAppliedAll(true);
            this.cmpThis.uiState.qfFilterState.stateVars.repositorySelector = OrbiResultsFilterEnum.reposet;
            this.cmpThis.uiState.qfFilterState.stateVars.repositorySelectorCustomValue = ev;
            this.cmpThis.onGetRepos(undefined, this.cmpThis.navItemRepos);
        }, */

        removeSelectedRepo(repoName:string) {
            this.cmpThis.tabStateFilterRepositories?.removeSelection(repoName);
        },

        onChangeSelectedRepos(tag:string, ev:any) {
            let additionalTopicRepos : string[] = [];
            let targetValue = ev?.target?.value ?? ev;
            switch (tag) {
                case 'tabStateFilterRepoTopics': {
                    if (Array.isArray(targetValue)) {
                        this.cmpThis.model.selectedRepoSet = targetValue;
                        this.cmpThis.tabStateGlobalFilters.repoTopicsSelectedOut = targetValue;
                        let additionalTopicReposArrayArray : string[][] = targetValue.map( (val:string) => { return this.cmpThis.tabStateGlobalFilters.repoTopicMap[val]; } );
                        additionalTopicRepos = additionalTopicReposArrayArray.reduce((acc, val) => acc.concat(val), []);
                        if (Array.isArray(additionalTopicRepos) && 0 < additionalTopicRepos.length) {
                            setTimeout(() => {
                                // we want to update the repo selector widget with the names selected by this topic
                                this.cmpThis.tabStateGlobalFilters.repoNamesSelectedIn = Array.from(new Set(this.cmpThis.tabStateGlobalFilters.repoNamesSelectedIn.concat(additionalTopicRepos)));
                            }, 0);
                        }
                    } else {
                        this.cmpThis.model.selectedRepoSet = [];
                        this.cmpThis.tabStateGlobalFilters.repoTopicsSelectedOut = [];
                    }
                    break;
                }

                case 'tabStateFilterRepositories':
                    if (Array.isArray(targetValue)) {                                            
                        setTimeout(() => {
                            this.cmpThis.tabStateGlobalFilters.repoNamesSelectedOut = targetValue;
                            this.cmpThis.tabStateGlobalFilters.repoTopicsSelectedIn = [];
                            this.cmpThis.uiState.qfFilterState.stateVars.repositorySelector = OrbiResultsFilterEnum.repocustom;
                            this.cmpThis.uiStateQfFilterStateChange(this, 'repositorySelector', undefined);
                        }, 0);
                    }
                    break;
            } // switch

            // update the quick filter
            this.cmpThis.uiStateQfFilterStateChange(this, 'repositorySelector', OrbiResultsFilterEnum.repocustom)

        },

        /*
        deselectRepoTopicFavorite(rt:string) {
            var newFavs = this.repoTopicsFavourites.filter( (val, index) => { return (rt!=val); } );
            this.repoTopicsFavourites = newFavs;
        } */
    };
    
    public static readonly NavItemReposOverview = "navitem_synthetic_overview";
    public static readonly NavItemReposPublic = "navitem_synthetic_repospublic";
    // public static readonly NavItemBranchProtection = "navitem_synthetic_branchprotection";
    public static readonly NavItemAccessControl = "navitem_synthetic_accesscontrol";
    public static readonly NavItemSettings = "navitem_synthetic_settings";
    public static readonly NavItemTeamMembers = "navitem_synthetic_teammember";
    public readonly navItemOverview : string =  GithubComponent.NavItemReposOverview;
    public readonly navItemWebhookEventsOrganization: string =  OrbiReportType.GithubWebhookEventOrg;
    public readonly navItemWebhookEventsSourceCode: string =  OrbiReportType.GithubWebhookEventSourceCode;
    public readonly navItemWebhookEventsProjMgmt: string =  OrbiReportType.GithubWebhookEventProjMgmt;
    public readonly navItemWebhookEventsCiCd: string =  OrbiReportType.GithubWebhookEventCiCd;
    public readonly navItemRepos : string =  OrbiReportType.GithubRepo;
    public readonly navItemReposPublic  : string = GithubComponent.NavItemReposPublic;
    // public readonly navItemBranchProtection : string = GithubComponent.NavItemBranchProtection;
    public readonly navItemPrs : string =  OrbiReportType.GithubPullRequest;
    public readonly navItemCommits : string =  OrbiReportType.GithubCommit;
    public readonly navItemReleaseMgmt : string =  OrbiReportType.GithubReleaseTag;
    public readonly navItemWorkflows : string =  OrbiReportType.GithubWorkflow;
    public readonly navItemWorkflowRuns : string =  OrbiReportType.GithubWorkflowRun;
    public readonly navItemUsers : string = OrbiReportType.GithubUser;
    public readonly navItemTeams : string = OrbiReportType.GithubTeam;
    public readonly navItemTeamMembers : string = GithubComponent.NavItemTeamMembers;
    public readonly navItemAccessControl : string = GithubComponent.NavItemAccessControl;
    public readonly navItemCodeAnalysisVulns : string = OrbiReportType.GithubCodeAnalysisVulns;
    public readonly navItemEnvironments : string = OrbiReportType.GithubEnvironment;
    public readonly navItemSecrets : string = OrbiReportType.GithubSecret;
    public readonly navItemSettings : string = GithubComponent.NavItemSettings;
    public onNavItemActionString : string | undefined = undefined;
   
    public static tabStateNavItemShortcuts : any = {};
    public static tabStateNavItems : string[] = [];
    public tabState : GithubComponentTabState;

    public debugJsonStringify(s : any[]) {
        return JSON.stringify(s);
    }
    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private fb : FormBuilder,
        private router: ActivatedRoute,
        private calendar: NgbCalendar,
        private svcDebug: OrbidebugService,
        private readonly svcProfile : OrbiprofileService,
        public svcSettings : OrbisettingsService, 
        public svcGithub : OrbigithubService, 
        private modalService : NgbModal,
        private svcOangEvents : OangeventsService,
        public svcNotifier : OangnotificationsService,
        public svcStorage : OrbiBrowserStorageService,
        private svcStartuprequests : Orbistartuprequestsservice,
        private svcAuditResultsMgr : OrbiAuditResultsManagerService)
    { 
        this.tabStateGlobalFilters.init(this);
        GithubComponent.tabStateNavItems = [ 
            this.navItemWebhookEventsOrganization, this.navItemWebhookEventsSourceCode, this.navItemWebhookEventsProjMgmt, this.navItemWebhookEventsCiCd,
            this.navItemRepos, this.navItemReposPublic,this.navItemPrs, this.navItemCommits,this.navItemReleaseMgmt, 
            this.navItemWorkflows, this.navItemWorkflowRuns, this.navItemUsers, this.navItemTeams, this.navItemTeamMembers, 
            this.navItemAccessControl, this.navItemEnvironments, this.navItemSecrets, 
            this.navItemCodeAnalysisVulns,
            this.navItemSettings];
        GithubComponent.tabStateNavItemShortcuts = {
            'eventsorgs' : this.navItemWebhookEventsOrganization, 
            'eventssourcecode' : this.navItemWebhookEventsSourceCode, 
            'eventsprojmgmt' : this.navItemWebhookEventsProjMgmt, 
            'eventscicd' : this.navItemWebhookEventsCiCd,
            'repositories' : this.navItemRepos, 
            'publicrepositories' : this.navItemReposPublic,
            'pullrequests' : this.navItemPrs, 
            'commits' : this.navItemCommits,
            'releases' : this.navItemReleaseMgmt, 
            'workflows' : this.navItemWorkflows, 
 //           'workflowruns' : this.navItemWorkflowRuns, 
            'users' : this.navItemUsers, 
            'teams' : this.navItemTeams, 
            'teammembership' : this.navItemTeamMembers, 
            'accesscontrol' : this.navItemAccessControl, 
            'environments' : this.navItemEnvironments, 
            'secrets' : this.navItemSecrets, 
//            'eventssourcecode' : this.navItemCodeAnalysisVulns,
//           'eventssourcecode' : this.navItemSettings
        };
    
        this.tabState = new GithubComponentTabState(this.svcAuditResultsMgr, GithubComponent.tabStateNavItems, this.svcDebug, this);
        GithubColumnDefs.initColDefs(/*this.tabState.resultsFilter*/);
        this.healthcheckgithubcontroller$ = this.svcGithub.healthcheck$;
        this.reposcached$ = this.svcGithub.reposcached$;
        this.accesscontrols$ = this.svcGithub.repoaccesssettings$; 
        this.reposexport$  = this.svcGithub.reposexport$;
        this.codeanalysisvulnalerts$ = this.svcGithub.codeanalysisvulnalerts$;
        this.pullrequestscached$ = this.svcGithub.pullrequestscached$;
        this.commitscached$ = this.svcGithub.commitscached$;        
        this.releasemgmtscached$ = this.svcGithub.releasemgmtscached$;
        this.workflowscached$ = this.svcGithub.workflowscached$;
        this.workflowrunscached$  = this.svcGithub.workflowrunscached$;
        this.users$ = this.svcGithub.users$;
        this.teams$ = this.svcGithub.teams$;
        this.githubsettingsGet$ = this.svcSettings.githubsettingsGet$;
        this.webhookevents$ = this.svcGithub.webhookevents$;
        this.auditresults$ = this.svcGithub.auditresults$;
        this.githubenvironments$ = this.svcGithub.githubenvironments$;
        this.githubsecrets$ = this.svcGithub.githubsecrets$;
        this.uiState.init(this, locale);        
        this.model.locale = locale;
    }

    public model : any = { 
        auditworkbookName: 'AuditWorkbookNameContinuous',
        selectedTopic: undefined,
        selectedRepoSet: undefined,
        orderby: DefaultOrderBy,
        orderbyDir: 'asc' ,
        locale: "",

        init(cmpThis:GithubComponent) {
            this.cmpThis = cmpThis;           
            this.auditworkbookName = "AuditWorkbookContinuous"; // OrbiConstants.AuditWorkbookNameDefault;
        },

        textFilterResultsTypeahead :  (text$: Observable<string>) =>
            text$.pipe(
                debounceTime(500),
                tap ( (x:string) => {
                    this.orbiresultstablenative.textFilterResults = x;
                }),
                map( x => [])
            ),
        

            

        getGithubRepoFilter() : string {        
            console.warn("Implement getGithubRepoFilter");
            let r : string = '';
            /*
            let rsortby : string = ' sort:'+ this.orderby + "-" + this.orderbyDir;

            if (this.cmpThis)
            {
                if (this.selectedTopic == TopicNameAll) {
                    r = 'org:' + this.cmpThis.uiState.qfFilterState.stateVars.orgName + rsortby;
                } else {
                    r = 'org:' + this.cmpThis.uiState.qfFilterState.stateVars.orgName + ' topic:' + this.selectedTopic + rsortby;
                }
            } */
            return r;
        },
    };
    
    @ViewChild("ngbNavGlobalFilter") public navGlobalFilter : any; // Get a reference to the ngbNav
    @ViewChild("tabStateFilterRepositoriesByTopic") public tabStateFilterRepositoriesByTopic : OrbiwidgetdateselectorComponent | undefined; // Get a reference to the ngbNav
    @ViewChild("tabStateFilterRepositories") public tabStateFilterRepositories : OrbiwidgetdateselectorComponent | undefined; // Get a reference to the ngbNav
    @ViewChild("tabStateFiltersDates") public tabStateFiltersDates : OrbiwidgetdateselectorComponent | undefined; // Get a reference to the ngbNav
    @ViewChild('orbiresultstablenative', { static: false }) public orbiresultstablenative : any;
    @ViewChild('contentmodal', { static: false }) private contentmodal : any;
    @ViewChild('fromDatePicker',{static:false}) fromDatePicker : any;
    @ViewChild('toDatePicker',{static:false}) toDatePicker : any ;

    private registerObservers() {
        this.auditresults$.subscribe(
            {
                next : (val: ApiMwgithubResult) => {
                    if (val && OanHttpUtils.isHttpSuccess(val.httpStatus) && val.data && Array.isArray(val.data)) 
                    {
                        // objecttype from server is OrbiObjectType.GithubWebhookCallback
                        let resultContext = val.context; 
                        if (resultContext) {                            
                            let currentlyActiveTab = this.tabState.activeTab;
                            let myAuditResults = this.svcAuditResultsMgr.addAuditResultsWithObjectType(val.context.objectType, val.data);

                            //console.log(`auditresults$ - updating audit data for objectType=${val.context.objectType},importId=${val.context.importId},offset=${val.context.reqPageState.offset} `);
                            this.tabState.onUpdatedData(this.tabState.activeTab, val);

                            // in theory this shouldnt be necessary, but angular change detection (ie ngChangeEvent) doesnt trigger when 
                            // the auditresults object is modified with the tabledata object..  so we force it here
                            this.orbiresultstablenative.triggerRenderAuditResults(val.context.objectType);
                        } else {
                            debugger;
                        }                        
                    }                    
                }, 
                error: (err) => {
                    OanDebugutils.debuggerWrapper(".?.");
                    console.error("repos$:" + JSON.stringify(err));
                }
            });      

        this.webhookevents$.pipe(
            tap((val : any) => {                
                this.tabStateGlobalFilters.setFilterNotApplied(this.tabState.activeTab, false);    
            })).subscribe({
                next : (val: any) => {
                    var itemCount = 0;
                    var pageInfo : any = undefined;
                    itemCount = 0;
                    this._webhookeventssourcecode = [];   
                    this._webhookeventsprogmgmt = []; 
                    this._webhookeventsorg = []; 
                    this._webhookeventscicd = [];      

                    if (val && OanHttpUtils.isHttpSuccess(val.httpStatus) && val.data && Array.isArray(val.data)) 
                    {
                        itemCount = val.data.length;
                        pageInfo = val.pageInfo;
                        val.data.forEach((element : ApiMwgithubWebhookevent) => {
                            let elKey = element.identifier;
                            element.synthetic.isLive = element.webhookevent.isLive;

                            let newObj : UiObj<any> | undefined = undefined;
                            let myReportType : OrbiReportType = OrbiReportType.Unknown;
                            let eventType : GithubEventTypeCategory = (<GithubEventTypeCategory>(element.webhookevent.eventcategory));
                            switch (eventType as GithubEventTypeCategory) 
                            {
                                case GithubEventTypeCategory.Sourcecode:
                                    myReportType = OrbiReportType.GithubWebhookEventSourceCode;
                                    newObj = new UiObj<any>(0, elKey, element.idlogical, element, OrbiObjectType.GithubWebhookEvent, myReportType);
                                    this._webhookeventssourcecode.push(newObj);
                                    break;
                                case GithubEventTypeCategory.Projmgmt:
                                    myReportType = OrbiReportType.GithubWebhookEventProjMgmt;
                                    newObj = new UiObj<any>(0, elKey, element.idlogical, element, OrbiObjectType.GithubWebhookEvent, myReportType);
                                    this._webhookeventsprogmgmt.push(newObj);
                                    break;
                                case GithubEventTypeCategory.Organization:
                                    myReportType = OrbiReportType.GithubWebhookEventOrg;
                                    newObj = new UiObj<any>(0, elKey, element.idlogical, element, OrbiObjectType.GithubWebhookEvent, myReportType);
                                    this._webhookeventsorg.push(newObj);
                                    break;
                                case GithubEventTypeCategory.CiCd:
                                    myReportType = OrbiReportType.GithubWebhookEventCiCd;
                                    newObj = new UiObj<any>(0, elKey, element.idlogical, element, OrbiObjectType.GithubWebhookEvent, myReportType);
                                    this._webhookeventscicd.push(newObj);
                                    break;

                                // handle these toggether                            
                                case GithubEventTypeCategory.Unknown:
                                    // @ts-ignore
                                case GithubEventTypeCategory.Alerts:
                                    console.log( `Unexpected GithubEventTypeCategory ${eventType}` );

                                default:
                                    OanDebugutils.debuggerWrapper("Unexpected GithubEventTypeCategory", eventType);
                            }

                            // select repo by default
                            if (undefined != newObj) {
                                newObj.selected = true;
                            }                        
                        });               

                        this.tabState.onUpdatedData(this.tabState.activeTab, val);     
                        this.onGetAuditResults(OrbiObjectType.GithubWebhookEvent, {});
                    } else {
                        if (val.httpStatus != HttpStatusCode.EarlyHints) {
                            console.error("Unexcepted response audit events");                    
                        }
                    }
                }, 
                error: (err) => {
                    OanDebugutils.debuggerWrapper(".?.");
                    console.error("repos$:" + JSON.stringify(err));
                }
            });        
        this.healthcheckgithubcontroller$.subscribe({
            next: (val:ApiMwgithubResult) => {
                switch (val.httpStatus)
                {
                    case HttpStatusCode.EarlyHints:
                        break;

                    case HttpStatusCode.Ok:
                        break;
                
                    default:
                        this.svcNotifier.addPageAlertError(OangnotificationsErrorId.Error_AppNotAuthorizedForOrg, { type: 'danger', msg: 'User not authorized for github organization' } );
                }
            },
            error: (err:any) => {
                this.svcNotifier.addPageAlertError(OangnotificationsErrorId.Error_AppNotAuthorizedForOrg, { type: 'danger', msg: 'User not authorized for github organization' } );
            },
            complete: () => {
                this.onNavItemActionString = undefined;                
            }
        });
        this.reposcached$.subscribe({
            next: (val:ApiMwgithubResult) => {
                this._repos = [];
                this._repospublic = [];
                this._branchprotections = [];
                if (val && OanHttpUtils.isHttpSuccess(val.httpStatus) && val.data && Array.isArray(val.data)) 
                {
                    let importIds : any = OanHttpUtils.importIdInit(this.uiState.importhistory);
                    this._repos = [];
                    this._repospublic = [];
                    this._branchprotections = [];
                    val.data.forEach((element : ApiMwgithubRepo) => {
                        let elKey = element.identifier;
                        OanHttpUtils.importIdAdd(importIds, element, true);

                        // repo obj
                        {
                            let newObj = new UiObj<ApiMwgithubRepo>(0, elKey, element.idlogical, <ApiMwgithubRepo>element, OrbiObjectType.GithubRepo, OrbiReportType.GithubRepo);
                            // moved to OanAnalyzerGithub.setObjs newObj.calculateEnrichedAttrs();
                            newObj.selected = true;
                            element.synthetic.isLive = element.repo.isLive;
                            this._repos.push(newObj);
                        }

                        // public repo obj
                        {
                            if (false == element.repo.isPrivate)
                            {
                                let newObj = new UiObj<ApiMwgithubRepo>(0, elKey, element.idlogical, <ApiMwgithubRepo>element, OrbiObjectType.GithubRepo, OrbiReportType.GithubRepoPublic);
                                // moved to OanAnalyzerGithub.setObjs newObj.calculateEnrichedAttrs();
                                newObj.selected = true;
                                this._repospublic.push(newObj);
                            }
                        }                       
                    });               
                    
                    // @ts-ignore
                    let liveRepos = this._repos.filter( (repo:UiObj<ApiMwgithubRepo>) => repo.obj.repo.isLive);
                    // @ts-ignore
                    OanAnalyzerParams.OrbiProdRepositories = liveRepos.map( (repo:UiObj<ApiMwgithubRepo>) => repo.obj.repo.name);
                    this.tabState.onUpdatedData(this.tabState.activeTab, val);

                    // get teh audit data                    
                    this.onGetAuditResults(OrbiObjectType.GithubRepo, importIds);

                    // TBD the transmogrification should be done in the serviece and instead of this static variable 
                    // the other class should subscribe to same observer
                    switch (this.onNavItemActionString)
                    {
                        /*
                        case this.navItemBranchProtection:                        
                            this.tabState.onUpdatedData(this.navItemBranchProtection);
                            break; */

                        case this.navItemReposPublic: {       
                            let myPageState = this.tabState.getTabPagination(this.navItemReposPublic);   
                            myPageState.expectedTotal = this._repospublic.length;
                            let publicreposVal : ApiMwgithubResult = {
                                httpStatus : HttpStatusCode.Ok,
                                context: val.context,
                                eTag: undefined,
                                data : this._repospublic,
                                errors : [],
                                pageInfo : {
                                    totalCount : this._repospublic.length,
                                    limit : this._repospublic.length,
                                    offset : 0
                                }
                            }
                            this.tabState.onUpdatedData(this.onNavItemActionString, publicreposVal);
                            break;
                        }

                        default: {
                            let reposVal : ApiMwgithubResult = {
                                httpStatus : HttpStatusCode.Ok,
                                context: val.context,
                                eTag:undefined,
                                data : this._repos,
                                errors : [],
                                pageInfo : {
                                    totalCount : val.pageInfo.totalCount,
                                    limit : this._repos.length,
                                    offset : 0
                                }
                            };
                            this.tabState.onUpdatedData(this.navItemRepos, reposVal);
                        }
                    }
                }
                else if (val && val.httpStatus == HttpStatusCode.EarlyHints)
                {
                    // init stuff
                }
                else {
                    console.error("Unexcepted response repo");                    
                }
            },
            error: (err:any) => {
                console.error("repos$:" + JSON.stringify(err));
            },
            complete: () => {
                this.onNavItemActionString = undefined;
            }
        });
        this.accesscontrols$.pipe(
            tap((val:any) => {
            })).subscribe((val:any)=>{
                var itemCount = 0;
                var pageInfo : any = undefined;
                this._accesscontrols = [];
                if (val)
                {
                    if (val.data && OanHttpUtils.isHttpSuccess(val.httpStatus)) 
                    {
                        let importIds : any = {}
                        let myPageState = this.tabState.getTabPagination(this.navItemAccessControl, val);
                        val.data.forEach( (element : ApiMwgithubAccessControl) => {
                            let elKey = element.identifier;
                            let importId = element.context.lastImportParentId;
                            if (! elKey || ! importId) {
                                debugger;
                                console.error (`element missing id`);
                            } else {
                                importIds[importId] = true;
                            }
                            
                            // syntethic values 
                            let permValues : any = JSON.parse(element.accesscontrol?.permissionValue);
                            if (! element.synthetic) {
                                element.synthetic = {};
                            }
                            element.synthetic.permValues = permValues;
                            element.synthetic.isLive = undefined;


                            var newEl = new UiObj<ApiMwgithubAccessControl>(0, elKey, element.idlogical, element, OrbiObjectType.GithubRepoAccessControl, OrbiReportType.GithubAccessControl);
                            // moved to OanAnalyzerGithub.setObjs newEl.calculateEnrichedAttrs();                           
                            this._accesscontrols.push(newEl);        
                        });                        

                        this.tabState.onUpdatedData(this.navItemAccessControl, val);
                        this.onGetAuditResults(OrbiObjectType.GithubRepoAccessControl, importIds);
                    } 
                    else if (HttpStatusCode.EarlyHints == val.httpStatus)
                    {
                        // init
                    }
                    else 
                    {
                        console.error("Unexcepted response accesscontrols");                    
                    }
                }
                else 
                {
                    console.error("Unexcepted response accesscontrols2");                    
                }
            }, err => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("repopermissions$:" + JSON.stringify(err));
            });       
        this.reposexport$.subscribe( (val : any) => {
            if (val.body) {
                this.uiState.strReposExport = val.body;
            }
        }, err => {
            OanDebugutils.debuggerWrapper(".?.");
            console.error("reposexport$:" + JSON.stringify(err));
        });                    
        this.pullrequestscached$.subscribe({                
            next: (val:ApiMwgithubResult) => {
                this._prs = [];

                if (val)
                {
                    if (val.data && OanHttpUtils.isHttpSuccess(val.httpStatus)) 
                    {
                        let importIds : any = OanHttpUtils.importIdInit(this.uiState.importhistory);
                        let myPageState = this.tabState.getTabPagination(this.navItemPrs, val);
                        val.data.forEach( (element : ApiMwgithubPullrequest) => {
                            let elKey = element.identifier;
                            OanHttpUtils.importIdAdd(importIds, element, true);
                            element.synthetic.isLive = element.pr.isLive;
                            var newEl = new UiObj<ApiMwgithubPullrequest>(0, element.identifier, element.idlogical, element, OrbiObjectType.GithubPrsForRepo, OrbiReportType.GithubPullRequest);
                            this._prs.push(newEl);        
                        });

                        this.tabState.onUpdatedData(this.navItemPrs, val);

                        // get teh audit data                    
                        this.onGetAuditResults(OrbiObjectType.GithubPrsForRepo, importIds);
                    } 
                    else if (HttpStatusCode.EarlyHints == val.httpStatus)
                    {
                        // init
                    }
                    else 
                    {
                        console.error("Unexcepted response prs");                    
                    }
                }
                else 
                {
                    console.error("Unexcepted response prs2");                    
                }
            },
            error: (err:any) => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("pullrequestscached$:" + JSON.stringify(err));
            },
            complete: () => {
            }
        });
        this.commitscached$.subscribe({                
            next: (val:ApiMwgithubResult) => {
                this._commits = [];

                if (val)
                {
                    if (val.data && OanHttpUtils.isHttpSuccess(val.httpStatus)) 
                    {
                        let importIds : any = OanHttpUtils.importIdInit(this.uiState.importhistory);
                        let myPageState = this.tabState.getTabPagination(this.navItemCommits, val);
                        val.data.forEach( (element : ApiMwgithubCommit) => {
                            let elKey = element.identifier;
                            OanHttpUtils.importIdAdd(importIds, element, false);
                            element.synthetic.isLive = element.commit.isLive;
                            var newEl = new UiObj<ApiMwgithubCommit>(0, element.idlogical, element.idlogical, element, OrbiObjectType.GithubCommitsForRepo, OrbiReportType.GithubCommit);
                            // moved to OanAnalyzerGithub.setObjs newEl.calculateEnrichedAttrs();                           
                            this._commits.push(newEl);        
                        });

                        this.tabState.onUpdatedData(this.navItemCommits, val);
                        this.onGetAuditResults(OrbiObjectType.GithubCommitsForRepo, importIds);
                    } 
                    else if (HttpStatusCode.EarlyHints == val.httpStatus)
                    {
                        // init
                    }
                    else 
                    {
                        console.error("Unexcepted response commits");                    
                    }
                }
                else 
                {
                    console.error("Unexcepted response commits2");                    
                }
            },
            error: (err:any) => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("commitscached$:" + JSON.stringify(err));
            },
            complete: () => {
            }
        });        
        this.codeanalysisvulnalerts$.subscribe((val:ApiMwgithubResult)=>{
                     
                this._codeanalysisvulnalerts = [];

                if (val)
                {
                    if (val.data && OanHttpUtils.isHttpSuccess(val.httpStatus)) 
                    {
                        let myPageState = this.tabState.getTabPagination(this.navItemCodeAnalysisVulns, val);
                        val.data.forEach( (element : ApiMwgithubVulnAlert) => {
                                    
                            var newEl = new UiObj<ApiMwgithubVulnAlert>(0, element.identifier, element.idlogical, element, OrbiObjectType.GithubVulnsForRepo, OrbiReportType.GithubCodeAnalysisVulns);
                            // moved to OanAnalyzerGithub.setObjs newEl.calculateEnrichedAttrs();                           
                            this._codeanalysisvulnalerts.push(newEl);        
                        });

                        this.tabState.onUpdatedData(this.navItemCodeAnalysisVulns, val);
                    } 
                    else if (HttpStatusCode.EarlyHints == val.httpStatus)
                    {
                        // init
                    }
                    else 
                    {
                        console.error("Unexcepted response codeanalysisvulnalerts");                    
                    }
                }
                else 
                {
                    console.error("Unexcepted response codeanalysisvulnalerts2");                    
                }
            }, err => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("codeanalysisvulnalerts$:" + JSON.stringify(err));
            });                           
        this.releasemgmtscached$.subscribe({
            next: (val:ApiMwgithubResult) => {
                this._releasemgmts = [];

                if (val)
                {
                    if (val.data && OanHttpUtils.isHttpSuccess(val.httpStatus)) 
                    {
                        let importIds : any = OanHttpUtils.importIdInit(this.uiState.importhistory);
                        let myPageState = this.tabState.getTabPagination(this.navItemReleaseMgmt, val);
                        val.data.forEach( (element : ApiMwgithubRelease) => {
                            let elKey = element.identifier;
                            OanHttpUtils.importIdAdd(importIds, element, false);                        
                            var newEl = new UiObj<ApiMwgithubRelease>(0, elKey, element.idlogical, element, OrbiObjectType.GithubReleaseForRepo, OrbiReportType.GithubReleaseTag);
                            // moved to OanAnalyzerGithub.setObjs newEl.calculateEnrichedAttrs();                           
                            this._releasemgmts.push(newEl);        
                        });
    
                        UiObj.setReleaseMgmts(val.data);
                        this.tabState.onUpdatedData(this.navItemReleaseMgmt, val);
                        this.onGetAuditResults(OrbiObjectType.GithubReleaseForRepo, importIds);
                    } 
                    else if (HttpStatusCode.EarlyHints == val.httpStatus)
                    {
                        // init
                    }
                    else 
                    {
                        console.error("Unexcepted response releasemgmtscached");                    
                    }
                }
                else 
                {
                    console.error("Unexcepted response releasemgmtscached2");                    
                }
            },
            error: (err:any) => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("releasemgmtscached$:" + JSON.stringify(err));
            },
            complete: () => {
            }
        });                
        this.workflowscached$.subscribe({
            next: (val: ApiMwgithubResult) => {
                this._workflows = [];

                if (val)
                {
                    if (val.data && OanHttpUtils.isHttpSuccess(val.httpStatus)) 
                    {
                        let importIds : any = OanHttpUtils.importIdInit(this.uiState.importhistory);
                        let myPageState = this.tabState.getTabPagination(this.navItemWorkflows, val);
                        val.data.forEach( (element : ApiMwgithubWorkflow) => {
                            let elKey = element.identifier;
                            OanHttpUtils.importIdAdd(importIds, element, true);

                            // synthetic attrs
                            element.synthetic = {};
                            element.synthetic.workflowFile = element.workflow.workflowPath.split('/').pop();
                            
                            var newEl = new UiObj<ApiMwgithubWorkflow>(0, elKey, element.idlogical, element, OrbiObjectType.GithubWorkflowsForRepo, OrbiReportType.GithubWorkflow);
                            // moved to OanAnalyzerGithub.setObjs newEl.calculateEnrichedAttrs();                           
                            this._workflows.push(newEl);        
                        });

                        this.tabState.onUpdatedData(this.navItemWorkflows, val);
                        this.onGetAuditResults(OrbiObjectType.GithubWorkflowsForRepo, importIds);
                    } 
                    else if (HttpStatusCode.EarlyHints == val.httpStatus)
                    {
                        // init
                    }
                    else 
                    {
                        console.error("Unexcepted response _workflowruns");                    
                    }
                }
                else 
                {
                    console.error("Unexcepted response _workflowruns2");                    
                }
            },
            error: (err:any) => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("workflows$:" + JSON.stringify(err));
            },
            complete: () => {
            }
        });         
        this.workflowrunscached$.subscribe({
            next: (val:ApiMwgithubResult) => {
                this._workflowruns = [];
                if (val)
                {
                    if (val.data && OanHttpUtils.isHttpSuccess(val.httpStatus)) 
                    {
                        let myPageState = this.tabState.getTabPagination(this.navItemWorkflowRuns, val);
                        this._workflowruns = val.data.map((wfr:ApiMwgithubWorkflowrun)=>{
                            var elId = wfr.identifier;
                            var newEl = new UiObj<ApiMwgithubWorkflowrun>(0, elId, wfr.idlogical, wfr, OrbiObjectType.GithubWorkflowRunsForRepo, OrbiReportType.GithubWorkflowRun);
                            return newEl;
                        });

                        this.tabState.onUpdatedData(this.navItemWorkflowRuns, val);
                    } 
                    else if (HttpStatusCode.EarlyHints == val.httpStatus)
                    {
                        // init
                    }
                    else 
                    {
                        console.error("Unexcepted response _workflowruns");                    
                    }
                }
                else 
                {
                    console.error("Unexcepted response _workflowruns2");                    
                }
            },
            error: (err:any) => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("workflowrunscached$:" + JSON.stringify(err));
            },
            complete: () => {
            }
        });
        this.users$.pipe(
            tap((val : any) => {
            })).subscribe((val: any) => {
                this._users = [];
                if (val)
                {
                    if (OanHttpUtils.isHttpSuccess(val.httpStatus) && Array.isArray(val.data) ) 
                    {
                        let importIds : any = OanHttpUtils.importIdInit(this.uiState.importhistory);
                        let myPageState = this.tabState.getTabPagination(this.navItemUsers, val);
                        val.data.forEach( (element : ApiMwgithubUser) => {
                            let elKey = element.identifier;
                            OanHttpUtils.importIdAdd(importIds, element, true);

                            // synthetic attrs
                            element.synthetic = {};
                            
                            var newEl = new UiObj<ApiMwgithubUser>(0, elKey, element.idlogical, element, OrbiObjectType.GithubUser, OrbiReportType.GithubUser);
                            // moved to OanAnalyzerGithub.setObjs newEl.calculateEnrichedAttrs();                           
                            this._users.push(newEl);    
                        });
                        
                        this.tabState.onUpdatedData(this.navItemUsers, val);
                        this.onGetAuditResults(OrbiObjectType.GithubUser, importIds);
                    } 
                    else if (HttpStatusCode.EarlyHints == val.httpStatus)
                    {
                        // init
                    }
                    else 
                    {
                        console.error("Unexcepted response users");                    
                    }
                }
                else 
                {
                    console.error("Unexcepted response users");
                }
            }, err => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("users$:" + JSON.stringify(err));
            });      
        this.teams$.subscribe({
            next : (val: ApiMwgithubResult) => {
                this._teams = [];
                this._teamMembers = [];
                if (val)
                {
                    if (OanHttpUtils.isHttpSuccess(val.httpStatus) && val.data && Array.isArray(val.data))
                    {
                        let myPageState = this.tabState.getTabPagination(this.navItemTeams, val);
                        //numChildteams : orgName,
                        let teamMap : any = {};
                        val.data.forEach( (tm:ApiMwgithubTeam) => { 
                            if (undefined == tm.synthetic) {
                                tm.synthetic = {};
                            }
                            tm.synthetic.childTeams = []; 
                            tm.synthetic.childMembers = []; 
                            teamMap[tm.team.slug] = tm;
                        });
                        val.data.forEach( (tm:ApiMwgithubTeam) => {
                            if (tm.team.parentSlug) {
                                teamMap[tm.team.parentSlug].synthetic.childTeams.push(tm);
                            }
                        });
    
                        this._teams = val.data.map( (u : ApiMwgithubTeam) => {
                            
                            u.synthetic.numChildteams = teamMap[u.team.slug].synthetic.childTeams.length;
                            let r = new UiObj<ApiMwgithubTeam>(0, u.identifier, u.idlogical, u, OrbiObjectType.GithubTeam, OrbiReportType.GithubTeam);
                            return r;
                        });
    
                        let teamMembers : any[] = [];
                        val.data.forEach( (tm:ApiMwgithubTeam) => {
                            let numMembers = Array.isArray(tm.team.teamMembers) ? tm.team.teamMembers.length : 0;
                            let currRow = {
                                url : tm.team.url,
                                slug : tm.team.slug,
                                name : tm.team.name,                            
                            };
                            if (Array.isArray(tm.team.teamMembers)) {
                                tm.team.teamMembers.forEach( (m:any,i:number) => {
                                    let childUserMember = { 
                                        identifier:`${m.slug}\\User\\${i}`,
                                        idlogical:`${m.slug}\\User\\${i}`,
                                        ... currRow,
                                        memberName : m.login,
                                        memberType : "User",
                                        memberRole : m.role
                                    };
                                    teamMembers.push(childUserMember);
                                });
                            }
                            if (teamMap[tm.team.slug] && Array.isArray(teamMap[tm.team.slug].childTeams)) {
                                let childTeams = teamMap[tm.team.slug].childTeams;
                                childTeams.forEach( (m:any, index:number) => {
                                    let childTeamMember = { 
                                        identifier:`${m.identifier}\\team\\${index}`,
                                        idlogical:`${m.idlogical}\\team\\${index}`,
                                        ... currRow,
                                        memberName : m.slug,
                                        memberType : "Team",
                                        memberRole : m.role
                                    };
                                    teamMembers.push(childTeamMember);
                                });
                            }
                        });
    
                        this._teamMembers = teamMembers.map( (u : any) => new UiObj<any>(0, u.identifier, u.slug, u, OrbiObjectType.GithubTeamMember, OrbiReportType.GithubTeamMember));;
                        let myTeamMembersPageState = this.tabState.getTabPagination(this.navItemTeamMembers);
                        myTeamMembersPageState.expectedTotal = this._teamMembers.length;
                    }
                    else if (HttpStatusCode.EarlyHints == val.httpStatus)
                    {
                        // init
                    }
                    else 
                    {
                        console.error("Unexcepted response teams");
                    }
                }

                switch (this.tabState.pseudoView)
                {
                    case GithubComponentPseudoView.GithubComponentPseudoView_onGithubTeamMembers: {
                        let myVals : ApiMwgithubResult = {
                            httpStatus : HttpStatusCode.Ok,
                            context : val.context,
                            eTag : undefined,
                            data : this._teamMembers,
                            errors : [],
                            pageInfo : {
                                totalCount : this._teamMembers.length,
                                limit : this._teamMembers.length,
                                offset : 0
                            }
                        }                        
                        this.tabState.onUpdatedData(this.navItemTeamMembers, myVals);
                        break;
                    }

                    default:        {
                        let myVals : ApiMwgithubResult = {
                            httpStatus : HttpStatusCode.Ok,
                            context : val.context,
                            eTag: undefined,
                            data : this._teams,
                            errors : [],
                            pageInfo : {
                                totalCount : this._teams.length,
                                limit : this._teams.length,
                                offset : 0
                            }
                        }
                        this.tabState.onUpdatedData(this.navItemTeams, myVals);
                        break;                        
                    }                        
                }
            }, 
            error: (err:any) => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("usersandteams$:" + JSON.stringify(err));
            }
        });                   

        this.githubenvironments$.subscribe(
            (val: ApiMwgithubResult) => {
                // TBD - this is not well organized as we have also getTenantSettings
                this._githubenvironments = [];
                if (val) {
                    switch (val.httpStatus) {
                        case HttpStatusCode.EarlyHints:
                            this._githubenvironments = [];
                            break;

                        case HttpStatusCode.Ok:
                            if (Array.isArray(val.data)) {
                                let importIds : any = OanHttpUtils.importIdInit(this.uiState.importhistory);
                                let myPageState = this.tabState.getTabPagination(this.navItemEnvironments, val);
                                val.data.forEach( (element : ApiMwgithubEnvironment) => {
                                    let elKey = element.identifier;
                                    OanHttpUtils.importIdAdd(importIds, element, true);
                                    
                                    var newEl = new UiObj<ApiMwgithubEnvironment>(0, elKey, element.idlogical, element, OrbiObjectType.GithubEnvironment, OrbiReportType.GithubEnvironment);
                                    // moved to OanAnalyzerGithub.setObjs newEl.calculateEnrichedAttrs();                           
                                    this._githubenvironments.push(newEl);        
                                });

                                this.tabState.onUpdatedData(this.navItemEnvironments, val);
                                this.onGetAuditResults(OrbiObjectType.GithubEnvironment, importIds);
                            } else  {
                                console.error("unexpected response githubenvironments$");
                            }
                            break;

                        default:
                            OanDebugutils.debuggerWrapper(".?.");
                            console.error("githubenvironments$: resposne=" + JSON.stringify(val));    
                            this._githubenvironments = [];
                    }
                } else {
                    console.error("unexpected response githubenvironments$");
                }
            }, err => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("githubenvironments$:" + JSON.stringify(err));
            }
        );
        this.githubsecrets$.subscribe(
            (val: ApiMwgithubResult) => {
                if (val) {
                    switch (val.httpStatus) {
                        case HttpStatusCode.EarlyHints:
                            this._githubsecrets = [];
                            break;

                        case HttpStatusCode.Ok:
                            if (Array.isArray(val.data)) {
                                let importIds : any = OanHttpUtils.importIdInit(this.uiState.importhistory);
                                let myPageState = this.tabState.getTabPagination(this.navItemSecrets, val);
                                val.data.forEach( (element : ApiMwgithubSecret) => {
                                    OanHttpUtils.importIdAdd(importIds, element, true);

                                    let strSecretUpdatedAt : string = element.secret.updated_at;
                                    let dteSecretUpdatedAt : Date = new Date(strSecretUpdatedAt);
                                    let dteDiff = (Date.now() - dteSecretUpdatedAt.valueOf()) / 1000 / 60 / 60 / 24;
                                    element.synthetic.daysSinceLastRotation = Math.floor(dteDiff);
                                    this._githubsecrets.push(new UiObj<ApiMwgithubSecret>(0, element.idlogical, element.idlogical, element, OrbiObjectType.GithubSecret, OrbiReportType.GithubSecret));
                                });
                                this.tabState.onUpdatedData(this.navItemSecrets, val);
                                this.onGetAuditResults(OrbiObjectType.GithubSecret, importIds);
                            } else  {
                                console.error("unexpected response _githubsecrets$");
                            }
                            break;

                        default:
                            OanDebugutils.debuggerWrapper(".?.");
                            console.error("githubenvironments$: resposne=" + JSON.stringify(val));    
                            this._githubsecrets = [];
                    }
                } else {
                    console.error("unexpected response _githubsecrets$");
                }
            }, err => {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("githubsecrets$:" + JSON.stringify(err));
            }
        );
    }

    public quickFilterRepoSelector : Record<string,string> = {
        [OrbiResultsFilterEnum.repoall] : "All", 
        [OrbiResultsFilterEnum.repolive]: "Live",
        [OrbiResultsFilterEnum.repocustom]: "Custom",
    };

    public quickFilterStatusSelector : Record<string,string> = {
        [OrbiResultsFilterEnum.auditall] : "All", 
        [OrbiResultsFilterEnum.auditonlypass]: "Pass",
        [OrbiResultsFilterEnum.auditonlyfail]: "Fail",
    };

    public quickFilterDateSelector : Record<string,string> = {
        [OrbiResultsFilterEnum.dateweek1] : "Last Week", 
        [OrbiResultsFilterEnum.dateweek5]: "Last Month",
        [OrbiResultsFilterEnum.datecustom]: "Custom",
    };

    public quickFilterIcons : Record<string,string> = {
        [OrbiResultsFilterEnum.repoall] : "faFilter", 
        [OrbiResultsFilterEnum.repolive]: "faShieldAlt",
        [OrbiResultsFilterEnum.auditall] : '', 
        [OrbiResultsFilterEnum.auditonlypass]: "faCheck",
        [OrbiResultsFilterEnum.auditonlyfail]: "faExclamationTriangle",
        [OrbiResultsFilterEnum.dateweek1] : "faCheck", 
        [OrbiResultsFilterEnum.dateweek5]: "faExclamationTriangle",
        [OrbiResultsFilterEnum.datecustom]: "faExclamationTriangle",
    };

    ngOnInit()
    {
        let fnName = "ngOnInit";
        

        this.svcStartuprequests.startupRequestsL2State$.pipe(
                switchMap( async (startupState:OrbistartuprequestsL2Interface) => {
                    if (startupState.isInit) {
                        let myQfFilterSavedState = await this.svcStorage.readItem(OrbiBrowserStorageItemId.QfFilterStateVars);    
                        if (myQfFilterSavedState) {
                            this.uiState.qfFilterState.stateVars = myQfFilterSavedState;
                        }
                    
                        let myQfFilterSavedStateDate = await this.svcStorage.readItem(OrbiBrowserStorageItemId.QfFilterStateVarsDate);    
                        if (myQfFilterSavedStateDate) {
                            this.uiState.qfFilterState.stateVarsDate = OrbiwidgetdateselectorComponent.getInitValues(this.locale, this.calendar, myQfFilterSavedStateDate);
                            if (this.uiState.qfFilterState.stateVarsDate.quickValue == OrbiResultsFilterEnum.datecustom) {
                                this.tabStateGlobalFilters.tabActive = this.tabStateGlobalFilters.navItemDate;
                                this.uiState.setTabStateGlobalFiltersCollapse(undefined, false, 'togglebtn')
                            }
                        } else {
                            this.uiState.qfFilterState.stateVarsDate = OrbiwidgetdateselectorComponent.getInitValues(this.locale, this.calendar, {});
                        }

                        this.uiState.qfFilterState.stateVars.orgName = this.svcProfile.getActiveOrgName();
                        if (!this.uiState.qfFilterState.stateVars.repositorySelector) {
                            this.uiState.qfFilterState.stateVars.repositorySelector = OrbiResultsFilterEnum.repoall;
                        }
                        if (!this.uiState.qfFilterState.stateVars.auditfocusSelector) {
                            this.uiState.qfFilterState.stateVars.auditfocusSelector = OrbiResultsFilterEnum.auditall;
                        }
                        
                        this.uiStateQfFilterStateChange(this, 'init', undefined);
                        this.getTenantSettings();  
                    }
                    return startupState;
                })
            ).subscribe({
                next: (state:OrbistartuprequestsL2Interface) => {
                    if (state.isInit) 
                    {

                    }
                }, error: (err) => {
                }
        });        
        this.svcStartuprequests.startupRequestsL3State$.pipe(
            switchMap( async (startupState:OrbistartuprequestsL3Interface) => {
                if (startupState.isInit) {
                    this.uiState.importhistory = startupState.importhistory
                    this.tabStateGlobalFilters.repoTopics = startupState.repoTopics;
                    this.tabStateGlobalFilters.repoTopicMap = startupState.repoTopicMap;
                    this.tabStateGlobalFilters.repoNamesForReport = startupState.reposummaryList;
                    this.tabStateGlobalFilters.repoNamesForReportForSelectReposWidget = startupState.reposummaryList.map((element:any) => element.repoName);
                    this.uiStateQfFilterStateChange(this, 'repositorySelector', undefined);
                    this.uiState.qfFilterState.setRepoSummaryList(startupState.reposummaryList);
                    setTimeout(this.registerObservers.bind(this));
                    
                    // we dont do this here because the router url subscription does it now
                    // this.setActiveTabWrapper(this.navItemRepos);    
                    this.router.url.subscribe(() => {
                        let tabId = this.router.snapshot?.params?.tabId;
                        if (tabId && (GithubComponent.tabStateNavItems[tabId] || GithubComponent.tabStateNavItemShortcuts[tabId])) {
                            let realTabId = GithubComponent.tabStateNavItems[tabId] ?? GithubComponent.tabStateNavItemShortcuts[tabId];
                            this.setActiveTabWrapper(realTabId);
                        } else {
                            this.setActiveTabWrapper(this.navItemRepos);
                        }
                    });            
                }
                
                return startupState;
            })
        ).subscribe();        

        this.reposexport$ = this.svcGithub.reposexport$;
        this.pullrequestscached$ = this.svcGithub.pullrequestscached$;
        this.commitscached$ = this.svcGithub.commitscached$;
        this.users$ = this.svcGithub.users$;
        this.teams$ = this.svcGithub.teams$;
        this.workflowrunscached$ = this.svcGithub.workflowrunscached$;
        this.githubsettingsGet$ = this.svcSettings.githubsettingsGet$;
        this.webhookevents$ = this.svcGithub.webhookevents$;

        this.tabStateGlobalFilters.tabActive = this.tabStateGlobalFilters.navItemOrg;
        this.tabStateGlobalFilters.cmpThis = this;


        this.model.init(this);
        this.githubsettingsGet$.subscribe((val: ApiMwgithubResult) => {
            
            let baseObj = val.data[0].settingobjects[OrbiSettingNvpName.GithubLiveProductionAssets_base];
            switch (val.data[0].settinggroup) {
                case OrbiSettingGroupName.GithubLiveProductionAssetsBranches:
                case OrbiSettingGroupName.GithubLiveProductionAssetsRepos: {
                    let selectionType = baseObj[ApiFormCtrlId_Ten_Assets.LiveRepoDefType] && baseObj[ApiFormCtrlId_Ten_Assets.LiveRepoDefType].selectedvalue;
                    if (selectionType) {
                        let selectionKey;                    
                        switch (selectionType) {
                            case ApiFormCnst_RepoProdDefType.ByTopic: selectionKey=ApiFormCtrlId_Ten_Assets.LiveRepoDefByTopic; break;
                            case ApiFormCnst_RepoProdDefType.ByIndNames: selectionKey=ApiFormCtrlId_Ten_Assets.LiveRepoDefByIndName; break;
                            case ApiFormCnst_RepoProdDefType.ByPattern: selectionKey=ApiFormCtrlId_Ten_Assets.LiveRepoDefByPattern; break;
                            case ApiFormCnst_RepoProdDefType.ByReposAll: selectionKey=ApiFormCtrlId_Ten_Assets.LiveRepoDefByAll; break;
                            case ApiFormCnst_BranchProdDefType.ByNameConv: selectionKey=ApiFormCtrlId_Ten_Assets.LiveBranchDefByNameConv; break;
                            case ApiFormCnst_BranchProdDefType.ByPattern: selectionKey=ApiFormCtrlId_Ten_Assets.LiveBranchDefByPattern; break;
                            case ApiFormCnst_BranchProdDefType.ByBranchAll: selectionKey=ApiFormCtrlId_Ten_Assets.LiveBranchDefByDefault; break;
                            case ApiFormCnst_BranchProdDefType.ByDefault: selectionKey=ApiFormCtrlId_Ten_Assets.FavouriteTopics; break;
                        }

                        let selectionCriteria : string | undefined = (selectionKey && baseObj[selectionKey]) ? baseObj[selectionKey].selectedvalues || baseObj[selectionKey].selectedvalue : undefined;
                        if (selectionType && selectionCriteria)
                        {
                            switch (selectionType) 
                            {
                                case ApiFormCnst_RepoProdDefType.ByTopic:
                                    this.uiState.explainStrCfgLiveRepos = `Repos with topic matching ${JSON.stringify(selectionCriteria)}`;
                                    break;
                                case ApiFormCnst_RepoProdDefType.ByIndNames:
                                    this.uiState.explainStrCfgLiveRepos = `Repos with names matching ${JSON.stringify(selectionCriteria)}`;
                                    break;
                                case ApiFormCnst_RepoProdDefType.ByPattern:
                                    this.uiState.explainStrCfgLiveRepos = `Repos with names matching ${JSON.stringify(selectionCriteria)}`;
                                    break;
                                case ApiFormCnst_RepoProdDefType.ByReposAll:
                                    this.uiState.explainStrCfgLiveRepos = `All repos are live.`;
                                    break;
                                case ApiFormCnst_BranchProdDefType.ByNameConv:
                                    this.uiState.explainStrCfgLiveBranches = `Branches with names matching ${JSON.stringify(selectionCriteria)}.`;
                                    break;
                                case ApiFormCnst_BranchProdDefType.ByPattern:
                                    this.uiState.explainStrCfgLiveBranches = `Branches with names matching ${JSON.stringify(selectionCriteria)}.`;
                                    break;
                                case ApiFormCnst_BranchProdDefType.ByBranchAll:
                                    this.uiState.explainStrCfgLiveBranches = `All branches are live.`;
                                    break;
                                case ApiFormCnst_BranchProdDefType.ByDefault:
                                    this.uiState.explainStrCfgLiveBranches = `All branches are live.`;
                                    break;
                                default:
                                    debugger;
                            }
                        } else {
                            this.uiState.explainStrCfgLiveBranches = '';
                            this.uiState.explainStrCfgLiveRepos = '';
                        }
                    }
                    break;
                }                
            }
        });

    }

    ngAfterViewInit(): void {
        // we cant do here as we may not yet have selected an orgName etc which is needed to query data; instead we 
        // call doFirstLoad once precondtions are done
    }

    setActiveTabWrapper(tabId: string) {
        this.tabState.setActiveTab(tabId);
    }

    getTenantSettings() 
    {
        let fnName = "getTenantSettings";
        let selectedOrgname = this.svcProfile.getActiveOrgName();
        if (undefined == selectedOrgname || 0 == selectedOrgname.length) {
            return;
        }
    
        let apiSettingsKeys : OrbiSettingGroupName[] = [ OrbiSettingGroupName.GithubAuditSettings, OrbiSettingGroupName.GithubLiveProductionAssetsRepos, OrbiSettingGroupName.GithubLiveProductionAssetsBranches];
        apiSettingsKeys.forEach((currSettingGroup:OrbiSettingGroupName) => {            
            this.svcSettings.apiGithubSettings(selectedOrgname!, currSettingGroup);
        });
    
        this.svcSettings.apiSettingsGroup(selectedOrgname, OrbiSettingGroupName.ImportSyncDate).subscribe({
            next: (value: HttpEvent<any[]>) => {                
                let httpResp : HttpResponse<any[]> = <HttpResponse<any[]>>(<unknown>value);
                if (httpResp.status >= 200 && httpResp.status < 300) {
                    this._tenantSettingsImportSync = (httpResp.body) ? httpResp.body : [];  
                    for (let i=0; i < this._tenantSettingsImportSync.length; ++i) {
                        let c : any = this._tenantSettingsImportSync[i];
                        let syncDatePayload = c?.payload; // ?.syncDate;
                        switch (c.settingobject) {
                            case OrbiSettingNvpName.ImportSyncDate_webhookevents:
                                this.tabState.lastSync[this.navItemWebhookEventsOrganization] = syncDatePayload;
                                this.tabState.lastSync[this.navItemWebhookEventsSourceCode] = syncDatePayload;
                                this.tabState.lastSync[this.navItemWebhookEventsProjMgmt] = syncDatePayload;
                                this.tabState.lastSync[this.navItemWebhookEventsCiCd] = syncDatePayload;
                                break;                                                                                                       
                            case OrbiSettingNvpName.ImportSyncDate_repos:
                                this.tabState.lastSync[this.navItemRepos] = syncDatePayload;
                                break;
                            case OrbiSettingNvpName.ImportSyncDate_pullrequests:
                                this.tabState.lastSync[this.navItemPrs] = syncDatePayload;
                                break;      
                            case OrbiSettingNvpName.ImportSyncDate_commits:
                                this.tabState.lastSync[this.navItemCommits] = syncDatePayload;
                                break;     
                            case OrbiSettingNvpName.ImportSyncDate_vulns:
                                this.tabState.lastSync[this.navItemCodeAnalysisVulns] = syncDatePayload;
                                break;                                                                                                
                            case OrbiSettingNvpName.ImportSyncDate_releases:
                                this.tabState.lastSync[this.navItemReleaseMgmt] = syncDatePayload;
                                break;                                
                            case OrbiSettingNvpName.ImportSyncDate_workflows:
                                this.tabState.lastSync[this.navItemWorkflows] = syncDatePayload;
                                break;
                            case OrbiSettingNvpName.ImportSyncDate_workflowruns:
                                this.tabState.lastSync[this.navItemWorkflowRuns] = syncDatePayload;
                                break;
                            case OrbiSettingNvpName.ImportSyncDate_user:
                                this.tabState.lastSync[this.navItemUsers] = syncDatePayload;
                                break;
                            case OrbiSettingNvpName.ImportSyncDate_team:
                                this.tabState.lastSync[this.navItemTeams] = syncDatePayload;
                                this.tabState.lastSync[this.navItemTeamMembers] = syncDatePayload;
                                break;
                            case OrbiSettingNvpName.ImportSyncDate_repoaccesscontrol:
                                this.tabState.lastSync[this.navItemAccessControl] = syncDatePayload;
                                break;
                            case OrbiSettingNvpName.ImportSyncDate_environment:
                                this.tabState.lastSync[this.navItemEnvironments] = syncDatePayload;
                                break;
                            case OrbiSettingNvpName.ImportSyncDate_secret:
                                this.tabState.lastSync[this.navItemSecrets] = syncDatePayload;
                                break;
                            default:
                                console.warn("Unrecognised nvpname " + c.settingobject);                           
                        }
                    }

                    this.tabState.refreshData();
                }
                else {
                    console.error(`${fnName} unexpected Http response ${httpResp}`);
                    OanDebugutils.debuggerWrapper(httpResp);
                    this._tenantSettingsImportSync = []
                }
            },
            error: (err:any) => {
                this._tenantSettingsImportSync = []
                console.error(err.error);
                OanDebugutils.debuggerWrapper(err);
            }, 
            complete: () => {
            }
        });

        /*
        


        this.githubsettingsGet$.subscribe({
            next: (value: any) => {                
                var httpResp : HttpResponse<any[]> = <HttpResponse<any[]>>(<unknown>value);
                if (httpResp.status >= 200 && httpResp.status < 300) {
                    this._tenantSettings = (httpResp.body) ? httpResp.body : [];                    
                    this._tenantSettings.forEach(element => {
                        switch (element.settinggroup) {
                            case OrbiSettingGroupName.GithubOrg:
                                if (OrbiSettingNvpName.GithubOrg_TopicInfo == element.settingobject)  
                                {
                                    // TBD ### add a type attr to these payloads so we can do some santiy checks
                                    // e.g. element.payload.type = { "repotopics", version "20211231" }
                                    this.tabStateGlobalFilters.repoTopics = [];
                                    if (element.payload) {
                                        this.tabStateGlobalFilters.repoTopics.push(...Object.keys(element.payload));
                                    }

                                    this.tabStateGlobalFilters.repoTopicsFavourites = [];
                                    this.tabStateGlobalFilters.repoTopicsFixedFavourites = []; 
                                    
                                    this.tabStateGlobalFilters.repoTopics.forEach((currRtKey:string) => {
                                        if (element.payload[currRtKey] && 9 < element.payload[currRtKey].length) {
                                            this.tabStateGlobalFilters.repoTopicsFavourites.push(currRtKey);
                                        }
                                    });                                     
                                }
                                break;
                        }
                    });
                }
            },
            error: (err: any) => {
                console.error(err);
                OanDebugutils.debuggerWrapper(err);
            },
            complete: () => {

            }
        });*/

    }


    onExport(ev: Event, colDefs : any[], objs : UiObj<any>[]) {

        this.uiState.strReposExport = "";
        let renderedTableHeaders : OrbiHdr[] = this.orbiresultstablenative.renderedTableHeaders;
        let renderedTableData : OrbiRow[] = this.orbiresultstablenative.renderedTableData;
        const numExpectedCells = renderedTableHeaders.length+3;

        let hdrsForExport : OrbiHdr[] = [ this.orbiresultstablenative.statusCol, this.orbiresultstablenative.impactsCol, 
                                        { isExportsCsv:true, title:"Observations" }, 
                                        ...renderedTableHeaders ];
        if (Array.isArray(hdrsForExport)) 
        {
            let exportHdrs = hdrsForExport.filter( (rth : OrbiHdr) => (undefined != rth) ? rth.isExportsCsv : '' );
            this.uiState.strReposExport += exportHdrs.map( (rth) => rth.title ).join( "," );
            this.uiState.strReposExport += "\n";

            for (let currRowIndex=0; currRowIndex < renderedTableData.length; ++currRowIndex)
            {
                let currRow : OrbiRow = renderedTableData[currRowIndex];
                /*
                let rowFlags = currRow.rowFlags.map( (crf:any)=>crf.label).join(',');
                let auditResultVal = JSON.stringify(currRow.statusCol.auditresult);
                if (undefined == auditResultVal) {
                    auditResultVal="na";
                }
                let cellsForExport : Partial<OrbiCellValue>[] = [ { val: auditResultVal, exportcsv:true }, { val: rowFlags, exportcsv:true }, {val:currRow.statusCol.auditresulttooltip, exportcsv:true}, ...currRow.cells ];
                */
                let cellsForExport : Partial<OrbiCellValue>[] = [ { val: "" /*auditResultVal*/, exportcsv:true }, { val: ""/*rowFlags*/, exportcsv:true }, {val:"" /*currRow.statusCol.auditresulttooltip */, exportcsv:true}, ...currRow.cells ];
                this.svcDebug.callDbgWrapperIfCondition(`GithubCopmonent.onExport currRowIndex=${currRowIndex}`, undefined, (numExpectedCells != cellsForExport.length));            
                let rowStr : string = ''; 
                for (let currCellIndex=0; currCellIndex < cellsForExport.length; ++currCellIndex)
                {
                    let currCelVal = cellsForExport[currCellIndex].val;
                    if (cellsForExport[currCellIndex].exportcsv) {
                        if (currCelVal)
                        {
                            let marshalledVal : string = ("string" == typeof currCelVal) ? currCelVal.replace(/[,\n]/g, ' ') : currCelVal;
                            rowStr += marshalledVal;
                        }

                        rowStr += ",";
                    } 
                }

                this.uiState.strReposExport += (rowStr  + "\n") ;
            }
        }

        // size is 'lg', 'xl'
        var refContentModal : NgbModalRef = this.modalService.open(this.contentmodal, { size: 'xl' } );
        refContentModal.result.then((result) => {
            console.log("refContentModal.result.then(" + JSON.stringify(result) + ")");
        }, (reason) => {
            console.log("refContentModal.result.reason(" + JSON.stringify(reason) + ")");
        });
    }

    onGetAuditResults(objType:OrbiObjectType, importIds : any) {
        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
        myFilterState.fromDate = this.uiState.qfFilterState.stateVarsDate.fromDate;
        myFilterState.toDate = this.uiState.qfFilterState.stateVarsDate.toDate;
        this.svcGithub.apiGetAuditResults(objType, myFilterState, importIds);
    }

    onGetRepos(ev: Event | undefined, tabId : string) {
        if (this.tabStateGlobalFilters.getFilterNotApplied(tabId) && this.uiState.qfFilterState.stateVarsDate.isValid) {
           
            this.onNavItemActionString = tabId;
            let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
            [myFilterState.fromDate,myFilterState.toDate] = OrbiwidgetdateselectorComponent.getNormalizedValues(this.calendar, this.uiState.qfFilterState.stateVarsDate);
             
            this.uiState.repoSelectionDesc = this.uiState.qfFilterState.stateVars.repositorySelectorCustomValue;
            let query = this.model.getGithubRepoFilter();
            let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemRepos);

            if (query && 0 < query.length) {
                myFilterState.extraParamsNameValues = [ `query=${query}`];
            }

            this.svcGithub.apiGetCachedRepos(myFilterState, pageState);
        }
    }

    onGetWebhookEvents(ev: Event | undefined, tabId : string, eventCategory:GithubEventTypeCategory) {
        if (this.tabStateGlobalFilters.getFilterNotApplied(tabId)) {

            let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
            [myFilterState.fromDate,myFilterState.toDate] = OrbiwidgetdateselectorComponent.getNormalizedValues(this.calendar, this.uiState.qfFilterState.stateVarsDate);

            myFilterState.extraParamsNameValues = [ `eventCategory=${eventCategory}` ];            

            let pageState : ApiPageStateInterface = this.tabState.getTabPagination(tabId);
            this.svcGithub.apiGetWebhookEvents(myFilterState, pageState);

            let currentlyActiveTab = this.tabState.activeTab;
            this.onGetAuditResults(OrbiObjectType.GithubWebhookEvent, {});
            this.svcAuditResultsMgr.setPendingTabForGetAuditResults(this.tabState, this.tabState.activeTab);
        }
    }

    onGetPrs(ev: Event, tabId : string) {
        if (this.tabStateGlobalFilters.getFilterNotApplied(tabId)) {
                    
            // pull requests need this data to funciton
            // TBD this is a race, we dont know which response comes back first
            // this.onGithubRepoReleases(ev, this.navItemReleaseMgmt);
            let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
            /*
            var filteredRepos = this._repos.filter((r) => r.selected);
            if (0 == filteredRepos.length) {
                throw new Error("You must select the repositories you are interested in");
            } 
            
            var underylingObjs = UiObj.getSelectedInnerObjs(filteredRepos);
            var reposet : string = this.uiState.qfFilterState;
            */

            this._prs = [];
            myFilterState.fromDate = this.uiState.qfFilterState.stateVarsDate.fromDate;
            myFilterState.toDate = this.uiState.qfFilterState.stateVarsDate.toDate;

            // we use a clean page config here to get all PRs
            let pageState : ApiPageStateInterface = ApiPageState_init(); // this.tabState.getTabPagination(this.navItemPrs);
            this.svcGithub.apiGetCachedPullrequests(myFilterState, /* reposet, strFromDate, strToDate,*/ pageState);            
        }
    }
    
    onGetCommits(ev: Event, tabId : string) {
        if (this.tabStateGlobalFilters.getFilterNotApplied(tabId)) {
        
            /*
            var filteredRepos = this._repos.filter((r) => r.selected);
            if (0 == filteredRepos.length) {
                throw new Error("You must select the repositories you are interested in");
            } */

            this._commits = [];

            let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
            myFilterState.fromDate = this.uiState.qfFilterState.stateVarsDate.fromDate;
            myFilterState.toDate = this.uiState.qfFilterState.stateVarsDate.toDate;

            /*
            var strFromDate : string = (null != fromDate) ? OangRenderers.renderDate(this.locale, fromDate, strGithubDateFormat) : JSON.stringify(null);
            var strToDate : string = (null != toDate) ? OangRenderers.renderDate(this.locale, toDate, strGithubDateFormat) : JSON.stringify(null);
            var reposet : string = this.uiState.qfAuditStatus;
            */
            let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemCommits);
            this.svcGithub.apiGetCachedCommits(myFilterState, pageState);            
        }
    }

    onGetVulns(ev: Event, tabId : string) {
        if (this.tabStateGlobalFilters.getFilterNotApplied(tabId)) {
        
            var filteredRepos = this._repos.filter((r) => r.selected);
            if (0 == filteredRepos.length) {
                throw new Error("You must select the repositories you are interested in");
            }

            this._commits = [];
            let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
            myFilterState.fromDate = this.uiState.qfFilterState.stateVarsDate.fromDate;
            myFilterState.toDate = this.uiState.qfFilterState.stateVarsDate.toDate;

            let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemCodeAnalysisVulns);
            this.svcGithub.apiGetCachedCodeAnalysVulnAlerts(myFilterState, pageState);            
        }
    }

    onGetReleases(ev: Event, tabId : string) {
        if (this.tabStateGlobalFilters.getFilterNotApplied(tabId)) {
            // var filteredRepos = this.tabStateGlobalFilters.getSelectedRepos();
            var filteredRepos = this._repos.filter((r) => r.selected);

            this._releasemgmts = [];
            let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
            myFilterState.fromDate = this.uiState.qfFilterState.stateVarsDate.fromDate;
            myFilterState.toDate = this.uiState.qfFilterState.stateVarsDate.toDate;

            let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemReleaseMgmt);
            this.svcGithub.apiGetCachedReleases(myFilterState, pageState);
        }
    }

    onGetWorkflows(ev: Event, repo? : UiObj<ApiMwgithubRepo>) {
        // var filteredRepos = this.tabStateGlobalFilters.getSelectedRepos();   
        var filteredRepos = this._repos.filter((r) => r.selected); 
        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
        myFilterState.fromDate = this.uiState.qfFilterState.stateVarsDate.fromDate;
        myFilterState.toDate = this.uiState.qfFilterState.stateVarsDate.toDate;
        let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemWorkflows);
        this.svcGithub.apiGetRepoWorkflowsCached(myFilterState, pageState);
    }    

    onGetWorkflowRuns(ev: Event, repo? : UiObj<ApiMwgithubRepo>) {

        var fnName = "onGithubWorkflowRuns";
        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
        myFilterState.fromDate = this.uiState.qfFilterState.stateVarsDate.fromDate;
        myFilterState.toDate = this.uiState.qfFilterState.stateVarsDate.toDate;
        
        let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemWorkflowRuns);
        this.svcGithub.apiGetRepoWorkflowRunsCached(myFilterState, pageState);
    }     

    onGetUser(ev:Event, user? : UiObj<any>) {
        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
        let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemUsers);
        this.svcGithub.apiGetUsers(myFilterState, pageState);
    }

    onGetTeam(ev:Event, user? : UiObj<any>) {
        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
        let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemTeams);
        this.tabState.pseudoView = GithubComponentPseudoView.GithubComponentPseudoView_onGithubTeam;
        this.svcGithub.apiGetTeams(myFilterState, pageState);
    }

    onGetTeamMembers(ev:Event, user? : UiObj<any>) {
        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
        let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemTeamMembers);
        this.tabState.pseudoView = GithubComponentPseudoView.GithubComponentPseudoView_onGithubTeamMembers;
        this.svcGithub.apiGetTeams(myFilterState, pageState);
    }

    onGetAccessControl(ev:Event, user? : UiObj<any>) {
        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
        let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemAccessControl);
        this.svcGithub.apiGetRepoAccessSettings(myFilterState, pageState);  
    }

    /*
    // TBD remove this if we go with seperate setting page
    onSettings(ev:Event, settingGroupName : string) {
        let grpName : OrbiSettingGroupName = <OrbiSettingGroupName>(settingGroupName);
        this.modalSettingsRef = this.modalService.open(OrbisettingswidgetComponent);
        this.modalSettingsRef.componentInstance.activeModal = this.modalSettingsRef;
        this.modalSettingsRef.componentInstance.orgName = this.uiState.qfFilterState.stateVars.orgName;
        this.modalSettingsRef.componentInstance.repos = this._repos;
        this.modalSettingsRef.componentInstance.repoTopics = this.tabStateGlobalFilters.repoTopics;
        this.modalSettingsRef.componentInstance.settingGroupName = settingGroupName;
        this.modalSettingsRef.componentInstance.settingData = undefined;
        this.modalSettingsRef.componentInstance.modalClose = () => {
            this.modalSettingsRef?.close();
            this.modalSettingsRef = undefined;
        };

        this.svcSettings.apiGithubSettings(this.uiState.qfFilterState, grpName);
        this.modalSettingsRef.componentInstance.onInputChanges();
        return true;
    } */

    onGithubEnvironments($event:any, tabId:string|undefined)
    {
        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
        let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemEnvironments);
        this.svcGithub.apiGetEnvironments(myFilterState, pageState);  
    }

    onGithubSecrets($event:any, tabId:string|undefined)
    {
        let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.uiState.qfFilterState.stateVars));
        let pageState : ApiPageStateInterface = this.tabState.getTabPagination(this.navItemSecrets);
        this.svcGithub.apiGetSecrets(myFilterState, pageState);  
    }

    onApiPageChangeRequest(tabId:string, myPagestate : ApiPageStateInterface)
    {
        switch (tabId) {            
            case this.navItemWebhookEventsOrganization:
                this.onGetWebhookEvents(undefined, tabId, GithubEventTypeCategory.Organization);
                break;                
            case this.navItemWebhookEventsSourceCode:
                this.onGetWebhookEvents(undefined, tabId, GithubEventTypeCategory.Sourcecode);
                break;                
            case this.navItemWebhookEventsProjMgmt:
                this.onGetWebhookEvents(undefined, tabId, GithubEventTypeCategory.Projmgmt);
                break;                
            case this.navItemWebhookEventsCiCd:
                this.onGetWebhookEvents(undefined, tabId, GithubEventTypeCategory.CiCd);
                break;                
            case this.navItemRepos:
            case this.navItemReposPublic:
                break;
            /*
                this.cmpThis.onReposPublic($event, activeTab);
                break;*/
            case this.navItemPrs:
                break;  
            case this.navItemCommits:
                break;  
            case this.navItemCodeAnalysisVulns:
                break;                                       
            case this.navItemReleaseMgmt:
                break;                           
            case this.navItemUsers:
                break;                      
            case this.navItemTeams:
                break;        
            case this.navItemTeamMembers:
                break;                                      
            case this.navItemAccessControl:
                break;                   
                /*
            case this.cmpThis.navItemSettings:
                this.cmpThis.onSettings($event);
                break;                            */
            case this.navItemWorkflows:                                                         
                break;    
            case this.navItemWorkflowRuns:                                                         
                break;     
            case this.navItemEnvironments:                                                         
                break;   
            case this.navItemSecrets: 
                break;   
            default:
                console.warn(`onSync unrecognised tab ${tabId}`);               
        }        
    }
}
