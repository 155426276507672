

export interface OrbiHttpResponse<T> {
    statusCode : number,
    message : string,
    data : T
}

export class OrbiHttpOptions {
    private static _opts : any = undefined; 
    public static getHttpOptions() : any
    {
        if (undefined == this._opts) {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json; charset=utf-8');
            headers.append('Cache-Control', 'no-cache');
            headers.append('Pragma', 'no-cache');
            headers.append('Expires', '0');
            let options : any = {  
                headers: headers,
                observe: 'response' as const,
                responseType: 'json' as const
            };

            this._opts = options;
        }

        return this._opts;
    }
}
