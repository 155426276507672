import { HttpErrorResponse, HttpEvent, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Inject, Injectable, LOCALE_ID, NgZone, OnInit, ViewChild } from '@angular/core';
import { ApiMwgithubResult, EntityMwimporterworkload_Status, OrbiObjectType, OrbiSettingGroupName, OrbiSettingNvpName } from '@danclarke2000/gitrospectdto';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OanAnalyzerParams } from '../cmn/analyzer/OanAnalyzerParams';
import { OanDebugutils } from '../cmn/utils/OanDebugutils';
import { OrbibackendPublicService } from './orbibackend.public.service';
import { OrbiBrowserStorageItemId, OrbiBrowserStorageService } from './orbibrowserstorage.service';
import { OrbidebugService } from './orbidebug.service';
import { OrbigithubService } from './orbigithub.service';
import { OrbiprofileService } from './orbiprofile.service';
import { OrbisettingsService } from './orbisettings.service';

export interface OrbistartuprequestsL0Interface {
    isInit : boolean;
    svcurls : any;
    amILoggedIn : boolean,
}

export interface OrbistartuprequestsL1Interface {
    isInit : boolean;
    userprefs : any;
    storageKeyInfo : any;
    userInfo : any;
    accessibleresources : string[],
}

export interface OrbistartuprequestsL2Interface {
    isInit : boolean;
    isTargetGithubOrg : boolean;
    healthcheckAuthz : boolean;
    authorizedTenant : string;
    hasActiveTenant : boolean;
}

// this is state which can be loaded after the first batch of data is loaded
export interface OrbistartuprequestsL3Interface {
    isInit : boolean;
    importhistory : any;
    repoTopics : string[];
    reposummaryList : any[];
    teamsAndMembers : any,
    repoTopicMap : any,
    settingsLiveRepo : any,
    settingsLiveBranch : any,
    aggregates : any | undefined,
}

export interface OrbistartuprequestsTenantNotReady {
    isInit : boolean;
    onboardingOrganizationSignup : boolean | undefined,
    onboardingHasBegin : boolean | undefined;
    onboardingImportingHasBegin : boolean | undefined;
    onboardingImportingComplete : boolean | undefined;
    importHistory : any[];
}

@Injectable({
    providedIn: 'root'
})
export class Orbistartuprequestsservice {
    private startupL0Responses : any = {};
    private startupRequestsL0State : OrbistartuprequestsL0Interface = {
        isInit : false,
        svcurls : undefined,
        amILoggedIn : false,
    };

    private startupL1Responses : any = {};
    private startupRequestsL1State : OrbistartuprequestsL1Interface = {
        isInit : false,
        userprefs : undefined,
        storageKeyInfo : undefined,
        userInfo : undefined,
        accessibleresources : [],
    };


    // l2 checks the current teannt has already imported data
    private startupL2Responses : any = {};
    private startupRequestsL2State : OrbistartuprequestsL2Interface = {
        isInit : false,
        isTargetGithubOrg : false,
        healthcheckAuthz : false,
        authorizedTenant : '',
        hasActiveTenant : false
    }
    
    // l3 gets data related to the github state of current tenant
    private startupL3Responses : any = {
        aggregates : true
    };
    private startupRequestsL3State : OrbistartuprequestsL3Interface = {
        isInit : false,
        importhistory : undefined,
        repoTopics : [],
        reposummaryList : [],
        repoTopicMap : {},
        teamsAndMembers : {},
        settingsLiveRepo : {},
        settingsLiveBranch : {},
        aggregates : {}
    };
    
    private startRequestsTenantNotReadyState : OrbistartuprequestsTenantNotReady = {
        isInit : false,
        onboardingOrganizationSignup : undefined,
        onboardingHasBegin : undefined,
        onboardingImportingHasBegin : undefined,
        onboardingImportingComplete : undefined,
        importHistory : []
    };

    private _startupRequestsL0State$ = new BehaviorSubject<OrbistartuprequestsL0Interface>(this.startupRequestsL0State);
    private _startupRequestsL1State$ = new BehaviorSubject<OrbistartuprequestsL1Interface>(this.startupRequestsL1State);
    private _startupRequestsL2State$ = new BehaviorSubject<OrbistartuprequestsL2Interface>(this.startupRequestsL2State);
    private _startupRequestsL3State$ = new BehaviorSubject<OrbistartuprequestsL3Interface>(this.startupRequestsL3State);
    private _startupRequestsTenantNotReady$ = new BehaviorSubject<OrbistartuprequestsTenantNotReady>(this.startRequestsTenantNotReadyState);

    get startupRequestsL0State$() { return this._startupRequestsL0State$.asObservable(); }
    get startupRequestsL1State$() { return this._startupRequestsL1State$.asObservable(); }
    get startupRequestsL2State$() { return this._startupRequestsL2State$.asObservable(); }
    get startupRequestsL3State$() { return this._startupRequestsL3State$.asObservable(); }
    get startupRequestsTenantNotReady$() { return this._startupRequestsTenantNotReady$.asObservable(); }

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private svcDebug: OrbidebugService,
        private readonly svcApiPub : OrbibackendPublicService,
        private readonly svcGithub : OrbigithubService,
        public svcSettings : OrbisettingsService,         
        public svcProfile : OrbiprofileService,
        public svcStorage : OrbiBrowserStorageService)
    {
        this.startupL0Responses.isInit = false;
        this.startupL1Responses.isInit = false;
        this.startupL2Responses.isInit = false;
        this.startupL3Responses.isInit = false;
    }

    private signalL1InitIfL0Ready(signallerName:string) {
        let missingVals : string[] = Object.keys(this.startupRequestsL0State).filter((key:string) => this.startupL0Responses[key] != true);
        if (0 == missingVals.length && false == this.startupRequestsL1State.isInit) {
            this.startupRequestsL0State.isInit = true;
            this._startupRequestsL0State$.next(this.startupRequestsL0State);
            if (this.startupRequestsL0State.amILoggedIn) {
                this.doStartupRequestsL1();
            }
        }
    }

    private signalL2InitIfL1Ready(signallerName:string) {
        let missingVals : string[] = Object.keys(this.startupRequestsL1State).filter((key:string) => this.startupL1Responses[key] != true);
        if (0 == missingVals.length && false == this.startupRequestsL1State.isInit) {
            this.startupRequestsL1State.isInit = true;
            let promSetActiveOrgName : Promise<void> | undefined = undefined;
            let activeOrgName = this.svcProfile.getActiveOrgName();
            if (! Array.isArray(this.startupRequestsL1State.accessibleresources) || false == this.startupRequestsL1State.accessibleresources.some((a:any) => a.targetObject == activeOrgName)) {
                activeOrgName = undefined;
                this.svcProfile.setActiveOrgName(undefined);
            }

            if (undefined == activeOrgName) {
                let orgTgt : any = undefined;
                let preferredUsername = this.startupRequestsL1State?.userInfo?.preferred_username;
                if (Array.isArray(this.startupRequestsL1State.accessibleresources) && 0 < this.startupRequestsL1State.accessibleresources.length) 
                {
                    if (orgTgt = this.startupRequestsL1State.accessibleresources.find((a:any) => a.targetObjectType == 'EntityMwAuthzTarget.GithubOrgObject')) {
                        promSetActiveOrgName = this.svcProfile.setActiveOrgName(orgTgt.targetObject);                    
                    } else {
                        orgTgt = preferredUsername ? this.startupRequestsL1State.accessibleresources.find((a:any) => a.targetObject == preferredUsername) : undefined;
                        if (! orgTgt) {
                            orgTgt = this.startupRequestsL1State.accessibleresources[0];
                        }

                        promSetActiveOrgName = this.svcProfile.setActiveOrgName(orgTgt.targetObject);
                    }
                } else {
                    promSetActiveOrgName = Promise.resolve();
                }
                
                /* else if (Array.isArray(this.startupRequestsL1State?.userInfo?.userOrgs) && 0 < this.startupRequestsL1State?.userInfo?.userOrgs.length) {
                    let firstNonMeOrgName : string[] = this.startupRequestsL1State?.userInfo?.userOrgs.filter((orgName:string) => orgName != this.startupRequestsL1State?.userInfo?.preferred_username);
                    if (0 < firstNonMeOrgName.length) {
                        promSetActiveOrgName = this.svcProfile.setActiveOrgName(firstNonMeOrgName[0]);
                    } else {
                        promSetActiveOrgName = this.svcProfile.setActiveOrgName(this.startupRequestsL1State?.userInfo?.preferred_username);
                    }                        
                } else {
                    promSetActiveOrgName = this.svcProfile.setActiveOrgName(this.startupRequestsL1State?.userInfo?.preferred_username);
                } */
            } else {
                promSetActiveOrgName = Promise.resolve();
            }

            promSetActiveOrgName.then((val:any) => {
                this._startupRequestsL1State$.next(this.startupRequestsL1State);
                let activeOrgName = this.svcProfile.getActiveOrgName();
                if (undefined != activeOrgName) {
                    this.doStartupL2Requests();
                } else {
                    this.startRequestsTenantNotReadyState = {
                        isInit : true,
                        onboardingOrganizationSignup : false,
                        onboardingHasBegin : undefined,
                        onboardingImportingHasBegin : undefined,
                        onboardingImportingComplete : undefined,
                        importHistory : []
                    };
                    this._startupRequestsTenantNotReady$.next(this.startRequestsTenantNotReadyState);    
                }
            }, (err:any) => {
                debugger;
                console.warn('Orbistartuprequestsservice.signalInitIfReady', 'error setting active org name', err);
            });
        }
    }

    private signalL3IfL2Ready(attrName:string) {
        let stateObj = this.startupRequestsL2State;
        let responseObj = this.startupL2Responses;
        let obsToSignal : BehaviorSubject<any> = this._startupRequestsL2State$;
        let missingVals : string[] = Object.keys(stateObj).filter((key:string) => responseObj[key] != true);
        if (0 == missingVals.length && false == stateObj.isInit) {
            stateObj.isInit = true;
         
            // have we authz or there's something not ready ?
            let selectedOrgname = this.svcProfile.getActiveOrgName();
            if ("string" == typeof selectedOrgname 
                && 0 < selectedOrgname.length 
                && selectedOrgname == stateObj.authorizedTenant)
            {
                obsToSignal.next(stateObj);
                if (stateObj.hasActiveTenant && stateObj.healthcheckAuthz) {
                    //  && stateObj.isTargetGithubOrg) {
                    setTimeout(this.dostartupL3Requests.bind(this),500);
                } else {
                    this.startRequestsTenantNotReadyState = {
                        isInit : true,
                        onboardingOrganizationSignup : true,
                        onboardingHasBegin : selectedOrgname == stateObj.authorizedTenant,
                        onboardingImportingHasBegin : false,
                        onboardingImportingComplete : undefined,
                        importHistory : []
                    };
                    this._startupRequestsTenantNotReady$.next(this.startRequestsTenantNotReadyState);    
                }
            }
            else 
            {
                this.startRequestsTenantNotReadyState = {
                    isInit : true,
                    onboardingOrganizationSignup : true,
                    onboardingHasBegin : selectedOrgname == stateObj.authorizedTenant,
                    onboardingImportingHasBegin : false,
                    onboardingImportingComplete : undefined,
                    importHistory : [],
                };
                this._startupRequestsTenantNotReady$.next(this.startRequestsTenantNotReadyState);
            }
        }
    }

    private signalReadyIfL3Ready(updatedAttr:string/*, stateObj:any, responseObj:any, obsToSignal : BehaviorSubject<any>, extra:string*/) {
        let stateObj = this.startupRequestsL3State;
        let responseObj = this.startupL3Responses;
        let obsToSignal : BehaviorSubject<any> = this._startupRequestsL3State$;
        let missingVals : string[] = Object.keys(stateObj).filter((key:string) => responseObj[key] != true);
        if (0 == missingVals.length && false == stateObj.isInit) {
            stateObj.isInit = true;
            // init some worker clases while we're here if we have all data
            let allOk = true;
            if (! Array.isArray(stateObj.importhistory) || 0 == stateObj.importhistory.length) {
                this.startRequestsTenantNotReadyState = {
                    isInit : true,
                    onboardingOrganizationSignup : true,
                    onboardingHasBegin : true,
                    onboardingImportingHasBegin : false,
                    onboardingImportingComplete : undefined,
                    importHistory : []
                };
                allOk = false;
                this._startupRequestsTenantNotReady$.next(this.startRequestsTenantNotReadyState);   
            } else {
                let completedImports = stateObj.importhistory.filter((importItem:any) => importItem.status == EntityMwimporterworkload_Status.COMPLETE && importItem.objecttype == OrbiObjectType.GithubRepo);
                if (0 == completedImports.length) {
                    this.startRequestsTenantNotReadyState = {
                        isInit : true,
                        onboardingOrganizationSignup : true,
                        onboardingHasBegin : true,
                        onboardingImportingHasBegin : true,
                        onboardingImportingComplete : false,
                        importHistory : []
                    };
                    allOk = false;
                    this._startupRequestsTenantNotReady$.next(this.startRequestsTenantNotReadyState);   
                }
            }

            // if all the checks workedout then we can signal the init 
            if (allOk) {
                if (this.startupRequestsL0State && this.startupRequestsL1State && this.startupRequestsL2State && this.startupRequestsL3State) {
                    OanAnalyzerParams.init([this.startupRequestsL0State, this.startupRequestsL1State, this.startupRequestsL2State, this.startupRequestsL3State ]);
                }

                obsToSignal.next(stateObj);
            }
        }
    } 

        /*
        let missingVals : string[] = Object.keys(this.startupRequestsL2State).filter((key:string) => this.startupL2Responses[key] != true);
        if (0 == missingVals.length && false == this.startupRequestsL2State.isInit) {
            this.startupRequestsL2State.isInit = true;
            // init some worker clases while we're here
            OanAnalyzerParams.init([this.startupRequestsState, this.startupRequestsL2State]);
            this._startupRequestsL2State$.next(this.startupRequestsL2State);
        }
    } */

    // this can be called once 
    private async getUserProfileInfo() {
        this.svcProfile.profileinfo$.subscribe({
                next: (val: any) => {
                    if ("object" == typeof val?.data?.datamap?.userinfo) {
                        let userInfo : any = val.data.datamap.userinfo
                        this.startupRequestsL1State.userInfo = userInfo;
                        this.startupL1Responses.userInfo = true;

                        this.startupRequestsL1State.accessibleresources = val.data?.datamap?.accessibleresources;
                        this.startupL1Responses.accessibleresources = true;

                        // check if we now have everything
                        this.signalL2InitIfL1Ready("getUserProfileInfo");
                    }
                },
                error: (err:any) => {
                    OanDebugutils.debuggerWrapper(".?.");
                    console.error(err);
                }
            });

        // get the user profile
        this.svcProfile.apiProfileInfo();
    }

    async doStartupRequestsL0(){
        if (true != this.startupL0Responses.isInit)
        {
            this.startupL0Responses.isInit = true;
            this.startupL0Responses.svcurls = true;
            let svcurls = await this.svcApiPub.getTenantInfo();
            this.startupRequestsL0State.svcurls = svcurls;

            let amILoggedIn = await this.svcApiPub.amILoggedIn().subscribe({ 
                error: (err  : any) => { 
                    this.startupRequestsL0State.amILoggedIn = false;
                    this.startupL0Responses.amILoggedIn = true;
                    this.signalL1InitIfL0Ready("not Signed in");
                    OanDebugutils.debuggerWrapper(err);
                },
                next: (o : any) => {
                    if (o.isInit) {
                        this.startupRequestsL0State.amILoggedIn = o.amILoggedIn;
                        this.startupL0Responses.amILoggedIn = true;
                        this.signalL1InitIfL0Ready("Signed in");
                    }
                }
            });
        }
    }

    async doStartupRequestsL1(){
        if (true != this.startupL1Responses.isInit)
        {
            this.startupL1Responses.isInit = true;
            OanDebugutils.debuggerWrapperOnCondition(! this.startupRequestsL0State.amILoggedIn, "Should only do this if logged in");
            this.svcProfile.userprefs$.pipe(
                switchMap(async (val:any) => { 
                    let userprefs = val.userprefs;
                    await this.svcStorage.setUserPrefsFromProfileApi(userprefs);

                    // userprefs.storageKeyInfo
                    this.startupL1Responses.userprefs = true;
                    this.startupL1Responses.storageKeyInfo = true;
                    this.startupRequestsL1State.userprefs = userprefs;
                    this.startupRequestsL1State.storageKeyInfo = userprefs?.storageKeyInfo

                    // see if storage has a default orgName
                    let activeOrgName = await this.svcProfile.loadActiveOrgName();
                    return val;
                })).subscribe({
                    next: (valuserprefs:any) => {
                        // check if we now have everything
                        this.signalL2InitIfL1Ready("userprefs$");
                    },
                    error: (err:any) => {
                        console.error(`doStartupRequests  - failed request ${err}`);
                    }
                }); 

            // get user profile stuff
            this.svcProfile.apiGetUserBrowserStorageKey();
            this.getUserProfileInfo();
        }
    }

    /*
    private requestsL3ObserverOrgSettings = {
        next: (value: HttpEvent<any[]>) => {                
            var httpResp : HttpResponse<any[]> = <HttpResponse<any[]>>(<unknown>value);
            if (httpResp.status >= 200 && httpResp.status < 300) {                
                let tenantSettings : any[] = (httpResp.body && Array.isArray(httpResp.body)) ? httpResp.body : [];                    
                tenantSettings.forEach(element => {
                    switch (element.settinggroup) {
                        case OrbiSettingGroupName.GithubOrg:
                            switch(element.settingobject) {
                                case OrbiSettingNvpName.GithubOrg_TopicInfo: {
                                    // TBD ### add a type attr to these payloads so we can do some santiy checks
                                    // e.g. element.payload.type = { "repotopics", version "20211231" }
                                    this.startupL3Responses.repoTopics = true;
                                    this.startupL3Responses.repoTopicMap = true;
                                    this.startupRequestsL3State.repoTopicMap = [];
                                    this.startupRequestsL3State.repoTopics = [];
                                    if (element.payload) {
                                        this.startupRequestsL3State.repoTopicMap = element.payload;
                                        this.startupRequestsL3State.repoTopics.push(...Object.keys(element.payload));
                                    }                                 
                                }
                                break;
                                case OrbiSettingNvpName.Github_RepoSet: {
                                    // TBD ### add a type attr to these payloads so we can do some santiy checks
                                    // e.g. element.payload.type = { "repotopics", version "20211231" }
                                    this.startupL3Responses.reposummaryList = true; 
                                    this.startupRequestsL3State.reposummaryList = element.payload;                       
                                }
                                break;
                            }
                    }
                });

                let settingsGroups = tenantSettings.map((currSetting:any) => currSetting.settinggroup);
                this.signalReadyIfL3Ready("requestsL3Observer; " + settingsGroups.join(","));
            }

            let r = true;
            return r;
        },
        error: (err: any) => {
            console.error(err);
            OanDebugutils.debuggerWrapper(err);
        }
    }; */

    private requestsL3ObserverGithubSettings = {
        next: (val: ApiMwgithubResult) => {        
            if (val.httpStatus != HttpStatusCode.EarlyHints) {      
                if (Array.isArray(val?.data) && 0 < val?.data.length) {
                    let settingGroup = val?.data[0].settinggroup;
                    switch (settingGroup) {
                        case OrbiSettingGroupName.GithubOrg:
                            this.startupL3Responses.repoTopics = true;
                            this.startupL3Responses.repoTopicMap = true;
                            this.startupRequestsL3State.repoTopicMap = [];
                            this.startupRequestsL3State.repoTopics = [];
                            if (Array.isArray(val?.data) && 0 < val?.data.length
                                && val.data[0].settingobjects
                                && val.data[0].settingobjects[OrbiSettingNvpName.GithubOrg_TopicInfo]
                                && val.data[0].settingobjects[OrbiSettingNvpName.Github_RepoSet])
                            {
                                // TBD ### add a type attr to these payloads so we can do some santiy checks
                                // e.g. element.payload.type = { "repotopics", version "20211231" }                        
                                let payload = val.data[0].settingobjects[OrbiSettingNvpName.GithubOrg_TopicInfo];
                                if (payload) {
                                    this.startupRequestsL3State.repoTopicMap = payload;
                                    this.startupRequestsL3State.repoTopics = Object.keys(payload);
                                    // this.startupRequestsL3State.repoTopics.push(...payload);
                                }

                                this.startupL3Responses.reposummaryList = true; 
                                let payloadRepoSet = val.data[0].settingobjects[OrbiSettingNvpName.Github_RepoSet];
                                if (payloadRepoSet?.repoSummaryList) {
                                    this.startupRequestsL3State.reposummaryList = payloadRepoSet.repoSummaryList;
                                    this.startupRequestsL3State.aggregates.reposById = {};
                                    this.startupRequestsL3State.reposummaryList.forEach((currRepo: any) => {
                                        this.startupRequestsL3State.aggregates.reposById[currRepo.repoId] = currRepo;
                                    });
                                } else {
                                    this.startupRequestsL3State.reposummaryList = [];
                                }

                                this.startupL3Responses.teamsAndMembers = true; 
                                let payloadMembersSet = val.data[0].settingobjects[OrbiSettingNvpName.GithubOrg_Members];
                                this.startupRequestsL3State.teamsAndMembers = { teams: [], members: [] };
                                if (Array.isArray(payloadMembersSet?.teams)) {
                                    this.startupRequestsL3State.teamsAndMembers.teams = payloadMembersSet?.teams;
                                } 

                                if (Array.isArray(payloadMembersSet?.members)) {
                                    this.startupRequestsL3State.teamsAndMembers.members = payloadMembersSet?.members;
                                    this.startupRequestsL3State.aggregates.usersById = {};
                                    this.startupRequestsL3State.teamsAndMembers.members.forEach((currUser: any) => {
                                        this.startupRequestsL3State.aggregates.usersById[currUser.orgmemberid] = currUser;
                                    });
                                } 
                            }
                            break;
                        case OrbiSettingGroupName.GithubLiveProductionAssetsBranches:
                            this.startupL3Responses.settingsLiveBranch = true;
                            this.startupRequestsL3State.settingsLiveBranch = val?.data[0].settingobjects;
                            break;
                        case OrbiSettingGroupName.GithubLiveProductionAssetsRepos:
                            this.startupL3Responses.settingsLiveRepo = true;
                            this.startupRequestsL3State.settingsLiveRepo = val?.data[0].settingobjects;
                            break;
                    } 

                    this.signalReadyIfL3Ready("requestsL3Observer; requestsL3ObserverGithubSettings");                
                } else {
                    OanDebugutils.debuggerWrapper("requestsL3ObserverGithubSettings - unexpected response format; maybe queried apiSetting via tenantSetting interface");
                }
            }
        },
        error: (err: any) => {
            console.error(err);
            OanDebugutils.debuggerWrapper(err);
        }
    };

    async dostartupL3Requests() {
        let selectedOrgname = this.svcProfile.getActiveOrgName();
        if (selectedOrgname) {
            this.startupL3Responses.isInit = true;
            this.svcGithub.importhistory$.subscribe({
                next: (val: any) => {
                    if (val.httpStatus != HttpStatusCode.EarlyHints) {
                        this.startupL3Responses.importhistory = true;
                        this.startupRequestsL3State.importhistory = val.data;
                        this.signalReadyIfL3Ready("importhistory");
                    }                    
                },
                error: (err:any) => {
                    OanDebugutils.debuggerWrapper(".?.");
                    console.error(err);
                }
            });

            this.svcGithub.apiImportHistory(selectedOrgname!); 
            this.svcSettings.githubsettingsGet$.subscribe(this.requestsL3ObserverGithubSettings);
            this.svcSettings.apiGithubSettings(selectedOrgname, OrbiSettingGroupName.GithubOrg);
            this.svcSettings.apiGithubSettings(selectedOrgname, OrbiSettingGroupName.GithubAuditSettings);            
            this.svcSettings.apiGithubSettings(selectedOrgname, OrbiSettingGroupName.GithubLiveProductionAssetsBranches);
            this.svcSettings.apiGithubSettings(selectedOrgname, OrbiSettingGroupName.GithubLiveProductionAssetsRepos); 
        }
    }


    async doStartupL2Requests() {
        let selectedOrgname = this.svcProfile.getActiveOrgName();
        if (selectedOrgname) {
            this.startupL2Responses.isInit = true;

            this.svcGithub.healthcheck$.subscribe({
                next: (val: any) => {
                    if (val.httpStatus != HttpStatusCode.EarlyHints && HttpStatusCode.Ok == val.httpStatus) {
                        this.startupL2Responses.isTargetGithubOrg = true;
                        this.startupL2Responses.healthcheckAuthz = true;
                        this.startupL2Responses.authorizedTenant = true;
                        this.startupL2Responses.hasActiveTenant = true;
                        let payload = val.data[0];
                        this.startupRequestsL2State.isTargetGithubOrg = payload?.isTargetGithubOrg;
                        this.startupRequestsL2State.healthcheckAuthz = payload?.healthcheckAuthz;
                        this.startupRequestsL2State.hasActiveTenant = payload?.hasActiveTenant;
                        this.startupRequestsL2State.authorizedTenant = val.context?.authorizedTenant;
                        this.signalL3IfL2Ready("healthcheckAuthz");
                    }
                },
                error: (err:any) => {
                    OanDebugutils.debuggerWrapper(".?.");
                    console.error(err);
                }
            });
            setTimeout(() => {    
                this.svcGithub.apiHealthcheck(selectedOrgname!);
            });                       
        } else {
            debugger;
        }
    }

    async healthCheckAuthzError(err: HttpErrorResponse)
    {
        this.startupL2Responses.healthcheckAuthz = false;
        this.signalL3IfL2Ready("healthcheckAuthz");
    }
}
