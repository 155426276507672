<div class="table-scroll-holder">
    <table class="table orbi-table table-row-hover">
        <thead>
        <!-- <tr> -->
            <th class="orbirownumber" >                
                <!-- this is for the status icon -->
                <div><span></span></div>
            </th>
            <th class="orbiiconcol" >                
                <!-- this is for the status icon -->
                <div><span>Status</span></div>
            </th>
            <th class="orbirelevancecol" >                
                <!-- this is for the status icon -->
                <div><span>Relevance</span></div>
                <div class="icon-filter" type="button" (click)="this.filter.filterBy(relevanceCol)">
                    <app-orbiicon  iconName="faFilter"></app-orbiicon>
                </div>
            </th>
            <th class="orbiimpactscol">
                <div><span>Impacts</span></div>               
            </th>
            <th *ngFor="let currCol of renderedTableHeaders" 
                [ngClass]="currCol.hdrClasses"
                [ngStyle]="currCol.ngStyleObj" 
                [hidden]="! currCol.colVisible"
                scope="col">
                <div draggable="true" (dragstart)="currCol.dragstart($event)" (dragend)="currCol.dragend($event)"
                        class="resize-handle">
                    </div>
                <div class="th-inner">
                    <div class="label"><span class="resultsTitleRotate">{{currCol.title}}</span></div>
                    <div class="button-holder">
                        <div *ngIf="currCol.isSortable" 
                            class="icon-sort" type="button" (click)="this.sorter.sortBy(currCol)">
                            <app-orbiicon iconName="faCaretDown"></app-orbiicon>                          
                        </div>
                        <div *ngIf="currCol.isFilterable"
                            class="icon-filter" type="button" (click)="this.filter.filterBy(currCol)">
                            <app-orbiicon  iconName="faFilter"></app-orbiicon>
                        </div>                
                    </div>

                </div>
                          
            </th>
        <!-- </tr> -->
        </thead>
        <tbody>
        <tr *ngFor="let i = index; let currRow of renderedFilteredTableData"
            [hidden]="! currRow.isFilteredIn "
            [ngClass]="currRow.rowClasses.cssclass">
            <td>        
                {{i+1}}        
            </td>
            <td class="status-col" [ngClass]="currRow.statusCol.cssclass">                
                <app-orbiicon iconName="faExclamationTriangle"></app-orbiicon>
                <app-orbiicon iconName="faBan" title=""></app-orbiicon>
                <app-orbiicon iconName="faCheck" title=""></app-orbiicon>
            </td>
            <td [ngClass]="currRow.relevanceCol.cssclass">                
                <app-orbiicon iconName="faExclamationTriangle" ></app-orbiicon>
                <app-orbiicon iconName="faStar" title=""></app-orbiicon>
                <app-orbiicon iconName="faCheck" title=""></app-orbiicon>
                <ul>
                    <li *ngFor="let currReason of currRow.relevanceCol.reasons" [ngClass]="currReason.reasonClass">{{currReason.reasonText}}</li>
                </ul>
            </td>
            <td class="impacts-col">
                <div class="badge-holder">                    
                    <span *ngIf="currRow.rowMeta.isLive" class="badge badge-pill badge-live"  >
                        <app-orbiicon iconName="faShieldAlt" placement="top tooltipClass="my-custom-class ngbTooltip="Important events"></app-orbiicon> Live</span>                    
                    <span *ngIf="! currRow.rowMeta.isLive" class="badge badge-pill badge-not-live">
                        <app-orbiicon iconName="faFilter" placement="top tooltipClass="my-custom-class ngbTooltip="Important events"></app-orbiicon> Not live</span>                    
                </div>
                <span class="impacts-col-rowflag" *ngFor="let currRowFlag of currRow.impactCol.meta.rowFlags" [ngClass]="currRowFlag.classname">{{currRowFlag.label}}</span>                
                <br>
                <span class="impacts-col-explainmsg" *ngIf="0 < currRow.impactCol.reasons.length">
                    <ul>
                        <li *ngFor="let currExplain of currRow.impactCol.reasons">{{currExplain}}</li>
                    </ul>
                </span>                
            </td>            
            <td *ngFor="let currCol of renderedTableHeaders; let currColRowIndex = index;  "                 
                class="resultsColMaxWidth" [ngClass]="currRow.cells[currColRowIndex].cellClasses!"> 
                <!-- {{    debugJsonStringify([currRow, currCol, currColRowIndex, currRow?.cells]) }} -->
                <span>       
                    <a *ngIf="currCol.copiable" class="hovericons" 
                        ngbTooltip="{{currCol.copied ? 'Copied' : 'Copy to clipboard' }}"                                                                      
                        (click)="copyValueToClipboard(undefined, currCol, currRow.cells[currColRowIndex])"><app-orbiicon iconName="faCopy"></app-orbiicon>&nbsp;</a>
                    <span *ngIf="currRow.cells[currColRowIndex]?.link">
                        <a *ngIf="currRow.cells[currColRowIndex]?.link" [target]="currCol.linkTarget" [attr.href]="currRow.cells[currColRowIndex].link">
                            <app-orbiicon iconName="faExternalLink" ></app-orbiicon>
                            {{currRow.cells[currColRowIndex].val}}</a>                         
                    </span>

                    <span *ngIf="currRow.cells[currColRowIndex].linkArray">
                        <span *ngFor="let arraylinkElI=index; let arraylinkCurr of currRow.cells[currColRowIndex].linkArray">
                            <a target="_blank" [attr.href]="arraylinkCurr.linkHref">
                                <app-orbiicon iconName="faExternalLink"></app-orbiicon>
                                {{arraylinkCurr.linkText}}</a> 
                            &nbsp;
                        </span>
                    </span>
                    
                    
                    <span *ngIf="! currRow.cells[currColRowIndex].linkArray && ! currRow.cells[currColRowIndex].link">                        
                        {{currRow.cells[currColRowIndex].val}}
                    </span>
                </span>
            </td>        
        </tr>
        </tbody>
    </table>
    <!-- 
    <div>
        <button class="btn btn-filter btn-sm selected" (click)="this.debugCheckUnknownResults() ">debugCheckUnknownResults</button>
        <button class="btn btn-filter btn-sm selected" (click)="this.triggerRenderAuditResults()">triggerRenderAuditResults</button>


        Objects with no audit results: 
        <span id="objsWithNoAuditResults">
            {{debugJsonStringify(objsWithNoAuditResults)}}
        </span>
        <a href="navigatory.clipboard.writeText(document.getElementById('objsWithNoAuditResults').select.setSelectionRange(0,9999))"><app-orbiicon iconName="faCopy"></app-orbiicon></a>
    </div>
-->
</div>
