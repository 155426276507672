import { OrbiObjectType } from "@danclarke2000/gitrospectdto";

export enum OrbiReportType {
    GithubWebhookEventOrg = "OrbiReportType_github.webhookeventorg",
    GithubWebhookEventSourceCode = "OrbiReportType_github.webhookeventsourcecode",
    GithubWebhookEventProjMgmt = "OrbiReportType_github.webhookeventprojmgmt",
    GithubWebhookEventCiCd = "OrbiReportType_github.webhookeventcicd",
    GithubRepo = "OrbiReportType_github.repo",
    GithubRepoPublic = "OrbiReportType_github.repopublic",
    GithubBranchProtection = "OrbiReportType_github.branchprotection",
    GithubAccessControl = "OrbiReportType_github.accesscontrol",
    GithubPullRequest = "OrbiReportType_github.pullrequest",
    GithubReleaseTag = "OrbiReportType_github.releasetag",
    GithubWorkflow = "OrbiReportType_github.workflow",
    GithubWorkflowRun = "OrbiReportType_github.workflowrun",
    GithubCodeAnalysisVulns = "OrbiReportType_github.codeanalysis.vulns",
    GithubCommit = "OrbiReportType_github.commit",
    GithubUser = "OrbiReportType_github.user",
    GithubTeam = "OrbiReportType_github.team",
    GithubTeamMember = "OrbiReportType_github.teammember",
    GithubEnvironment = "OrbiReportType_github.environment",
    GithubSecret = "OrbiReportType_github.secret",
    OrbiSetting = "OrbiReportType_orbi.setting",
    Unknown = "OrbiReportType_unknown"
}


export enum UiLabels_SyntheticTabs_GithubReports
{
    NavItemReposPublic = "navitem_synthetic_repospublic",
    NavItemAccessControl = "navitem_synthetic_accesscontrol",
    NavItemSettings = "navitem_synthetic_settings",
    NavItemTeamMembers = "navitem_synthetic_teammember"
};

export class OrbiReportTypeMapper {
    public static toObjectTypes = {
        [OrbiReportType.GithubWebhookEventOrg] : OrbiObjectType.GithubWebhookEvent,
        [OrbiReportType.GithubWebhookEventSourceCode] : OrbiObjectType.GithubWebhookEvent,
        [OrbiReportType.GithubWebhookEventProjMgmt] : OrbiObjectType.GithubWebhookEvent,
        [OrbiReportType.GithubWebhookEventCiCd] : OrbiObjectType.GithubWebhookEvent,
        [OrbiReportType.GithubRepo] : OrbiObjectType.GithubRepo,
        [OrbiReportType.GithubRepoPublic] : OrbiObjectType.GithubRepo,
        [OrbiReportType.GithubBranchProtection] : undefined,
        [OrbiReportType.GithubAccessControl] : OrbiObjectType.GithubRepoAccessControl,
        [OrbiReportType.GithubPullRequest] : OrbiObjectType.GithubPrsForRepo,
        [OrbiReportType.GithubReleaseTag] : OrbiObjectType.GithubReleaseForRepo,
        [OrbiReportType.GithubWorkflow] : OrbiObjectType.GithubWorkflowsForRepo,
        [OrbiReportType.GithubWorkflowRun] : OrbiObjectType.GithubWorkflowRunsForRepo,
        [OrbiReportType.GithubCodeAnalysisVulns] : OrbiObjectType.GithubVulnsForRepo,
        [OrbiReportType.GithubCommit] : OrbiObjectType.GithubCommitsForRepo,
        [OrbiReportType.GithubUser] : OrbiObjectType.GithubUser,
        [OrbiReportType.GithubTeam] : OrbiObjectType.GithubTeam,
        [OrbiReportType.GithubTeamMember] : OrbiObjectType.GithubTeam,
        [OrbiReportType.GithubEnvironment] : OrbiObjectType.GithubEnvironment,
        [OrbiReportType.GithubSecret] : OrbiObjectType.GithubSecret,
        [OrbiReportType.OrbiSetting] : undefined,
        [OrbiReportType.Unknown] : undefined,
        [UiLabels_SyntheticTabs_GithubReports.NavItemReposPublic] : OrbiObjectType.GithubRepo,
        [UiLabels_SyntheticTabs_GithubReports.NavItemAccessControl] : OrbiObjectType.GithubRepoAccessControl,
        [UiLabels_SyntheticTabs_GithubReports.NavItemSettings] : undefined,
        [UiLabels_SyntheticTabs_GithubReports.NavItemTeamMembers] : undefined
    }
};

export class UiLabels {
    
    public static workbookLabelsByTabId = {
        [OrbiReportType.GithubWebhookEventOrg] : 'Events org',
        [OrbiReportType.GithubWebhookEventSourceCode] : 'Events sourcecode',
        [OrbiReportType.GithubWebhookEventProjMgmt] : 'Events projmgmt',
        [OrbiReportType.GithubWebhookEventCiCd] : 'Events cicd',
        [OrbiReportType.GithubRepo] : 'Repositories',
        [UiLabels_SyntheticTabs_GithubReports.NavItemReposPublic] : 'Public repositories',
        [OrbiReportType.GithubPullRequest] : 'Pull request',
        [OrbiReportType.GithubCommit] : 'Commit',
        [OrbiReportType.GithubReleaseTag] : 'Releases',
        [OrbiReportType.GithubWorkflow] : 'Workflows',
        [OrbiReportType.GithubWorkflowRun] : 'Workflow runs',
        [OrbiReportType.GithubUser] : 'Users',
        [OrbiReportType.GithubTeam] : 'Teams ',
        [UiLabels_SyntheticTabs_GithubReports.NavItemTeamMembers] : 'Team membership',
        [UiLabels_SyntheticTabs_GithubReports.NavItemAccessControl] : 'Access control',
        // [UiLabels_SyntheticTabs_GithubReports.NavItemAccessControlDeployKeys ] : 'Deploy keys',
        [OrbiReportType.GithubCodeAnalysisVulns] : 'Vulnerability mgmt',
        [OrbiReportType.GithubEnvironment] : 'Environment secrets',
        [OrbiReportType.GithubSecret] : 'Github secrets',
        [UiLabels_SyntheticTabs_GithubReports.NavItemSettings] : 'Settings'
    };

    public static importerobjtypeLabels = {
        [OrbiObjectType.GithubParent] : 'Github parent',
        [OrbiObjectType.GithubRepo] : 'Github repo',
        [OrbiObjectType.GithubReleaseForRepo] : 'Github release',
        [OrbiObjectType.GithubPrsForRepo] : 'Github PRs',
        [OrbiObjectType.GithubCommitsForRepo] : 'Github commits',
        [OrbiObjectType.GithubVulnsForRepo] : 'Github vulns',
        [OrbiObjectType.GithubWorkflowsForRepo] : 'Github workflows',
        [OrbiObjectType.GithubWorkflowRunsForRepo] : 'Github workflow runs',
        [OrbiObjectType.GithubWebhookEvent] : 'Github audit event',
        [OrbiObjectType.GithubUser] : 'Github user',
        [OrbiObjectType.GithubUserOauthInfo] : 'Github user',
        [OrbiObjectType.GithubTeam] : 'Github team',
        [OrbiObjectType.GithubRepoAccessControl] : 'Github repo access control',
        [OrbiObjectType.GithubEnvironment] : 'Github environment',
        [OrbiObjectType.GithubSecret] : 'Github secret'
    };
}
