
<!--   -->
        <div ngbRadioGroup class="btn-group app-setting-buttons" role="group">
            <span  class="" *ngFor="let currValue of this.uiState.possiblevalues">
                <label ngbButtonLabel class="btn btn-primary"  [ngClass]="{'active':(currValue.key == this.model.selectedvalue) || isActive(currValue)}" [for]="currValue.key">
                    <input ngbButton type="radio" [value]="currValue.key" class="btn-check d-none" 
                        [ngClass]="(currValue.key == this.model.selectedvalue) ? 'active' : ''" 
                        [formControl]="frmCtrlRadio" [id]="currValue.key" autocomplete="off">
                    {{currValue.label}}
                </label>
            </span>
        </div> 


