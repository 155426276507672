import { Injectable } from "@angular/core";
import { OrbiObjectType } from "@danclarke2000/gitrospectdto";
import { OrbiReportType } from "../cmn/ui/UiLabels";

class Impl 
{
    public static mapOrbiReportTypeToObjectType(reportType:OrbiReportType) : OrbiObjectType
    {
        let r : OrbiObjectType | undefined;
        switch (reportType) 
        { 
            case OrbiReportType.GithubWebhookEventOrg:
            case OrbiReportType.GithubWebhookEventCiCd:
            case OrbiReportType.GithubWebhookEventSourceCode:
            case OrbiReportType.GithubWebhookEventProjMgmt:
                r = OrbiObjectType.GithubWebhookCallback;
                break;

            case OrbiReportType.GithubRepo:
            case OrbiReportType.GithubRepoPublic:
                r = OrbiObjectType.GithubRepo;
                break;

            default:
                // TBD remove when this is complete
                r = OrbiObjectType.GithubParent;
        }

        if (! r) {
            debugger;
            throw new Error(`mapOrbiReportTypeToObjectType - Cannot convert ${reportType} to OrbiObjectType`);
            r = OrbiObjectType.GithubParent;
        }
        
        return r;
    }
}

@Injectable({
    providedIn: 'root'
})
export class OrbiAuditResultsManagerService 
{
    // "OrbiReportType_github.auditeventorg"
    // private 
    private pendingAuditResultsTab : string | undefined;
    private auditResultsMap : any = {};
    private tabState: any | undefined;
    private getObjectTypeKey(myObjectType:OrbiObjectType) {
        let indexer = [myObjectType];
        let currRootObj = this.auditResultsMap;
        indexer.forEach( (currKey:string) => {
            if (! currRootObj.hasOwnProperty(currKey)) {
                currRootObj[currKey] = {};
            }

            currRootObj = currRootObj[currKey];
        });

        return this.auditResultsMap[myObjectType];
    }

    private getObjectTypeKeyFromReport(reportType:OrbiReportType) {

        let myObjectType : OrbiObjectType = Impl.mapOrbiReportTypeToObjectType(reportType);
        let result = this.getObjectTypeKey(myObjectType);
        return result;
    }

    constructor() 
    {}

    getAuditResultsForObjectType(objectType:OrbiObjectType) {
        let myKey = this.getObjectTypeKey(objectType);
        return myKey;
    }

    addAuditResultsWithObjectType(objectType:OrbiObjectType, auditResults:any)
    {
        let myKey : any | undefined;
        if (undefined != objectType) {
            myKey = this.getObjectTypeKey(objectType);
            if (Array.isArray(auditResults)) {
                auditResults.forEach((curr:any) => {
                    // could be an optimisation here, as if the cache has anything from a more recent audit, then we can skip this for all
                    // however it may not be fully safe so not chancing it
                    let idRef = undefined; 
                    if (0 < curr.auditresult.objectid) {
                        idRef = curr.auditresult.objectid;
                    } else if ("string" == typeof curr.auditresult.objectidlogical && 0 < curr.auditresult.objectidlogical.length) {
                        idRef = curr.auditresult.objectidlogical;
                    } else {
                        debugger;
                    }

                    if (idRef) {
                        if (undefined != myKey[idRef])  {
                            let existing = myKey[idRef];
                            if (existing.auditresult.arImporterParentId > curr.auditresult.arImporterParentId) {
                                myKey[idRef] = curr;
                            }
                        } else {
                            myKey[idRef] = curr;
                        }
                    } else {
                        debugger;
                    }
                });
            } else {
                debugger;
            }
        } else {
            debugger;
        }

        return myKey;
    }

    /*
    hasAuditResults(fromDate:string, toDate:string, auditEnvelopeId:string, objectType:string)
    {
        let myKey = this.getKeyForObjectAndDates(objectType, auditEnvelopeId);
        return "object" == typeof myKey;
    } */

    getAuditResultsForObject(reportType:OrbiReportType, objectId:string)
    {
        let result = undefined;
        if (reportType && objectId)
        {
            let myKey = this.getObjectTypeKeyFromReport(reportType);
            result = myKey[objectId];
        }

        return result;
    }

    setPendingTabForGetAuditResults(tabState: any, currentlyActiveTab:string) {
        this.tabState = tabState;
        this.pendingAuditResultsTab = currentlyActiveTab;
    }
}
