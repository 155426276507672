import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, ReplaySubject, Subject, SubscriptionLike, throwError} from 'rxjs';
import { DecimalPipe} from '@angular/common';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { OrbiHttpOptions } from 'src/app/cmn/svc/orbibackend.types';
import { OrbiSettingNvpName, OrbiSettingDTO } from '@danclarke2000/gitrospectdto';
import  { ApiMwgithubResult } from '@danclarke2000/gitrospectdto';
// import * as internal from 'stream';
import { OrbiRequest, OrbiRequestBase } from 'src/app/cmn/svc/orbirequest';
import { OrbibackendPublicService } from './orbibackend.public.service';
import { OanDebugutils } from '../cmn/utils/OanDebugutils';
import { OrbiSvcArgsValidator } from './orbisvcargsvalidator';

  

@Injectable({
  providedIn: 'root'
})
export class OrbisettingsService {

    // private apiRoot : string | undefined = (environment.urlPrivbeMw) ? environment.urlPrivbeMw : environment.urlPrivbe;
    // private apiBase : string | undefined = this.apiRoot + '/settings';

    private apiRoot2 : string | undefined;
    private composeUrl(path:string) {
        if (! this.apiRoot2) {
            this.apiRoot2 = this.svcOrbiBePublic.getUrlbaseTenantSettings();
        }

        return this.apiRoot2 + path;
    };

    // /tenantsettings/githubsettings/:orgname/:schemaname
    // 
    private readonly API_ROUTES = {
        githubsettings: (orgName:string, groupName:string) => this.composeUrl(`/githubsettings/${orgName}/${groupName}`),
        postgithubsettings: (orgName:string,groupName:string,objName:string) => this.composeUrl(`/githubsettings/${orgName}/${groupName}/${objName}`),
        getsettingsgroup: (orgName:string,groupName:string) => this.composeUrl(`/getsettinggroup/${orgName}/${groupName}`),
    };

    private _githubsettingsGet$ = new ReplaySubject<ApiMwgithubResult>();
    private _githubsettingsPost$ = new ReplaySubject<ApiMwgithubResult>();

    constructor(
        private svcOrbiBePublic : OrbibackendPublicService,
        private httpClient: HttpClient, 
        private pipe: DecimalPipe) {

    }

    get githubsettingsGet$() { return this._githubsettingsGet$.asObservable(); }
    get githubsettingsPost$() { return this._githubsettingsPost$.asObservable(); }
    
    public subscribeObs(fnName: string, obsHttpEv : Observable<any>, bsVal: ReplaySubject<ApiMwgithubResult>, bsLoading: BehaviorSubject<any>) {
        bsLoading.next({ isLoading: true, msg: "", ctxt: "bsLoading" });

        obsHttpEv.subscribe({
            complete: () => {
                console.log("blah");
            },
            next: (val: any) => {
                bsVal.next(val.body);
                bsLoading.next({ isLoading: false, msg: "", ctxt: "bsLoading2" });                
            },
            error: (err:any) => {
                OanDebugutils.debuggerWrapper(".?.");
                bsLoading.next({ isLoading: false, msg: "", ctxt: "bsLoading3" });
                console.error(fnName + ": " + JSON.stringify(err)); 
                throwError(err);
            }
        });
    }

    public apiGithubSettings(orgName:string, groupName:string) {
        let fnName = "apiGithubSettings(";
        let url : string = this.API_ROUTES.githubsettings(orgName, groupName);
        let argsOk = OrbiSvcArgsValidator.validateOrgName(fnName, orgName) 
            && OrbiSvcArgsValidator.validateSettingGroupName(fnName, groupName);        
        if ( argsOk ) {      
            let obsHttpEv : Observable<HttpEvent<OrbiSettingDTO[]>> = this.httpClient.get<OrbiSettingDTO[]>(url, OrbiHttpOptions.getHttpOptions());
            this.subscribeObs(fnName, obsHttpEv, this._githubsettingsGet$, OrbiRequestBase.getLoadingBs());
        } else {
            throw new Error(`${fnName} - orgName or groupName not defined orgName=${orgName} groupName=${groupName}`);
        }
    }

    public apiGithubSettingsUpdate(orgName:string, groupName:string, objName:string, updateSettings:any) {
        let fnName = "apiGithubSettingsUpdate";
        let url : string = this.API_ROUTES.postgithubsettings(orgName,groupName,objName);
        let opts : any = OrbiHttpOptions.getHttpOptions();
        let argsOk = OrbiSvcArgsValidator.validateOrgName(fnName, orgName) 
            && OrbiSvcArgsValidator.validateSettingGroupName(fnName, groupName);  
        if ( argsOk ) {      
            let obsHttpEv : Observable<HttpEvent<any>> = this.httpClient.post<any>(url, updateSettings, opts);
            this.subscribeObs(fnName, obsHttpEv, this._githubsettingsPost$, OrbiRequestBase.getLoadingBs());
        } else {
            throw new Error(`${fnName} - orgName or groupName not defined orgName=${orgName} groupName=${groupName}`);
        }

        return this.githubsettingsPost$;
    }

    //
    // this is useful for things which are not useful in many places such as sync date objects
    // most things should go through apiGithubSettings as that has logic for default values
    public apiSettingsGroup(orgName:string, groupName:string) : Observable<HttpEvent<any[]>> {
        let fnName = "apiSettingsGroup(";
        let obsHttpEv : Observable<HttpEvent<any[]>> | undefined= undefined;
        let argsOk = OrbiSvcArgsValidator.validateOrgName(fnName, orgName) 
            && OrbiSvcArgsValidator.validateSettingGroupName(fnName, groupName);
        if ( argsOk ) {
            let url : string = this.API_ROUTES.getsettingsgroup(orgName, groupName);
            obsHttpEv = this.httpClient.get<OrbiSettingDTO[]>(url, OrbiHttpOptions.getHttpOptions());
        } else {
            throw new Error(`${fnName} - orgName or groupName not defined orgName=${orgName} groupName=${groupName}`);
        }
        return obsHttpEv;
    }  
}
