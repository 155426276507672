<p>Welcome to gitrospect ! </p> 

<ng-template #installationContent let-cmp>
    <ul>
        <table class="table">
            <thead>
            <tr>
                <th scope="col">GitHub resource</th>
                <th scope="col">Resource type</th>
                <th scope="col">GitRospect installed</th>
                <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
                <tr scope="row" *ngFor="let is of uiState.installationStatus">
                    <td>{{is.objectName}}</td>
                    <td>{{is.objectType}}</td>
                    <td>{{ is.isInstalled ? "Yes" : "Not yet" }}</td>
                    <td><button *ngIf="! is.isInstalled" class="btn btn-primary" (click)="uiState.signupOrg(is.objectName)">Sign-up</button></td>
                </tr>
                <tr scope="row">
                    <td>Other private GitHub organization<br><i>Click here if you dont see your organization name above.  This Sign-Up button brings you to the GitHub page where you can choose which organizations you want to use with GitRospect.</i></td>
                    <td>Organization</td>
                    <td></td>
                    <td><button class="btn btn-primary" (click)="uiState.signupOrg()">Sign-up</button></td>
                </tr>
            </tbody>
        </table>
    </ul>
</ng-template>

<app-notification-pagealert *ngIf="uiState.hasSummaryAlert">
    <ngb-alert   [type]="uiState.hasSummaryAlertType" (closed)="uiState.dismissPageAlert($event)">
        <h4 class="summary">{{ uiState.hasSummaryAlertSummary }}</h4>
        <p *ngFor="let currDescLine of uiState.hasSummaryAlertDesc">{{ currDescLine }}</p>
        <button *ngIf="uiState.hasSummaryAlertShowButton['Reload']"  class="btn btn-primary" (click)="uiState.reloadPage()">Reload</button> 
        <button *ngIf="uiState.hasSummaryAlertShowButton['SignUp']"  class="btn btn-primary" (click)="uiState.signupOrg()">Sign-up</button> 
        <a *ngIf="uiState.hasSummaryAlertShowButton['GithubReports']" class="btn btn-primary" href="/mwgithub">GitHub reports</a>
    </ngb-alert>
</app-notification-pagealert>        

<div class="row mb-4 mx-0">
    <div class="col-2 dashboard-sidebar" [hidden]="this.tabState.activeTab != this.navItemDashboard" >
        <!-- SIDEBAR  -->

        <!-- ngbNav #githubContent="ngbNav" [(activeId)]="tabState.tabActive" (navChange)="navChange($event)" --> 
        <ul class="nav-pills" orientation="vertical">            
            <li class="nav-link orbi-nav-link-title"><a 
                [class.disabled]="(uiState.dashboardenabled) ? 'disabled' : null" 
                (click)="this.tabState.setActiveTab(this.navItemDashboard)">Dashboard</a></li>
            <li class="nav-link orbi-nav-link-title"><a 
                (click)="this.tabState.setActiveTab(this.navItemTenantConfigHealth)">Config health</a></li>
            <li class="nav-link orbi-nav-link-title"><a (click)="this.tabState.setActiveTab(this.navItemImportHistory)">Import history</a></li>
            <li class="nav-link orbi-nav-link-title"><a href="/mwgithub">Github reports</a></li>            
        </ul>
    </div>

    <!-- 
        -- Config health
    -->

    <div class="col-10 dashboard-main pr-4" 
        *ngIf="tabState.activeTab == navItemTenantConfigHealth">
        <ngb-accordion #accTenantHealth="ngbAccordion" [closeOthers]="true" [activeIds]="uiState.panelConfigHealth.accordianPanelId">
            <ngb-panel id="ShowPanelIds.AccessibleResources" title="Installation requests">
                    <ng-template ngbPanelContent>
                        <!-- panel when there is no backend configuration (e.g. after user clicks "install", but before that installation has updated the db) 
                                                [ngStyle]="{'border' : (uiState.showPanel == 'ShowPanelIds.TenantNotReady') ? 'solid 5px green' : 'dashed 1px grey'}"
                            *ngIf="true || uiState.showPanel == 'ShowPanelIds.TenantNotReady'"-->
                            <h2 class="orbi-section-title">Sign-in Successful</h2>
                            <p>You have signed into Gitrospect.</p>
                            <div *ngIf="uiState.hasSomeAccessibleInstallations; then hasSomeInstallationContent else hasNoInstallationContent"></div>
                            <ng-template #hasSomeInstallationContent>
                                <p>The below table shows resources in GitHub are accessible to you, and resources which could be available when the integration is installed.  Gitrospect can only report on a GitHub Organziation once it is installed as an application on that Organization.</p>
                                <p><i>You may have additional accessible resources as private organizations, in this case click 'other' and GitHub will prompt you for which of these organizations you want to work with.</i></p>    
                            </ng-template>
                            <ng-template #hasNoInstallationContent>
                                <p>This user is not a member of any GitHub organizations with GitRospect installed, and GitRospect is not installed as a 3rd party app for the user either.</p>
                            </ng-template>                            
                    </ng-template>
                </ngb-panel>

                <ngb-panel id="panelTenantNotReady" title="Setup steps">
                <ng-template ngbPanelContent>
                    <!-- panel when there is no backend configuration (e.g. after user clicks "install", but before that installation has updated the db) 
                                            [ngStyle]="{'border' : (uiState.showPanel == 'ShowPanelIds.TenantNotReady') ? 'solid 5px green' : 'dashed 1px grey'}"
                        *ngIf="true || uiState.showPanel == 'ShowPanelIds.TenantNotReady'"-->
                        <h2 class="orbi-section-title">Installation request status</h2>
                        <p>Gitrospect has received installation requests for the following GitHub Users & Organizations</p>                        
                        <p>There is no installation pending for the following GitHub Users & Organizations</p>
                        <ul>
                            <li class="orbi-nav-link-title" *ngFor="let nrm of this.uiState.notReadyMessages" [ngClass]="nrm.ngClass">
                                <app-orbiicon [iconName]="nrm.iconName"></app-orbiicon>
                                {{nrm.desc}}
                                {{nrm.moreInfoTemplateRef}}
                                <span *ngIf="nrm.moreInfoTemplateRef" >
                                    <ng-container *ngTemplateOutlet="installationContent;context:{cmp:this}"></ng-container>
                                </span>
                            </li>
                        </ul>
                        <p>If you have recently requested the installation, try refreshing this page in a few minutes.</p>

                        
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{uiState.notReadyProgess}}"
                                aria-valuemin="0" aria-valuemax="100" [style]="'width:'+ uiState.notReadyProgess +'%'">
                                <span class="sr-only">{{uiState.notReadyProgess}}% Complete</span>
                            </div>
                        </div>
                </ng-template>
            </ngb-panel>


            <ngb-panel id="panelTenantAuthorization" title="Authorization for {{uiState.panelTenantAuthz.orgName}}">
                <ng-template ngbPanelContent>
                    <h2 class="orbi-section-title mb-4">Checking permissions..</h2>
                    <div class="load-holder d-flex flex-wrap">
                        <div class="col-sm-12 col-md-4 mb-4">
                            <div class="img-holder">
                                <img src="../../../assets/img/icon-time.png" alt="wait icon">
                            </div>
                            
                        </div>
                        <div class="col-sm-12 col-md-8 d-flex flex-column justify-content-center">
                            <h3 class="orbi-section-subtitle">Checking selected organization is a github organization '{{uiState.panelTenantAuthz.orgName}}' (multi-user, multi-repo) </h3>                            
                            <h3 class="orbi-section-subtitle">Checking permission for user {{uiState.panelTenantAuthz.userName}} to  access github organization {{uiState.panelTenantAuthz.orgName}}</h3>
                        </div>
                    </div>            
                </ng-template>
            </ngb-panel>
            <ngb-panel id="panelTenantImportData" title="Importing metadata">
                <ng-template ngbPanelContent>
                    <h2 class="orbi-section-title mb-4">Importing data</h2>
                    <div class="load-holder d-flex flex-wrap">
                        <div class="col-sm-12 col-md-4 mb-4">
                            <div class="img-holder">
                                <img src="../../../assets/img/icon-time.png" alt="wait icon">
                            </div>
                            
                        </div>
                        <div class="col-sm-12 col-md-8 d-flex flex-column justify-content-center">
                            
                            <h3 class="orbi-section-subtitle">Please wait why we import meta data. This may take up 30-60 mins</h3>
                            
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{uiState.tenantImportingData.progress}}"
                                    aria-valuemin="0" aria-valuemax="100" [style]="'width:'+ uiState.tenantImportingData.progress +'%'">
                                    <span class="sr-only">{{uiState.tenantImportingData.progress}}% Complete</span>
                                </div>
                            </div>
                            
                            <ul >
                                <li *ngFor="let currTask of uiState.tenantImportingData.taskList">
                                    <app-orbiicon iconName="{{currTask.iconName}}" cssClass="fa-spin"></app-orbiicon>
                                    <span>{{currTask.taskName}}</span>
                                    <span class="progress-bar" role="progressbar" attr.aria-valuenow="{{currTask.progress}}"
                                        aria-valuemin="0" aria-valuemax="100" [style]="'width:'+ currTask.progress + '%'"></span>
                                </li>
                            </ul>   

                            <ul class="">
                                <li>Number of completed import tasks: {{ uiState.tenantImportingSummaryData?.complete }} </li>
                                <li>Number of pending import tasks: {{ uiState.tenantImportingSummaryData?.failed }}  </li>
                                <li>Number of failed import tasks: {{ uiState.tenantImportingSummaryData?.pending }} </li>
                            </ul>
                        </div>
                    </div>            
                </ng-template>
            </ngb-panel>


            <!-- panel checking everything seems shipshape 
            
                [ngStyle]="{'border' : (uiState.showPanel == 'ShowPanelIds.TenantImporting') ? 'solid 5px green' : 'dashed 1px grey'}"
                *ngIf="true || uiState.showPanel == 'ShowPanelIds.TenantImporting'"
            -->
            <ngb-panel id="panelTenantShipshape" title="Summary">
                <ng-template ngbPanelContent>
                    <div class="col" >
                        <h2 class="orbi-section-title mb-4">Checking everything is shipshape</h2>
                        <div class="col-sm-12 col-md-10">
                            <h3 class="orbi-section-subtitle"></h3>
                            <!-- 
                            <ul>
                                    <li>Hardcoded for styling Metadata for 17 repositories  acquired</li>
                                    <li *ngFor="let thp  of uiState.tenantHealthPositives">Metadata for {{thp.count}} {{thp.objecttype}} acquired</li>
                            </ul>
                            <app-notification-pagealert>
                                <ngb-alert   type="warning">Hardcoded error for styling warning </ngb-alert>
                                <ngb-alert   type="danger">Hardcoded error for styling danger</ngb-alert>
                                <ngb-alert   type="info">Hardcoded error for styling info</ngb-alert>
                            </app-notification-pagealert> -->
                            <app-notification-pagealert>
                                <p  *ngFor="let thn of uiState.tenantHealthNegatives"> 
                                    <ngb-alert   [type]="thn.type">
                                        <h4 class="summary">{{ thn.summary }}</h4>
                                        <p>{{ thn.desc }}</p>
                                    </ngb-alert>
                                </p>
                            </app-notification-pagealert>                
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>

        <!-- 
            -- Import history
        -->
    <div class="col-10 dashboard-main pr-4" *ngIf="tabState.activeTab == navItemImportHistory">
        <div class="col loadpanel-3" >
            <h2 class="orbi-section-title mb-2 w-100">Import tasks in progress</h2>
            
            <div class="progress-holder d-flex row w-100 m-4">
                <div class="col-sm-12 col-md-2">
                    <div class="img-holder">
                        <img src="../../../assets/img/icon-time.png" alt="wait icon">
                    </div>
                </div>
                <div class="col-sm-12 col-md-10">
                    <h3 class="orbi-section-subtitle">Please wait why we load your data. This may take up to 30 mins</h3>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow="70"
                        aria-valuemin="0" aria-valuemax="100" style="width:70%">
                            <span class="sr-only">20% Complete</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="table-holder">
                <table class="table orbi-table table-row-hover">
                    <thead>
                        <th>What</th>
                        <th>Status</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th># Todo</th>
                        <th># Done</th>
                        <th># Failed</th>
                        <th># Total</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ih of uiState.importhistory">
                            <td>{{ih.objecttype}}</td>
                            <td>{{ih.status}}</td>
                            <td>{{ih.fromDate}}</td>
                            <td>{{ih.toDate}}</td>
                            <td>{{ih.todoCount}}</td>
                            <td>{{ih.doneCount}}</td>
                            <td>{{ih.failedCount}}</td>
                            <td>{{ih.todoCount + ih.doneCount + ih.failedCount}}</td>
                            
                        </tr>
                        <!-- 
                            ngFor="let frt of uiState.orgDropDownPossibleValues"
                            <td>Repositories</td>
                            <td>In progress</td>
                            <td>2022-09-15</td>
                            <td>2022-09-23</td>
                            <td>15</td>
                            <td>23</td>
                            <td>38</td>
                        </tr> 
                        <tr>
                            <td>Repositories</td>
                            <td>In progress</td>
                            <td>2022-09-15</td>
                            <td>2022-09-23</td>
                            <td>15</td>
                            <td>23</td>
                            <td>38</td>
                        </tr>
                        <tr>
                            <td>Pull request</td>
                            <td>Not started</td>
                            <td>2022-09-15</td>
                            <td>2022-09-23</td>
                            <td>0</td>
                            <td>44</td>
                            <td>44</td>
                        </tr>
                        <tr>
                            <td>Users</td>
                            <td>Not started</td>
                            <td></td>
                            <td></td>
                            <td>0</td>
                            <td>0</td>
                            <td>57</td>
                        </tr>-->
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-10 dashboard-main pr-4" 
        [ngStyle]="{'border' : (uiState.showPanel == 'ShowPanelIds.Dashboard') ? 'solid 5px green' : 'dashed 1px grey'}"
        *ngIf="tabState.activeTab == navItemDashboard">
        <h2 class="orbi-section-title mb-4">Dashboard Views</h2>        
        <div class="dashboard-charts" *ngIf="undefined != widgetData && 0 <  widgetData.length; then tplcharts; else tplnodata">
            <!-- 
            <canvas *ngFor="" baseChart class="chart"
                [data]="barChartData"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [type]="barChartType"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)">
            </canvas>
                                [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [legend]="barChartLegend"

        -->
        </div>

        <ng-template #tplcharts>            
            <div *ngFor="let chartItem of widgetData" class="card">
                {{chartItem.meta.metricName}}
                <canvas baseChart
                    [legend]="chartItem.meta.metricName"
                    [data]="chartItem.chartdata"
                    [type]="'bar'">
                </canvas>
            </div>
        </ng-template>

        <ng-template #widgetRepoBreakdown>
            <div>
              Welcome back, friend.
            </div>
        </ng-template>
        <ng-template #tplnodata>
            <div class="card-header">
                <h5 class="card-title">Repository breakdown no data</h5>
                <p class="card-text">no data Which repositories are private, public, archived</p>
            </div>
             <div class="card-body">
                No data
             </div>
             
        </ng-template>
        <!-- 
                <div class="card-header">
                    <h5 class="card-title">Repository breakdown</h5>
                    <p class="card-text">Which repositories are private, public, archived</p>
                </div>
                 <div class="card-body">
                    
                    <canvas baseChart type="pie" [data]="polardata" [options]="options"></canvas>
                 </div>
                 
            </div>
            <div class="card chart-2">
                <div class="card-header">
                    <h5 class="card-title">Card title 2</h5>
                    <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                 <div class="card-body">
                    
                 </div>
                 
            </div>
            <div class="card chart-3">
                <div class="card-header">
                    <h5 class="card-title">Card title 3</h5>
                    <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                 <div class="card-body">
                    <canvas baseChart type="line" [data]="linedata" [options]="options"></canvas>
                 </div>
                 
            </div>
            <div class="card chart-4">
                <div class="card-header">
                    <h5 class="card-title">Card title 4</h5>
                    <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                 <div class="card-body">
                    Body
                 </div>
                 
            </div>
            <div class="card chart-5">
                <div class="card-header">
                    <h5 class="card-title">Card title 5</h5>
                    <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                 <div class="card-body">
                    Body
                 </div>                 
            </div>
            <div class="card chart-6">
                <div class="card-header">
                    <h5 class="card-title">Card title 6</h5>
                    <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                 <div class="card-body">
                    body
                 </div>
                                 
            </div>
        </div> -->
    </div>

</div>  
