<div class="profile row justify-content-md-center no-gutters"> 
    <!-- PROFILE VIEW COL LEFT -->      
    <div *ngIf="_uiState.profileData" class="col-12 col-lg-4 col-md-6 p-2 profile-left">
        <h2 class="orbi-section-title">Profile</h2>        
        <div class="img-holder">
            <img src="../../../assets/img/img-profile.jpeg" alt="" class="profile__photo">
        </div>
        <form class="test-hide mt-4">
            <div class="form-group">
                <label for="exampleFormControlFile1">Example file input</label>
                <input type="file" class="form-control-file" id="exampleFormControlFile1">
            </div>
        </form>
        <ul *ngIf="! _uiState.uiFlags.modeedit" class="profile__list profile__list--personal mt-5 test-show">
            <li class="list-item profile__name"><span class="profile__label">Name: </span>{{_uiState.profileData.name}}</li>
            <li class="list-item profile__status"><span class="profile__label">Member Status: </span>{{_uiState.profileData.memberStatus}}</li>
            <li class="list-item profile__phone"><span class="profile__label">Phone: </span>{{_uiState.profileData.telephone}}</li>
            <li class="list-item profile__email"><span class="profile__label">Email: </span>{{_uiState.userInfo.email}}</li>   
            <li class="list-item profile__email"><span class="profile__label">Description: </span>{{_uiState.profileData.description}}</li>        
        </ul>
        <form *ngIf="_uiState.uiFlags.modeedit" >
            <div class="form-group text-left mt-5">
              <label for="profileData[name]">Name</label>
              <input type="text" class="form-control" [(ngModel)]="_uiState.profileData.name" name="profileData[name]" aria-describedby="namelHelp">
            </div>
            <!-- 
            <div class="form-group text-left">
                <label for="exampleFormControlSelect1">Member Status</label>
                <select class="form-control" id="exampleFormControlSelect1">
                  <option>Guest Member</option>
                  <option selected>Dedicated Member</option>
                </select>
              </div> -->
            <div class="form-group text-left">
                <label for="profileData[telephone]">Phone</label>
                <input type="text" class="form-control" [(ngModel)]="_uiState.profileData.telephone" name="profileData[telephone]" aria-describedby="phonelHelp">
            </div>                    
            <div class="form-group text-left">
                <label for="profileData[email]">Email address</label>
                <input type="email" readonly class="form-control" [(ngModel)]="_uiState.userInfo.email" name="profileData[email]"  aria-describedby="emailHelp">
            </div>    
            <div class="form-group text-left">
                <label for="profileData[description]">Description</label>
                <input type="text" class="form-control" [(ngModel)]="_uiState.profileData.description" name="profileData[description]"  aria-describedby="emailHelp">
            </div>    
          </form>
        <hr>
        <h3 class="orbi-section-subtitle">Location</h3>
        <ul *ngIf="! _uiState.uiFlags.modeedit" class="profile__list profile__list--address test-show">
            <li class="list-item profile__street">{{_uiState.profileData.location}}</li>
        </ul>
        <form *ngIf="_uiState.uiFlags.modeedit">
            <div class="form-group text-left">
              <label for="profileData[location]">Location</label>
              <input type="text" class="form-control" [(ngModel)]="_uiState.profileData.location" name="profileData[location]" aria-describedby="addreeslHelp">
            </div>
            <!--
            <div class="form-group">
                <label for="name">City</label>
                <input type="text" class="form-control" id="city" aria-describedby="citylHelp">
            </div>                    
            <div class="form-group">
                <label for="name">Country</label>
                <input type="text" class="form-control" id="country" aria-describedby="countrylHelp">
            </div>  
            <div class="form-group">
                <label for="name">Postcode</label>
                <input type="text" class="form-control" id="postcode" aria-describedby="postcodelHelp">
            </div>   -->

            <div class="button-holder d-flex justify-content-between">
                <button class="btn btn-light" >Cancel</button>                
                <button type="submit" class="btn btn-primary" (click)="uievents.profileEditSubmit($event)">Submit</button>
              </div>
            
          </form>
          
        <button *ngIf="! _uiState.uiFlags.modeedit" type="button" class="btn btn-primary mt-3" (click)="uievents.profileEditClick($event)">Edit</button>
    </div>
    <!-- END PROFILE VIEW COL LEFT -->       

    <!-- PROFILE VIEW COL RIGHT -->       
    <div class="col-12 col-lg-4 col-md-6 p-2 profile-right">   
        <div class="perso-list perso-list--projects">
            <h3 class="orbi-section-subtitle">Current Projects</h3>
            <ul   *ngFor="let cprj of _uiState.projects">                
                <li class="project-label">{{cprj.name}}</li>
                <li><a href="#"><fa-icon [icon]="['fas', 'list']"></fa-icon></a></li>
                <li><a href="#"><fa-icon [icon]="['fas', 'list']"></fa-icon></a></li>
            </ul>
        </div>
 
        <div class="perso-list perso-list--team">
            <h3 class="orbi-section-subtitle">Team Members</h3>
            <ul   *ngFor="let ctm of _uiState.teammembers">
                <li>
                    <img src="../../assets/img/profile-photo.png">
                </li>
                <li class="project-label">{{ctm.name}}</li>
                <li><a href="#"><fa-icon [icon]="['fas', 'list']"></fa-icon></a></li>
                <li><a href="#"><fa-icon [icon]="['fas', 'list']"></fa-icon></a></li>
            </ul>
        </div>        
    </div>
</div>