<!-- <p>orbiresultsfilter works!</p> -->

<!-- Filter modal  
<div class="modal-dialog-centered"> -->
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{name}} filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table table-row-hover-light">
            <tbody>  
                
                <div *ngIf="filterOp == OrbiresultsfilterOp.SubStr" class="btn-group btn-group-toggle">                    
                    <input type="text" class="form-control" [(ngModel)]="substrfilter" 
                        (ngModelChange)="this.substrfilterUpdate.next($event)" />
                </div>
                <div *ngIf="filterOp == OrbiresultsfilterOp.Match" class="btn-group btn-group-toggle" >                    
                    <div class="orbi-filters-list">

                        <ul class="mb-3">
                            <li *ngFor="let currOption of this.matchOptions">
                                <button [ngClass]="currOption.selected ? 'selected' : 'selected-not'" 
                                    (click)="currOption.selected = !currOption.selected; this.matchFilterUpdate.next($event);"
                                    class="btn btn-filter btn-sm">{{currOption.label}}                                                        
                                    <app-orbiicon iconName="faTimes"></app-orbiicon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div> 
                <div *ngIf="filterOp == OrbiresultsfilterOp.SubStrMatch" class="btn-group btn-group-toggle" >                    
                    <div class="orbi-filters-list">
                        <ul class="mb-3">
                            <input type="text" class="form-control" [(ngModel)]="substrfilter" 
                                (ngModelChange)="this.substrmatchfilterstringUpdate.next($event)" />
                        </ul>
                        <ul>
                            <li *ngFor="let currOption of this.substrmatchOptions" [hidden]="! currOption.visible">
                                <button [ngClass]="currOption.selected ? 'selected' : 'selected-not'" 
                                    (click)="currOption.selected = !currOption.selected; this.substrmatchfilteroptionsUpdate.next($event);"
                                    class="btn btn-filter btn-sm">{{currOption.label}}                                                        
                                    <app-orbiicon iconName="faTimes"></app-orbiicon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="filterOp == OrbiresultsfilterOp.Metadata" class="btn-group btn-group-toggle" >                    
                    <div class="orbi-filters-list">
                        <p>Show rows matching:</p>
                        <ul class="mb-3">
                            <li>
                            <button [ngClass]="metadataFilterAllSelected ? 'selected' : 'selected-not'" 
                                    (click)="metadataFilterAllSelected = !metadataFilterAllSelected; this.metadatamatchfilteroptionsUpdate.next(this);"
                                    class="btn btn-filter btn-sm">All selected criteria
                                    <app-orbiicon iconName="faTimes"></app-orbiicon>
                                </button>
                                </li>
                                <li>
                            <button [ngClass]="! metadataFilterAllSelected ? 'selected' : 'selected-not'" 
                                    (click)="metadataFilterAllSelected = !metadataFilterAllSelected; this.metadatamatchfilteroptionsUpdate.next(this);"
                                    class="btn btn-filter btn-sm">One of selected criteria
                                    <app-orbiicon iconName="faTimes"></app-orbiicon>
                                </button>                                    
                                </li>
                        </ul>
                        <p>Criteria</p>
                        <ul>                            
                            <li *ngFor="let currOption of this.metadatamatchOptions" [hidden]="! currOption.visible">
                                <button [ngClass]="currOption.selected ? 'selected' : 'selected-not'" 
                                    (click)="currOption.selected = !currOption.selected; this.metadatamatchfilteroptionsUpdate.next(this);"
                                    class="btn btn-filter btn-sm">{{currOption.label}}                                                        
                                    <app-orbiicon iconName="faTimes"></app-orbiicon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>                
            </tbody>
        </table>            
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal"  (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-primary" (click)="closeModal()" >Apply Filters</button>
    </div>
<!-- </div> -->


