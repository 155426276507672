import { ApiMwgithubResult, ApiPageStateInterface, ApiPageState_init, OrbiApiEntrypointKey, OrbiObjectType } from "@danclarke2000/gitrospectdto";
import { OrbiAuditResultsManagerService } from "src/app/svc/orbiauditresultsmanager.service";
import { OrbidebugService } from "src/app/svc/orbidebug.service";
import { environment } from "src/environments/environment";
import { OanDebugutils } from "../utils/OanDebugutils";
import { OrbiReportType, OrbiReportTypeMapper } from "./UiLabels";

export interface OangNavTabStateActiveContentAreaHeader
{
    targetelement : any;
    label : string;
}

export interface OangNavTabStateActiveContentAreaMeta
{
    title:string;
    desc:string;
    sectionHdrs: OangNavTabStateActiveContentAreaHeader[];
}

export interface OangNavTabStateActiveContentAreaMetaMap 
{
    [keyTabId:string] : OangNavTabStateActiveContentAreaMeta;
}

export abstract class OangNavTabState<ComponentType extends { }> 
{
    public activeTab : string = '';
    public activeObjs : any[] = [];
    public activeObjsBeforeFilter : any[] = [];
    public activeAuditResults  : any | undefined;
    public activeTabContentAreaMeta : OangNavTabStateActiveContentAreaMeta | undefined;
    private tabContentAreaHeaders : OangNavTabStateActiveContentAreaMetaMap = {};

    // public filterCols: any[] = [];
    // public activeObjsAfterFilter : any[] = [];
    // public activeSortCol : any = undefined;
    // public isSortDesc : boolean = true;
    // public hasFilteredObjects : boolean = false;
    // public resultsFilter : ColDefFilter = new ColDefFilter(this.svcDebug, this.cmpThis);

    // private uiStateFilterDefault : (obj:any) => boolean = (obj:any) => true;
    // private newUiStateFilter : (obj:any) => boolean;

    public abstract refreshData() : void;
    public abstract onSync($event : any) : void;

    private forceRefresh : boolean = true;
    /*
    filterNone(obj: any) : boolean {
        return true;
    } */
    
    public tabMeta : any = {
        blank: { 
            class : '',
            numPass : 0,
            numFail : 0,
            hasBadgeData : false
        }
    };

    constructor(
        private validTabs : string[], 
        private svcDebug: OrbidebugService, 
        protected cmpThis : ComponentType, 
        private svcAuditResultsMgr : OrbiAuditResultsManagerService ) 
    {        
        // this.newUiStateFilter = this.uiStateFilterDefault;
        this.init(this.activeTab);
        validTabs.forEach( (vt:string) => {
            this.tabMeta[vt] = Object.assign({}, this.tabMeta.blank);            
        });
    }

    public init (activeTab : string) {
        this.setActiveTab(activeTab);
  //      this.activeFilter = this.filterNone;
    }

    public getTabMeta(tabId:string) {
        var r = this.tabMeta[tabId];
        return r;
    }

    /*
    public refreshTab(tabId:string) {
        this.forceRefresh = true;
        this.setActiveTab(tabId);
    } */

    public setContentAreaHeaders(tabContentAreaHeaders : OangNavTabStateActiveContentAreaMetaMap)
    {
        this.tabContentAreaHeaders = tabContentAreaHeaders;
    }

    public setActiveTab(tabId : string) {
        if (tabId && 0 != tabId.length) 
        {    
            if (! this.validTabs.includes(tabId))
            {
                OanDebugutils.debuggerWrapper(".?.");
            }

            this.forceRefresh = false;                
            setTimeout(() => {
                this.setActiveTabPageData(tabId);
                let oldTabId = this.activeTab;
                if (oldTabId) {
                    this.tabMeta[oldTabId].class = '';
                }

                if (this.tabContentAreaHeaders.hasOwnProperty(tabId)) 
                {
                    this.activeTabContentAreaMeta = this.tabContentAreaHeaders[tabId];    
                }

                this.tabMeta[tabId].class = "active";
                this.activeTab = tabId;


                // load new data
                this.onSync(null);
                this.refreshData();
            });
        }
    }

    public setActiveTabPageData(tabId:string, apiResult? : ApiMwgithubResult)
    {
        let myPageState : ApiPageStateInterface = this.getTabPagination(this.activeTab);

        let currPageValue = (apiResult?.pageInfo) ? Math.floor(apiResult.pageInfo.offset / apiResult.pageInfo.limit) : myPageState?.currentPage;
        let pageSizeValue = (apiResult?.pageInfo) ? Math.floor(apiResult.pageInfo.limit) : myPageState?.limit;
        let totalValue = (apiResult?.pageInfo) ? apiResult?.pageInfo.totalCount : myPageState?.expectedTotal;
        // @ts-ignore
        let myActionPageState : any = this.cmpThis.uiState?.activeTabPageState;
        if (myPageState && myActionPageState) 
        {
            let numRowsLoaded = (apiResult?.data?.length) ? apiResult?.data?.length : 0;
            myActionPageState.hasPagination = true;
            myActionPageState.paginationHasMoreData = numRowsLoaded < myPageState.expectedTotal;
            myActionPageState.currentPage = currPageValue;
            myActionPageState.pageSize  = pageSizeValue;
            myActionPageState.collectionSize  = totalValue;
            myActionPageState.tabId = tabId;
        }
    }

    // only call from with ngZone !
    protected setObjsAndFilter(newObjsBeforeFilter:any[], newObjsAfterFilter  : any[]) 
    {
        let doFilterForDebug = (environment.snipdatafordebug) ? environment.snipdatafordebug : false;
        if (doFilterForDebug) {
            console.error("Applying debug filter for repos");
            let objsType :string = (0 < newObjsAfterFilter.length && newObjsAfterFilter[0].type) ? newObjsAfterFilter[0].type : '';
            if (0 < newObjsAfterFilter.length && 
                 (!['orbiobjtype_github.user', 'orbiobjtype_github.team','orbiobjtype_github.teammember'].includes(objsType)
                    && !objsType.startsWith('orbiobjtype_github.audit'))) 
            {
                newObjsAfterFilter = newObjsAfterFilter.filter( (obj:any) => { 
                    let repoName = (obj.obj?.repo?.name) ? obj.obj?.repo?.name : 
                        (obj.obj?.repository?.name) ? obj.obj?.repository?.name : 
                            (obj.obj?.patched?.repoName) ? obj.obj?.patched?.repoName : 
                                (obj.obj?.permissionTargetId) ? obj.obj?.permissionTargetId : 
                                    (obj.obj?.repoName) ? obj.obj?.repoName :
                                        (obj.obj?.targetObject) ? obj.obj.targetObject :undefined;
                    if (! obj.obj?.targetObject && !repoName) { debugger; }
                    let r = ['cma-service','Casper_training','koa-sensors','customer-service','xai-recsys-explain-eval','licensebat'                ].includes(repoName) ;
                    return r;
                });
            }
        }

        // new filter here seems to be the search box which hasnt worked in a while
        // | oanpipefilterwithcallback: uiState.resultsFilter.colFilter.bind(uiState.resultsFilter, GithubColumnDefs.colDefs[this.tabState.activeTab])
        this.activeObjsBeforeFilter = newObjsAfterFilter;
        if (Array.isArray(newObjsAfterFilter) && 0 < newObjsAfterFilter.length)
        {
            let myAuditResults : any = {};
            if (this.activeTab) {
                let currActiveTab : OrbiReportType = <OrbiReportType>(this.activeTab);
                let myObjType : OrbiObjectType | undefined = OrbiReportTypeMapper.toObjectTypes[currActiveTab];
                if (undefined != myObjType) {                    
                    myAuditResults = this.svcAuditResultsMgr.getAuditResultsForObjectType(myObjType);
                } else {
                    debugger;
                }              
            }

            this.activeAuditResults = myAuditResults;
            this.activeObjs = newObjsAfterFilter;
        } 
        else
        {
            // done this way as otherwise angular doesnt detect population of array as a change
            this.activeAuditResults = {};
            this.activeObjs = [];
        }    
    }

    private onRefreshDataDebounceTimerId : any | undefined = undefined;
    public onUpdatedData(tabId : string, apiResult : ApiMwgithubResult) {
        if (tabId == this.activeTab) {
            if (! apiResult?.context?.endPoint) {
                debugger; 
            } else {
                switch (apiResult.context.endPoint) {            
                    case OrbiApiEntrypointKey.invalid:
                    case OrbiApiEntrypointKey.na:
                    case OrbiApiEntrypointKey.healthcheck:
                    case OrbiApiEntrypointKey.getimporthistory:
                    case OrbiApiEntrypointKey.getrepolist:
                    case OrbiApiEntrypointKey.exportrepos:
                    case OrbiApiEntrypointKey.auditresults:
                        // not relevant for pagination
                        break;
                        
                    default: {
                        let myTab = this.getTabPagination(tabId, apiResult);
                        this.setActiveTabPageData(tabId, apiResult);
                    }
                }
            }
            
            if (undefined != this.onRefreshDataDebounceTimerId) {
                clearTimeout(this.onRefreshDataDebounceTimerId);
            }
            this.onRefreshDataDebounceTimerId = setTimeout( () => {
                this.refreshData();
                this.onRefreshDataDebounceTimerId = undefined;
            }, 500);            
        }
    } 

    private tabPaginationObjects : any = {};
    public getTabPagination(tabId : string | undefined, apiResult? : Partial<ApiMwgithubResult>) : ApiPageStateInterface {
        let myPageState = undefined;
        if (tabId) {

            if (!this.tabPaginationObjects[tabId]) {
                this.tabPaginationObjects[tabId] = ApiPageState_init(tabId);
            }

            myPageState = this.tabPaginationObjects[tabId];
            if (apiResult) {
                myPageState.expectedTotal = (apiResult && apiResult.pageInfo?.totalCount) ? apiResult.pageInfo.totalCount : -1;                
            }
        } else if (undefined != tabId && 0 < tabId.length) {
            OanDebugutils.debuggerWrapper("Invalid page state");
        }

        return myPageState;
    }
}