import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OrbistartuprequestsL0Interface, Orbistartuprequestsservice } from "src/app/svc/orbistartuprequests.service";

@Component({
    selector: 'app-gitrospectsignupflow',
    templateUrl: './gitrospectsignupflow.component.html',
    styleUrls: ['./gitrospectsignupflow.component.scss']
  })
  export class GitrospectSignupFlowComponent implements OnInit {
    @Input() orgName : string | undefined;
    @Input() appInstallUrl : string | undefined;

    constructor(public activeModal: NgbActiveModal,
        private svcStartuprequestsservice : Orbistartuprequestsservice) 
    {
    }

    ngOnInit(): void {
        this.svcStartuprequestsservice.startupRequestsL0State$.subscribe({
            next: (state:OrbistartuprequestsL0Interface) => {
                if (state.isInit) 
                {
                    this.appInstallUrl = state.svcurls.appInstallUrl;
                }
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onInputChanges();
    }

    closeModal() : void {
        this.activeModal.close('Close click');
    }

    public onInputChanges() 
    {
    }

    async onSignup(event?: MouseEvent) 
    {
        if (this.appInstallUrl) {
            window.location.href = this.appInstallUrl;
        }
    }

    async onSignupCancel(event?: MouseEvent) 
    {
        this.activeModal.close('Close click');
    }
}

      
  