import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { OrbiConstants, OrbiSettingDTO, OrbiSettingGroupName, OrbiSettingGroupName_allvalues, OrbiSettingNvpName } from '@danclarke2000/gitrospectdto';
import { OrbisettingsService } from 'src/app/svc/orbisettings.service';
import {KeyValue} from '@angular/common';
import { OanAnalyzerParams } from 'src/app/cmn/analyzer/OanAnalyzerParams';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrbiwidgetValue } from './orbiwidgetcmn';
import { OanDebugutils } from '../utils/OanDebugutils';

class Impl
{
    /*
    public static managePropertyFilter(valDataFromServer:any, uiStateFromController:any, inVars : any, settingGroup:string,currKey:string,currVdProp:any)
    {
        if (currVdProp.type == "JSON.array.string") {                                                                                        
            // 
            // this.uiState.settingsState.valuesmeta[currVdProp.key][currVdProp.key] = {};
            uiStateFromController.valuesmeta[settingGroup][currKey].properties[currVdProp.key] = {};
            let arrValMeta = uiStateFromController.valuesmeta[settingGroup][currKey].properties[currVdProp.key]; 
            arrValMeta.key = currVdProp.key;
            arrValMeta.type = currVdProp.type;                                           
            arrValMeta.valuesFilter = '';
            arrValMeta.valuesIncludes = {};
            arrValMeta.valuesFilter = '';
            arrValMeta.valueParsed = JSON.parse(currVdProp.value);
            arrValMeta.valueParsed.forEach( (cvdVal:string) => {
                arrValMeta.valuesIncludes[cvdVal] = true;
            });
            arrValMeta.valuesPossible = [];                                            
            switch (currVdProp.datasource)
            {
                case 'repo.names':
                    // sometimes array of strings, othertimes objects..
                    arrValMeta.valuesPossible = inVars.repos.map( (r:any) => (r.obj?.repo?.name) ? r.obj.repo.name : r);
                    break;
                case 'repo.topics':
                    arrValMeta.valuesPossible = inVars.repoTopics;
                    break;
                default:
                    OanDebugutils.debuggerWrapper(".?.");
            }

            arrValMeta.valuesDisplay = arrValMeta.valuesPossible;
            arrValMeta.filterUpdate = new Subject<any>();
            arrValMeta.filterUpdate.pipe(debounceTime(OanAnalyzerParams.DefaultDebounceTime)).subscribe(
                {
                    next: (newVal:string) => 
                    {
                        let value = newVal;
                        if (2 < value.length)
                        {
                            var re = new RegExp(value,"i");
                            arrValMeta.valuesDisplay = arrValMeta.valuesPossible.filter( (vp:string) => null != vp.match(re) );
                        } 
                        else 
                        {
                            arrValMeta.valuesDisplay = arrValMeta.valuesPossible;
                        }
                    },
                    error: (err:any) => 
                    {
                        arrValMeta.valuesDisplay = arrValMeta.valuesPossible;
                        console.error(`OrbiresultsfilterComponent.matchfilterchange.error ${err}`);
                    }
                }
            );
        } 
    }

    public static marshallSettingsToUiState(valDataFromServer:any, uiStateFromController:any, inVars : any)
    {
        let settingGroup = valDataFromServer.settinggroup;
        let settingObjects = valDataFromServer.settingobjects;

        
        //
        // object structure is {
        //      SettingsGroupId (e.g. GithubReportSettings)
        //      settingsobjects : {
        //          settingsobjectId: [ 
        //              {
        //                  key, label,
        //                  properties: [
        //                      { key, label, type, value }
        //                  ]
        //              }
        //          ] 
        //      }
        let firstTabId : string | undefined;
        Object.keys(settingObjects).forEach( (currSoKey:string) => {
            // init maps
            uiStateFromController.editmode[settingGroup][currSoKey] = {};
            uiStateFromController.values[settingGroup][currSoKey] = settingObjects[currSoKey];
            uiStateFromController.valuesmeta[settingGroup][currSoKey] = {};
            uiStateFromController.formgroups[settingGroup][currSoKey] = {};
        
            // there is only one setting obj even though it's an array..   this is mianly to have a common return object from server
            // but downside is this kind of compleixty..
            // there could be a scenario where we want multi-value setting object
            // but this will get more complex
            let currSo = settingObjects[currSoKey][0];
            if (! firstTabId) { 
                firstTabId = currSo.key;
            }

            // iterate over properties for this settings.. note currSoKey
            uiStateFromController.editmode[settingGroup][currSo.key] = false;
            uiStateFromController.valuesmeta[settingGroup][currSoKey].properties = {};
            currSo.properties.forEach( (currVdProp:any) => {
                Impl.managePropertyFilter(valDataFromServer, uiStateFromController, inVars, settingGroup, currSoKey, currVdProp);
            });
        });

        return firstTabId;
    } */
           
}

const frmCtrlIdLiveProdDefinitionTypeForValuesFilters = 'orbiwidgetnameselector_valuesfilter';

interface OrbiwidgetnameselectorUistate 
{
    cmpThis : OrbiwidgetnameselectorComponent;
    values : OrbiwidgetValue[];
}

@Component({
  selector: 'app-orbiwidgetnameselector',
  templateUrl: './orbiwidgetnameselector.component.html',
  styleUrls: ['./orbiwidgetnameselector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: OrbiwidgetnameselectorComponent,
    multi: true,
  }]
})
export class OrbiwidgetnameselectorComponent implements OnInit {
    public static readonly className = "orbiwidgetnameselector";
    public frmCtrlIdLiveProdDefinitionTypeForValuesFilters : string | undefined = frmCtrlIdLiveProdDefinitionTypeForValuesFilters;
    
    private _allpossiblevalues : string[] = [];
    private _lastSelectedValuesIn : string[] | undefined = undefined;
    @Input() filtervalues : string = '';    
    @Input() set allpossiblevalues(value : string[]) {
         this.uiState.values = value.map( (strValue:string) => {
            // selected values is not useful attribute but part of type we're reusing
            let no = { label: strValue, key: strValue, visible:true, selected:false, selectedvalues:[] } ;
            return no;
        });
        this.uiValuesToShow = this.uiState.values
    }
    get allpossiblevalues() : string[] {
        return this._allpossiblevalues;
    }

    @Input() set inselectedvalues(selectedvalues : string[]) {
        if (undefined == this._lastSelectedValuesIn
            || JSON.stringify(this._lastSelectedValuesIn) != JSON.stringify(selectedvalues)) {
            this._lastSelectedValuesIn = selectedvalues;

            this.uiValuesSelected.clear();
            selectedvalues.forEach( (currOption:any) => {
                this.uiValuesSelected.set(currOption, true);
            });

            this.updateSelectedAttributesForCss(false);
        }
    }
    get inselectedvalues() : string[] {
        let val = Array.from(this.uiValuesSelected.keys());
        return val;
    }

    @Input() includeSelectAllNone : boolean = true;
    @Output() selectedvaluesChange = new EventEmitter<string[]>();

    // ui values
    uiValuesToShow : OrbiwidgetValue[] = [];
    uiValuesSelected : Map<string, boolean> = new Map<string, boolean>();

    public uiEvents : any = {
        className : "uiEvents",
        cmpThis:this,        
        
        onBtnFilterClear:($event:any) => {
            this.frmCtrlValuesFilter.setValue('');
        },
        onNameSelectClick:($event:any, clickedOption:any) => {
            let newVal = this.uiValuesSelected.get(clickedOption.key);
            if (newVal) {
                this.uiValuesSelected.delete(clickedOption.key);
            } else {
                this.uiValuesSelected.set(clickedOption.key, true);
            }
            
            this.updateSelectedAttributesForCss();
        },

        onBtnSelectAll:($event:any) => {
            this.uiValuesSelected.clear();
            this.uiValuesToShow.forEach( (currOption:any) => {
                this.uiValuesSelected.set(currOption.key, true);
            });
            
            this.updateSelectedAttributesForCss();
        },

        onBtnSelectAllAdditionally:($event:any) => {
            this.uiValuesToShow.forEach( (currOption:any) => {
                this.uiValuesSelected.set(currOption.key, true);
            });

            this.updateSelectedAttributesForCss();
        },

        onBtnSelectClearVisible:($event:any) => {
            this.uiValuesToShow.forEach( (currOption:any) => {
                this.uiValuesSelected.delete(currOption.key);
            });
        },

        onBtnSelectNone:($event:any) => {
            this.uiValuesSelected.clear();
            this.updateSelectedAttributesForCss();
        }
    }

    public frmCtrlValuesFilter : UntypedFormControl;  
    public model : any = {};
    public initUiEvents(cmpThis:OrbiwidgetnameselectorComponent) {
        cmpThis.uiEvents.cmpThis = cmpThis;
    }

    public uiState : OrbiwidgetnameselectorUistate = {    
        cmpThis : this,        
        values : [],     
    };
    private initUiState (cmpThis:OrbiwidgetnameselectorComponent) {
    }

    constructor(@Inject(LOCALE_ID) public locale: string,
        private calendar: NgbCalendar,
        private fb : UntypedFormBuilder,
        public svcSettings : OrbisettingsService) 
    {
        this.frmCtrlValuesFilter = this.fb.control(this.filtervalues)
        this.initUiEvents(this);
        this.initUiState(this);
    }

    /*
    public onInputChanges(changes: SimpleChanges) 
    {
        //@ts-ignore
        Object.keys(changes).forEach( (currKey:string) => {
            switch (currKey) {
                case 'allpossiblevalues':
                    if (Array.isArray(changes[currKey].currentValue)) {
                        this.uiState.values = changes[currKey].currentValue.map( (strValue:string) => {
                            let no = { label: strValue, key: strValue, visible:true, selected:false /*, selectedvalues:[]* / } ;
                            return no;
                        });
                        this.uiValuesToShow = this.uiState.values
                    }
                    break;
                case 'selectedvalues':
                    if (Array.isArray(changes[currKey].currentValue)) {
                        this.uiState.values.forEach( (currOption:any) => {
                            if (changes[currKey].currentValue.indexOf(currOption.key) >= 0) {
                                currOption.selected = true;
                            } else {
                                currOption.selected = false;
                            }
                        });
                    }
                    break;
            }
        });
    } */

    ngOnInit(): void {
    }

    /*
    ngOnChanges(changes: SimpleChanges) {
        this.onInputChanges(changes);
    } */   

    
    ngAfterViewInit(): void {
        // @ts-ignore
        let myFilterCtrl : UntypedFormControl = this.frmCtrlValuesFilter;
        myFilterCtrl.valueChanges.pipe(
            debounceTime(OanAnalyzerParams.DefaultDebounceTime),
            distinctUntilChanged())
            .subscribe(
                {
                    next: (value:string) => 
                    {
                        this.frmCtrlValuesFilter.setErrors(null);
                        try
                        {
                            let hasChange = false;
                            let isKept = new RegExp(value, 'i');
                            this.uiState.values.forEach( (currObj : any) => {
                                let newIsVisible = isKept.test(currObj.label);
                                if (newIsVisible != currObj.visible) {
                                    currObj.visible = newIsVisible;
                                    hasChange = true;
                                }                                 
                            }); 

                            if (hasChange) {
                                // we dont update selected values here as we're just changing what's visible, not whats selected
                                this.uiValuesToShow = this.uiState.values.filter( (currObj : any) => currObj.visible);
                            }
                        }
                        catch (e)
                        {
                            this.frmCtrlValuesFilter.setErrors({'incorrect': true});
                        }
                    },
                    error: (err:any) => 
                    {
                        console.error(`OrbiresultsfilterComponent.matchfilterchange.error ${err}`);
                    }
                }); 
    }

    /*
    * some additional business logic */
    removeSelection(valKey:string) {
        this.uiValuesSelected.delete(valKey);
        this.updateSelectedAttributesForCss();
    }

    /*
    * reactive form control interface */
    private onChangeDebounceTimerId = undefined as any;
    onChange(newSelectedValues:string[],emitChange:boolean = true) {
        if (emitChange && undefined == this.onChangeDebounceTimerId) {
            this.onChangeDebounceTimerId = setTimeout( () => {
                this.selectedvaluesChange.emit(newSelectedValues);
                this.onChangeDebounceTimerId = undefined;
            }, 1000);
        }        
    };

    updateSelectedAttributesForCss(emitChange:boolean = true) {
        // this is called when only selectedvalues is updated, so it doesnt effect what names are visible
        this.model.selectedvalues = Array.from(this.uiValuesSelected.keys());
        this.onChange(this.model.selectedvalues, emitChange);

        this.uiValuesToShow = this.uiValuesToShow.map( (currObj : OrbiwidgetValue) => {
            currObj.selected = this.uiValuesSelected.get(currObj.key) ?? false; 
            return currObj;
        });
    }

    onTouched = () => {};
    touched = false;
    disabled = false;

    writeValue(values: any) {
        debugger; // anything calling this ??

        if (values.type != OrbiwidgetnameselectorComponent.className) {
            OanDebugutils.debuggerWrapper(".?.");
        }

        this.model = values;   
        if (values.selectedvalues && Array.isArray(values.selectedvalues))
        {
            this.uiState.values.forEach( (currValue:any) => {
                if (values.selectedvalues.includes(currValue.key)) {
                    currValue.selected = true;
                }
            });
        }
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        } 
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
}
