import { HttpStatusCode } from '@angular/common/http';
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiMwGhMetric, ApiMwgithubResult, ApiMwgithubTeam, ApiPageStateInterface, ApiPageState_init, EntityMetricRecorderMetricName, EntityMetricRecorderPrincipalType, OrbiFilterState, OrbiFilterStateInterface, OrbiObjectType, OrbiResultsFilterEnum } from '@danclarke2000/gitrospectdto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//@ts-ignore
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { Observable } from 'rxjs';
import { UiLabels } from 'src/app/cmn/ui/UiLabels';
import { OanDebugutils } from 'src/app/cmn/utils/OanDebugutils';
import { OanHttpUtils } from 'src/app/cmn/utils/oanhttputils';
import { OrbigithubService } from 'src/app/svc/orbigithub.service';
import { OrbiprofileService } from 'src/app/svc/orbiprofile.service';
import { OrbistartuprequestsL1Interface, OrbistartuprequestsL2Interface, OrbistartuprequestsL3Interface, Orbistartuprequestsservice, OrbistartuprequestsTenantNotReady } from 'src/app/svc/orbistartuprequests.service';
import { GitrospectSignupFlowComponent } from '../gitrospectsignupflow/gitrospectsignupflow.component';
// import { Color, Label } from 'ng2-charts';

interface OrbiMetricsFilterInterface {
    selectedTeams : string[];
    selectedRepos : string[];
    selectedUsers : string[];
}

enum ShowPanelId {
    Init = "ShowPanelIds.Init",
    AccessibleResources  = "ShowPanelIds.AccessibleResources",
    TenantNotReady  = "ShowPanelIds.TenantNotReady",
    TenantAuthz  = "ShowPanelIds.TenantAuthz",
    TenantImporting  = "ShowPanelIds.TenantImporting",
    Shipshape  = "ShowPanelIds.panelTenantShipshape",
    Dashboard = "ShowPanelIds.Dashboard"
}

enum WidgetId {
    PrCreate_ByRepo_ByDay = "WidgetId.PrCreate_ByRepo_ByDay",
    PrCreate_ByRepo_ByWeek= "WidgetId.PrCreate_ByRepo_ByWeek",
    PrCreate_ByDev_ByDay = "WidgetId.PrCreate_ByDev_ByDay",
    PrCreate_ByDev_ByWeek= "WidgetId.PrCreate_ByDev_ByWeek",
    Commit_ByDev_Day= "WidgetId.Commit_ByDev_ByDay",
    Commit_ByDev_ByWeek= "WidgetId.Commit_ByDev_ByWeek",
    RepoActivity = "WidgetId.RepoActivity",
}

@Component({
  selector: 'app-githubdashboard',
  templateUrl: './githubdashboard.component.html',
  styleUrls: ['./githubdashboard.component.scss']
})
export class GithubdashboardComponent implements OnInit {

    public readonly navItemDashboard : string = "navitem_synthetic_dashboard";
    public readonly navItemTenantConfigHealth : string = "navitem_synthetic_tenantconfighealth";
    public readonly navItemImportHistory : string = "navitem_synthetic_importhistory";
    @ViewChild('accTenantHealth') accTenantHealth : any;

    private startupRequestsL1State : OrbistartuprequestsL1Interface | undefined;
    private startupRequestsL2State : OrbistartuprequestsL2Interface | undefined;
    private startupRequestsL3State : OrbistartuprequestsL3Interface | undefined;
    public filterModalSignupRef : any = undefined;
    public teams$: Observable<ApiMwgithubResult>;

    public tabState : any = {
        cmpThis : this,
        activeTab : this.navItemTenantConfigHealth,
        setActiveTab (strTabId:string)  {
            this.activeTab = strTabId;
            switch (this.activeTab) {
                case this.cmpThis.navItemDashboard:
                    let activeOrgName = this.cmpThis.svcProfile.getActiveOrgName();

                    // download teams data
                    let myFilterState : OrbiFilterStateInterface = JSON.parse(JSON.stringify(this.cmpThis.uiState.qfFilterState));
                    let pageState : ApiPageStateInterface = ApiPageState_init()
                    myFilterState.orgName = activeOrgName;
                    this.cmpThis.svcGithub.apiGetTeams(myFilterState, pageState);
            
                    // download metric data for dashboard
                    this.cmpThis.uiState.qfFilterState.orgName = activeOrgName;
                    this.cmpThis.svcGithub.apiGetMetricData(activeOrgName, this.cmpThis.uiState.qfFilterState); 
                    break;
            }
        }
    };

    public uiState = {
        dashboardenabled : false,
        hasSummaryAlert : false,
        hasSummaryAlertType : '',
        hasSummaryAlertSummary : '',
        hasSummaryAlertDesc : [] as string[],
        hasSummaryAlertShowButton : {
            GithubReports : true,
            Reload : true,
            SignUp : true,
            PendingImport : true
        },
        showPanel : ShowPanelId.Init,
        notReadyState: undefined as OrbistartuprequestsTenantNotReady | undefined,
        notReadyProgess : 0 as number,
        notReadyMessages : [] as any[],
        panelConfigHealth : {
            accordianPanelId : ['panelTenantNotReady']
        },
        panelTenantAuthz : {
            userName : '',
            orgName : '' as string | undefined,
        },
        hasSomeAccessibleInstallations : false as boolean,
        installationStatus : [] as any[],
        importhistory : [] as any[],        
        tenantImportingSummaryData : {} as any,
        tenantImportingData : {} as any,
        tenantHealthPositives : [] as any[],
        tenantHealthNegatives : [] as any[],
        tenantHealthWarnings : [] as any[],
        dismissPageAlert : ($event:any) => {
            this.uiState.hasSummaryAlert = false;
        },
        reloadPage : (objectName?:string) => {
            window.location.href = window.location.href;
        },
        signupOrg : (objectName?:string) => {
            this.filterModalSignupRef = this.modalService.open(GitrospectSignupFlowComponent);
            this.filterModalSignupRef.componentInstance.orgName = objectName;
        },
        hasAccessibleResources : false as boolean,
        qfFilterState : undefined as OrbiFilterStateInterface | undefined,
    };

    public widgetData = [] as any[];

    
    private progressLookupTable = {
        ["COMPLETE"] : 100,
        ["INPROGRESS"] : 50,
        ["NOTSTARTED"] : 0,
        ["FAILED"] : 0,
    };

    private progressIncrement : number = 100;
    private progressReducer(prev:number,curr:any)  {
        let currStatus : string = <string>(curr.status);
        //@ts-ignore
        let currStatusNumber : number = <number>(this.progressLookupTable[currStatus]);

        let result = prev;
        if (currStatusNumber > 0) {
            result = prev + (this.progressIncrement * currStatusNumber) / 100;
        } 

        return result;
    }

    private calculateTenantHealth()
    {
        let hasExplicitAuthz = false;
        let nextPanelId : string | undefined = undefined;
        let hasAllStartupStages : boolean = false;
        this.uiState.tenantHealthPositives = [];
        this.uiState.tenantHealthNegatives = [];
        this.uiState.tenantHealthWarnings = [];

        this.uiState.hasSummaryAlert = false;
        this.uiState.hasSummaryAlertType = '';
        this.uiState.hasSummaryAlertSummary = '';
        this.uiState.hasSummaryAlertDesc = [];

        let activeOrg = this.svcProfile.getActiveOrgName();
        if (this.startupRequestsL1State)
        {
            this.uiState.panelTenantAuthz.userName = (this.startupRequestsL1State.userInfo?.preferred_username) ? this.startupRequestsL1State.userInfo?.preferred_username : '';            
            this.uiState.panelTenantAuthz.orgName = activeOrg;

            hasExplicitAuthz = this.startupRequestsL1State.accessibleresources.some((item:any) => activeOrg == item.targetObject);
            if (! hasExplicitAuthz) {
                nextPanelId = "panelTenantNotReady";
                this.uiState.tenantHealthNegatives.push({type:"info", summary:``, desc:`The tenant is being created.  This process can take up to 5 minutes.  Please wait.`});
            }

            let selectedOrgIsUserWithAuthz = this.uiState.panelTenantAuthz.userName == this.uiState.panelTenantAuthz.orgName
                && this.startupRequestsL1State!.accessibleresources.some((item3:any) => item3.targetObject == this.uiState.panelTenantAuthz.userName);
            let userOrgs = this.startupRequestsL1State?.userInfo?.userOrgs;
            let prefUsername = this.startupRequestsL1State?.userInfo?.preferred_username;
            if ((Array.isArray(userOrgs) && userOrgs.length > 0)) {
                this.uiState.installationStatus = userOrgs.map((item:string) => {
                    let isInstalled = this.startupRequestsL1State!.accessibleresources.some((item2:any) => item2.targetObject == item);
                    return { objectName: item, objectType: 'Organization', isInstalled: isInstalled };
                });
                this.uiState.hasSomeAccessibleInstallations = this.uiState.installationStatus.some((item:any) => item.isInstalled);
            } else if (Array.isArray(this.startupRequestsL1State?.accessibleresources) && this.startupRequestsL1State?.accessibleresources.length > 0) {
                // the user might have rights to an org that they are not a  public member of..
                this.uiState.installationStatus = this.startupRequestsL1State!.accessibleresources.map((item:any) => {
                    return { objectName: item.targetObject, objectType: 'Organization', isInstalled: true };
                });

                this.uiState.hasSomeAccessibleInstallations = true;
            }
            else {
                this.uiState.hasSomeAccessibleInstallations = false;
                this.uiState.installationStatus = [];
            }

            if (false == this.uiState.hasSomeAccessibleInstallations) {
                if (false == selectedOrgIsUserWithAuthz) {
                    this.uiState.hasSummaryAlert = true;
                    this.uiState.hasSummaryAlertType = 'warning';
                    this.uiState.hasSummaryAlertSummary = 'You have signed in successfully, but you do not have access to any resources in GitRospect.';
                    this.uiState.hasSummaryAlertDesc.push('If you have just added an organization, wait a few minutes for the onboarding to start and reload the page.');
                    this.uiState.hasSummaryAlertDesc.push('You need to select the Sign-Up button and add GitRospect to one of your organizations or your user account.');
                } else {                    
                    this.uiState.hasSummaryAlert = true;
                    this.uiState.hasSummaryAlertType = 'warning';
                    this.uiState.hasSummaryAlertSummary = 'You have signed in successfully and installed Gitrospect in your GitHub user area.  ';
                    this.uiState.hasSummaryAlertDesc.push('You can browse resources in your GitHub user account, but none of your github organizations are Signed-Up to GitRospect.  You can use the Sign-Up button to add GitRospect to an organization.');
                }
            }

            let isImplicitOrgInstalled = this.startupRequestsL1State.accessibleresources.some((item:any) => item.targetObject == prefUsername);
            this.uiState.installationStatus.push({ objectName: prefUsername, objectType: 'User', isInstalled: isImplicitOrgInstalled });
            this.uiState.hasAccessibleResources =  0 < this.startupRequestsL1State.accessibleresources.length;
        }

        if (! nextPanelId && this.startupRequestsL2State)
        {
            if (! this.startupRequestsL2State.isTargetGithubOrg)
            {
                /* manage this in app component */
            }

            if (! this.startupRequestsL2State.healthcheckAuthz)
            {
                this.uiState.tenantHealthNegatives.push({type:"danger", summary:``, desc:`The github user ${this.uiState.panelTenantAuthz.userName} does not have permissions for any github organizations gitrospect is active.  Your user must be invited to a github organization, or if you are already in the organization the admin must install the gitrospect app.`});
            }

            if (! this.startupRequestsL2State.hasActiveTenant)
            {
                this.uiState.tenantHealthNegatives.push({type:"danger", summary:``, desc:`The github organization '${this.uiState.panelTenantAuthz.orgName}' has not correclty installed the github app gitrospect.  The application integration with github is not correctly configured.  An administrator of github should reinstall the application.`});
            }
        }

        if (! nextPanelId && this.startupRequestsL3State)
        {
            let reposummaryList = this.startupRequestsL3State.reposummaryList;
            if (Array.isArray(reposummaryList) && 0 == reposummaryList.length) {
                this.uiState.tenantHealthNegatives.push({type:"warning", summary:`No repos could be imported.`, desc:"Does the github organization have repositories ?  Does the Github App have permissions to access them in Github App settings ? "});
            } else {
                this.uiState.tenantHealthPositives.push({count:this.startupRequestsL3State.reposummaryList.length, objecttype:'repositories'});            
                hasAllStartupStages = true;
            }
        }
        else
        {
            this.uiState.hasSummaryAlertShowButton.GithubReports = false;
            if (true == this.uiState.hasSomeAccessibleInstallations) {
                this.uiState.hasSummaryAlertDesc.push('GitRospect has not finished building the GitHub reports. This can take up to 1 hour after Sign-Up.');
                let errors : any[] = this.uiState.tenantHealthNegatives.filter( (thn:any) => thn.type == "danger");
                if (0 < errors.length) {
                    this.uiState.tenantHealthNegatives.push({type:"danger", summary:`Could not import meta data.`, desc:"This can be a consequence of previous errors."});
                }
            }
        }

        if (nextPanelId) {
            this.uiState.panelConfigHealth.accordianPanelId = [nextPanelId];
        } else if (0 != this.uiState.tenantHealthNegatives.length) {
            if (! hasExplicitAuthz) {
                this.uiState.panelConfigHealth.accordianPanelId = ["panelTenantNotReady"];
            } else {
                this.uiState.panelConfigHealth.accordianPanelId = ["panelTenantShipshape"];
                this.uiState.hasSummaryAlertShowButton['Reload'] = false;
                this.uiState.hasSummaryAlertShowButton['SignUp'] = false;
                this.uiState.hasSummaryAlertShowButton.GithubReports = true;
                this.uiState.hasSummaryAlert = true;
                this.uiState.hasSummaryAlertType = "info";
                this.uiState.hasSummaryAlertSummary = "Dashboard view and Github Reports ready";
                this.uiState.hasSummaryAlertDesc.push('Access GitHub Reports or view the dashboard.');    
            }
        } else if (hasAllStartupStages) {
            this.tabState.setActiveTab(this.navItemDashboard);
        }
    }

    private _teams : ApiMwgithubTeam[] = [];
    private _teamsByMember : any = {};
    private _teamsMember : any = {};
    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private router: ActivatedRoute,
        private modalService : NgbModal,
        private svcStartuprequestsservice : Orbistartuprequestsservice,
        public svcProfile : OrbiprofileService, 
        public svcGithub : OrbigithubService 
    ) 
    { 
        this.teams$ = this.svcGithub.teams$;
        this.teams$.subscribe({
            next: (val:ApiMwgithubResult) => {
                this._teams = [];
                this._teamsByMember = [];
                if (val && OanHttpUtils.isHttpSuccess(val.httpStatus) && val.data && Array.isArray(val.data))
                {
                    this._teams = val.data;
                    val.data.forEach((team:ApiMwgithubTeam) => {
                        if (team.team.teamMembers && Array.isArray(team.team.teamMembers)) {
                            this._teamsMember[team.team.slug] = team.team.teamMembers.map((member:any) => member.login);
                            team.team.teamMembers.forEach((member:any) => {
                                if (! this._teamsByMember[member.login]) {
                                    this._teamsByMember[member.login] = [];
                                }
                                this._teamsByMember[member.login].push(team);
                            });
                        }
                    });
                }                
            },
            error: (err) => {
                console.log(`Erorr in dashboard teams ${err}`);
            }
        });
    }

    private getIconForFlag(flag:boolean|undefined) : string {
        let strIcon = "faMinus";
        if (undefined !== flag) {
            strIcon = flag ? "faCheck" : "faCircle";
        }

        return strIcon;
    }

    ngOnInit(): void {
        let qfInit : OrbiFilterStateInterface  = {
            locale: this.locale,
            orgName: undefined,
            repositorySelector: OrbiResultsFilterEnum.repoall,
            repositorySelectorCustomValue: undefined,
            auditfocusSelector: OrbiResultsFilterEnum.auditall,
            auditfocusSelectorCustomValue: undefined,
            dateSelector: OrbiResultsFilterEnum.dateweek5,
            fromDate: undefined,
            toDate: undefined,
            extraParamsNameValues: []
        } ;
        this.uiState.qfFilterState = qfInit;

        this.svcStartuprequestsservice.startupRequestsTenantNotReady$.subscribe({
            next: (state:OrbistartuprequestsTenantNotReady) => {
                if (state.isInit) 
                {
                    this.uiState.notReadyState = state;
                    this.uiState.notReadyProgess = 0;
                    this.uiState.notReadyMessages = [];
                    this.uiState.notReadyMessages.push({type:"info", iconName:"faCheck",  ngClass:{isComplete:state.onboardingHasBegin}, desc:`Successfully signed in.`});
                    this.uiState.notReadyMessages.push({type:"info", iconName: this.getIconForFlag(state.onboardingOrganizationSignup),  ngClass:{isComplete:state.onboardingOrganizationSignup}, 
                        desc:`GitHub resources have GitRospect integration.`,
                        moreInfoTemplateRef:state.onboardingOrganizationSignup ? undefined : 'installationContent' 
                        });
                    this.uiState.notReadyMessages.push({type:"info", iconName:this.getIconForFlag(state.onboardingHasBegin),  ngClass:{isComplete:state.onboardingHasBegin}, desc:`Getting things ready for the first time.`});
                    this.uiState.notReadyMessages.push({type:"info", iconName:this.getIconForFlag(state.onboardingImportingHasBegin), ngClass:{isComplete:state.onboardingImportingHasBegin}, desc:`Importing initial metadata.`});
                    this.uiState.notReadyMessages.push({type:"info", iconName:this.getIconForFlag(state.onboardingImportingComplete), ngClass:{isComplete:state.onboardingImportingComplete}, desc:`Import initial metadata complete.`});
                    if (state.onboardingImportingComplete) { 
                        this.uiState.notReadyProgess = 100;
                    } else if (state.onboardingImportingHasBegin) {
                        this.uiState.notReadyProgess = 66;
                    } else if (state.onboardingHasBegin) {
                        this.uiState.notReadyProgess += 33;
                    }

                    this.uiState.showPanel = ShowPanelId.TenantNotReady;
                    this.tabState.setActiveTab(this.navItemTenantConfigHealth);

                    if (true == state.onboardingHasBegin || true == state.onboardingImportingHasBegin || true == state.onboardingOrganizationSignup) {
                        if (false == state.onboardingImportingComplete) {
                            this.uiState.hasSummaryAlertShowButton.PendingImport = true;
                            this.uiState.hasSummaryAlertShowButton.SignUp = false;
                            this.uiState.hasSummaryAlert = true;
                            this.uiState.hasSummaryAlertType = "info";
                            this.uiState.hasSummaryAlertSummary = "Preparing GitHub reports";
                            this.uiState.hasSummaryAlertDesc.push('This step can take 20 minutes to 2 hours.');  
                            this.uiState.panelConfigHealth.accordianPanelId = ['panelTenantImportData'];
                        }
                    }

                    /*
                    setInterval(function() {
                        window.location.reload();
                      }, 60000); // 1 minutes */
                }
            }, error: (err) => {
            }
        });

        this.svcStartuprequestsservice.startupRequestsL1State$.subscribe({
            next: (state:OrbistartuprequestsL1Interface) => {
                if (state.isInit) 
                {                    
                    this.startupRequestsL1State = state;
                    this.calculateTenantHealth();
                }
            }, error: (err) => {
            }
        });
        
        this.svcStartuprequestsservice.startupRequestsL2State$.subscribe({
            next: (state:OrbistartuprequestsL2Interface) => {
                if (state.isInit) 
                {
                    this.startupRequestsL2State = state;
                    this.calculateTenantHealth();
                }
            }, error: (err) => {
            }
        });

        this.svcStartuprequestsservice.startupRequestsL3State$.subscribe({
            next: (state:OrbistartuprequestsL3Interface) => {
                if (state.isInit) 
                {
                    this.startupRequestsL3State = state;

                    let itemsNotToShow = [OrbiObjectType.GithubParent,OrbiObjectType.GithubUserOauthInfo,OrbiObjectType.Global,OrbiObjectType.Postprocessanalyze];
                    this.uiState.panelConfigHealth.accordianPanelId = ["panelTenantImportData"];
                    this.uiState.importhistory = state.importhistory
                        .filter((item:any) => ! itemsNotToShow.includes(item.objecttype))
                        .map((item:any) => {
                            // @ts-ignore
                            let strObjType = UiLabels.importerobjtypeLabels[item.objecttype];
                            OanDebugutils.debuggerWrapperOnCondition( strObjType === undefined, `No label for object type ${item.objecttype}`);
                            return {
                                ...item,
                                objecttype : strObjType, 
                                fromDate : (item.fromDate) ? new Date(item.fromDate).toLocaleString() : '',
                                toDate : (item.toDate) ? new Date(item.fromDate).toLocaleString() : '',
                            }
                        });

                    // calculate importing data data 
                    this.uiState.tenantImportingSummaryData = { complete:0, failed:0, pending:0 };
                    let myTasks = [...this.uiState.importhistory, 
                            { status : "INPROGRESS", objecttype:"extra1" },
                            { status : "FAILED", objecttype:"extra2" }, 
                            { status : "NOTSTARTED", objecttype:"extra3" }];
                    this.progressIncrement = 100 / myTasks.length;                    
                    this.uiState.tenantImportingData = {
                        progress : myTasks.reduce(this.progressReducer.bind(this), 0),
                        taskList : myTasks.map((item:any) => {
                            let r : any = {};
                            r.taskName = item.objecttype;
                            switch (item.status) {
                                case "COMPLETE":
                                    ++this.uiState.tenantImportingSummaryData.complete;
                                    r.iconName = "faCheck";
                                    r.progress = "100";
                                    break;

                                case "INPROGRESS":
                                    ++this.uiState.tenantImportingSummaryData.pending;
                                    r.iconName = "faSpinner";
                                    r.progress = "50";
                                    break;

                                case "NOTSTARTED":
                                    ++this.uiState.tenantImportingSummaryData.pending;
                                    r.iconName = "faClock";
                                    r.progress = "0";
                                    break;

                                case "FAILED":
                                    ++this.uiState.tenantImportingSummaryData.failed;
                                    r.iconName = "faExclamation";
                                    r.progress = "";
                                    break;
                            }
                            
                            return r;
                        })
                    };

                    this.uiState.dashboardenabled = true;
                    this.uiState.showPanel = ShowPanelId.Dashboard;
                    this.calculateTenantHealth();

                    // download
                }
            }, error: (err) => {
            }
        });

        this.svcGithub.metricdata$.subscribe({
            next: (val:ApiMwgithubResult) => {
                if (val.httpStatus != HttpStatusCode.EarlyHints) 
                {
                    let principalDevs = {} as any;
                    let principalRepos = {} as any;
                    if (val.data && Array.isArray(val.data)) {
                        /*
                        let prCreateByRepoByDayData = {} as any;
                        let repoData = {} as any;
                        this.widgetData[WidgetId.PrCreate_ByRepo_ByDay] = {
                            labels : [],
                            datasets : []
                        } as ChartData<'bar'> ;
                        this.widgetData[WidgetId.PrCreate_ByRepo_ByWeek] = {
                            labels : [],
                            datasets : []
                        }  as ChartData<'bar'>;
                        this.widgetData[WidgetId.PrCreate_ByDev_ByDay] = {
                            labels : [],
                            datasets : []
                        } as ChartData<'bar'> ;
                        this.widgetData[WidgetId.PrCreate_ByDev_ByWeek] = {
                            labels : [],
                            datasets : []
                        }  as ChartData<'bar'>;
                        */
                        let preppedData = {} as any;
                        val.data.forEach((item:ApiMwGhMetric) => {        
                            debugger;
                            /*
                                switch (item.metric.principalType) {
                                    case EntityMetricRecorderPrincipalType.GhUser:                                    
                                        principalDevs[item.metric.principalId] = true;                                                                        
                                        break;
                                    case EntityMetricRecorderPrincipalType.GhRepo:
                                        principalRepos[item.metric.principalId] = true;
                                        break;
                                    default:
                                        return;
                                }

                                let strRefDate = item.metric?.referenceDate.split('T')[0];//?.toISOString().split('T')[0];
                                if (! strRefDate || "object" != typeof item.metric?.metricdata) { return; }
                                Object.keys(item.metric?.metricdata?.metric).forEach((key:string) => {
                                    if (Object.values(EntityMetricRecorderMetricName).includes(key as EntityMetricRecorderMetricName)) {
                                        if (! preppedData[key]) { preppedData[key] = {}; }
                                        if (! preppedData[key][item.metric.principalType] ) { preppedData[key][item.metric.principalType] = {}; }
                                        if (! preppedData[key][item.metric.principalType][strRefDate]) { preppedData[key][item.metric.principalType][strRefDate] = {}; }
                                        if (! preppedData[key][item.metric.principalType][strRefDate][item.metric.principalId]) { preppedData[key][item.metric.principalType][strRefDate][item.metric.principalId] = 0; }
                                        preppedData[key][item.metric.principalType][strRefDate][item.metric.principalId] += item.metric.metricdata.metric[key].value;
                                    }
                                }); */
                        });

                        this.widgetData = [];
                        Object.keys(preppedData).forEach((metricName:string) => {                            
                            
                            if (preppedData[metricName][EntityMetricRecorderPrincipalType.GhUser]) {
                                let currWidgetData = preppedData[metricName][EntityMetricRecorderPrincipalType.GhUser];
                                let widgetDataItem = {
                                    meta : {
                                        metricName : metricName
                                    },
                                    chartdata : {
                                        labels : Object.keys(currWidgetData),
                                        datasets : [] as any[]
                                    }
                                };

                                Object.keys(principalDevs).forEach((devId:string) => {
                                    let newDataItem = {
                                        data: Object.keys(currWidgetData).map((key:string) => currWidgetData[key][devId] ? currWidgetData[key][devId] : 0),
                                        label: devId,
                                        stack: 'a'
                                    };
                                    widgetDataItem.chartdata.datasets.push(newDataItem);
                                });

                                this.widgetData.push(widgetDataItem);
                            }

                            /*
                            Object.keys(currWidgetData).
                            { 
                                data: .map((key:string) => {
                                    let r : number = 0;
                                    if (currWidgetData[key]) {
                                        r = Object.values(currWidgetData[key] as number[]).reduce((acc:number,curr:number) => acc + curr, 0);
                                    }

                                    return r;
                                }),
                                label: metricName
                            }] */

                            
                        });
                        debugger;
                    }

                    /*
                    debugger;
                    let myRepoBreakdownChart : ChartData<'pie', number[], string | string[]> = {
                        labels: [ [ 'Download', 'Sales' ], [ 'In', 'Store', 'Sales' ], 'Mail Sales' ],
                        datasets: [ {
                            data: [ 300, 500, 100 ]
                        } ]
                    };
                    this.widgetData[WidgetId.PrCreate_ByRepo] = myRepoBreakdownChart;
                    */
                }
            }, error: (err:any) => {
                debugger;
            }
        });

        this.router.url.subscribe(() => {
            let tabId = this.router.snapshot?.params?.tabId;
            switch (tabId) {

            }
        });            

      /*
    this.multiAxisData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            label: 'Dataset 1',
            fill: false,
            borderColor: '#42A5F5',
            yAxisID: 'y',
            tension: .4,
            data: [65, 59, 80, 81, 56, 55, 10]
        }, {
            label: 'Dataset 2',
            fill: false,
            borderColor: '#00bb7e',
            yAxisID: 'y1',
            tension: .4,
            data: [28, 48, 40, 19, 86, 27, 90]
        }]
    };


    this.multiAxisOptions = {
        stacked: false,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                ticks: {
                    color: '#495057'
                },
                grid: {
                    drawOnChartArea: false,
                    color: '#ebedef'
                }
            }
        }
    }; */

  }

}

/*
/*
    basicData: any;

    multiAxisData: any;

    multiAxisOptions: any;

    lineStylesData: any;

    basicOptions: any; 
    public polardata = {
        labels: [
          'Red',
          'Green',
          'Yellow',
          'Grey',
          'Blue'
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [11, 16, 7, 3, 14],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(75, 192, 192)',
            'rgb(255, 205, 86)',
            'rgb(201, 203, 207)',
            'rgb(54, 162, 235)'
          ]
        }]
      };

    linedata = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "My First dataset",
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1

          // backgroundColor: "#666"
        },
        {
            label: "My Second dataset",
            data: [165, 159, 180, 181, 156, 155, 140],
            fill: false,
            borderColor: 'rgb(192, 192, 00)',
            tension: 0.1

            // backgroundColor: "#666"
          }
      ]
    };
    options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
            display: true,
            text: 'Custom Chart Title'
        }
        }
    };
    */