import { HttpStatusCode } from "@angular/common/http";
import { ApiMwgithubBase } from "@danclarke2000/gitrospectdto";

export class OanHttpUtils
{
    public static importIdInit(importhistory : any[]) {
        let myIds = importhistory.filter((c:any) => c.objecttype == 'GithubParent');
        let importIds : any = {};
        myIds.forEach((c:any) => {
            importIds[c.id] = true;
        });
        return importIds;
    }

    public static importIdAdd(importIds : any, element : ApiMwgithubBase, mustHaveImportId:boolean) {
        // this is domain of private website 
        let elKey = element.identifier;
        let importId = element.context.lastImportParentId;
        if (mustHaveImportId && (! elKey || ! importId)) {
            debugger;
            console.error (`element missing id`);
        } else if (undefined == importIds[importId]) {
            importIds[importId] = true;
        }
    }

    public static isPublicWebsite() {
        // this is domain of private website 
        let isPublicWebsite = false;
        if (window.location.hostname.includes("soc2tools"))
        {
            isPublicWebsite = true;
        }

        return isPublicWebsite;
    }

    public static isHttpSuccess(resp:any) {
        let r = false;
        let statusNumber : number = 0;
        switch (typeof resp)
        {
            case 'number':
                statusNumber = resp;
                break;

            default:
                statusNumber = resp.statusCode;
        }

        if (HttpStatusCode.Ok <= statusNumber && statusNumber <= 300)
        {
            r = true;
        }

        return r;
    }
}
