import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { OrbiConstants, OrbiFilterState, OrbiResultsFilterEnum, OrbiSettingDTO, OrbiSettingGroupName, OrbiSettingGroupName_allvalues, OrbiSettingNvpName } from '@danclarke2000/gitrospectdto';
import { OrbisettingsService } from 'src/app/svc/orbisettings.service';
import {KeyValue} from '@angular/common';
import { OanAnalyzerParams } from 'src/app/cmn/analyzer/OanAnalyzerParams';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrbiwidgetValue } from './orbiwidgetcmn';
import { OanDebugutils } from '../utils/OanDebugutils';
import { ChartData, ChartTypeRegistry } from 'chart.js';
import { OangRenderers } from 'src/oang/cmn/OanRenderers';

/*
const Globalfilters_DateType_IsInThePast = 'globalfilters-date-type-isinthepast';
const Globalfilters_DateType_Range = 'globalfilters-date-type-isinthepast';
const Globalfilters_DateType_Default = Globalfilters_DateType_IsInThePast;
const Globalfilters_DateType_Default_Label = Globalfilters_DateType_IsInThePast_Label;
*/
const Globalfilters_DateType_IsInThePast_Label = 'Is in the past';
const Globalfilters_DateType_Range_Label = 'Is in the range';


export enum OrbiwidgetdateselectorDateType {
    Invalid = 'OWDSDT.Invalid',
    Default = 'OWDSDT.IsInThePast',
    IsInThePast = 'OWDSDT.IsInThePast',
    IsInTheRange = 'OWDSDT.IsInTheRange',
};

export enum OrbiwidgetdateselectorInThePastTypeThing {
    Invalid = 'OWDSITPTT.Invalid',
    Default = 'OWDSDT.Days',
    Days = 'OWDSITPTT.days',
    Mnths = 'OWDSITPTT.mnths',
    Qtrs = 'OWDSITPTT.qtrs' 
}

interface OrbiwidgetdateselectorUistate {
    cmpThis : OrbiwidgetdateselectorComponent;        
}

export interface OrbiwidgetdateselectorState {
    isValid : boolean;
    isDateChange : boolean;
    isUiOnlyChange : boolean;
    quickValue : OrbiResultsFilterEnum;
    dateLabel : string;
    dateSelectorType : OrbiwidgetdateselectorDateType;
    inThePastTypeThing : OrbiwidgetdateselectorInThePastTypeThing;
    inThePastNumThings : number;
    fromDate : Date | undefined;
    toDate : Date | undefined;
}

const InThePastTypeThingsProps = { 
    [OrbiwidgetdateselectorInThePastTypeThing.Days] : { label: 'days', multiplier: 1, maxValue : 365 },
    [OrbiwidgetdateselectorInThePastTypeThing.Mnths] : { label: 'months', multiplier: 31, maxValue : 12 },
    [OrbiwidgetdateselectorInThePastTypeThing.Qtrs] : { label: 'quarters', multiplier: 93, maxValue : 4 } } as any;


@Component({
  selector: 'app-orbiwidgetdateselector',
  templateUrl: './orbiwidgetdateselector.component.html',
  styleUrls: ['./orbiwidgetdateselector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: OrbiwidgetdateselectorComponent,
    multi: true,
  }]
})
export class OrbiwidgetdateselectorComponent implements OnInit {
    public static readonly className = "OrbiwidgetgraphComponent";
    
    @Input() quickValue : OrbiResultsFilterEnum = OrbiResultsFilterEnum.invalid;  
    @Input() dateSelectorType : OrbiwidgetdateselectorDateType = OrbiwidgetdateselectorDateType.Invalid;
    @Input() dateSelectorLabel : string | undefined;
    @Input() inThePastTypeThing : OrbiwidgetdateselectorInThePastTypeThing = OrbiwidgetdateselectorInThePastTypeThing.Invalid;
    @Input() inThePastNumThings : number = 7;
    @Input() fromDate : Date | undefined;
    @Input() toDate : Date  | undefined;
    @Output() change = new EventEmitter<OrbiwidgetdateselectorState>()
    @Output() changeUiOnly = new EventEmitter<OrbiwidgetdateselectorState>()
    
    public frmCtrlValuesFilter : UntypedFormControl;  
    public model : any = {
        
        dteSelection: {
            cmpThis : this,
            isTypeInThePast : false,
            isTypeInRange : true,
            isValid : true,
            selectedLabelForDateInPastTypeThing:'',
            changeGlobalfiltersdatetypelabel: Globalfilters_DateType_Range_Label,
            tabTitleLabel : '',
            inThePastTypeThings : InThePastTypeThingsProps,
            dteRange: {
                fromDate: NgbDate,
                toDate: NgbDate,
            },
            dteInThePast: {
                //numThings: OrbiFilterState.Globalfilters_DateType_Default_DaysLookBack,
                selectedTypeThingsKey : OrbiwidgetdateselectorInThePastTypeThing.Days,
            }, 
            /*
            getDateFromNgbDate(ngbDate:NgbDate) : Date | undefined {
                let dteR : Date | undefined = undefined;
                if (undefined != ngbDate) 
                {
                    if (undefined != ngbDate.year && undefined != ngbDate.month && undefined != ngbDate.day) {
                        dteR = new Date(ngbDate.year, ngbDate.month-1, ngbDate.day);
                    } else {
                        console.error("getFromDate: unexpected type for r: " + ngbDate.constructor.name);
                        OanDebugutils.debuggerWrapper("getFromDate: unexpected type for r: " + ngbDate.constructor.name);
                    }
                }
                else
                {
                    console.warn("getFromDate: unexpected type for r undefined");
                    OanDebugutils.debuggerWrapper("getFromDate: undefined from date");
                }

                return dteR;
            },
            getFromDate(cmpThis:any) {
                // TBD pull requests etc never uses inthepast date so it gets ignored
                let r;
                if ( this.isTypeInThePast ) {
                    let myItitpKey = cmpThis.model.dteSelection.dteInThePast.selectedTypeThingsKey;
                    if (myItitpKey) {
                        let numThings = cmpThis.frmCtrlValuesFilter.value;
                        let myItitp = cmpThis.model.dteSelection.inThePastTypeThings[myItitpKey];
                        if (myItitp) {
                            let numDays = myItitp.multiplier * numThings;
                            r = cmpThis.calendar.getNext(cmpThis.calendar.getToday(), 'd', -1 * numDays);
                        }
                    } else {
                        // TBD app error
                    }
                } else {
                    // range from date 
                    r = this.dteRange.fromDate;
                }

                let dteR = this.getDateFromNgbDate(r);
                return dteR;
            },
            getToDate(cmpThis:any) {
                var r;
                if ( this.isTypeInThePast ) {
                    r = cmpThis.calendar.getNext(cmpThis.calendar.getToday(), 'd', 2);
                }
                else {
                    r = this.dteRange.toDate;
                }

                let dteR = this.getDateFromNgbDate(r);
                return dteR;
            },    */     
            /*
            onChangeGlobalfiltersdatetypeByString(ev:Event, cmpThis:any, filterDteType:string) {
                var isTypeInThePast = false;
                var isTypeInRange = false;
                if (filterDteType == Globalfilters_DateType_IsInThePast) {
                    isTypeInThePast = true;
                } else {
                    isTypeInRange = true;
                }

                this.onChangeGlobalfiltersdatetype(ev, cmpThis, isTypeInThePast, isTypeInRange);
            },  */         
            onChangeNumThings(cmpThis:any, ev:Event) {
                let isChange = (undefined != ev);
                this.onDateSelection(cmpThis, ev, isChange);
            }, 

            onChangeGlobalfiltersdatetype(ev:Event, cmpThis:any, isTypeInThePast : boolean, isTypeInRange:boolean) {
                this.isTypeInRange = isTypeInRange;
                if (! isTypeInRange) {
                    this.isTypeInThePast = isTypeInThePast;
                } else {                    
                    let myState = cmpThis.getStateFromMemberVars();
                    let theDate = new Date();
                    let defaultToDate = myState.toDate ?? new Date(theDate.getFullYear(), theDate.getMonth(), theDate.getDate());
                    let defaultFromDate = myState.fromDate ?? new Date(defaultToDate.getFullYear(), defaultToDate.getMonth(), defaultToDate.getDate() - 7);
                    cmpThis.model.dteSelection.dteRange.fromDate = {year: defaultFromDate.getFullYear(), month: defaultFromDate.getMonth() + 1, day: defaultFromDate.getDate()};
                    cmpThis.model.dteSelection.dteRange.toDate = {year: defaultToDate.getFullYear(), month: defaultToDate.getMonth() + 1, day: defaultToDate.getDate()};
                    this.isTypeInThePast = false;                    
                }

                this.changeGlobalfiltersdatetypelabel = (isTypeInThePast) ? Globalfilters_DateType_IsInThePast_Label : Globalfilters_DateType_Range_Label;
                let isChange = (undefined != ev);
                this.onDateSelection(cmpThis, ev, isChange);
            }, 

            onChangeInThePastTypeThing(ev:Event, cmpThis:any, newTypeThingsKey:OrbiwidgetdateselectorInThePastTypeThing, selectedObj:any) {
                // typeThing is days, months, quarters
                this.dteInThePast.selectedTypeThingsKey = newTypeThingsKey;
                if ( selectedObj && selectedObj.label) {
                    this.selectedLabelForDateInPastTypeThing = selectedObj.label;
                }

                let isChange = (undefined != ev);
                this.onDateSelection(cmpThis, ev, isChange);
            },
         
            onDateSelection(cmpThis : any, ev : Event, isChange:boolean) {
                // date validation ugh
                this.isValid = false;
                if ( this.isTypeInThePast) {
                    var selectedTypeThings = InThePastTypeThingsProps[this.dteInThePast.selectedTypeThingsKey];
                    if (undefined != selectedTypeThings) {
                        var maxNumThings = selectedTypeThings.maxValue;
                        let newValNumThings = parseInt(this.cmpThis.frmCtrlValuesFilter.value);
                        if (isNaN(newValNumThings)) { 
                            console.warn("TBD error notification - num things strange value out of range");
                            newValNumThings = 1 ;
                            this.cmpThis.frmCtrlValuesFilter.setValue(newValNumThings);
                        }
                
                        if (0 < newValNumThings && newValNumThings <= maxNumThings) {
                            this.isValid = true;
                        } else {
                            console.warn("TBD error notification - user error, out of range");
                        }
                    } else {
                        console.warn("TBD error notification - internal error, no selected thing");
                    }
                } else if (this.isTypeInRange) {
                    // TBD validation for range
                    this.isValid = true;
                }
            
                if (this.isValid) {
                    if (isChange) {
                        this.cmpThis.quickValue = OrbiResultsFilterEnum.datecustom;
                    }
                    setTimeout( () => {
                        // this cludge is because the date picker change event doesnt work properly
                        // even though this function is called by date picker change, the model var
                        // has old value
                        let values = cmpThis.getStateFromMemberVars();
                        this.tabTitleLabel = cmpThis.getGlobalFilterTabTitleDate(values);
                        this.cmpThis.emitChange();                    
                    }, 500);
                }
            },            
        },
    };

    public static getInitValues(
        locale:string,
        calendar:NgbCalendar,
        values : Partial<OrbiwidgetdateselectorState>
/*
        quickValue : OrbiResultsFilterEnum | undefined, 
        dateSelectorType:OrbiwidgetdateselectorDateType|undefined, 
        inThePastTypeThing:OrbiwidgetdateselectorInThePastTypeThing, 
        inThePastNumThings:number | undefined, 
        fromDate:Date|undefined, 
        toDate:Date|undefined */) : OrbiwidgetdateselectorState 
    {
        values = values ?? {};
        values.quickValue = values.quickValue ?? OrbiResultsFilterEnum.dateweek1;
        switch (values.quickValue) {
            case OrbiResultsFilterEnum.dateweek1:
                values.dateSelectorType = OrbiwidgetdateselectorDateType.IsInThePast;
                values.inThePastTypeThing = OrbiwidgetdateselectorInThePastTypeThing.Days;
                values.inThePastNumThings = 7;
                break;
            case OrbiResultsFilterEnum.dateweek5:
                values.dateSelectorType = OrbiwidgetdateselectorDateType.IsInThePast;
                values.inThePastTypeThing = OrbiwidgetdateselectorInThePastTypeThing.Mnths;
                values.inThePastNumThings = 1;
                break;
            default:
                values.dateSelectorType = values.dateSelectorType ?? OrbiwidgetdateselectorDateType.IsInThePast;
                values.inThePastTypeThing = values.inThePastTypeThing ?? OrbiwidgetdateselectorInThePastTypeThing.Days;
                values.inThePastNumThings = values.inThePastNumThings ?? 7;
        }        

        let fromDateToUse = (values.dateSelectorType == OrbiwidgetdateselectorDateType.IsInTheRange) ? values.fromDate : undefined;
        let toDateToUse = (values.dateSelectorType == OrbiwidgetdateselectorDateType.IsInTheRange) ? values.toDate : undefined;
        if (values.dateSelectorType == OrbiwidgetdateselectorDateType.IsInThePast) {
            fromDateToUse = OrbiwidgetdateselectorComponent.getFromDateFromStateVars(calendar, values);
            toDateToUse = OrbiwidgetdateselectorComponent.getToDateFromStateVars(calendar, values);
        }
        let lbl = OrbiwidgetdateselectorComponent.getGlobalFilterTabTitleDateImpl(locale, calendar, values); 

        let chngPayload : OrbiwidgetdateselectorState = {
            isValid : true,
            isDateChange : true,
            isUiOnlyChange : true,
            quickValue : values.quickValue,
            dateLabel : lbl,
            dateSelectorType : values.dateSelectorType,
            inThePastTypeThing : values.inThePastTypeThing,
            inThePastNumThings : values.inThePastNumThings,
            fromDate : fromDateToUse,
            toDate : toDateToUse,
        };

        return chngPayload;
    }

    public uiState : OrbiwidgetdateselectorUistate = {    
        cmpThis : this,           
    };

    constructor(@Inject(LOCALE_ID) public locale: string,
        private calendar: NgbCalendar,
        private fb : UntypedFormBuilder,
        public svcSettings : OrbisettingsService) 
    {
        this.frmCtrlValuesFilter = this.fb.control('');
    }

    public onInputChanges(changes: SimpleChanges) 
    {
        if (changes['quickValue']) {
            switch (changes['quickValue'].currentValue) {                    
                case OrbiResultsFilterEnum.dateweek1: {
                    this.model.dteSelection.onChangeGlobalfiltersdatetype(undefined, this, true, false);
                    this.frmCtrlValuesFilter.setValue(7);
                    // OrbiFilterState.Globalfilters_DateType_Default_DaysLookBack this.model.dteSelection.dteInThePast.numThings = 7;
                    this.model.dteSelection.dteInThePast.selectedTypeThingsKey = OrbiwidgetdateselectorInThePastTypeThing.Days;

                    let typeThingsValue = InThePastTypeThingsProps[this.model.dteSelection.dteInThePast.selectedTypeThingsKey];
                    this.model.dteSelection.onChangeGlobalfiltersdatetype(undefined, this, true, false);
                    this.model.dteSelection.onChangeInThePastTypeThing(undefined, this, this.model.dteSelection.dteInThePast.selectedTypeThingsKey, typeThingsValue);
                    break;
                }

                case OrbiResultsFilterEnum.dateweek5: {
                    this.model.dteSelection.onChangeGlobalfiltersdatetype(undefined, this, true, false);
                    this.frmCtrlValuesFilter.setValue(1);
                    this.model.dteSelection.dteInThePast.selectedTypeThingsKey = OrbiwidgetdateselectorInThePastTypeThing.Mnths;

                    let typeThingsValue = InThePastTypeThingsProps[this.model.dteSelection.dteInThePast.selectedTypeThingsKey];
                    this.model.dteSelection.onChangeGlobalfiltersdatetype(undefined, this, true, false);
                    this.model.dteSelection.onChangeInThePastTypeThing(undefined, this, this.model.dteSelection.dteInThePast.selectedTypeThingsKey, typeThingsValue);
                    break;
                }

                case OrbiResultsFilterEnum.datecustom: {
                    let isInThePast = false, isInRange = false;
                    if (changes['dateSelectorType']) {
                        switch (changes['dateSelectorType'].currentValue) {
                            case OrbiwidgetdateselectorDateType.IsInThePast: isInThePast = true; break;
                            case OrbiwidgetdateselectorDateType.IsInTheRange: isInRange = true; break;
                        }
                        this.model.dteSelection.onChangeGlobalfiltersdatetype(undefined, this, isInThePast, isInRange);
                    }

                    if (changes['inThePastTypeThing']) {
                        let typeThingsValue = InThePastTypeThingsProps[changes['inThePastTypeThing'].currentValue];
                        if (typeThingsValue) {
                            this.model.dteSelection.onChangeInThePastTypeThing(undefined, this, changes['inThePastTypeThing'].currentValue, typeThingsValue);
                        }
                    }

                    if (changes['inThePastNumThings']) {
                        this.frmCtrlValuesFilter.setValue(changes['inThePastNumThings'].currentValue);
                    }

                    /*
                    this.tabStateGlobalFilters.tabActive = this.tabStateGlobalFilters.navItemDate;
                    this.uiState.setTabStateGlobalFiltersCollapse(undefined, false, 'datecustom')
                    */
                    break;
                }
            }
        }
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onInputChanges(changes);
    }    

    ngAfterViewInit(): void {
        let myFilterCtrl : UntypedFormControl = this.frmCtrlValuesFilter;
        myFilterCtrl.valueChanges.pipe(
            debounceTime(OanAnalyzerParams.DefaultDebounceTime),
            distinctUntilChanged())
            .subscribe(
                {
                    next: (value:string) => 
                    {
                        let someObjSoDetectedAsChangeEvent = {};
                        this.model.dteSelection.onChangeNumThings(this, someObjSoDetectedAsChangeEvent);
                    },
                    error: (err:any) => 
                    {
                        console.error(`dateselector value change error ${err}`);
                    }
                }); 
    }

    private onChangeDebounceTimerId = undefined as any;
    private emitChange() {
        let chngPayload : OrbiwidgetdateselectorState = {
            isValid : this.model.dteSelection.isValid,
            isDateChange : false,
            isUiOnlyChange : false,
            quickValue : this.quickValue,
            dateLabel : this.model.dteSelection.tabTitleLabel,
            dateSelectorType : this.model.dteSelection.isTypeInThePast ? OrbiwidgetdateselectorDateType.IsInThePast : OrbiwidgetdateselectorDateType.IsInTheRange,
            inThePastTypeThing : this.model.dteSelection.dteInThePast.selectedTypeThingsKey,
            inThePastNumThings : this.frmCtrlValuesFilter.value,
            fromDate : this.model.dteSelection.dteRange.fromDate,
            toDate: this.model.dteSelection.dteRange.toDate,
        };

        chngPayload.fromDate = OrbiwidgetdateselectorComponent.getFromDateFromStateVars(this.calendar, chngPayload),
        chngPayload.toDate = OrbiwidgetdateselectorComponent.getToDateFromStateVars(this.calendar, chngPayload),


        // a change was detected, but doesnt modify the from date or to date so only ui (e.g. label update)        
        chngPayload.isDateChange = (chngPayload.fromDate != this.fromDate || chngPayload.toDate != this.toDate);
        chngPayload.isUiOnlyChange = (this.dateSelectorLabel != chngPayload.dateLabel || this.quickValue != chngPayload.quickValue);
        

        if (chngPayload.isDateChange || chngPayload.isUiOnlyChange) 
        { 
            if (0 != this.onChangeDebounceTimerId) {
                clearTimeout(this.onChangeDebounceTimerId);
            }
            this.onChangeDebounceTimerId = setTimeout( () => {
                this.change.emit(chngPayload);
                this.onChangeDebounceTimerId = undefined;
            }, 500);
        }
    }

    private static getDateFromNgbDateImpl(ngbDate:NgbDate | Date | undefined) : Date | undefined 
    {
        let dteR : Date | undefined = undefined;
        if (undefined != ngbDate) 
        {
            if (ngbDate instanceof Date) {
                dteR = ngbDate;
            } else /* if (ngbDate instanceof NgbDate) -- this test fails so maybe object constructed in way not compat with instanceof */ {
                if (undefined != ngbDate.year && undefined != ngbDate.month && undefined != ngbDate.day) {
                    dteR = new Date(ngbDate.year, ngbDate.month-1, ngbDate.day);
                } 
            }
        }
        else
        {
        }

        return dteR;
    }

    private getStateFromMemberVars() : Partial<OrbiwidgetdateselectorState> {
        let values : Partial<OrbiwidgetdateselectorState> = {
            dateSelectorType : this.model.dteSelection.isTypeInThePast ? OrbiwidgetdateselectorDateType.IsInThePast : OrbiwidgetdateselectorDateType.IsInTheRange,
            inThePastTypeThing : this.model.dteSelection.dteInThePast.selectedTypeThingsKey,
            inThePastNumThings : this.frmCtrlValuesFilter.value,
        }

        switch (values.dateSelectorType) {
            case OrbiwidgetdateselectorDateType.IsInThePast:
                values.fromDate = OrbiwidgetdateselectorComponent.getFromDateFromStateVars(this.calendar, values);
                values.toDate = OrbiwidgetdateselectorComponent.getToDateFromStateVars(this.calendar, values);
                break;

            case OrbiwidgetdateselectorDateType.IsInTheRange:
                values.fromDate = OrbiwidgetdateselectorComponent.getDateFromNgbDateImpl(this.model.dteSelection.dteRange.fromDate);
                values.toDate = OrbiwidgetdateselectorComponent.getDateFromNgbDateImpl(this.model.dteSelection.dteRange.toDate);
                break;
        }

        return values;
    }

    public static getNormalizedValues(calendar:NgbCalendar, stateVarsDate : OrbiwidgetdateselectorState)  : [Date,Date]
    {
        let theDate = new Date();
        let fromDate = OrbiwidgetdateselectorComponent.getFromDateFromStateVars(calendar, stateVarsDate);
        let toDate = OrbiwidgetdateselectorComponent.getToDateFromStateVars(calendar, stateVarsDate);
        toDate = toDate ?? new Date(theDate.getFullYear(), theDate.getMonth(), theDate.getDate());

        if (! fromDate) {            
            let numThingsMultiplier = InThePastTypeThingsProps[stateVarsDate.inThePastTypeThing] ?? 1;
            let numThings = stateVarsDate.inThePastNumThings ?? 7;
            let numDays = numThingsMultiplier.multiplier * numThings;
            fromDate = fromDate ?? new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() - numDays);
        }

        return [fromDate, toDate];
    }

    private static getFromDateFromStateVars(calendar:NgbCalendar, values : Partial<OrbiwidgetdateselectorState>) : Date | undefined {
        // TBD pull requests etc never uses inthepast date so it gets ignored
        let r;
        if ( values.dateSelectorType == OrbiwidgetdateselectorDateType.IsInThePast ) {
            let myItitpKey = values.inThePastTypeThing;
            if (myItitpKey) {
                let numThings = values.inThePastNumThings;
                let myItitp = InThePastTypeThingsProps[myItitpKey];
                if (numThings && myItitp) {
                    let numDays = myItitp.multiplier * numThings;
                    r = calendar.getNext(calendar.getToday(), 'd', -1 * numDays);
                }
            } else {
                // TBD app error
            }
        } else {
            // range from date 
            r = values.fromDate;
        }

        let dteR : Date | undefined = OrbiwidgetdateselectorComponent.getDateFromNgbDateImpl(r);
        return dteR;
    }

    private static getToDateFromStateVars(calendar:NgbCalendar, values : Partial<OrbiwidgetdateselectorState>) {
        var r;
        if ( values.dateSelectorType == OrbiwidgetdateselectorDateType.IsInThePast ) {
            r = calendar.getNext(calendar.getToday(), 'd', 2);
        }
        else {
            r = values.toDate;
        }

        let dteR = OrbiwidgetdateselectorComponent.getDateFromNgbDateImpl(r);
        return dteR;
    }

    private static getGlobalFilterTabTitleDateImpl(
        locale : string,
        calendar : NgbCalendar,
        values : Partial<OrbiwidgetdateselectorState>
/*        dateSelectorType:OrbiwidgetdateselectorDateType, 
        inThePastTypeThing:OrbiwidgetdateselectorInThePastTypeThing, 
        inThePastNumThings:number, 
        fromDate:Date | undefined, 
        toDate:Date | undefined*/) : string {
        let r = '';
        switch(values.dateSelectorType) { 
            case OrbiwidgetdateselectorDateType.IsInThePast: {
                let typeThingsLabel = values.inThePastTypeThing ? InThePastTypeThingsProps[values.inThePastTypeThing] : undefined;
                if (undefined == typeThingsLabel) {
                    typeThingsLabel = InThePastTypeThingsProps[OrbiwidgetdateselectorInThePastTypeThing.Days];
                }

                let inThePastNumThings = values.inThePastNumThings ?? 7;
                if (typeThingsLabel && typeThingsLabel.label) {
                    r = Globalfilters_DateType_IsInThePast_Label + ' ' + inThePastNumThings + ' ' + typeThingsLabel.label;
                } else {
                    console.error("uknown inthepast object");
                }
                break;
            }
        case OrbiwidgetdateselectorDateType.IsInTheRange:
            r = 'From: ' + OangRenderers.renderDate(locale, values.fromDate) 
                    + ' To: ' + OangRenderers.renderDate(locale, values.toDate);
            break;
        }

        return r;
    }

    private getGlobalFilterTabTitleDate(myValues : Partial<OrbiwidgetdateselectorState>) : string {

        /*
        let dsType = OrbiwidgetdateselectorDateType.Invalid;
        if (this.model.dteSelection.isTypeInThePast) { dsType = OrbiwidgetdateselectorDateType.IsInThePast; };
        if (this.model.dteSelection.isTypeInRange) { dsType = OrbiwidgetdateselectorDateType.IsInTheRange; };
        let fromDate : Date | undefined = undefined;
        let toDate : Date | undefined = undefined;
        if (this.model.dteSelection.isTypeInRange) {
        } */


        /*let myValues = this.getStateFromMemberVars();
         let r = OrbiwidgetdateselectorComponent.getGlobalFilterTabTitleDateImpl(this.locale, dsType, this.model.dteSelection.dteInThePast.selectedTypeThingsKey, 
            this.frmCtrlValuesFilter.value, 
            fromDate,
            toDate); */
        let r = OrbiwidgetdateselectorComponent.getGlobalFilterTabTitleDateImpl(this.locale, this.calendar, myValues);
        return r;

            /*
        let r = '';
        if (this.model.dteSelection.isTypeInThePast) 
        {
            let typeThingsLabel = InThePastTypeThingsProps[this.model.dteSelection.dteInThePast.selectedTypeThingsKey];
            if (typeThingsLabel && typeThingsLabel.label) {
                r = Globalfilters_DateType_IsInThePast_Label + ' ' + this.frmCtrlValuesFilter.value + ' ' + typeThingsLabel.label;
            } else {
                console.error("uknown inthepast object");
            }
        } else if (this.model.dteSelection.isTypeInRange) {
            r = 'From: ' + OangRenderers.renderDate(this.locale, this.model.dteSelection.getFromDate(this)) 
                + ' To: ' + OangRenderers.renderDate(this.locale, this.model.dteSelection.getToDate(this));

        }

        return r; */
    }
}
