import { OrbiApiContstants } from "../cmn/utils/ApiConstants";
import { OanDebugutils } from "../cmn/utils/OanDebugutils";

export class OrbiSvcArgsValidator {
    public static validateExpectedString(fnName:string, argName:string, argVal:string, maxLen:number, pattern?:RegExp) {
        let isOk = "string" == typeof argVal && 0 < argVal.length && maxLen > argVal.length;
        OanDebugutils.debuggerWrapperOnCondition(!isOk, fnName, argName);
        return isOk;
    }

    public static validateOrgName(fnName:string, orgName: string) {
        // let isOk = "string" == typeof orgName && 0 < orgName.length && OrbiApiContstants.GithubMaxRepoNameLength > orgName.length;
        let isOk = OrbiSvcArgsValidator.validateExpectedString(fnName, "orgName", orgName, OrbiApiContstants.GithubMaxRepoNameLength); 
        return isOk;
    }

    public static validateSettingGroupName(fnName:string, groupName:string)  {
        // let isOk = "string" == typeof orgName && 0 < orgName.length && OrbiApiContstants.GithubMaxRepoNameLength > orgName.length;
        let isOk = OrbiSvcArgsValidator.validateExpectedString(fnName, "groupName", groupName, OrbiApiContstants.MaxEnumValue); 
        return isOk;
    }
}