import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import {formatDate} from '@angular/common';
import { LOCALE_ID } from '@angular/core';

export class OangRenderers { 

    public static renderDate(locale: string, dte:any, strDteFormat = 'd MMM y') {
        var strFromDate : string = '';
        if (undefined != dte) 
        {
            if (dte.year && dte.month && dte.day && locale) {
                const jsDate = new Date(dte.year, dte.month - 1, dte.day);            
                strFromDate = formatDate(jsDate, strDteFormat, locale);
            } else if ("function" === typeof dte.toISOString) {
                strFromDate = dte.toISOString().split('T')[0];
            }
        }
        
        return strFromDate;
    }
};
