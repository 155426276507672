import { Injectable, TemplateRef } from '@angular/core';
import { OanDebugutils } from 'src/app/cmn/utils/OanDebugutils';
import { OangJsonHelper } from '../cmn/OanJsonHelper';


export enum OangnotificationsErrorId 
{
    Info_FilterShowAllVsLiveChange,

    Info_Test,
    Info_HttpAuthExpired,
    Info_PayloadTooLarge,
        
    Error_Test,
    Error_HttpAuth,
    Error_HttpUnknown,
    Error_AppNotLoggedIn,
    Error_AppNotAuthorizedForOrg,
    Error_AppTenantNotConfigured,

};

class Impl 
{
    public static getMsgFromError(options:any) : string 
    {
        let msg : string = `(unknown)`;        
        if (options.error instanceof ErrorEvent) {
            msg = options.error.message;
        } else if (options.error) {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            msg = options.error.error;
        } else {
            msg = options.msg;
        }

        if (msg == undefined || 0 == msg.length) 
        {
            OanDebugutils.debuggerWrapper(".?.");
        }
        
        return msg;
    }
}

@Injectable({
  providedIn: 'root'
})
export class OangnotificationsService {
    private infoAlreadyToasted : Map<OangnotificationsErrorId, boolean> = new Map<OangnotificationsErrorId, boolean>();
    toasts: any[] = [];
    pageAlerts: any[] = [];

    constructor() { }

    public addPageAlertInfo(errorId : OangnotificationsErrorId, options: any) {
        let msg = Impl.getMsgFromError(options);
       let ctxt = (options.context) ? options.context : '';
        this.showPageInfo(errorId, msg, options);
        console.info('An error occurred: context=' + ctxt + ", message=" + msg);
    }

    public addPageAlertError(errorId : OangnotificationsErrorId, options: any) 
    {
        let msg = Impl.getMsgFromError(options);
        let ctxt = (options.context) ? options.context : '';
        this.showPageError(errorId, msg, options);
    }

    public addToastAlertError(errorId : OangnotificationsErrorId, options: any) 
    {
        let msg = Impl.getMsgFromError(options);
        let ctxt = (options.context) ? options.context : '';
        this.showToastError(errorId, msg);
    }

    /* 
    showToastSuccess(textOrTpl: string, options: any = {}) {
        //options.classname += 'bg-success text-light';
        OangJsonHelper.setPropIfNotExist(options, "classname", 'alert-primary');
        this.toasts.push({ textOrTpl, ...options });
    } -->
 
    showToastError(textOrTpl: string, options: any = {}) {
        // this.svcNotifier.showToastError('An error occurred',  { alerttype : this.testModel.pageAlertTypeSelected, msg: this.testModel.message });

        OangJsonHelper.setPropIfNotExist(options, "classname", 'alert-danger');
        var newT = { textOrTpl, ...options };
        this.toasts.push(newT);
    } */

    private getToastAlert(errorId:OangnotificationsErrorId, textOrTpl: string, options: any = {}) {
        // this.svcNotifier.showToastError('An error occurred',  { alerttype : this.testModel.pageAlertTypeSelected, msg: this.testModel.message });
        // OangJsonHelper.setPropIfNotExist(options, "classname", 'alert-danger');
        var newT = { errorId: errorId, textOrTpl:textOrTpl, ...options };
        this.toasts[errorId] = newT;
        return newT;
    } 

    private showToastError(errorId:OangnotificationsErrorId, text : string, options : any = {}) { 
        OangJsonHelper.setPropIfNotExist(options, "classname", 'alert-danger');
        let newAlert = this.getToastAlert(errorId, text, { type: "danger", ...options });        
    }

    public showToastInfo(errorId:OangnotificationsErrorId, text : string, options : any, showOnlyOnce:boolean, autoDismissAfterSeconds:number) { 
        if (false == showOnlyOnce || ! this.infoAlreadyToasted.get(errorId)) {
            this.infoAlreadyToasted.set(errorId, true);
            OangJsonHelper.setPropIfNotExist(options, "classname", 'alert-info');
            let newAlert = this.getToastAlert(errorId, text, { type: "info", ...options });        
            if (0 < autoDismissAfterSeconds) {
                setTimeout(() => {
                    this.removeToast(newAlert);
                }, autoDismissAfterSeconds * 1000);
            }
        }
    }

    public removeToast(toast : any) {
        this.toasts = this.toasts.filter((t : any) => {
            let r = (t.errorId != t.errorId);
            return r;
        });        
    }

    public removePagealert(pageAlert : any) { 
        this.pageAlerts = this.pageAlerts.filter((t : any) => {
            let r = (t.errorId != pageAlert.errorId);
            return r;
        });
    }


    // see https://mdbootstrap.com/docs/angular/components/alerts/ 
    private getPageAlert(errorId:OangnotificationsErrorId, text: string, options: any = {}) {
        // OangJsonHelper.setPropIfNotExist(options, "classname", 'alert-primary');        
        let newAlert = { errorId: errorId, text:text, ...options };
        this.pageAlerts[errorId] = newAlert;
        return newAlert;
    } 

    private showPageError(errorId:OangnotificationsErrorId, text : string, options : any = {}) { 
        OangJsonHelper.setPropIfNotExist(options, "classname", 'alert-danger');
        let newAlert = this.getPageAlert(errorId, text, { type: "danger", ...options });        
    }

    private showPageInfo(errorId:OangnotificationsErrorId, text : string, options : any = {}) { 
        OangJsonHelper.setPropIfNotExist(options, "classname", 'alert-info');
        let newAlert = this.getPageAlert(errorId, text, { type: "warning", ...options });
    }

    private showPageWarning(errorId:OangnotificationsErrorId, text : string, options : any = {}) { 
        OangJsonHelper.setPropIfNotExist(options, "classname", 'alert-warning');
        let newAlert = this.getPageAlert(errorId, text, { type: "warning", ...options });        
    }

    private showPageSuccess(errorId:OangnotificationsErrorId, text: string, options: any = {}) {
        OangJsonHelper.setPropIfNotExist(options, "classname", 'alert-primary');
        let newAlert = this.getPageAlert(errorId, text, { type: "success", ...options });
    } 

}
