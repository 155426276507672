import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { OrbiConstants, OrbiSettingDTO, OrbiSettingGroupName, OrbiSettingGroupName_allvalues, OrbiSettingNvpName } from '@danclarke2000/gitrospectdto';
import { OrbisettingsService } from 'src/app/svc/orbisettings.service';
import {KeyValue} from '@angular/common';
import { OanAnalyzerParams } from 'src/app/cmn/analyzer/OanAnalyzerParams';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrbiwidgetValue } from './orbiwidgetcmn';
import { OanDebugutils } from '../utils/OanDebugutils';
import { ChartData, ChartTypeRegistry } from 'chart.js';

interface OrbiwidgetgraphUistate {
    cmpThis : OrbiwidgetgraphComponent;        
}

@Component({
  selector: 'app-orbiwidgetgraph',
  templateUrl: './orbiwidgetgraph.component.html',
  styleUrls: ['./orbiwidgetgraph.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: OrbiwidgetgraphComponent,
    multi: true,
  }]
})
export class OrbiwidgetgraphComponent implements OnInit {
    public static readonly className = "OrbiwidgetgraphComponent";
    
    @Input() graphName : string = '';    
    @Input() legend : boolean = false;    
    @Input() data : ChartData | undefined= undefined;    
    @Input() type  : string | undefined= undefined;    
    @Input() chartOptions : any | undefined= undefined;
    change = new EventEmitter()

    public typeAsEnum : keyof ChartTypeRegistry | undefined;
    public myOptions : any = {};

    public uiEvents : any = {
        className : "uiEvents",
        cmpThis:this,        
        
    }

    public model : any = {};

    public uiState : OrbiwidgetgraphUistate = {    
        cmpThis : this,           
    };

    constructor(@Inject(LOCALE_ID) public locale: string,
        private calendar: NgbCalendar,
        private fb : UntypedFormBuilder,
        public svcSettings : OrbisettingsService) 
    {
    }

    public onInputChanges(changes: SimpleChanges) 
    {
        Object.keys(changes).forEach( (currKey:string) => {
            switch (currKey) {
                case 'type':
                    this.typeAsEnum = changes[currKey].currentValue;
                    break;

                case 'chartOptions':
                    this.myOptions = {};
                    if (changes[currKey].currentValue.generalOptions) {
                        Object.assign(this.myOptions, changes[currKey].currentValue.generalOptions);
                    }

                    if (changes[currKey].currentValue.plugins) {
                        this.myOptions.plugins = changes[currKey].currentValue.plugins;
                    }

                    if (this.data && changes[currKey].currentValue.datasetOptions) {
                        this.data.datasets.forEach((dataset:any, i:number) => {
                            Object.assign(dataset, changes[currKey].currentValue.datasetOptions);
                        });                        
                    }

                    break;
            }
        });
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onInputChanges(changes);
    }    

    ngAfterViewInit(): void {
    }
}
