import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import {  OrbiSettingGroupName, OrbiSettingGroupName_allvalues, OrbiSettingNvpName, ApiMwgithubResult, OrbiRadioValues, ApiFormCtrlId_Ten_Assets, ApiFormCnst_RepoProdDefType, ApiFormCnst_BranchProdDefType } from '@danclarke2000/gitrospectdto';                        

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OangNavTabState, OangNavTabStateActiveContentAreaHeader, OangNavTabStateActiveContentAreaMetaMap } from '../cmn/ui/OanNavTabState';
import { GithubColumnDefs } from '../github/githubcolumndefs';
import { OrbidebugService } from '../svc/orbidebug.service';
import { OrbisettingsService } from '../svc/orbisettings.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { OanDebugutils } from '../cmn/utils/OanDebugutils';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { OanAnalyzerParams } from '../cmn/analyzer/OanAnalyzerParams';
import { OanHttpUtils } from '../cmn/utils/oanhttputils';
import { OanAnalyzerCatalog } from '../cmn/analyzer/OanAnalyzerCatalog';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UiObj } from '../cmn/ui/UiObj';
import { OrbiwidgettextboxlistComponent } from '../cmn/widget/orbiwidgettextboxlist.component';
import { OrbiwidgetradioComponent } from '../cmn/widget/orbiwidgetradio.component';
import { OrbiwidgetnameselectorComponent } from '../cmn/widget/orbiwidgetnameselector.component';
import { OrbiBrowserStorageService } from '../svc/orbibrowserstorage.service';
import { OrbiprofileService } from '../svc/orbiprofile.service';
import { OrbistartuprequestsL2Interface, OrbistartuprequestsL3Interface, Orbistartuprequestsservice } from '../svc/orbistartuprequests.service';
import { OrbiAuditResultsManagerService } from '../svc/orbiauditresultsmanager.service';
import { DefaultApiMwgithubResultObj } from '../svc/orbigithub.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrbiAppState, OrbiAppStateService } from '../svc/orbiappstate.service';
import { OrbiAppComponentRoutes } from '../app.module';

const TabIdentifiers_navItemOrgProfile : string =  OrbiSettingGroupName.GithubOrg;
const TabIdentifiers_navItemSubscriptionPlan : string =  OrbiSettingGroupName.Subscription;
const TabIdentifiers_navItemBillingSettings : string =  OrbiSettingGroupName.Billing;
const TabIdentifiers_navItemUserMgmt : string =  OrbiSettingGroupName.Authorization;
const TabIdentifiers_navItemLiveProductionAssetsRepos : string = OrbiSettingGroupName.GithubLiveProductionAssetsRepos;
const TabIdentifiers_navItemLiveProductionAssetsBranches : string = OrbiSettingGroupName.GithubLiveProductionAssetsBranches;
const TabIdentifiers_navItemAuditChecks  : string =  OrbiSettingGroupName.GithubAuditChecks;
const TabIdentifiers_navItemReportSettings  : string =  OrbiSettingGroupName.GithubReportSettings;
const TabIdentifiers_all : string[] = [
    TabIdentifiers_navItemOrgProfile, TabIdentifiers_navItemSubscriptionPlan, TabIdentifiers_navItemBillingSettings,
    TabIdentifiers_navItemUserMgmt,
    TabIdentifiers_navItemLiveProductionAssetsRepos, TabIdentifiers_navItemLiveProductionAssetsBranches,
    TabIdentifiers_navItemAuditChecks, TabIdentifiers_navItemReportSettings
];

class Impl 
{
    // ctrl id for repositories
    public static  readonly frmCtrlIdLiveProdDefinitionTypeForRepos = ApiFormCtrlId_Ten_Assets.LiveRepoDefType; // 'assetsLiveProdDefinitionTypeForRepos';    
    public static  readonly frmCtrlIdLiveProdReposByTopic = ApiFormCtrlId_Ten_Assets.LiveRepoDefByTopic; // // 'assetsLiveProdReposByTopic';
    public static  readonly frmCtrlIdLiveProdReposByIndName = ApiFormCtrlId_Ten_Assets.LiveRepoDefByIndName;//'assetsLiveProdReposByIndNames';
    public static  readonly frmCtrlIdLiveProdReposByPattern = ApiFormCtrlId_Ten_Assets.LiveRepoDefByPattern; // 'assetsLiveProdReposByPattern';
    public static  readonly frmCtrlIdLiveProdReposByAll = ApiFormCtrlId_Ten_Assets.LiveRepoDefByAll;// 'assetsLiveProdReposByAll';

    // ctrl ids for live branches
    public static readonly frmCtrlIdLiveProdDefinitionTypeForBranches = ApiFormCtrlId_Ten_Assets.LiveBranchDefType;   // 'assetsLiveProdDefinitionTypeForBranches';
    public static readonly frmCtrlIdLiveProdBranchesByNamingConv = ApiFormCtrlId_Ten_Assets.LiveBranchDefByNameConv; // 'assetsLiveProdBranchesByNamingConv';
    public static readonly frmCtrlIdLiveProdBranchesByPattern = ApiFormCtrlId_Ten_Assets.LiveBranchDefByPattern; // 'assetsLiveProdBranchesByPattern';
    public static readonly frmCtrlIdLiveProdBranchesByAll = ApiFormCtrlId_Ten_Assets.LiveBranchDefByAll; // 'assetsLiveProdBranchesByAll';
    public static readonly frmCtrlIdLiveProdBranchesByDefault = ApiFormCtrlId_Ten_Assets.LiveBranchDefByDefault //'assetsLiveProdBranchesByDefault';

    public static initFormAuditChecks(cmpThis:TenantsettingsComponent, localeStr:string /*, auditCheckCtrls:any*/)
    {
        // let valuesAuditChecksCtrlByKey : any = {};
        cmpThis.model.auditCheckAreas = [];
        let auditCheckCtrls : any = {};

        let testsByGrouping = OanAnalyzerCatalog.getAuditTestKeyGroupings();
        Object.keys(testsByGrouping).forEach ((currAuditAreaKey:string) => {
            let currCheckArea :any = {
                areaKey : currAuditAreaKey,                    
                areaChecks : []
            };

            if (0 < testsByGrouping[currAuditAreaKey].length)
            {
                currCheckArea['areaLabel'] = OanAnalyzerCatalog.getAuditSectionHeader(localeStr, currAuditAreaKey);
                testsByGrouping[currAuditAreaKey].forEach( (currAuditTestKey:string) => {
                    let currCheck = {
                        key: currAuditTestKey,
                        label: OanAnalyzerCatalog.getAuditTestLabel(localeStr, currAuditTestKey),
                        ctrl: cmpThis.fb.control({ value: OrbiRadioValues.Must, disabled: false} , {validators: Validators.required }),
                        type: 'orbiradio',
                        possiblevalues: [ {id:currAuditTestKey+'must', valuekey: OrbiRadioValues.Must, valuelabel:'Must'},
                                        {id:currAuditTestKey+'should', valuekey:OrbiRadioValues.Should, valuelabel:'Should'},
                                        {id:currAuditTestKey+'nice', valuekey:OrbiRadioValues.Nice, valuelabel:'Nice'},
                                        {id:currAuditTestKey+'na', valuekey:OrbiRadioValues.NA, valuelabel:'NA'}]
                    };

                    currCheckArea.areaChecks.push(currCheck);
                    auditCheckCtrls[currAuditTestKey] = currCheck.ctrl;
                    //this.valuesAuditChecksCtrlByKey[currAuditTestKey] = currCheck.ctrl;
                });

                cmpThis.model.auditCheckAreas.push(currCheckArea);
            }
        });

        /*
        Object.keys(cmpThis.uiState.auditTestKeys).forEach ((currAuditAreaKey:string) => {
            let currCheckArea :any = {
                areaKey : currAuditAreaKey,                    
                areaChecks : []
            };

            if (0 < cmpThis.uiState.auditTestKeys[currAuditAreaKey].length)
            {
                currCheckArea['areaLabel'] = OanAnalyzerCatalog.getAuditSectionHeader(localeStr, currAuditAreaKey);
                cmpThis.uiState.auditTestKeys[currAuditAreaKey].forEach( (currAuditTestKey:string) => {
                    let currCheck = {
                        key: currAuditTestKey,
                        label: OanAnalyzerCatalog.getAuditTestLabel(localeStr, currAuditTestKey),
                        ctrl: cmpThis.fb.control({ value: OrbiRadioValues.Must, disabled: false} , {validators: Validators.required }),
                        type: 'orbiradio',
                        possiblevalues: [ {id:currAuditTestKey+'must', valuekey: OrbiRadioValues.Must, valuelabel:'Must'},
                                        {id:currAuditTestKey+'should', valuekey:OrbiRadioValues.Should, valuelabel:'Should'},
                                        {id:currAuditTestKey+'nice', valuekey:OrbiRadioValues.Nice, valuelabel:'Nice'},
                                        {id:currAuditTestKey+'na', valuekey:OrbiRadioValues.NA, valuelabel:'NA'}]
                    };

                    currCheckArea.areaChecks.push(currCheck);
                    auditCheckCtrls[currAuditTestKey] = currCheck.ctrl;
                    //this.valuesAuditChecksCtrlByKey[currAuditTestKey] = currCheck.ctrl;
                });

                cmpThis.model.auditCheckAreas.push(currCheckArea);
            }
        }); */

        return cmpThis.fb.group(auditCheckCtrls);            
    }

    public static initformAssetsAffectingLiveProdRepos(cmpThis:TenantsettingsComponent, localeStr:string)
    {
        // let valuesAuditChecksCtrlByKey : any = {};
        cmpThis.model.assetsLiveProdAreasRepos = {};
        
        // select repositories
        let ctrlAssetsLiveProdDefinitionTypeForRepos = {
                key: cmpThis.frmCtrlId.LiveProdDefinitionTypeForRepos,
                label: 'The following defines which repositories are considered relavant for the live or production environment.  Configuring this to match your environment allows makes it easy to filter the most relevant changes for an audit target, or where to focus security efforts.',
                type: OrbiwidgetradioComponent.className,
                possiblevalues: [ ApiFormCnst_RepoProdDefType.ByTopic, 
                    ApiFormCnst_RepoProdDefType.ByIndNames, 
/*                    ApiFormCnst_RepoProdDefType.ByPattern, */
                    ApiFormCnst_RepoProdDefType.ByReposAll ],
                possiblelabels: [ 'Github topic', 'List of repository names', /*'Regex pattern',*/ 'All repositories' ],
                selectedvalue:cmpThis.frmCtrlId.ctrlRadioLiveProdDefTypeForReposAll
            };

        let ctrlAssetsLiveProdReposByTopic = {
            key: cmpThis.frmCtrlId.LiveProdReposByTopic,
            label: '',
            type: OrbiwidgetnameselectorComponent.className,
            possiblevalues: [],
            possiblelabels: [],
            selectedvalues: []  
        };                        

        let ctrlAssetsLiveProdReposByIndName = {
            key: cmpThis.frmCtrlId.LiveProdReposByIndName,
            label: '',
            type: OrbiwidgetnameselectorComponent.className,
            possiblevalues: [],
            possiblelabels: [],
            selectedvalues: []                
        };            

        let ctrlAssetsLiveProdReposByPattern = {
            key: cmpThis.frmCtrlId.LiveProdReposByPattern,
            label: '',
            type: OrbiwidgettextboxlistComponent.className,
            possiblevalues: [],
            possiblelabels: [],
            selectedvalues: []
        };            

        
        // register controls
        cmpThis.model.assetsLiveProdAreasRepos[cmpThis.frmCtrlId.LiveProdDefinitionTypeForRepos] = ctrlAssetsLiveProdDefinitionTypeForRepos;
        cmpThis.model.assetsLiveProdAreasRepos[cmpThis.frmCtrlId.LiveProdReposByTopic] = ctrlAssetsLiveProdReposByTopic;
        cmpThis.model.assetsLiveProdAreasRepos[cmpThis.frmCtrlId.LiveProdReposByIndName] = ctrlAssetsLiveProdReposByIndName;
        cmpThis.model.assetsLiveProdAreasRepos[cmpThis.frmCtrlId.LiveProdReposByPattern] = ctrlAssetsLiveProdReposByPattern;


        /*
        let ctrls : any = {};
        Object.values(cmpThis.model.assetsLiveProdAreas).forEach ((ctrlWrapper:any)=> {
            ctrls[ctrlWrapper.key] = ctrlWrapper.ctrl;
        });
        let frmGroup = cmpThis.fb.group(ctrls); */
        let invalidKeys = Object.keys(cmpThis.model.assetsLiveProdAreasRepos).filter( (currKey:string) => ! currKey);
        let invalidValues = Object.values(cmpThis.model.assetsLiveProdAreasRepos).filter( (curr:any) => ! curr);
        if (0 != invalidKeys.length && 0 != invalidValues.length) {
            OanDebugutils.debuggerWrapper(".?.");
        }

        let frmGroup = cmpThis.fb.group(cmpThis.model.assetsLiveProdAreasRepos);
        return frmGroup;
    }

    public static initformAssetsAffectingLiveProdBranches(cmpThis:TenantsettingsComponent, localeStr:string)
    {
        // let valuesAuditChecksCtrlByKey : any = {};
        cmpThis.model.assetsLiveProdAreasBranches = {};
        // select branches
        let ctrlAssetsLiveProdDefinitionTypeForBranches = {
            key: cmpThis.frmCtrlId.LiveProdDefinitionTypeForBranches,
            label: 'Identify branches which affect production by:',
            type: OrbiwidgetradioComponent.className,
            possiblevalues: [ 
                cmpThis.frmCtrlId.ctrlRadioLiveProdDefTypeForBranchesDefault,
                cmpThis.frmCtrlId.ctrlRadioLiveProdDefTypeForBranchesByNamingConv,
                /*cmpThis.frmCtrlId.ctrlRadioLiveProdDefTypeForBranchesByPattern,*/
                cmpThis.frmCtrlId.ctrlRadioLiveProdDefTypeForBranchesAll ],
            possiblelabels: [ 'Default', 'Naming convention', /*'Branch name regex',*/ 'All branches' ]
        };


        let ctrlAssetsLiveProdReposByNamingConvention = {
            key: cmpThis.frmCtrlId.LiveProdBranchesByNamingConv,
            label: '',
            type: OrbiwidgettextboxlistComponent.className,
            possiblevalues: [],
            possiblelabels: [],
            selectedvalues: []                
        };            

        let ctrlAssetsLiveProdBranchesByPattern = {
            key: cmpThis.frmCtrlId.LiveProdBranchesByPattern,
            label: '',
            type: OrbiwidgettextboxlistComponent.className,
            possiblevalues: [],
            possiblelabels: [],
            selectedvalues: []
        };            

        cmpThis.model.assetsLiveProdAreasBranches[cmpThis.frmCtrlId.LiveProdDefinitionTypeForBranches] = ctrlAssetsLiveProdDefinitionTypeForBranches;
        cmpThis.model.assetsLiveProdAreasBranches[cmpThis.frmCtrlId.LiveProdBranchesByNamingConv] = ctrlAssetsLiveProdReposByNamingConvention;
        cmpThis.model.assetsLiveProdAreasBranches[cmpThis.frmCtrlId.LiveProdBranchesByPattern] = ctrlAssetsLiveProdBranchesByPattern;

        let frmGroup = cmpThis.fb.group(cmpThis.model.assetsLiveProdAreasBranches);
        return frmGroup;
    }
}

class TenantsettingsTabState extends OangNavTabState<TenantsettingsComponent> {
    public activeObjsLastSync : any = {};
    public lastSync : any = {};
    
    constructor(
        svcAuditResultsMgr : OrbiAuditResultsManagerService,
        validTabs : string[], 
        svcDebug: OrbidebugService,
        cmpThis : TenantsettingsComponent) 
    {
        super(validTabs, svcDebug, cmpThis, svcAuditResultsMgr);        
    }

    public localFilterColDefWithFilter(colDef : any) {
        var r = {}; 
        return r;
    }

    public refreshData() : void { 
        console.log("refreshData");
    }

    public onSync($event : any) : void { 
        let settingGroupName =  this.activeTab;
        /*
        switch (this.activeTab)
        {
            case this.cmpThis.navItemAuditChecks:
                settingGroupName = OrbiSettingGroupName.GithubAuditChecks;
                break;

            case this.cmpThis.navItemLiveProductionAssets:
                settingGroupName = OrbiSettingGroupName.GithubLiveProductionAssets;
                break;

            case this.cmpThis.navItemReportSettings:
                settingGroupName = OrbiSettingGroupName.GithubReportSettings;
                break;
        } */

        let orgName = this.cmpThis.svcProfile.getActiveOrgName();
        if (settingGroupName && orgName) {
            this.cmpThis.svcSettings.apiGithubSettings(orgName, settingGroupName);
        } else {
            console.warn(`onSync: no settingGroupName=${settingGroupName} or orgName=${orgName}`);
        }
    }
}

@Component({
  selector: 'app-tenantsettings',
  templateUrl: './tenantsettings.component.html',
  styleUrls: ['./tenantsettings.component.scss']
})
export class TenantsettingsComponent implements OnInit {
    
    @ViewChild('selectLiveRepoNames', { static: false }) public selectLiveRepoNames : any;
    @ViewChild('selectLiveRepoTopics', { static: false }) public selectLiveRepotopics : any;
    @ViewChild('livedefsrepo', { static: false }) public livedefsrepo : any;
    @ViewChild('livedefsbranches', { static: false }) public livedefsbranches : any;
    @ViewChild('auditchecksrepos', { static: false }) public auditchecksrepos : any;
    @ViewChild('auditchecksbranches', { static: false }) public auditchecksbranches : any;

    onTestClick($event:any, currValue:any) {
        debugger;
    }
    
    /*
    public githubFirstTimeLoader : ComponentFirstloadDependencies  = new ComponentFirstloadDependencies(new class implements ComponentFirstloadDependenciesCallbackInterface {

        constructor(private cmpThis : TenantsettingsComponent) {}

        getRequiredPropsBeforeLoading() : string[] { 
            return [ ComponentFirstloadDependenciesProperties.OrgName, ComponentFirstloadDependenciesProperties.GithubControllerAuthzOk, ComponentFirstloadDependenciesProperties.UserPrefs ];
        }

        async doFirstLoad() : Promise<void> {
            let myQfFilterSavedState = await this.cmpThis.svcStorage.readItem(OrbiBrowserStorageItemId.QfFilterStateVars);    
            if (myQfFilterSavedState) {
                this.cmpThis.uiState.qfFilterState.stateVars = myQfFilterSavedState;
                this.cmpThis.model.orgName = this.cmpThis.uiState.qfFilterState.stateVars.orgName;
            }

            let settingGroups : string[] = [OrbiSettingGroupName.GithubOrg,
                OrbiSettingGroupName.GithubAuditChecks, OrbiSettingGroupName.GithubLiveProductionAssets ];
            let settingValObserver = {
                next: (value: HttpEvent<any[]>) => {                
                    var httpResp : HttpResponse<any[]> = <HttpResponse<any[]>>(<unknown>value);
                    if (undefined != httpResp && httpResp.status >= 200 && httpResp.status < 300 && undefined != httpResp.body) {
                        this.cmpThis.model.setFormValuesFromSettings(httpResp.body);
                        /*
                        httpResp.body.forEach(element => {
                            switch (element.settinggroup) {
                                case OrbiSettingGroupName.GithubOrg:
                                    if (OrbiSettingNvpName.GithubOrg_TopicInfo == element.settingobject)  
                                    {
                                        // TBD ### add a type attr to these payloads so we can do some santiy checks
                                        // e.g. element.payload.type = { "repotopics", version "20211231" }
                                        this.model.repoTopics = [ TopicNameAll, TopicNameNone ];
                                        if (element.payload) {
                                            this.model.repoTopics.push(...Object.keys(element.payload));
                                        }                                    
                                    }
                                    break;
    
                                case OrbiSettingGroupName.GithubAuditChecks:
                                    this.model.formGroupAuditChecks.setValue(element.payload);
                                    break;
                                
                                case OrbiSettingGroupName.GithubLiveProductionAssetsRepos:
                                    this.model.formAssetsAffectingLiveProdRepos.setValue(element.payload);
                                    break;
    
                                case OrbiSettingGroupName.GithubLiveProductionAssetsBranches:
                                    this.model.formAssetsAffectingLiveProdBranches.setValue(element.payload);
                                    break;                                
                            }
                        }); 
                    }
                },
                error: (err: any) => {
                    console.error(err);
                    OanDebugutils.debuggerWrapper(err);
                },
                complete: () => {
                    // OanDebugutils.debuggerWrapper(".?.");
                    console.log(" the purpose here is to get  current values to populate the form");
                }
            }; 
            settingGroups.forEach( (currSettingGroupName:string) => {
                this.cmpThis.svcSettings.apiSettingsGroup(this.cmpThis.model.orgName, currSettingGroupName).subscribe(settingValObserver);
            });
        }
    }(this) );  */

    public debugJsonStringify(s : any[]) {
        return ''; // JSON.stringify(s);
    }

    githubsettingsGet$: Observable<ApiMwgithubResult>;    
    githubsettingsPost$: Observable<ApiMwgithubResult>;    

    public readonly navItemOrgProfile : string =  TabIdentifiers_navItemOrgProfile;
    public readonly navItemSubscriptionPlan : string = TabIdentifiers_navItemSubscriptionPlan;
    public readonly navItemBillingSettings : string = TabIdentifiers_navItemBillingSettings;
    public readonly navItemUserMgmt : string = TabIdentifiers_navItemUserMgmt;

    public readonly navItemLiveProductionAssetsRepos  : string = TabIdentifiers_navItemLiveProductionAssetsRepos;
    public readonly navItemLiveProductionAssetsBranches  : string = TabIdentifiers_navItemLiveProductionAssetsBranches;
    public readonly navItemAuditChecks  : string = TabIdentifiers_navItemAuditChecks;
    public readonly navItemReportSettings  : string = TabIdentifiers_navItemReportSettings;
    public tabStateNavItems = TabIdentifiers_all;

    public frmCtrlId : any = {
        // ctrl ids for live repos
        LiveProdDefinitionTypeForRepos : Impl.frmCtrlIdLiveProdDefinitionTypeForRepos,        
        LiveProdReposByTopic  : Impl.frmCtrlIdLiveProdReposByTopic,
        LiveProdReposByIndName : Impl.frmCtrlIdLiveProdReposByIndName,
        LiveProdReposByPattern : Impl.frmCtrlIdLiveProdReposByPattern,
        LiveProdReposByAll : Impl.frmCtrlIdLiveProdReposByAll,

        // ctrl ids for live branches
        LiveProdDefinitionTypeForBranches : Impl.frmCtrlIdLiveProdDefinitionTypeForBranches,
        LiveProdBranchesByNamingConv : Impl.frmCtrlIdLiveProdBranchesByNamingConv,
        LiveProdBranchesByPattern : Impl.frmCtrlIdLiveProdBranchesByPattern,
        LiveProdBranchesByAll : Impl.frmCtrlIdLiveProdBranchesByAll,
        LiveProdBranchesByDefault : Impl.frmCtrlIdLiveProdBranchesByDefault,

        // used for radio button - how to choose repos (used in ngif so must be member)
        ctrlRadioLiveProdDefTypeForReposByTopic : ApiFormCnst_RepoProdDefType.ByTopic, //  Impl.frmCtrlIdLiveProdDefinitionTypeForRepos+'topic', 
        ctrlRadioLiveProdDefTypeForReposByIndNames : ApiFormCnst_RepoProdDefType.ByIndNames, //+'individualnames', 
        ctrlRadioLiveProdDefTypeForReposByPattern : ApiFormCnst_RepoProdDefType.ByPattern, // +'pattern',
        ctrlRadioLiveProdDefTypeForReposAll : ApiFormCnst_RepoProdDefType.ByReposAll,

        // used for adio button on how to choose other things (used in ngif so must be member)
        ctrlRadioLiveProdDefTypeForBranchesByNamingConv : ApiFormCnst_BranchProdDefType.ByNameConv,
        ctrlRadioLiveProdDefTypeForBranchesByPattern : ApiFormCnst_BranchProdDefType.ByPattern,
        ctrlRadioLiveProdDefTypeForBranchesAll : ApiFormCnst_BranchProdDefType.ByBranchAll,
        ctrlRadioLiveProdDefTypeForBranchesDefault : ApiFormCnst_BranchProdDefType.ByDefault,
    };

    public uiState : any = {
        cmpThis : null,
        isPublicWebsite : OanHttpUtils.isPublicWebsite(),
        auditTestKeys : OanAnalyzerCatalog.getAuditTestKeyGroupings(),
        settingsAnalyzerChecks : [],
        assetsLiveProdAreasReposErrors : {
            errorByTopicYouMustSelectOneTopic : false,
            errorByTopicYouMustSelectOneRepositoryName : false,
            errorByTopicYouMustSelectOneRegexPattern : false
        },
        assetsLiveProdAreasBranchesErrors : {
            errorByTopicYouMustDefineSomeNames : false
        },
        getModelDataRepos() {
            return this.cmpThis.model.repos;
        },
        init(cmpThis:any) {
            this.cmpThis = cmpThis;
        }
    }

    public model : any = {
        cmpThis : null,        
        repos : [], /* OanAnalyzerParams.OrbiProdRepositories, / * .map( (repoName:string) => {
            let no = { label: repoName, key: repoName, visible:true, selected:false } ;
            return no;
         }), */
        repoTopics : [], // OanAnalyzerParams.OrbiRepoTopics,

        // these are the form controls to interface with the 
        formGroupAuditChecks : undefined,
        formAssetsAffectingLiveProdRepos : undefined,
        formAssetsAffectingLiveProdBranches : undefined,

        // these are the objects which define form presentation
        auditCheckAreas : [],
        assetsLiveProdAreasRepos : {},
        assetsLiveProdAreasRepoDefType : this.frmCtrlId.ctrlRadioLiveProdDefTypeForReposByIndNames,
        assetsLiveProdAreasBranches : {},

        init(cmpThis:any) {
            this.cmpThis = cmpThis;
            let currLocaleStr = 'en';
            // let auditCheckCtrls : any = {};
            this.formGroupAuditChecks = Impl.initFormAuditChecks(cmpThis, currLocaleStr);
            this.formAssetsAffectingLiveProdRepos = Impl.initformAssetsAffectingLiveProdRepos(cmpThis, currLocaleStr);

            this.formAssetsAffectingLiveProdBranches = Impl.initformAssetsAffectingLiveProdBranches(cmpThis, currLocaleStr);
        },
        setFormValuesFromSettings(settings : any[]) {
            if (Array.isArray(settings)) {
                settings.forEach( (element : any) => {
                    switch (element.settinggroup) {
                        case OrbiSettingGroupName.GithubOrg:
                            if (OrbiSettingNvpName.GithubOrg_TopicInfo == element.settingobject)  
                            {
                                // TBD ### add a type attr to these payloads so we can do some santiy checks
                                // e.g. element.payload.type = { "repotopics", version "20211231" }
                                if (element.payload) {
                                    this.cmpThis.model.repoTopics.push(...Object.keys(element.payload));
                                }                                    
                            }
                            break;                    

                        case OrbiSettingGroupName.GithubAuditChecks:{
                            let settingsVals = ("object" == typeof element.settingobjects) ? element.settingobjects[OrbiSettingNvpName.GithubLiveProductionAssets_base] : undefined;
                            if (undefined != settingsVals) {
                            /* if ("object" == typeof element.settingobjects) {
                                let settingsVals = element.settingobjects[OrbiSettingNvpName.GithubLiveProductionAssets_base];
                                if ("object" == typeof settingsVals) {
                                    delete settingsVals["canary"];
                                } 
                                
                                Object.keys(settingsVals).forEach( (key:string) => {
                                    this.cmpThis.model.formGroupAuditChecks.patchValue({
                                        key: settingsVals[key]
                                    });
                                });    */

                                this.cmpThis.model.formGroupAuditChecks.patchValue(settingsVals);                            
                            }
                            break;
                        }

                        case OrbiSettingGroupName.GithubLiveProductionAssetsRepos:  {
                            let settingsVals = ("object" == typeof element.settingobjects) ? element.settingobjects[OrbiSettingNvpName.GithubLiveProductionAssets_base] : undefined;                            
                            if (undefined != settingsVals) {
                                /* 
                                // if ("object" == typeof element.settingobjects) {
                                let settingsVals = element.settingobjects[OrbiSettingNvpName.GithubLiveProductionAssets_base];
                                if ("object" == typeof settingsVals) {
                                    delete settingsVals["canary"];
                                }
                                
                                Object.keys(settingsVals).forEach( (key:string) => {
                                    this.cmpThis.model.formAssetsAffectingLiveProdRepos.patchValue({
                                        key: settingsVals[key]
                                    });
                                }); */
                                
                                // this.cmpThis.model.formAssetsAffectingLiveProdRepos.setValue(settingsVals);
                                this.cmpThis.model.formAssetsAffectingLiveProdRepos.patchValue(settingsVals);
                                
                            }
                            break;                        
                        }

                        case OrbiSettingGroupName.GithubLiveProductionAssetsBranches: {
                            let settingsVals = ("object" == typeof element.settingobjects) ? element.settingobjects[OrbiSettingNvpName.GithubLiveProductionAssets_base] : undefined;                            
                            if (undefined != settingsVals) {
/*
                            if ("object" == typeof element.settingobjects) {
                                let settingsVals = element.settingobjects[OrbiSettingNvpName.GithubLiveProductionAssets_base];
                                if ("object" == typeof settingsVals) {
                                    delete settingsVals["canary"];
                                }
                                Object.keys(settingsVals).forEach( (key:string) => {
                                    this.cmpThis.model.formAssetsAffectingLiveProdBranches.patchValue({
                                        key: settingsVals[key]
                                    });
                                });
                                */

                                // this.cmpThis.model.formAssetsAffectingLiveProdBranches.setValue(settingsVals);
                                this.cmpThis.model.formAssetsAffectingLiveProdBranches.patchValue(settingsVals);                                
                            }
                            break;                        
                        }
                    }
                } );
            } else {
                OanDebugutils.debuggerWrapper("unexpected value. this fn should only be called with an array of settings");
            }
        }
    };

    public uiEvents : any = {
        cmpThis:null,
        init(cmpThis:any) {
            this.cmpThis = cmpThis;
        },
        clickCheckvalue(navItemId:string, $event:any) {
            let frmOk = false;
            let activeTabValue = this.cmpThis.tabState.activeTab;
            let frmValueAudit = this.cmpThis.model.formGroupAuditChecks.value;
            let frmValueRepos = this.cmpThis.model.formAssetsAffectingLiveProdRepos.value;
            let frmValueBranches = this.cmpThis.model.formAssetsAffectingLiveProdBranches.value;
            switch (navItemId) {
                case this.cmpThis.navItemLiveProductionAssetsRepos:
                    {            
                        let repoDefType = frmValueRepos[ApiFormCtrlId_Ten_Assets.LiveRepoDefType]?.selectedvalue;
                        Object.keys(this.cmpThis.uiState.assetsLiveProdAreasReposErrors).forEach((key:string) => {
                            this.cmpThis.uiState.assetsLiveProdAreasReposErrors[key] = false;
                        });

                        switch  (repoDefType) {
                            case ApiFormCnst_RepoProdDefType.ByTopic: {
                                let selectedValues = frmValueRepos[ApiFormCtrlId_Ten_Assets.LiveRepoDefByTopic]?.selectedvalues;
                                frmOk = Array.isArray(selectedValues) && selectedValues.length > 0;
                                if (! frmOk) {
                                    this.cmpThis.uiState.assetsLiveProdAreasReposErrors.errorByTopicYouMustSelectOneTopic = true;
                                }

                                break;
                            }
                            case ApiFormCnst_RepoProdDefType.ByIndNames: {
                                let selectedValues = frmValueRepos[ApiFormCtrlId_Ten_Assets.LiveRepoDefByIndName]?.selectedvalues;
                                frmOk = Array.isArray(selectedValues) && selectedValues.length > 0;
                                if (! frmOk) {
                                    this.cmpThis.uiState.assetsLiveProdAreasReposErrors.errorByTopicYouMustSelectOneRepositoryName = true;
                                }

                                break;
                            }
                            case ApiFormCnst_RepoProdDefType.ByPattern: {
                                let selectedValues = frmValueRepos[ApiFormCtrlId_Ten_Assets.LiveRepoDefByPattern]?.selectedvalues;
                                frmOk = Array.isArray(selectedValues) && selectedValues.length > 0;
                                if (! frmOk) {
                                    this.cmpThis.uiState.assetsLiveProdAreasReposErrors.errorByTopicYouMustSelectOneRegexPattern = true;
                                }

                                break;
                            }
                            case ApiFormCnst_RepoProdDefType.ByReposAll:
                                frmOk = true;
                                break;
                        }
                    }
                    break;
            
                    case this.cmpThis.navItemLiveProductionAssetsBranches:
                        {            
                            let branchDefType = frmValueBranches[ApiFormCtrlId_Ten_Assets.LiveBranchDefType]?.selectedvalue;
                            Object.keys(this.cmpThis.uiState.assetsLiveProdAreasBranchesErrors).forEach((key:string) => {
                                this.cmpThis.uiState.assetsLiveProdAreasBranchesErrors[key] = false;
                            });
    
                            switch  (branchDefType) {
                                case ApiFormCnst_BranchProdDefType.ByNameConv: {
                                    let selectedValues = frmValueBranches[ApiFormCtrlId_Ten_Assets.LiveBranchDefByNameConv]?.selectedvalues;
                                    frmOk = Array.isArray(selectedValues) && selectedValues.length > 0;
                                    if (! frmOk) {
                                        this.cmpThis.uiState.assetsLiveProdAreasBranchesErrors.errorByTopicYouMustDefineSomeNames = true;
                                    }
    
                                    break;
                                }

                                default:
                                    frmOk = true;
                                    break;
                            }
                        }
                        break;

                default:
                    break;
            }

            return frmOk;
        },
        clickSubmit(navItemId:string, $event:any) { 
            let settingGroupName : string | undefined;
            let settingNvpName : string | undefined;
            let frmOk = this.clickCheckvalue(navItemId, $event);
            if (frmOk) 
            {
                let frmValue : any;
                switch (navItemId)
                {
                    case this.cmpThis.navItemAuditChecks:
                        frmValue = this.cmpThis.model.formGroupAuditChecks.value;
                        settingGroupName = OrbiSettingGroupName.GithubAuditChecks;
                        settingNvpName = OrbiSettingNvpName.GithubAuditChecks_base;
                        console.log(frmValue);
                        break;

                    case this.cmpThis.navItemLiveProductionAssetsRepos:
                        // ### this is not picking up values
                        frmValue = this.cmpThis.model.formAssetsAffectingLiveProdRepos.value;
                        settingGroupName = OrbiSettingGroupName.GithubLiveProductionAssetsRepos;
                        settingNvpName = OrbiSettingNvpName.GithubLiveProductionAssets_base;
                        console.log(frmValue);
                        break;

                    case this.cmpThis.navItemLiveProductionAssetsBranches:
                        // ### this is not picking up values
                        frmValue = this.cmpThis.model.formAssetsAffectingLiveProdBranches.value;
                        settingGroupName = OrbiSettingGroupName.GithubLiveProductionAssetsBranches;
                        settingNvpName = OrbiSettingNvpName.GithubLiveProductionAssets_base;
                        console.log(frmValue);
                        break;
                        
                    default: 
                        OanDebugutils.debuggerWrapper(".?.");
                }

                if (settingGroupName && settingNvpName && frmValue)
                {
                    let orgName = this.cmpThis.svcProfile.getActiveOrgName();
                    this.cmpThis.svcSettings.apiGithubSettingsUpdate(orgName, settingGroupName, settingNvpName, frmValue).subscribe(
                        (val: any) => {
                            console.log("settings saved ok");      
                        }, (err:any) => {
                            OanDebugutils.debuggerWrapper(".?.");
                            alert(`The settings could not be saved; ${err}`);
                        }
                    );      
                }
            }
        },

        clickCancel(navItemId:string, $event:any) {
        },
    }

    public tabState : TenantsettingsTabState; 
    private tabStateRefresh() : void { 
    }
    public tabStateOnSync($event : any) {            
    }

    public jsonDebugStringify(args : any[]) {
        let x = args.length;
        return x;
    }

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private router: ActivatedRoute,
        public fb: UntypedFormBuilder,
        private svcDebug: OrbidebugService,
        public svcSettings : OrbisettingsService, 
        public svcAppState : OrbiAppStateService, 
        private modalService  : NgbModal,
        public svcProfile : OrbiprofileService,
        public svcStorage : OrbiBrowserStorageService,
        private svcStartuprequestsservice : Orbistartuprequestsservice,
        private svcAuditResultsMgr : OrbiAuditResultsManagerService
    ) 
    {               
        let a = OrbiSettingGroupName.GithubLiveProductionAssetsRepos;
        let b = OrbiSettingGroupName.GithubLiveProductionAssetsBranches;

        this.tabState = new TenantsettingsTabState(this.svcAuditResultsMgr, this.tabStateNavItems, this.svcDebug, this);

        // observers
        this.githubsettingsGet$ = this.svcSettings.githubsettingsGet$;
        this.githubsettingsPost$ = this.svcSettings.githubsettingsPost$;

        // init uistate
        this.uiState.init(this);
        this.model.init(this);
        this.uiEvents.init(this);
    }

    ngOnInit(): void {       
        let fnGetVal = (val: ApiMwgithubResult) => {
            this.model.setFormValuesFromSettings(val.data);
        };
        
        let fnGetErr = (err:any) => {
            OanDebugutils.debuggerWrapper(".?.");
            console.error("settings$:" + JSON.stringify(err));
        };
     
        let fnPostVal = (val: ApiMwgithubResult) => {
            // TBD - this is not well organized as we have also getTenantSettings
            if (val && 200 == val.httpStatus) {  
                let inVars = {
                    repos : this.model.repos,
                    repoTopics: this.model.repoTopics
                }
            } else {
                OanDebugutils.debuggerWrapper(".?.");
                console.error("githubsettings$: resposne=" + JSON.stringify(val));    
            }
        };
        let fnPostErr = (err:any) => {
            OanDebugutils.debuggerWrapper(".?.");
            console.error("settings$:" + JSON.stringify(err));
        };

        this.githubsettingsPost$.subscribe(fnPostVal, fnPostErr); 

        this.svcStartuprequestsservice.startupRequestsL3State$.pipe(
            switchMap(async (startupState:OrbistartuprequestsL3Interface) => {
                if (true == startupState.isInit) {
                    this.model.repoTopics = startupState.repoTopics;
                    this.model.repos = startupState.reposummaryList.map((x:any) => x.repoName);

                    // load the settings after we have the org topography
                    this.githubsettingsGet$.subscribe(fnGetVal, fnGetErr);   
                }
                
                return startupState;
            })).subscribe();

        let tabContentAreaHeaders : OangNavTabStateActiveContentAreaMetaMap = {
            [OrbiSettingGroupName.GithubOrg] : {
                title:"Content Area Org Profile " + this.tabState.activeTab,
                desc:"This section allows to configure contact information and other organizational information.",
                sectionHdrs: [{ targetelement:this.livedefsrepo, label:"Some link example" },
                { targetelement:this.livedefsbranches, label:"Some other link example" }]
            },
            [OrbiSettingGroupName.GithubLiveProductionAssetsRepos] : {
                title:"Settings for assets that are live or otherwise affect production",
                desc:"This section allows to identify which assets is either in production, or affects production. The tool can provide more effective feedback when it can tell the difference between a live production repo and a repo which is an investigation or no longer used. Similarly, branches can be live, or they can be developer and by configuring the naming convention the tool can make the difference and provide better feedback.  Changes to these settings can take around 4 hours to be fully reflected in the reports.",
                sectionHdrs: [{ targetelement:this.livedefsrepo, label:"Repositories" } ]
            },
            [OrbiSettingGroupName.GithubLiveProductionAssetsBranches] : {
                title:"Settings for assets that are live or otherwise affect production",
                desc:"This section allows to identify which assets is either in production, or affects production. The tool can provide more effective feedback when it can tell the difference between a live production repo and a repo which is an investigation or no longer used. Similarly, branches can be live, or they can be developer and by configuring the naming convention the tool can make the difference and provide better feedback.  Changes to these settings can take around 4 hours to be fully reflected in the reports. ",
                sectionHdrs: [ { targetelement:this.livedefsbranches, label:"Branches" }]
            },
            [OrbiSettingGroupName.GithubAuditChecks] : {
                title:"Enabled analyzer check settings",
                desc:"This section allows to enables/disables the tests & verifications against the baseline configuration of different objects in github.",
                sectionHdrs: [
                    { targetelement:this.auditchecksrepos, label:"Repositories" },
                    { targetelement:this.auditchecksbranches, label:"Branches" }]
                }
        };
        this.tabState.setContentAreaHeaders(tabContentAreaHeaders);

        let mapKeys = Object.keys(tabContentAreaHeaders);
        this.svcAppState.registerComponent(OrbiAppComponentRoutes.TenantSettings as keyof typeof OrbiAppComponentRoutes, mapKeys, OrbiSettingGroupName.GithubOrg);
    
        this.router.url.subscribe(() => {
            let tabId = this.router.snapshot?.params?.tabId;
            if (tabId && tabContentAreaHeaders[tabId]) {
                this.tabState.setActiveTab(tabId);
            } else {
                this.tabState.setActiveTab(this.navItemOrgProfile);
            }
        });
    }

    ngAfterViewInit(): void {
        /*
        let nextActiveTab = this.svcAppState.getNextActiveTab(OrbiAppComponentRoutes.TenantSettings as keyof typeof OrbiAppComponentRoutes);
        if (nextActiveTab) {
            this.tabState.setActiveTab(nextActiveTab);    
        } else {
            this.tabState.setActiveTab(this.navItemOrgProfile);    
        } */       
    }
}

